import { QRCodeSVG } from 'qrcode.react';
import { useRef } from 'react';
import { GoDownload } from 'react-icons/go';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../../shared/hooks/useNotification';

type Props = {
  url: string;
};

export default function DownloadQRCode({ url }: Props) {
  const qrCodeSize = 256;
  const { showInfoToast } = useNotification();
  const qrCodeRef = useRef<SVGSVGElement | null>(null);

  const downloadQRCode = () => {
    if (qrCodeRef.current) {
      const svg = qrCodeRef.current;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = qrCodeSize;
      canvas.height = qrCodeSize;

      const svgData = new XMLSerializer().serializeToString(svg);
      const img = new Image();
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;

      img.onload = () => {
        if (ctx) {
          ctx.drawImage(img, 0, 0, qrCodeSize, qrCodeSize);
          const pngUrl = canvas.toDataURL('image/png');
          const downloadLink = document.createElement('a');
          downloadLink.href = pngUrl;
          downloadLink.download = 'qr-code.png';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      };
    }
  };

  return (
    <FlexContainer
      justifyContent="space-between"
      alignItems="center"
      gap="15px"
    >
      <FlexContainer
        stack
        gap="10px"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <p>Download this QR code to share on your website or social media.</p>

        <Button
          rounded
          onClick={downloadQRCode}
          variant="secondary"
          size="small"
        >
          <FlexContainer gap="5px">
            <GoDownload /> Download
          </FlexContainer>
        </Button>
      </FlexContainer>

      <QRCodeSVG
        value={url}
        ref={qrCodeRef}
        size={60}
        bgColor="#000"
        fgColor="#EBEEF9"
      />
    </FlexContainer>
  );
}
