import { InviteTypeEnum } from '../..';

/* eslint no-console: "warn" */

const getPageAttributes = (source: InviteTypeEnum | null) => {
  switch (source) {
    case InviteTypeEnum.MAILCHIMP:
      return {
        breadcrumb: 'Contacts',
        title: 'Select MailChimp contacts',
        drawerTitle: 'MailChimp contacts',
        message:
          "Start by selecting the contacts you'd like to send review requests to",
        buttonLabel: 'Select an audience',
      };

    case InviteTypeEnum.INTERCOM:
      return {
        breadcrumb: 'Contacts',
        title: 'Select Intercom contacts',
        drawerTitle: 'Intercom contacts',
        message:
          "Start by selecting the contacts you'd like to send review requests to",
        buttonLabel: 'Select contacts',
      };

    case InviteTypeEnum.KLAVIYO:
      return {
        breadcrumb: 'Contacts',
        title: 'Select Klaviyo contacts',
        drawerTitle: 'Klaviyo contacts',
        message:
          "Start by selecting the contacts you'd like to send review requests to",
        buttonLabel: 'Select contacts',
      };

    case InviteTypeEnum.ACTIVECAMPAIGN:
      return {
        breadcrumb: 'Contacts',
        title: 'Select ActiveCampaign contacts',
        drawerTitle: 'ActiveCampaign contacts',
        message:
          "Start by selecting the contacts you'd like to send review requests to",
        buttonLabel: 'Select contacts',
      };

    case InviteTypeEnum.SENDGRID:
      return {
        breadcrumb: 'Contacts',
        title: 'Select SendGrid contacts',
        drawerTitle: 'SendGrid contacts',
        message:
          "Start by selecting the contacts you'd like to send review requests to",
        buttonLabel: 'Select contacts',
      };

    case InviteTypeEnum.ORDERS:
      return {
        breadcrumb: 'Orders',
        title: 'Select orders',
        drawerTitle: 'Previous orders',
        message:
          "Start by selecting the orders you'd like to request reviews for",
        buttonLabel: 'Select orders',
      };

    default:
      return {
        breadcrumb: 'Contact',
        title: 'Invite a single contact',
      };
  }
};

export default getPageAttributes;
