import { useEffect } from 'react';
import hexToRgba from '../../../../../../shared/utils/hexToRgba';
import { useAppSelector } from '../../../../../../store';
import { settingSelector } from '../../../../../widgetSettings/redux/widgetSettings.slice';

export default function useUpdateWidgetPreview() {
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { carouselMarqueeWidget } = siteWidgetSettings!;

  useEffect(() => {
    const reviewCards = document.getElementsByClassName('review-card');
    Array.from(reviewCards).forEach((card) => {
      const backgroundColor = hexToRgba(
        carouselMarqueeWidget.reviewCardColor,
        carouselMarqueeWidget.reviewCardColorOpacity / 100
      );

      const borderStyle = carouselMarqueeWidget.showBorder
        ? `${carouselMarqueeWidget.borderWidth ?? 0}px ${
            carouselMarqueeWidget.borderColor
          } solid`
        : 'none';

      // @ts-ignore
      Object.assign(card.style, {
        borderRadius: carouselMarqueeWidget.borderRadius,
        background: backgroundColor,
        border: borderStyle,
      });
    });
  }, [
    carouselMarqueeWidget.borderRadius,
    carouselMarqueeWidget.reviewCardColor,
    carouselMarqueeWidget.reviewCardColorOpacity,
    carouselMarqueeWidget.showBorder,
    carouselMarqueeWidget.borderWidth,
    carouselMarqueeWidget.borderColor,
  ]);

  useEffect(() => {
    const fullRatingStars = document.getElementsByClassName('full-rating');
    Array.from(fullRatingStars).forEach((star) => {
      star.setAttribute('fill', carouselMarqueeWidget.starColor);
    });
  }, [carouselMarqueeWidget.starColor]);

  useEffect(() => {
    const reviewerNames = document.getElementsByClassName('reviewer-name');
    Array.from(reviewerNames).forEach((name) => {
      // @ts-ignore
      name.style.color = carouselMarqueeWidget.linkColor;
    });
  }, [carouselMarqueeWidget.linkColor]);

  useEffect(() => {
    const reviewContents = document.getElementsByClassName('review-content');
    Array.from(reviewContents).forEach((content) => {
      // @ts-ignore
      content.style.color = carouselMarqueeWidget.textColor;
    });
  }, [carouselMarqueeWidget.textColor]);

  useEffect(() => {
    const reviewDates = document.getElementsByClassName('review-date');
    Array.from(reviewDates).forEach((date) => {
      // @ts-ignore
      date.style.color = carouselMarqueeWidget.dateColor;
    });
  }, [carouselMarqueeWidget.dateColor]);
}
