import { debounce } from 'lodash';
import { useRef } from 'react';
import { Divider, Input, InputPicker } from 'rsuite';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../../../../../store';
import FormRow from '../../../../../../widgetSettings/components/form-row';
import {
  ModalReviewWidgetType,
  siteWidgetSettingsSelector,
  updateSiteWidgetSettings,
  WidgetPosition,
} from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import useDispatchUpdateSiteWidgetSettings from '../../../../../../widgetSettings/shared/hooks/useDispatchUpdateSiteWidgetSettings';

const Wrapper = styled.div`
  padding-top: 10px;
  .title {
    font-weight: bold;
    margin-bottom: 10px;
  }
`;

const Label = styled.span`
  display: block;
  margin-bottom: 5px;
`;

const Code = styled.code`
  font-size: 13px;
  color: crimson;
`;

const buttonTypes = [
  {
    label: 'Default',
    value: ModalReviewWidgetType.DEFAULT,
  },
  {
    label: 'Advanced (With rating summary)',
    value: ModalReviewWidgetType.ADVANCED,
  },
  {
    label: 'Display Platform Logo (Google, Yelp, etc.)',
    value: ModalReviewWidgetType.WITH_REVIEW_SOURCE,
  },
];

const reviewSources = [
  {
    label: 'Google',
    value: 'google',
  },
  {
    label: 'Tripadvisor',
    value: 'tripadvisor',
  },
  {
    label: 'Yelp',
    value: 'yelp',
  },
  {
    label: 'Airbnb',
    value: 'airbnb',
  },
  {
    label: 'Etsy',
    value: 'etsy',
  },
  {
    label: 'Tourradar',
    value: 'tourradar',
  },
  {
    label: 'Product Hunt',
    value: 'product-hunt',
  },
  {
    label: 'Trustpilot',
    value: 'trustpilot',
  },
  {
    label: 'Apple Store',
    value: 'apple-store',
  },
];

const getSelectedWidgetPosition = (selected: WidgetPosition) => {
  switch (selected) {
    case WidgetPosition.RIGHT:
      return 'Right';
    case WidgetPosition.BOTTOM_RIGHT:
      return 'Bottom right';
    case WidgetPosition.LEFT:
      return 'Left';
    case WidgetPosition.BOTTOM_LEFT:
      return 'Bottom left';
    default:
      return null;
  }
};

export default function ButtonTemplates() {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(siteWidgetSettingsSelector);
  const { modalWidget, translation } = settings ?? {};
  const { desktopSettings } = modalWidget ?? {};
  const { variant, reviewSource } = desktopSettings ?? {};

  const updateTranslation = useRef(
    debounce((value: string, payload: object) => {
      dispatch(updateSiteWidgetSettings(payload));
    }, 300)
  ).current;

  const { dispatchUpdateSiteWidgetSettings } =
    useDispatchUpdateSiteWidgetSettings();

  return (
    <Wrapper>
      <FormRow>
        <Label>Choose a template</Label>
        <InputPicker
          cleanable={false}
          data={buttonTypes}
          style={{ width: '100%' }}
          value={variant}
          onChange={(value) => {
            if (value) {
              dispatchUpdateSiteWidgetSettings(
                'desktopSettings.variant',
                value,
                'modal'
              );
            }
          }}
        />
      </FormRow>

      {variant === ModalReviewWidgetType.WITH_REVIEW_SOURCE && (
        <FormRow>
          <Label>Select review platform</Label>
          <InputPicker
            cleanable={false}
            data={reviewSources}
            style={{ width: '100%' }}
            value={reviewSource}
            onChange={(value) => {
              if (value) {
                dispatchUpdateSiteWidgetSettings(
                  'desktopSettings.reviewSource',
                  value,
                  'modal'
                );
              }
            }}
          />
        </FormRow>
      )}

      <Divider />

      <FormRow>
        <Label>Select position</Label>

        <div style={{ width: 300, paddingLeft: 10 }}>
          <InputPicker
            cleanable={false}
            data={[
              { label: 'Left', value: WidgetPosition.LEFT },
              { label: 'Right', value: WidgetPosition.RIGHT },
              { label: 'Bottom Left', value: WidgetPosition.BOTTOM_LEFT },
              { label: 'Bottom', value: WidgetPosition.BOTTOM },
              {
                label: 'Bottom Right',
                value: WidgetPosition.BOTTOM_RIGHT,
              },
            ]}
            value={settings?.modalWidget.desktopSettings.position}
            onChange={(value) => {
              if (value) {
                dispatchUpdateSiteWidgetSettings(
                  'desktopSettings.position',
                  value,
                  'modal'
                );
              }
            }}
            style={{ width: '100%' }}
          />
        </div>
      </FormRow>

      {/* TODO: When variant===ModalReviewWidgetType.DEFAULT, show the */}
      {/* translation input for the REVIEW text on the button */}

      {(ModalReviewWidgetType.ADVANCED,
      ModalReviewWidgetType.WITH_REVIEW_SOURCE).includes(variant!) && (
        <FormRow stack alignItems="flex-start">
          <Label>
            Based on <Code>&#123;totalReview&#125;</Code> verified reviews
          </Label>
          <Input
            placeholder="Based on &#123;totalReview&#125; verified reviews"
            defaultValue={translation?.widgetTitle}
            onChange={(value) =>
              updateTranslation(value, {
                ...settings,
                translation: { ...settings?.translation, widgetTitle: value },
              })
            }
          />
        </FormRow>
      )}
    </Wrapper>
  );
}
