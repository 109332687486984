import { unwrapResult } from '@reduxjs/toolkit';
import { Controller, useForm } from 'react-hook-form';
import { Input, SelectPicker } from 'rsuite';
import styled, { css } from 'styled-components';
import Button from '../../../../../shared/components/Button';
import StyledText from '../../../../../shared/components/styled-text';
import {
  AppleConnectFormData,
  Loading,
} from '../../../../../shared/types/review.types';
import capitalize from '../../../../../shared/utils/capitalize';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getAppleStores } from '../../../../socialReviews/redux/actions';
import {
  initAppleStoresSearch,
  socialReviewsSelector,
} from '../../../../socialReviews/redux/social-reviews.slice';
import appleLanguagesJson from '../../../../socialReviews/utils/apple-languages.json';
import appleRegionsJson from '../../../../socialReviews/utils/apple-regions.json';

const FormContainer = styled.form`
  padding-top: 20px;
`;

const StyledInput = styled(Input)<{ error: boolean }>`
  background: #fff;
  margin-bottom: 20px;
  border-color: #cdcdcd;
  border-radius: 20px;
  margin-top: 5px;
  outline: none !important;
  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;

const StyledLabel = styled(StyledText)`
  font-size: 14px;
  color: #7b7b7b;
`;

const StyledPicker = styled(SelectPicker)`
  margin-bottom: 20px;
  border-color: #cdcdcd;
  margin-top: 5px;
  outline: none !important;

  .rs-picker-toggle {
    border-radius: 20px !important;
  }
`;

const SearchButton = styled(Button)`
  background-color: #000;
  color: #fff;
  border-color: #000;
  width: 100%;
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:disabled {
    background-color: #bbbbbb;
    color: #000;
    border-color: #bbbbbb;
  }
`;

const appleRegions = Object.entries(appleRegionsJson).map(([key, value]) => ({
  label: capitalize(value),
  value: key,
}));

const appleLanguages = Object.entries(appleLanguagesJson).map(
  ([key, value]) => ({
    label: capitalize(value.language),
    value: key,
  })
);

type Props = {
  search: string;
  lang: string;
  country: string;
  onNext: (search: string, lang: string, country: string) => void;
};

export default function Form({ search, lang, country, onNext }: Props) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AppleConnectFormData>({
    defaultValues: {
      search: search ?? '',
      country: country ?? 'us',
      lang: lang ?? 'en-us',
    },
  });
  const dispatch = useAppDispatch();

  const onSubmit = handleSubmit((data) => {
    onNext(data.search, data.lang, data.country);
    dispatch(initAppleStoresSearch());
    dispatch(getAppleStores(data))
      .then(unwrapResult)
      .catch(() => {});
  });

  const { loadingGoogleBusinesses } = useAppSelector(socialReviewsSelector);

  return (
    <>
      <FormContainer onSubmit={onSubmit}>
        <StyledLabel>Business name and city (as shown on Google)</StyledLabel>

        <Controller
          name="search"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <StyledInput
              error={errors.search}
              placeholder="E.g. Netflix"
              {...field}
            />
          )}
        />

        <StyledLabel>In what country are your Apple apps? </StyledLabel>
        <Controller
          name="country"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <StyledPicker
              block
              cleanable={false}
              data={appleRegions}
              {...field}
            />
          )}
        />

        <StyledLabel>In what language are your Apple apps? </StyledLabel>
        <Controller
          name="lang"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <StyledPicker
              block
              cleanable={false}
              data={appleLanguages}
              {...field}
            />
          )}
        />

        <SearchButton
          type="submit"
          disabled={loadingGoogleBusinesses === Loading.PENDING}
        >
          {loadingGoogleBusinesses === Loading.PENDING ? (
            <b>Please wait...</b>
          ) : (
            <b>Search</b>
          )}
        </SearchButton>
      </FormContainer>
    </>
  );
}
