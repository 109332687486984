import { Placeholder } from 'rsuite';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

export default function PlaceholderElement() {
  return (
    <Wrapper>
      <Placeholder.Paragraph />
      <Placeholder.Paragraph />
      <Placeholder.Paragraph />
      <Placeholder.Paragraph />
      <Placeholder.Paragraph />
      <Placeholder.Paragraph />
      <Placeholder.Paragraph />
      <Placeholder.Paragraph />
    </Wrapper>
  );
}
