import { useState } from 'react';
import { Tabs } from 'rsuite';
import styled from 'styled-components';
import BackgroundSettings from './BackgroundSettings';
import BorderSettings from './BorderSettings';

const SettingsSection = styled.div<{ $isOpen: boolean }>`
  background-color: #fff;
  border-radius: 5px;
  width: 100%;

  .content {
    padding-top: 20px;
  }
`;

const StyledTabs = styled(Tabs)`
  .rs-nav-pills {
    width: 100%;
  }

  .rs-nav-item {
    width: 50%;
    color: #000;
    font-weight: 600;
  }
`;

export default function DesignSettings() {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <SettingsSection $isOpen={isOpen}>
      <div className="content">
        <StyledTabs defaultActiveKey="1" appearance="subtle">
          <Tabs.Tab eventKey="1" title="Background">
            <BackgroundSettings />
          </Tabs.Tab>

          <Tabs.Tab eventKey="2" title="Border">
            <BorderSettings />
          </Tabs.Tab>
        </StyledTabs>
      </div>
    </SettingsSection>
  );
}
