import { useTranslation } from 'react-i18next';
import { Button, List, Radio } from 'rsuite';
import styled, { css } from 'styled-components';
import StyledText from '../../../../shared/components/styled-text';
import { TransformedProduct } from '../../types';

const ProductImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
`;
const ProductName = styled(StyledText)`
  line-height: 19px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 370px;
`;
const PriceText = styled(StyledText)`
  line-height: 19px;
  text-align: left;
  font-size: 13px;
  color: #676767;
  margin-top: 5px;
`;
const StyledButton = styled(Button)`
  display: none;
`;
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 460px;
`;
const TextWrapper = styled.div``;

const StyledList = styled(List)`
  cursor: pointer;
  border: 0;
  box-shadow: none;
`;

const StyledListItem = styled(List.Item)<{ disabled?: boolean }>`
  display: flex;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  &:hover {
    button {
      display: block;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.7;
    `}
`;

interface Props {
  name: string;
  imageUrl: string;
  price: string;
  onSelect: () => void;
  disabled?: boolean;
  productId: string;
  selectedProduct: TransformedProduct | null;
}

export const ProductItem = ({
  disabled,
  name,
  imageUrl,
  price,
  onSelect,
  productId,
  selectedProduct,
}: Props) => {
  const { t } = useTranslation('common');

  return (
    <StyledList disabled={disabled} hover>
      <StyledListItem onClick={onSelect}>
        <LeftWrapper>
          <Radio checked={selectedProduct?.id === productId} />
          <ProductImage src={imageUrl} />
          <TextWrapper>
            <ProductName>{name}</ProductName>
            <PriceText>{price}</PriceText>
          </TextWrapper>
        </LeftWrapper>
      </StyledListItem>
    </StyledList>
  );
};
