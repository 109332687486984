import { createSelector, createSlice } from '@reduxjs/toolkit';
import { EmailStatus } from '../../../shared/types/email.types';
import { Loading, PaginatedResponse } from '../../../shared/types/review.types';
import getStartAndCurrentDateOfMonth from '../../../shared/utils/getStartAndCurrentDateOfMonth';
import { RootState } from '../../../store';
import { Review } from '../../manage-reviews/redux/reviews.slice';
import { sendSingleReviewRequest } from '../../share-form/redux /action';
import { fetchEmails, getStats } from './action';

/* eslint no-console: "warn" */

export enum EmailSendingMode {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
}

export type ContactItem = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  image: string;
  price: string;
  productId: string;
  productName: string;
  createdDate: string;
  orderNumber: string;
  productImage: string;
};

export type Email = {
  _id: string;
  siteId: string;
  orderId: string;
  productId: string | null;
  subject: string;
  message: string;
  status: EmailStatus;
  mode: EmailSendingMode;
  contactName: string;
  contactEmail: string;
  replyToEmail: string;
  replyFromName: string;
  sendWithIntegration: {
    id: string;
    name: string;
  };
  emailSendCount: number;
  emailOpenCount: number;
  emailClickCount: number;
  created_at: string;
  review: Review | null;
  deliveredDate: string;
  bouncedDate: string;
  openedDate: string;
  clickedDate: string;
  reviewedDate: string;
  scheduledDate: string;
  sentDate: string;
};

type ReviewState = {
  emails: {
    loading: Loading;
    data: PaginatedResponse<Email> | null;
  };
  stats: {
    loading: Loading;
    totalEmails: number;
    reviewedEmails: number;
    conversionRate: number;
    totalSentThisMonth: number;
  };
  filter: {
    dateRange: Date[] | null;
    productId: string | null;
    orderId: string | null;
    pageNumber: number;
    search: string;
    status: EmailStatus | null;
    searchedByDate: boolean;
  };
  selectedContacts: ContactItem[];
  sendingSingleReviewRquest: Loading;
};

const [startDate, endDate] = getStartAndCurrentDateOfMonth();

const initialState: ReviewState = {
  emails: {
    loading: Loading.INITIAL,
    data: null,
  },
  stats: {
    conversionRate: 0,
    reviewedEmails: 0,
    totalEmails: 0,
    loading: Loading.INITIAL,
    totalSentThisMonth: 0,
  },
  filter: {
    orderId: null,
    productId: null,
    pageNumber: 1,
    search: '',
    status: null,
    dateRange: [startDate, endDate],
    searchedByDate: false,
  },
  selectedContacts: [],
  sendingSingleReviewRquest: Loading.INITIAL,
};

const emails = createSlice({
  name: 'emails',
  initialState,
  reducers: {
    setFilter: (state, { payload }) => {
      if (payload.dateRange) {
        state.filter.dateRange = payload.dateRange;
      }

      state.filter.orderId = payload.orderId ?? '';
      state.filter.pageNumber = payload.pageNumber ?? 1;
      state.filter.productId = payload.productId ?? '';
      state.filter.search = payload.search ?? '';
      state.filter.status = payload.status ?? '';
      state.filter.searchedByDate = payload.searchedByDate;
    },
    setSelectedOrders: (state, { payload }) => {
      state.selectedContacts = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchEmails.pending,
      (
        state,
        {
          meta: {
            arg: { showPreloader },
          },
        }
      ) => {
        if (!!showPreloader) {
          state.emails.loading = Loading.PENDING;
        }
      }
    );
    builder.addCase(fetchEmails.fulfilled, (state, { payload }) => {
      state.emails.loading = Loading.SUCCESS;
      state.emails.data = payload.emails;
    });
    builder.addCase(fetchEmails.rejected, (state) => {
      state.emails.loading = Loading.ERROR;
    });

    builder.addCase(getStats.pending, (state) => {
      state.stats.loading = Loading.PENDING;
    });

    builder.addCase(getStats.rejected, (state) => {
      state.stats.loading = Loading.ERROR;
    });
    builder.addCase(getStats.fulfilled, (state, { payload }) => {
      state.stats.loading = Loading.SUCCESS;
      state.stats.totalEmails = payload.totalEmails;
      state.stats.reviewedEmails = payload.reviewedEmails;
      state.stats.conversionRate = payload.conversionRate;
      state.stats.totalSentThisMonth = payload.totalSentThisMonth;
    });

    builder.addCase(sendSingleReviewRequest.pending, (state) => {
      state.sendingSingleReviewRquest = Loading.PENDING;
    });
    builder.addCase(sendSingleReviewRequest.fulfilled, (state) => {
      state.sendingSingleReviewRquest = Loading.SUCCESS;
    });
    builder.addCase(sendSingleReviewRequest.rejected, (state) => {
      state.sendingSingleReviewRquest = Loading.ERROR;
    });
  },
});

export const emailsSelector = (state: RootState) => state.emails;
export const emailStatsSelector = createSelector(
  emailsSelector,
  (stats) => stats
);

export const { setFilter, setSelectedOrders } = emails.actions;

export default emails.reducer;
