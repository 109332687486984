import ColorPicker from '../../../../../../../shared/components/colorPicker';
import { useAppSelector } from '../../../../../../../store';
import FormRow from '../../../../../../widgetSettings/components/form-row';
import { settingSelector } from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import useDispatchUpdateSiteWidgetSettings from '../../../../../../widgetSettings/shared/hooks/useDispatchUpdateSiteWidgetSettings';

export default function ColorSettings() {
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { dispatchUpdateSiteWidgetSettings } =
    useDispatchUpdateSiteWidgetSettings();

  return (
    <>
      <FormRow justifyContent="space-between" gap="10px">
        <span>Reviewer name</span>
        <ColorPicker
          color={siteWidgetSettings?.carouselMarqueeWidget.linkColor!}
          changeColor={(color: string) => {
            dispatchUpdateSiteWidgetSettings('linkColor', color, 'marquee');
          }}
        />
      </FormRow>
      <FormRow justifyContent="space-between" gap="10px">
        <span>Star color</span>
        <ColorPicker
          color={siteWidgetSettings?.carouselMarqueeWidget.starColor!}
          changeColor={(color: string) => {
            dispatchUpdateSiteWidgetSettings('starColor', color, 'marquee');
          }}
        />
      </FormRow>
      <FormRow justifyContent="space-between" gap="10px">
        Text color
        <ColorPicker
          color={siteWidgetSettings?.carouselMarqueeWidget.textColor!}
          changeColor={(color: string) => {
            dispatchUpdateSiteWidgetSettings('textColor', color, 'marquee');
          }}
          placement="top"
        />
      </FormRow>
      <FormRow justifyContent="space-between" gap="10px">
        <span>Date color</span>
        <ColorPicker
          color={siteWidgetSettings?.carouselMarqueeWidget.dateColor!}
          changeColor={(color: string) => {
            dispatchUpdateSiteWidgetSettings('dateColor', color, 'marquee');
          }}
          placement="top"
        />
      </FormRow>
      {/* <FormRow justifyContent="space-between" gap="10px">
        <span>Button text color</span>
        <ColorPicker
          color={siteWidgetSettings?.carouselMarqueeWidget.buttonTextColor!}
          changeColor={(color: string) => {
            dispatchUpdateSiteWidgetSettings(
              'buttonTextColor',
              color,
              'marquee'
            );
          }}
        />
      </FormRow>
      <FormRow justifyContent="space-between" gap="10px">
        <span>Button color</span>
        <ColorPicker
          color={
            siteWidgetSettings?.carouselMarqueeWidget.buttonBackgroundColor!
          }
          changeColor={(color: string) => {
            dispatchUpdateSiteWidgetSettings(
              'buttonBackgroundColor',
              color,
              'marquee'
            );
          }}
        />
      </FormRow> */}
    </>
  );
}
