import { batch } from 'react-redux';
import { WidgetType } from '../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  BrandingSettings,
  CarouselWidgetSettings,
  ModalReviewsOtherSettings,
  ProductWidgetSettings,
  SiteWidgetSettings,
  setWidgetSettingsUpdated,
  settingSelector,
  updateSiteWidgetSettings,
} from '../../redux/widgetSettings.slice';

type SettingsKeys =
  | keyof SiteWidgetSettings
  | keyof CarouselWidgetSettings
  | keyof BrandingSettings
  | keyof ModalReviewsOtherSettings
  | keyof ProductWidgetSettings
  | 'selectedReviewType';

const BrandingSettingsKey = [
  'rtl',
  'thankYouImage',
  'showFullName',
  'fontFamily',
  'dateFormat',
  'autoPublishEnabled',
  'autoPublishRating',
  'useMyWebsiteFontFamily',
  'displayLocation',
];

const ModalReviewsOtherSettingsKey = [
  'isSticky',
  'showReviewDate',
  'hideSourceIcon',
  'hideHelpfulCount',
  'backgroundColor',
  'borderColor',
  'borderWidth',
  'reviewCardColor',
  'linkColor',
  'starColor',
  'dateColor',
  'textColor',
  'showBusinessReviews',
  'showProductReviews',
  'visible',
  'desktopSettings.visible',
  'desktopSettings.variant',
  'desktopSettings.position',
  'desktopSettings.reviewSource',
  'mobileSettings.visible',
  'mobileSettings.variant',
  'mobileSettings.position',
  'mobileSettings.reviewSource',
  'acceptReviews',
  'buttonBackgroundColor',
  'buttonTextColor',
  'selectedReviewType',
];

const EmbeddedWidgetsKeys = [
  'autoplay',
  'autoPlaySpeed',
  'pauseOnHover',
  'variant',
  'borderColor',
  'showBorder',
  'borderColor',
  'borderWidth',
  'borderRadius',
  'shadowType',
  'shadowSize',
  'shadowColor',
  'backgroundColorType',
  'backgroundColor',
  'backgroundColorGradient',
  'reviewCardColor',
  'textColor',
  'dateColor',
  'linkColor',
  'starColor',
  'buttonTextColor',
  'buttonBackgroundColor',
  'hideSourceIcon',
  'showOverallRating',
  'acceptReviews',
  'mixWithProductReviews',
  'showReviewDate',
  'reviewCardBackgroundType',
  'reviewCardColorOpacity',
];

const ProductWidgetsKeys = [
  'autoplay',
  'variant',
  'borderColor',
  'showBorder',
  'borderColor',
  'borderWidth',
  'borderRadius',
  'shadowType',
  'shadowSize',
  'shadowColor',
  'backgroundColorType',
  'backgroundColor',
  'backgroundColorGradient',
  'reviewCardColor',
  'textColor',
  'dateColor',
  'linkColor',
  'starColor',
  'buttonTextColor',
  'buttonBackgroundColor',
  'showReviewDate',
  'reviewCardBackgroundType',
  'reviewsLimit',
  'hideHelpfulCount',
  'reviewCardColorOpacity',
];

const useDispatchUpdateSiteWidgetSettings = () => {
  const dispatch = useAppDispatch();
  const { siteWidgetSettings } = useAppSelector(settingSelector);

  const dispatchUpdateSiteWidgetSettings = (
    key: SettingsKeys,
    value: unknown,
    widgetType?: WidgetType
  ) => {
    let updates = { [key]: value };

    if (BrandingSettingsKey.includes(key)) {
      updates = {
        brandingSettings: {
          ...siteWidgetSettings?.brandingSettings,
          [key]: value,
        },
      };
    }

    if (EmbeddedWidgetsKeys.includes(key) && widgetType !== 'modal') {
      if (widgetType === 'masonry') {
        updates = {
          masonryWidget: {
            ...siteWidgetSettings?.masonryWidget,
            [key]: value,
          },
        };
      } else if (widgetType === 'grid') {
        updates = {
          gridWidget: {
            ...siteWidgetSettings?.gridWidget,
            [key]: value,
          },
        };
      } else if (widgetType === 'list') {
        updates = {
          listWidget: {
            ...siteWidgetSettings?.listWidget,
            [key]: value,
          },
        };
      } else if (widgetType === 'marquee') {
        updates = {
          carouselMarqueeWidget: {
            ...siteWidgetSettings?.carouselMarqueeWidget,
            [key]: value,
          },
        };
      } else if (widgetType === 'slider') {
        updates = {
          carouselSliderWidget: {
            ...siteWidgetSettings?.carouselSliderWidget,
            [key]: value,
          },
        };
      }
    } else {
      if (ModalReviewsOtherSettingsKey.includes(key)) {
        const [subKey, prop] = key.split('.');

        if (!key.includes('.')) {
          if (key === 'selectedReviewType') {
            if (value === 'business') {
              updates = {
                modalWidget: {
                  desktopSettings:
                    siteWidgetSettings?.modalWidget.desktopSettings,
                  mobileSettings:
                    siteWidgetSettings?.modalWidget.mobileSettings,
                  ...siteWidgetSettings?.modalWidget,
                  showBusinessReviews: true,
                  showProductReviews: false,
                },
              };
            } else if (value === 'product') {
              updates = {
                modalWidget: {
                  desktopSettings:
                    siteWidgetSettings?.modalWidget.desktopSettings,
                  mobileSettings:
                    siteWidgetSettings?.modalWidget.mobileSettings,
                  ...siteWidgetSettings?.modalWidget,
                  showBusinessReviews: false,
                  showProductReviews: true,
                },
              };
            } else {
              updates = {
                modalWidget: {
                  desktopSettings:
                    siteWidgetSettings?.modalWidget.desktopSettings,
                  mobileSettings:
                    siteWidgetSettings?.modalWidget.mobileSettings,
                  ...siteWidgetSettings?.modalWidget,
                  showBusinessReviews: true,
                  showProductReviews: true,
                },
              };
            }
          } else {
            updates = {
              modalWidget: {
                desktopSettings:
                  siteWidgetSettings?.modalWidget.desktopSettings,
                mobileSettings: siteWidgetSettings?.modalWidget.mobileSettings,
                ...siteWidgetSettings?.modalWidget,
                [key]: value,
              },
            };
          }
        } else if (subKey === 'desktopSettings') {
          updates = {
            modalWidget: {
              ...siteWidgetSettings?.modalWidget,
              mobileSettings: siteWidgetSettings?.modalWidget.mobileSettings,
              desktopSettings: {
                ...siteWidgetSettings?.modalWidget.desktopSettings,
                [prop]: value,
              },
            },
          };
        } else if (subKey === 'mobileSettings') {
          updates = {
            modalWidget: {
              ...siteWidgetSettings?.modalWidget,
              desktopSettings: siteWidgetSettings?.modalWidget.desktopSettings,
              mobileSettings: {
                ...siteWidgetSettings?.modalWidget.mobileSettings,
                [prop]: value,
              },
            },
          };
        }
      }
    }

    if (ProductWidgetsKeys.includes(key) && widgetType === 'product') {
      updates = {
        productWidget: {
          ...siteWidgetSettings?.productWidget,
          [key]: value,
        },
      };
    }

    batch(() => {
      dispatch(updateSiteWidgetSettings({ ...siteWidgetSettings, ...updates }));
      dispatch(setWidgetSettingsUpdated());
    });
  };

  return {
    dispatchUpdateSiteWidgetSettings,
  };
};

export default useDispatchUpdateSiteWidgetSettings;
