import styled from 'styled-components';
import { useAppSelector } from '../../../../../../../store';
import {
  EmbeddedWidgetSettings,
  settingSelector,
} from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import ReviewCard from './ReviewCard';
import reviews from './reviews';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding-top: 20px;
`;

const WidgetPreviewOuterWrapper = styled.div`
  width: fit-content;
  margin: auto;
`;

const WidgetPreviewInnerWrapper = styled.div<{
  $wallOflove: EmbeddedWidgetSettings;
  $borderWidth: number;
  $showBorder: boolean;
}>`
  padding: 0 25px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ReviewsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ReviewsInnerWrapper = styled.div<{
  $borderWidth: number;
  $showBorder: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(
    3,
    calc(
      310px +
        (
          ${({ $borderWidth, $showBorder }) =>
            $showBorder ? $borderWidth + 'px' : '0px'}
        )
    )
  );
  column-gap: 10px;
  row-gap: 10px;
  @media (max-width: 644px) {
    grid-template-columns: repeat(1, 100%);
  }

  @media (min-width: 645px) and (max-width: 1000px) {
    grid-template-columns: repeat(
      2,
      calc(
        50% - 10px -
          (
            ${({ $borderWidth, $showBorder }) =>
              $showBorder ? $borderWidth + 'px' : '0px'}
          )
      )
    );
    column-gap: 30px;
  }
`;

export default function WidgetPreview() {
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const {
    gridWidget,
    gridWidget: { borderWidth, showBorder },
    brandingSettings,
    translation,
  } = siteWidgetSettings!;

  return (
    <Wrapper>
      <WidgetPreviewOuterWrapper>
        <WidgetPreviewInnerWrapper
          $wallOflove={gridWidget}
          $borderWidth={borderWidth}
          $showBorder={showBorder}
        >
          <ReviewsWrapper>
            <ReviewsInnerWrapper
              $borderWidth={borderWidth}
              $showBorder={showBorder}
            >
              {reviews.map((review, index) => {
                return (
                  <ReviewCard
                    fluid
                    key={review._id}
                    review={review}
                    width="310px"
                    widgetSettings={gridWidget}
                    branding={brandingSettings}
                    translation={translation}
                  />
                );
              })}
            </ReviewsInnerWrapper>
          </ReviewsWrapper>
        </WidgetPreviewInnerWrapper>
      </WidgetPreviewOuterWrapper>
    </Wrapper>
  );
}
