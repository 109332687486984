import initials from 'initials';
import generateColorHsl from './generateColorHsl';

const getInitialAvatar = (fullName: string, len = 2) => {
  const avatar = initials(fullName).substring(0, len).toUpperCase();
  const backgroundColor = generateColorHsl(fullName);

  return { avatar, backgroundColor };
};

export default getInitialAvatar;
