import { unwrapResult } from '@reduxjs/toolkit';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';
import axios from 'axios';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaCheck, FaSpinner } from 'react-icons/fa';
import { RxCross2 } from 'react-icons/rx';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form, Input, InputGroup, Schema } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../shared/components/Button';
import ReviewsJetCrownLogo from '../../../shared/components/ReviewsJetCrownLogo';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../shared/hooks/useNotification';
import useQuery from '../../../shared/hooks/useQuery';
import { Loading } from '../../../shared/types/review.types';
import localStorage from '../../../shared/utils/localStorage';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { useAppDispatch, useAppSelector } from '../../../store';
import { validateRedemptionCode } from '../../userData/redux/action';
import { resetRedemptionCodeValidation } from '../../userData/redux/userData.slice';
import {
  AuthForm,
  AuthFormGroup,
  AuthIconWrapper,
  AuthLabel,
  AuthTextField,
  AuthTitle,
} from '../components';
import OtherPlatforms from './OtherPlatforms';

/* eslint no-console: "warn" */

const AppSumo = styled.img`
  height: 20px;
`;

const RedemptionCodeStatus = styled(FlexContainer)`
  margin-right: 10px;
`;

const FormWrapper = styled.div`
  width: 100%;
  padding: 20px 40px;
  max-width: 440px;
  max-height: 90%;
  overflow: auto;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  @media (max-width: 530px) {
    max-width: 100%;
    box-shadow: none;
    border: 0;
  }

  input {
    border-radius: 25px;
  }
`;
const LogosSeparator = styled.span`
  display: block;
  margin: 0 10px;
  font-size: 20px;
`;

const Intro = styled.p`
  margin-bottom: 30px;
  color: #737373;
  font-size: 15px;
`;

const StyledButton = styled(Button)`
  background-color: #000;
  color: #fff;
  border-radius: 25px;
  width: 100%;
  border-color: #000;
`;

const { StringType } = Schema.Types;

const model = Schema.Model({
  email: StringType()
    .isEmail('Please enter a valid email address.')
    .isRequired('This field is required.'),
  password: StringType().isRequired('This field is required.'),
});

const Register = () => {
  const query = useQuery();
  const { showErrorToast } = useNotification();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [captchaToken, setCaptchaToken] = useState('');
  const formRef = useRef<{ check: () => void }>(null);
  const [visible, setVisible] = useState(false);
  const { code } = useParams<{ code: string }>();
  const [redemptionCode, setRedemptionCode] = useState(code);
  const [creating, setCreating] = useState(Loading.INITIAL);

  const {
    validate: { loading: validating },
  } = useAppSelector((state) => state.userData);

  const [formValue, setFormValue] = useState({
    email: query.get('email') ?? '',
    password: '',
  });

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  const handleSubmit = useCallback(async () => {
    if (!formRef.current?.check() || validating === Loading.PENDING) {
      return;
    }

    let payload = {
      ...formValue,
      token: captchaToken,
    };

    if (redemptionCode && validating === Loading.SUCCESS) {
      // @ts-ignore
      payload = { ...payload, redemptionCode };
    }

    try {
      setCreating(Loading.PENDING);

      await axios.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/account/create`,
        payload
      );

      setCreating(Loading.SUCCESS);
      localStorage.set('REVIEWSJET_ACCOUNT_EMAIL', formValue.email);

      navigate(`/verify`);
    } catch (error) {
      setCreating(Loading.ERROR);

      // @ts-ignore
      let errorMessage = error.message;

      // @ts-ignore
      if (error.response) {
        // @ts-ignore
        errorMessage = error.response.data.errorMessage;
      }

      showErrorToast(errorMessage ?? 'Failed to create an account.');
    }
  }, [validating, redemptionCode, formValue]);

  const validate = async (value: string) => {
    if (!value) {
      return dispatch(resetRedemptionCodeValidation());
    }
    setRedemptionCode(value);
    dispatch(validateRedemptionCode(value))
      .then(unwrapResult)
      .catch(() => {});
  };

  const handleValidateRedemption = useRef(debounce(validate, 500)).current;

  useEffect(() => {
    if (code) {
      setRedemptionCode(code);
      validate(code);
    }
  }, [code]);

  return (
    <>
      <Helmet>
        <title>Sign Up to ReviewsJet | Manage Your Reviews</title>
        <meta
          name="description"
          content="Sign up to ReviewsJet to manage your product reviews, boost your sales, and grow your business. Access your dashboard to monitor and respond to customer feedback."
        />
      </Helmet>
      <FormWrapper>
        <FlexContainer
          justifyContent="flex-start"
          alignItems="center"
          gap="10px"
        >
          <a href={process.env.REACT_APP_REVIEWSJET_WEBSITE!}>
            <ReviewsJetCrownLogo />
          </a>
          {pathname.includes('appsumo') && (
            <>
              <LogosSeparator>|</LogosSeparator>
              <AppSumo alt="Appsumo" src="/images/appsumo.svg" />
            </>
          )}
        </FlexContainer>
        <AuthTitle marginBottom="10px">Welcome to ReviewsJet</AuthTitle>
        <Intro>Start collecting and displaying reviews in minutes.</Intro>

        <AuthForm
          fluid
          ref={formRef}
          onChange={setFormValue}
          formValue={formValue}
          model={model}
          nestedField={false}
        >
          <AuthFormGroup controlId="password">
            {/* <AuthLabel>Email</AuthLabel> */}
            <AuthTextField
              name="email"
              type="email"
              autoComplete="off"
              placeholder="Enter your email"
            />
          </AuthFormGroup>
          <div style={{ height: 20 }}></div>
          <AuthFormGroup controlId="password">
            <AuthIconWrapper onClick={toggleVisibility}>
              {visible ? <EyeIcon /> : <EyeSlashIcon />}
            </AuthIconWrapper>
            {/* <AuthLabel>Password</AuthLabel> */}
            <AuthTextField
              isPassword
              name="password"
              type={visible ? 'text' : 'password'}
              autoComplete="off"
              placeholder="Enter your password"
            />
          </AuthFormGroup>

          {pathname.includes('appsumo') && (
            <AuthFormGroup
              controlId="redemptionCode"
              className="redemption-code"
            >
              <AuthLabel>Redemption Code</AuthLabel>
              <InputGroup
                inside
                style={{
                  borderColor: ` ${
                    validating == Loading.ERROR
                      ? 'crimson'
                      : validating == Loading.SUCCESS
                      ? 'green'
                      : ''
                  }`,
                }}
              >
                <Input
                  type="text"
                  placeholder="Enter your redemption code if you have one."
                  defaultValue={redemptionCode}
                  onChange={handleValidateRedemption}
                />
                <RedemptionCodeStatus>
                  {validating === Loading.PENDING && (
                    <>
                      {/* @ts-ignore */}
                      <FaSpinner size="18px" pulse />
                    </>
                  )}
                  {validating == Loading.ERROR && (
                    <RxCross2 color="crimson" size={20} />
                  )}
                  {validating == Loading.SUCCESS && <FaCheck color="green" />}
                </RedemptionCodeStatus>
              </InputGroup>
            </AuthFormGroup>
          )}

          <div style={{ marginBottom: 20, marginTop: 10 }}></div>

          <Form.Group>
            <StyledButton
              type="submit"
              size="large"
              onClick={handleSubmit}
              disabled={creating === Loading.PENDING}
            >
              {creating === Loading.PENDING ? (
                <b>Please wait...</b>
              ) : (
                <FlexContainer gap="5px">
                  <b>Sign up</b>
                </FlexContainer>
              )}
            </StyledButton>
          </Form.Group>

          {!pathname.includes('appsumo') && (
            <FlexContainer
              justifyContent="flex-start"
              alignItems="center"
              gap="5px"
            >
              Already have an account?
              <Link to="/login">Sign in</Link>
            </FlexContainer>
          )}

          <div style={{ display: 'none' }}>
            <AuthTextField name="siteId" type="hidden" />
            <AuthTextField name="userId" type="hidden" />
            <AuthTextField name="platform" type="hidden" />
            <AuthTextField
              name="source"
              type="hidden"
              value={pathname.includes('appsumo') ? 'appsumo' : null}
            />
          </div>
        </AuthForm>

        <OtherPlatforms />
      </FormWrapper>
    </>
  );
};

export default Register;
