import { useContext } from 'react';
import { Button, Divider, Drawer, Steps } from 'rsuite';
import styled, { css } from 'styled-components';
import { ImportReviewsContext } from '..';
import { useNotification } from '../../../../../../shared/hooks/useNotification';
import { useAppDispatch } from '../../../../../../store';
import { Step } from '../../../../../csv-import';
import {
  OnboardingStep,
  setCurrentStep,
} from '../../../../redux/onboarding.slice';
import updateOnboarding from '../../../../services/updateOnboarding';
import MapFields from './MapFields';
import PreviewReviews from './PreviewReviews';

const CustomSteps = styled(Steps)`
  margin: 25px 0;
`;

const CustomStep = styled(Steps.Item)<{ $clickable?: boolean }>`
  ${({ $clickable }) =>
    $clickable
      ? css`
          cursor: pointer;
        `
      : ''}
`;
type Props = {
  open: boolean;
  step: Step;
  onClose: () => void;
  setStep: (step: Step) => void;
};

export default function ReviewCSVUpload({
  step,
  open,
  setStep,
  onClose,
}: Props) {
  const dispatch = useAppDispatch();
  const { showWarningToast, showSuccessToast } = useNotification();

  const context = useContext(ImportReviewsContext);

  const onPublishReviewsComplete = async () => {
    onClose();
    showSuccessToast(
      'Your reviews have been imported successfully! Moving to next step.'
    );

    setTimeout(async () => {
      dispatch(setCurrentStep(OnboardingStep.InstallWidget));

      await updateOnboarding({
        nextStep: OnboardingStep.InstallWidget,
      });
    }, 3000);
  };

  if (!context) {
    return null;
  }

  const { mappedFields } = context;

  return (
    <>
      <Drawer open={open} onClose={onClose} size="90%">
        <Drawer.Header>
          <Drawer.Title>Import from CSV</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={onClose} size="sm">
              Cancel
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body style={{ background: '#ECEFF3' }}>
          <CustomSteps small current={step}>
            <CustomStep
              key={Step.MAP_FIELDS}
              title="Map Fields"
              onClick={() => {
                if (step === Step.PREVIEW_REVIEWS) {
                  setStep(Step.MAP_FIELDS);
                }
              }}
              $clickable={step === Step.PREVIEW_REVIEWS}
            />
            <CustomStep title="Preview" key={Step.PREVIEW_REVIEWS} />
          </CustomSteps>

          <Divider />

          {step === Step.MAP_FIELDS && (
            <MapFields
              onComplete={() => {
                if (
                  !mappedFields.reviewer_name.value ||
                  !mappedFields.review_text.value ||
                  !mappedFields.rating
                ) {
                  return showWarningToast(
                    <>
                      To continue, please map <b>reviewer_name</b>,{' '}
                      <b>rating</b>, and <b>review_text</b>.
                    </>
                  );
                }
                setStep(Step.PREVIEW_REVIEWS);
              }}
            />
          )}
          {step === Step.PREVIEW_REVIEWS && (
            <PreviewReviews onComplete={onPublishReviewsComplete} />
          )}
        </Drawer.Body>
      </Drawer>
    </>
  );
}
