import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../shared/components/Button';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import { SubscriptionPlan } from '../../../shared/types/review.types';
import { getLimit } from '../../../shared/utils/usage-limits';
import { useAppDispatch, useAppSelector } from '../../../store';
import { toggleUpgradeModal } from '../../../store/app.slice';
import { accountSelector } from '../../userData/redux/userData.slice';

/* eslint no-console: "warn" */

const InnerWrapper = styled(FlexContainer)`
  width: 100%;
  margin-top: 10px;

  .left {
    max-width: 350px;
    flex: 1;

    .title {
      font-size: 20px;
      margin-top: 0;
      line-height: 30px;
      margin-bottom: 20px;
    }
    .desc {
      margin-bottom: 20px;
    }
    button {
      margin-bottom: 20px;
    }
  }

  .right {
    flex: 1;
    max-width: 210px;
  }

  a {
    text-underline-offset: 4px;
    text-decoration: underline !important;
  }
`;

const Wrapper = styled(FlexContainer)``;

export default function CreditLimitModal({ onClose }: { onClose: () => void }) {
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { plan } = selectedSite ?? {};
  const emailsCredit = getLimit(plan)?.reviewsRequests;

  return (
    <Wrapper
      stack
      justifyContent="flex-start"
      alignItems="flex-start"
      gap="20px"
    >
      <InnerWrapper justifyContent="flex-start" gap="20px">
        <div className="left">
          <h3 className="title">Your've hit your limit!</h3>
          <p className="desc">
            Upgrade to get more credits and keep collecting feedback!
          </p>

          <p style={{ marginBottom: 20 }}>
            <b>Current plan: {emailsCredit} requests per month</b>
          </p>

          <Button
            rounded
            onClick={() => {
              onClose();

              dispatch(toggleUpgradeModal(SubscriptionPlan.ADVANCED));
            }}
          >
            Upgrade plan
          </Button>

          <p>
            Click <Link to="/settings?tab=account">here</Link> to manage your
            subscription.
          </p>
        </div>
        <div>
          <img src="/images/balloons.svg" alt="" style={{ width: 250 }} />
        </div>
      </InnerWrapper>

      <FlexContainer justifyContent="flex-end" style={{ width: '100%' }}>
        <Button rounded size="small" variant="tertiary" onClick={onClose}>
          Not now
        </Button>
      </FlexContainer>
    </Wrapper>
  );
}
