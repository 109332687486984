import { useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { FaCode } from 'react-icons/fa6';
import { Divider, Drawer, Button as RsButton, Tabs } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import AppModal from '../../../../../shared/components/modal';
import useModal from '../../../../../shared/components/modal/useModal';
import useInstallation, {
  Widget,
} from '../../../../../shared/hooks/useInstallation';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import capitalize from '../../../../../shared/utils/capitalize';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { AuthTitle } from '../../../../external/components';
import { ReviewsJetPlatform } from '../../../../product/types';
import GoDaddyEmbeddedWidget from '../../../../tutorials/components/godaddy/embedded-widget';
import OthersEmbeddedWidget from '../../../../tutorials/components/others/embedded-widget';
import PixpaEmbeddedWidget from '../../../../tutorials/components/pixpa/embedded-widget';
import ShopifyEmbeddedWidget from '../../../../tutorials/components/shopify/embedded-widget';
import SquarespaceEmbeddedWidget from '../../../../tutorials/components/squarespace/embedded-widget';
import WebflowEmbeddedWidget from '../../../../tutorials/components/webflow/embedded-widget';
import WixEmbeddedWidget from '../../../../tutorials/components/wix/embedded-widget';
import WordpressCarouselWidget from '../../../../tutorials/components/wordpress/carousel-widget';
import { accountSelector } from '../../../../userData/redux/userData.slice';
import { settingSelector } from '../../../../widgetSettings/redux/widgetSettings.slice';
import useDispatchUpdateSiteWidgetSettings from '../../../../widgetSettings/shared/hooks/useDispatchUpdateSiteWidgetSettings';
import HowToInstall from '../../../../widgetSettings/widgets/HowToInstall';
import SaveButton from '../SaveButton';
import { Templates } from '../Templates';
import ContentSettings from './components/ContentSettings';
import DesignSettings from './components/DesignSettings';
import WidgetPreview from './components/WidgetPreview/index';

/* eslint no-console: "warn" */

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-width: 550px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 7;
  padding: 20px;
  background-color: #f1f1f1;
`;

const BackBtn = styled(RsButton)`
  font-weight: bold;
`;

const TopContainer = styled(FlexContainer)`
  width: 100%;
  justify-content: space-between;
`;

const ActionsContainer = styled(FlexContainer)`
  width: 100%;
  gap: 10px;
`;

const BottomContainer = styled(FlexContainer)`
  width: 100%;
  padding-top: 30px;

  @media (max-width: 930px) {
    flex-direction: column;
  }
`;

const SettingsContainer = styled(FlexContainer)`
  min-width: 350px;
  max-width: 350px;
  height: 100%;
  flex: 1;
  @media (max-width: 930px) {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
`;

const PreviousWidgetContainer = styled.div`
  flex: 1;
  flex-grow: 1;
  min-width: 550px;
  height: calc(100vh - 100px);
  overflow: hidden;
  @media (max-width: 930px) {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
`;

const SettingsSection = styled.div`
  background-color: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  width: 100%;
`;

const SaveBtn = styled(Button)<{ $show: boolean }>`
  background-color: #000;
  color: #fff;
  border-color: #000;

  &:disabled {
    background-color: #909090;
    color: #fff;
    border-color: #909090;
  }
  /* visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')}; */
`;

const StyledTabs = styled(Tabs)`
  .rs-nav-pills {
    width: 100%;
  }

  .rs-nav-item {
    width: 50%;
    color: #000;
    font-weight: 600;
  }
`;

const StyledAuthTitle = styled(AuthTitle)`
  font-size: 20px;
`;

type Props = {
  onBack: () => void;
};

export default function Masonry({ onBack }: Props) {
  const dispatch = useAppDispatch();
  const {
    siteWidgetSettings,
    updateWidgetSettings: { saving },
    isWidgetSettingsUpdated,
  } = useAppSelector(settingSelector);
  const { masonryWidget: widgetSettings } = siteWidgetSettings ?? {};
  const { dispatchUpdateSiteWidgetSettings } =
    useDispatchUpdateSiteWidgetSettings();
  const { showSuccessToast } = useNotification();
  const { handleCloseModal, handleOpenModal, showModal } = useModal();
  const [open, setOpen] = useState<ReviewsJetPlatform | null>(null);
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { platform } = selectedSite ?? {};

  const { installationCode } = useInstallation(Widget.EMBEDDED);

  const embedCode = `${installationCode}
<reviewsjet-masonry id="${selectedSite?._id}" count="3" platform="others"></reviewsjet-masonry>`;

  return (
    <>
      <Wrapper>
        <TopContainer>
          <FlexContainer
            justifyContent="flex-start"
            gap="5px"
            style={{ width: '100%' }}
          >
            <BackBtn appearance="subtle" onClick={onBack}>
              <FlexContainer gap="5px">
                <FaArrowLeft />
                <span>Back</span>
              </FlexContainer>
            </BackBtn>
            <StyledAuthTitle marginBottom="0">Masonry Widget</StyledAuthTitle>
          </FlexContainer>

          <ActionsContainer justifyContent="flex-end">
            <Button
              variant="primary"
              rounded
              onClick={() => {
                if (platform === ReviewsJetPlatform.WIX) {
                  setOpen(ReviewsJetPlatform.WIX);
                } else if (platform === ReviewsJetPlatform.SHOPIFY) {
                  setOpen(ReviewsJetPlatform.SHOPIFY);
                } else {
                  handleOpenModal();
                }
              }}
            >
              <FlexContainer gap="5px">
                <FaCode />
                <b>Add to your website</b>
              </FlexContainer>
            </Button>
            <SaveButton />
          </ActionsContainer>
        </TopContainer>

        <BottomContainer
          justifyContent="flex-start"
          gap="20px"
          alignItems="flex-start"
        >
          <SettingsContainer stack gap="10px">
            <SettingsSection>
              <StyledTabs defaultActiveKey="1" appearance="pills">
                <Tabs.Tab eventKey="1" title="Templates">
                  <Templates widgetType="masonry" />
                </Tabs.Tab>
                <Tabs.Tab eventKey="2" title="Customize">
                  <DesignSettings />
                  <Divider />
                  <ContentSettings />
                </Tabs.Tab>
              </StyledTabs>
            </SettingsSection>
          </SettingsContainer>
          <PreviousWidgetContainer>
            <WidgetPreview />
          </PreviousWidgetContainer>
        </BottomContainer>
      </Wrapper>

      <AppModal
        size="sm"
        shouldOpenModal={showModal}
        onModalClose={handleCloseModal}
        body={
          <HowToInstall
            embedCode={embedCode}
            onClose={handleCloseModal}
            openTutorial={(platform: ReviewsJetPlatform) => setOpen(platform)}
          />
        }
      />
      <Drawer
        size="lg"
        open={!!open}
        onClose={() => setOpen(null)}
        className="tutorial-drawer"
      >
        <Drawer.Header>
          <Drawer.Title>{`How To Install the Masonry Widget ${
            open !== ReviewsJetPlatform.OTHERS
              ? `on ${capitalize(open ?? '')}`
              : ``
          }`}</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setOpen(null)}>Close</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          {open === ReviewsJetPlatform.WORDPRESS && (
            <WordpressCarouselWidget widgetType="masonry" />
          )}
          {open === ReviewsJetPlatform.WIX && (
            <WixEmbeddedWidget widgetType="masonry" />
          )}
          {open === ReviewsJetPlatform.WEBFLOW && (
            <WebflowEmbeddedWidget widgetType="masonry" />
          )}
          {open === ReviewsJetPlatform.SQUARESPACE && (
            <SquarespaceEmbeddedWidget widgetType="masonry" />
          )}
          {open === ReviewsJetPlatform.GO_DADDY && (
            <GoDaddyEmbeddedWidget widgetType="masonry" />
          )}

          {open === ReviewsJetPlatform.PIXPA && (
            <PixpaEmbeddedWidget widgetType="masonry" />
          )}
          {open === ReviewsJetPlatform.SHOPIFY && (
            <ShopifyEmbeddedWidget widgetType="masonry" />
          )}
          {open === ReviewsJetPlatform.OTHERS && (
            <OthersEmbeddedWidget widgetType="masonry" />
          )}
        </Drawer.Body>
      </Drawer>
    </>
  );
}
