import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 25px;
  margin: 0px;
  color: rgb(0, 0, 0);
  line-height: 30px;
  margin-bottom: 5px;
  font-family: ubuntu;
`;
