import { useCallback, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import Preloader from '../../../../../shared/components/Preloader';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import {
  Loading,
  ReviewerPlatform,
  ReviewStatus,
  SubscriptionPlan,
} from '../../../../../shared/types/review.types';
import { getLimit } from '../../../../../shared/utils/usage-limits';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  fetchReviews,
  getReviewsUsage,
  toggleReviewStatus,
} from '../../../../manage-reviews/redux/action';
import {
  reviewsSelector,
  Review as ReviewType,
} from '../../../../manage-reviews/redux/reviews.slice';
import Review from './Review';

/* eslint no-console: "warn" */

const Wrapper = styled.div``;

const ReviewsList = styled(FlexContainer)`
  width: 100%;
  margin-top: 20px;
`;

type Props = {
  onNext: () => void;
  platform: ReviewerPlatform;
  onReadyForNextStep: () => void;
};

export default function ListReviews({
  onNext,
  platform,
  onReadyForNextStep,
}: Props) {
  const dispatch = useAppDispatch();
  const {
    reviewUsage: {
      loading: reviewUsageLoading,
      published: totalPublishedReviews,
    },
    reviews: { loading, data: paginatedResponse },
  } = useAppSelector(reviewsSelector);
  const { showInfoToast } = useNotification();
  const reviewsCredit = getLimit(SubscriptionPlan.FREE).reviews;

  const loadedReviews = paginatedResponse?.docs ?? [];
  const [publishedReviews, setPublishedReviews] = useState(0);

  useEffect(() => {
    setPublishedReviews(totalPublishedReviews);
  }, [totalPublishedReviews]);

  const [reviews, setReviews] = useState<ReviewType[]>([]);

  const handleClick = useCallback(
    (reviewId: string) => {
      if (publishedReviews >= reviewsCredit) {
        onNext();
        return;
      }

      const index = reviews.findIndex((review) => review._id === reviewId);

      const updatedReviews = [
        ...reviews.slice(0, index),
        ...reviews.slice(index + 1),
      ];

      onReadyForNextStep();

      setReviews(updatedReviews);
      dispatch(toggleReviewStatus({ id: reviewId, action: 'publish' }));
      showInfoToast('Review published successfully');
      setPublishedReviews(publishedReviews + 1);

      if (updatedReviews.length === 0) {
        onNext();
      }
    },
    [reviews, publishedReviews, reviewsCredit]
  );

  useEffect(() => {
    setReviews(loadedReviews);
  }, [loadedReviews]);

  const getData = () => {
    batch(() => {
      dispatch(getReviewsUsage());
      dispatch(
        fetchReviews({
          showPreloader: true,
          payload: {
            platform,
            status: ReviewStatus.PENDING,
          },
        })
      );
    });
  };

  useEffect(() => {
    getData();
  }, []);

  if ([reviewUsageLoading, loading].includes(Loading.PENDING)) {
    return <Preloader text="Loading reviews..." />;
  }

  if ([reviewUsageLoading, loading].includes(Loading.ERROR)) {
    return (
      <FlexContainer stack>
        <p style={{ marginBottom: 10 }}>Error loading reviews</p>
        <Button size="small" rounded onClick={getData}>
          Retry
        </Button>
      </FlexContainer>
    );
  }

  return (
    <Wrapper>
      <p>Click each of the reviews to publish them to your website.</p>

      {!reviews.length && <div>No reviews found</div>}

      <ReviewsList
        stack
        justifyContent="flex-start"
        alignItems="flex-start"
        gap="10px"
      >
        {reviews.map((review) => (
          <Review key={review._id} review={review} onClick={handleClick} />
        ))}
      </ReviewsList>
    </Wrapper>
  );
}
