import dayjs from 'dayjs';
import { forwardRef } from 'react';
import { Review } from '../../../../../../manage-reviews/redux/reviews.slice';
import {
  BrandingSettings,
  EmbeddedWidgetSettings,
  TranslationType,
} from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import TextReview from './TextReview';

type Props = {
  review: Review;
  translation: TranslationType;
  branding: BrandingSettings;
  widgetSettings: EmbeddedWidgetSettings;
};

const SliderReviewCard = forwardRef<HTMLVideoElement, Props>((props, ref) => {
  const { review, widgetSettings, branding, translation } = props;

  const { dateFormat = 'DD/MM/YYYY' } = branding;

  let reviewDate = review.reviewDate;

  try {
    reviewDate = dayjs(review.reviewDate).format(dateFormat);

    if (reviewDate === 'Invalid Date') {
      reviewDate = review.reviewDate;
    }
  } catch (error) {
    reviewDate = review.reviewDate;
  }

  return (
    <TextReview
      review={review}
      widgetSettings={widgetSettings}
      branding={branding}
      translation={translation}
    />
  );
});

export default SliderReviewCard;
