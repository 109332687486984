import styled, { CSSProperties } from 'styled-components';
import { useAppSelector } from '../../../../../../../store';
import { settingSelector } from '../../../../../../widgetSettings/redux/widgetSettings.slice';

const Wrapper = styled.div`
  position: absolute;
  width: max-content;
  padding: 14px 20px 13px;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  &.bottom_right {
    right: 20px;
    bottom: 20px;
    @media (max-width: 644px) {
      padding: 12px 15px;
    }
  }

  &.bottom {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.bottom_left {
    left: 20px;
    bottom: 20px;
    @media (max-width: 644px) {
      padding: 12px 15px;
    }
  }

  &.left {
    top: 50%;
    transform: translateY(-50%);
    transform-origin: 0 50%;
    transform: rotate(-90deg) translate(-50%, 50%);
    border-radius: 0;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    &:hover {
      left: 0;
    }
  }

  &.right {
    top: 50%;
    border-radius: 0;
    right: -1px;
    transform-origin: top right;
    transform: rotate(-90deg) translate(50%, -99%);
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &:hover {
      right: 0;
    }
  }
`;

interface DefaultButtonProps {
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

export default function DefaultButton({
  className,
  style,
  onClick,
}: DefaultButtonProps) {
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { modalWidget } = siteWidgetSettings ?? {};
  const { desktopSettings, buttonBackgroundColor, buttonTextColor, starColor } =
    modalWidget ?? {};
  const { position } = desktopSettings ?? {};

  return (
    <Wrapper
      className={position}
      style={{ backgroundColor: buttonBackgroundColor, color: buttonTextColor }}
      onClick={onClick}
    >
      ★ REVIEWS
    </Wrapper>
  );
}
