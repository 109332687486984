import { useEffect } from 'react';
import hexToRgba from '../../../../../../shared/utils/hexToRgba';
import { useAppSelector } from '../../../../../../store';
import { settingSelector } from '../../../../../widgetSettings/redux/widgetSettings.slice';

export default function useUpdateWidgetPreview() {
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { masonryWidget } = siteWidgetSettings!;

  useEffect(() => {
    const reviewCards = document.getElementsByClassName('review-card');
    Array.from(reviewCards).forEach((card) => {
      const backgroundColor = hexToRgba(
        masonryWidget.reviewCardColor,
        masonryWidget.reviewCardColorOpacity / 100
      );

      const borderStyle = masonryWidget.showBorder
        ? `${masonryWidget.borderWidth ?? 0}px ${
            masonryWidget.borderColor
          } solid`
        : 'none';

      // @ts-ignore
      Object.assign(card.style, {
        borderRadius: masonryWidget.borderRadius,
        background: backgroundColor,
        border: borderStyle,
      });
    });
  }, [
    masonryWidget.borderRadius,
    masonryWidget.reviewCardColor,
    masonryWidget.reviewCardColorOpacity,
    masonryWidget.showBorder,
    masonryWidget.borderWidth,
    masonryWidget.borderColor,
  ]);

  useEffect(() => {
    const fullRatingStars = document.getElementsByClassName('full-rating');
    Array.from(fullRatingStars).forEach((star) => {
      star.setAttribute('fill', masonryWidget.starColor);
    });
  }, [masonryWidget.starColor]);

  useEffect(() => {
    const reviewerNames = document.getElementsByClassName('reviewer-name');
    Array.from(reviewerNames).forEach((name) => {
      // @ts-ignore
      name.style.color = masonryWidget.linkColor;
    });
  }, [masonryWidget.linkColor]);

  useEffect(() => {
    const reviewContents = document.getElementsByClassName('review-content');
    Array.from(reviewContents).forEach((content) => {
      // @ts-ignore
      content.style.color = masonryWidget.textColor;
    });
  }, [masonryWidget.textColor]);

  useEffect(() => {
    const reviewDates = document.getElementsByClassName('review-date');
    Array.from(reviewDates).forEach((date) => {
      // @ts-ignore
      date.style.color = masonryWidget.dateColor;
    });
  }, [masonryWidget.dateColor]);
}
