import styled from 'styled-components';

import { ReactNode } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaChrome } from 'react-icons/fa6';
import { IoCopyOutline } from 'react-icons/io5';
import Divider from 'rsuite/esm/Divider';
import Drawer from 'rsuite/esm/Drawer';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import Preloader, {
  PreloaderSize,
} from '../../../../../shared/components/Preloader';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import { ReviewerPlatform } from '../../../../../shared/types/review.types';
import capitalize from '../../../../../shared/utils/capitalize';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { selectedSiteSelector } from '../../../../userData/redux/userData.slice';

const DrawerTitle = styled(Drawer.Title)`
  h2 {
    line-height: 45px;
  }
  .drawer-title {
    /* margin-bottom: -20px; */
  }

  .next-step {
    font-family: 'Wix Madefor Text', sans-serif;
    font-size: 14px;
    font-weight: normal;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #000000;
  border-radius: 6px;
  margin: 20px 0;
  color: #fff;
  svg {
    cursor: pointer;
  }

  span {
    font-size: 18px;
    font-weight: 600;
  }
`;

const Wrapper = styled.div`
  ul {
    margin-left: 20px;
    margin-bottom: 25px;
  }

  ul li {
    position: relative;
    margin-bottom: 10px;

    &::before {
      content: '';
      position: absolute;
      left: -20px;
      top: 3px;
      width: 1em;
      height: 1em;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"></path></svg>');
    }
  }
`;

const getSentence = (platform: ReviewerPlatform) => {
  switch (platform) {
    case ReviewerPlatform.ALI_EXPRESS:
      return 'Visit your AliExpress product page';

    case ReviewerPlatform.ETSY:
      return 'Visit your Etsy profile page';
    case ReviewerPlatform.TOUR_RADAR:
      return 'Visit your Tourradar profile page';
    case ReviewerPlatform.AIRBNB:
      return 'Visit your Airbnb profile page';
    default:
      return ``;
  }
};

type Props = {
  logo: ReactNode;
  platform: ReviewerPlatform;
  onClose: () => void;
};

export default function EmptyState({ platform, logo, onClose }: Props) {
  const dispatch = useAppDispatch();

  const site = useAppSelector(selectedSiteSelector);
  const { showInfoToast } = useNotification();

  return (
    <>
      <Wrapper>
        <Preloader
          text="Waiting for reviews..."
          position="relative"
          size={PreloaderSize.SMALL}
        />

        <Divider />

        <p style={{ marginBottom: 10 }}>
          Follow these steps to import your reviews from {capitalize(platform)}:
        </p>

        <ul>
          <li>
            <p style={{ marginBottom: 10 }}>Install our Chrome extension</p>

            <Button
              rounded
              size="large"
              onClick={() => {
                window.open(
                  'https://chromewebstore.google.com/detail/reviewsjet/cdoapcelcmonibckhnojjalhildkhbbf',
                  '_blank'
                );
              }}
            >
              <FlexContainer gap="5px">
                <FaChrome /> <b>Install Chrome Extension</b>
              </FlexContainer>
            </Button>
          </li>
          <li>{getSentence(platform)}</li>
          <li>
            <p>Use your AccountID to sign in and import your reviews</p>
            <LinkWrapper>
              <b>{site?._id}</b>
              <CopyToClipboard text={site?._id}>
                <IoCopyOutline
                  size={20}
                  onClick={() => showInfoToast('Copied to clipboard')}
                />
              </CopyToClipboard>
            </LinkWrapper>

            {/* <p>
              Click{' '}
              <a href="/" onClick={(e) => e.preventDefault()}>
                here
              </a>{' '}
              to learn how.
            </p> */}
          </li>
        </ul>
      </Wrapper>
    </>
  );
}
