import { SubscriptionPlan } from '../types/review.types';

type Tier = {
  seat: number;
  reviews: number;
  videoReviews: number;
  reviewsRequests: number;
  integrations: number;
  widgets: number;
};

export type Usage = {
  free: Tier;
  professional: Tier;
  'onetime-pro': Tier;
  advanced: Tier;
  'one-time-advanced': Tier;
  premium: Tier;
  'onetime-premium': Tier;
};

const USAGE: Usage = {
  free: {
    seat: -1,
    integrations: -1,
    reviews: 10,
    videoReviews: 2,
    reviewsRequests: 50,
    widgets: -1,
  },
  professional: {
    seat: -1,
    integrations: -1,
    reviews: 500,
    videoReviews: 2,
    reviewsRequests: 250,
    widgets: -1,
  },
  'onetime-pro': {
    seat: -1,
    integrations: -1,
    reviews: 500,
    videoReviews: 2,
    reviewsRequests: 250,
    widgets: -1,
  },
  advanced: {
    seat: -1,
    integrations: -1,
    reviews: -1,
    videoReviews: 50,
    reviewsRequests: 5000,
    widgets: -1,
  },
  'one-time-advanced': {
    seat: -1,
    integrations: -1,
    reviews: -1,
    videoReviews: 50,
    reviewsRequests: 5000,
    widgets: -1,
  },
  premium: {
    seat: -1,
    integrations: -1,
    reviews: -1,
    videoReviews: -1,
    reviewsRequests: -1,
    widgets: -1,
  },
  'onetime-premium': {
    seat: -1,
    integrations: -1,
    reviews: -1,
    videoReviews: -1,
    reviewsRequests: -1,
    widgets: -1,
  },
};

export const getTotalCredit = (value: number) => {
  return value === -1 ? 'Unlimited' : value;
};

export const getLimit = (plan?: SubscriptionPlan) => {
  if (plan) {
    return USAGE[plan];
  }
  return {
    seat: 1,
    integrations: 1,
    reviews: 1,
    reviewsRequests: 1,
    videoReviews: 1,
  };
};

export default USAGE;
