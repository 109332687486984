import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Auth from '../features/external/components/auth';
import {
  ConfirmAccount,
  ForgotPasswordPage,
  LoginPage,
  RegisterPage,
  SelectSite,
  VerifyAccount,
} from '../features/external/pages';
import AppSumo from '../features/external/pages/register';
import SetPasswordPage from '../features/external/pages/set-password';
import ShopifyAuth from '../features/external/pages/shopify-auth';
import Onboarding from '../features/onboarding';
import PlansPage from '../features/plans';
import SelectWidgets from '../features/select-widgets';
import CarouselWidget from '../features/widgetSettings/widgets/carousel-slider';
import Preloader from '../shared/components/Preloader';
import useTheme from '../shared/hooks/useTheme';
import PrivateRoute from './components/PrivateRoute';

const Masonry = lazy(
  () => import('../features/widgetSettings/widgets/masonry')
);
const Grid = lazy(() => import('../features/widgetSettings/widgets/grid'));
const List = lazy(() => import('../features/widgetSettings/widgets/list'));
const Slider = lazy(
  () => import('../features/widgetSettings/widgets/carousel-slider')
);
const Marquee = lazy(
  () => import('../features/widgetSettings/widgets/carousel-marquee')
);

const ShareReview = lazy(() => import('../features/share-review'));

const BusinessRichSnippets = lazy(
  () => import('../features/widgetSettings/widgets/business-rich-snippets')
);
const ProductReviewsWidget = lazy(
  () => import('../features/widgetSettings/widgets/product-widget')
);
const ManageReviews = lazy(() => import('../features/manage-reviews/'));
const ImportReviews = lazy(() => import('../features/import-reviews'));
const Dashboard = lazy(() => import('../features/dashboard'));
const NotFoundPage = lazy(() => import('../shared/components/not-found-page'));
const UpdatePasswordPage = lazy(
  () => import('../features/external/pages/update-password')
);
const ExternalAuthLayout = lazy(
  () => import('../features/external/components/external-auth-layout')
);
const WixAuth = lazy(() => import('../features/wixAuth/components'));
const CheckoutSuccessPage = lazy(
  () => import('../features/plans/checkout-success-page')
);
const CheckoutFailPage = lazy(
  () => import('../features/plans/checkout-fail-page')
);
const WidgetSettingsLayout = lazy(
  () => import('../layouts/WidgetSettingsLayout')
);

const ModalWidget = lazy(
  () => import('../features/widgetSettings/widgets/modal-widget')
);
const Settings = lazy(() => import('../features/settings'));
const QuestionsAndAnswers = lazy(
  () => import('../features/questions-and-answers')
);
const CollectReviews = lazy(() => import('../features/collect-reviews/index'));
const ShareForm = lazy(() => import('../features/share-form'));
const WallOfLove = lazy(() => import('../features/wall-of-love'));
const ShareReviewForm = lazy(() => import('../features/share-review-form'));
const InviteSingleCustomer = lazy(
  () => import('../features/invite-single-customer')
);
const Emails = lazy(() => import('../features/emails'));
const Orders = lazy(() => import('../features/orders'));
const OpenLogin = lazy(() => import('../features/open-login'));
const ShopifyOnboarding = lazy(() => import('../features/shopify-onboarding'));
const SuccessfulShopifySubscription = lazy(
  () => import('../features/payment-successful/Shopify')
);

const Container = styled.main<{ $pageBackgroundColor?: string }>`
  background-color: ${({ $pageBackgroundColor }) => $pageBackgroundColor};
  height: 100%;
`;

const AppRoutes = () => {
  const { pageBackgroundColor } = useTheme() ?? {};

  return (
    <>
      <Suspense fallback={<Preloader />}>
        <Container $pageBackgroundColor={pageBackgroundColor}>
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route path="/get-started" element={<ShopifyOnboarding />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/questions" element={<QuestionsAndAnswers />} />
              <Route path="/manage-reviews">
                <Route path="" element={<ManageReviews />} />
                <Route path="google" element={<ManageReviews />} />
                <Route path="yelp" element={<ManageReviews />} />
                <Route path="apple" element={<ManageReviews />} />
                <Route path="producthunt" element={<ManageReviews />} />
                <Route path="trustpilot" element={<ManageReviews />} />
                <Route path="capterra" element={<ManageReviews />} />
                <Route path="via-reviewsjet" element={<ManageReviews />} />
              </Route>
              <Route path="/select-widgets" element={<SelectWidgets />} />
              <Route path="/widgets" element={<WidgetSettingsLayout />}>
                <Route path="carousel" element={<CarouselWidget />} />
                <Route path="masonry" element={<Masonry />} />
                <Route path="grid" element={<Grid />} />

                <Route path="list" element={<List />} />
                <Route path="slider" element={<Slider />} />
                <Route path="marquee" element={<Marquee />} />
                <Route path="modal-widget" element={<ModalWidget />} />
                <Route path="product" element={<ProductReviewsWidget />} />
              </Route>
              <Route path="/seo" element={<WidgetSettingsLayout />}>
                <Route
                  path="business-rich-snippet"
                  element={<BusinessRichSnippets />}
                />
              </Route>
              <Route path="/import-reviews" element={<ImportReviews />} />
              <Route path="/collect-reviews" element={<CollectReviews />} />
              <Route path="/share-review-form" element={<ShareReviewForm />} />
              <Route
                path="/invite-single-customer"
                element={<InviteSingleCustomer />}
              />
              <Route path="wall-of-love" element={<WallOfLove />} />
              <Route path="/share-form" element={<ShareForm />} />
              <Route path="/csv" element={<ImportReviews />} />
              <Route path="/emails" element={<Emails />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                path="/dashboard"
                element={<Navigate to="/manage-reviews" />}
              />
              <Route path="/plans" element={<PlansPage />} />
              <Route path="/share-review" element={<ShareReview />} />
              <Route
                path="/shopify/successful-payment"
                element={<SuccessfulShopifySubscription />}
              />
            </Route>
            <Route path="/o-login" element={<OpenLogin />} />
            <Route path="/checkout/fail" element={<CheckoutFailPage />} />
            <Route path="/checkout/success" element={<CheckoutSuccessPage />} />
            {/*  */}
            <Route element={<Auth />}>
              <Route path="login" element={<LoginPage />} />
              <Route path="signup" element={<RegisterPage />} />
              <Route path="appsumo/:code" element={<AppSumo />} />
              <Route path="verify" element={<VerifyAccount />} />
              <Route path="update-password" element={<UpdatePasswordPage />} />
              <Route path="forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/onboarding" element={<Onboarding />} />
              <Route
                path="confirm-account/:token"
                element={<ConfirmAccount />}
              />
              <Route path="set-password" element={<SetPasswordPage />} />
              <Route path="set-password/:token" element={<SetPasswordPage />} />
              <Route path="select-site" element={<SelectSite />} />
            </Route>
            {/*  */}
            <Route element={<ExternalAuthLayout />}></Route>
            <Route path="/reviews" element={<WixAuth />} />
            <Route path="wix/auth" element={<WixAuth />} />
            <Route path="shopify/auth" element={<ShopifyAuth />} />
            <Route path="/" element={<Navigate to="/manage-reviews" />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Container>
      </Suspense>
    </>
  );
};

export default AppRoutes;
