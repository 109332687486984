import { unwrapResult } from '@reduxjs/toolkit';
import { ChangeEvent, useState } from 'react';
import { IoLink } from 'react-icons/io5';
import styled from 'styled-components';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import StyledText from '../../../../../shared/components/styled-text';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import { IAppleStore, Loading } from '../../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { addAppleAccount } from '../../../../socialReviews/redux/actions';
import { socialReviewsSelector } from '../../../../socialReviews/redux/social-reviews.slice';

const BackBtn = styled(Button)`
  margin-bottom: 20px;
  background-color: #000;
  color: #fff;
  border-color: #000;
`;

const Wrapper = styled.div`
  margin: 20px 0;

  .business-img {
    max-width: 85px;
    max-height: 85px;
    background: rgb(245, 245, 245);
    border-radius: 10px;
    padding: 1px;
    border: 1px solid rgb(194, 194, 194);
    object-fit: cover;
  }
  .business-name {
    font-size: 16px;
    font-weight: 600;
  }
  .business-address,
  .business-address {
    max-width: 405px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const ConfirmButton = styled(Button)<{ disabled?: boolean }>`
  background-color: #000;
  color: #fff;
  border-color: #000;
  width: 100%;
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;

  &:disabled {
    background-color: #bbbbbb;
    color: #000;
    border-color: #bbbbbb;
  }
`;

const CheckBox = styled.input`
  width: 16px;
  height: 16px;
`;

const CheckBoxWithTextWrapper = styled.label`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;
const ConfirmText = styled(StyledText)`
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  line-height: 16px;
  color: #5f5f5f;
  line-height: 23px;
`;

export default function ConfirmApp({
  selectedApp,
  onBack,
  onNext,
}: {
  onNext: () => void;
  selectedApp: IAppleStore | null;
  onBack: () => void;
}) {
  const dispatch = useAppDispatch();

  const { showErrorToast } = useNotification();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const { addingAppleAccount } = useAppSelector(socialReviewsSelector);

  const handleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  const handleConfirm = () => {
    if (selectedApp) {
      dispatch(
        addAppleAccount({
          productId: selectedApp.product_id,
          pageTitle: selectedApp.title,
          country: selectedApp.country,
          language: selectedApp.language,
        })
      )
        .then(unwrapResult)
        .then(onNext)
        .catch(() => {
          showErrorToast('Something went wrong');
        });
    }
  };

  if (!selectedApp) {
    return null;
  }

  return (
    <Wrapper>
      <FlexContainer gap="10px" justifyContent="flex-start" alignItems="center">
        <img
          className="business-img"
          src={selectedApp.logos[0].link}
          alt={selectedApp.title}
          referrerPolicy="no-referrer"
        />
        <div>
          <p className="business-name">{selectedApp.title}</p>
          <p className="business-address">
            <IoLink /> {selectedApp.link}
          </p>
        </div>
      </FlexContainer>

      <CheckBoxWithTextWrapper>
        <CheckBox
          type="checkbox"
          onChange={handleCheckBox}
          defaultChecked={isChecked}
        />
        <ConfirmText
          onClick={() => {
            setIsChecked(!isChecked);
          }}
        >
          I confirm that I own the right the use reviews from{' '}
          <b>{selectedApp.title}</b>
        </ConfirmText>
      </CheckBoxWithTextWrapper>

      <ConfirmButton
        disabled={!isChecked || addingAppleAccount === Loading.PENDING}
        onClick={handleConfirm}
      >
        <b>
          {addingAppleAccount === Loading.PENDING
            ? 'Please wait...'
            : 'Import reviews'}
        </b>
      </ConfirmButton>
    </Wrapper>
  );
}
