import { createContext } from 'react';
import { Divider, Steps } from 'rsuite';
import styled, { css } from 'styled-components';
import ContactSupport from '../../shared/components/ContactSupport';
import { useNotification } from '../../shared/hooks/useNotification';
import MapFields from './components/MapFields';
import PreviewReviews from './components/PreviewReviews';
import UploadCsv from './components/UploadCsv';
import useCsvImport from './hooks/useCsvImport';

const Wrapper = styled.div`
  padding: 20px 0 0;
  max-width: 1100px;
  margin: auto;
`;
const CustomSteps = styled(Steps)`
  margin: 25px 0;
`;

const CustomStep = styled(Steps.Item)<{ $clickable?: boolean }>`
  ${({ $clickable }) =>
    $clickable
      ? css`
          cursor: pointer;
        `
      : ''}
`;

export enum Step {
  UPLOAD_CSV,
  MAP_FIELDS,
  PREVIEW_REVIEWS,
  FINAL,
}

export type ReservedFields =
  | 'title'
  | 'product_id'
  | 'reviewer_name'
  | 'reviewer_email'
  | 'rating'
  | 'helpful_count'
  | 'review_text'
  | 'your_review_reply'
  | 'review_date'
  | 'reviewer_location_country'
  | 'reviewer_location_city';

export type MappedField = {
  title: {
    value: string;
    skipped: boolean;
  };
  reviewer_name: {
    value: string;
    skipped: boolean;
  };
  rating: {
    value: string;
    skipped: boolean;
  };
  helpful_count: {
    value: string;
    skipped: boolean;
  };
  review_text: {
    value: string;
    skipped: boolean;
  };
  your_review_reply: {
    value: string;
    skipped: boolean;
  };
  review_date: {
    value: string;
    skipped: boolean;
  };
  product_id: {
    value: string;
    skipped: boolean;
  };
  reviewer_email: {
    value: string;
    skipped: boolean;
  };
  reviewer_location_country: {
    value: string;
    skipped: boolean;
  };
  reviewer_location_city: {
    value: string;
    skipped: boolean;
  };
};

export type ImportReviewsContextType = {
  unmappedFields: string[];
  mappedFields: MappedField;
  mappedFieldsList: string[];
  handleToggleSkipColumn: (header: {
    label: string;
    isSkipped: boolean;
  }) => void;
  parsedFile: Record<string, string>[];
  handleSkipUnmappedFields: () => void;
  headers: { label: string; isSkipped: boolean }[];
  handleMapping: (field: string, mapTo: ReservedFields) => void;
};

export const ImportReviewsContext =
  createContext<ImportReviewsContextType | null>(null);

const CSVReviewImport = () => {
  const {
    step,
    setStep,
    parsedFile,
    setParsedFile,
    headers,
    handleMapping,
    mappedFields,
    mappedFieldsList,
    unmappedFields,
    handleToggleSkipColumn,
    handleSkipUnmappedFields,
    uploadedFile,
    setMapping,
    setUploadedFile,
  } = useCsvImport();

  const { showWarningToast } = useNotification();

  return (
    <ImportReviewsContext.Provider
      value={{
        headers,
        parsedFile,
        mappedFields,
        handleMapping,
        unmappedFields,
        mappedFieldsList,
        handleToggleSkipColumn,
        handleSkipUnmappedFields,
      }}
    >
      <ContactSupport />
      <Wrapper>
        <CustomSteps small current={step}>
          <CustomStep
            key={Step.UPLOAD_CSV}
            title="Upload CSV"
            onClick={() => {
              if ([Step.MAP_FIELDS, Step.PREVIEW_REVIEWS].includes(step)) {
                setStep(Step.UPLOAD_CSV);
              }
            }}
            $clickable={[Step.MAP_FIELDS, Step.PREVIEW_REVIEWS].includes(step)}
          />
          <CustomStep
            key={Step.MAP_FIELDS}
            title="Map Fields"
            onClick={() => {
              if (step === Step.PREVIEW_REVIEWS) {
                setStep(Step.MAP_FIELDS);
              }
            }}
            $clickable={step === Step.PREVIEW_REVIEWS}
          />
          <CustomStep title="Preview" key={Step.PREVIEW_REVIEWS} />
        </CustomSteps>

        <Divider />

        {step === Step.UPLOAD_CSV && (
          <UploadCsv
            file={uploadedFile}
            onUpload={(file, parsedFile) => {
              setUploadedFile(file);
              setMapping(parsedFile);
              setParsedFile(parsedFile);
            }}
            onNextClick={() => {
              setStep(Step.MAP_FIELDS);
            }}
          />
        )}
        {step === Step.MAP_FIELDS && (
          <MapFields
            onComplete={() => {
              if (
                !mappedFields.reviewer_name.value ||
                !mappedFields.review_text.value ||
                !mappedFields.rating
              ) {
                return showWarningToast(
                  <>
                    To continue, please map <b>reviewer_name</b>, <b>rating</b>,
                    and <b>review_text</b>.
                  </>
                );
              }
              setStep(Step.PREVIEW_REVIEWS);
            }}
          />
        )}
        {step === Step.PREVIEW_REVIEWS && <PreviewReviews />}
      </Wrapper>
    </ImportReviewsContext.Provider>
  );
};

export default CSVReviewImport;
