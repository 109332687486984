import { useState } from 'react';
import Http from '../../../../../../../services/http';
import { Loading } from '../../../../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../../../../shared/utils/removeTrailingSlash';
import { ContactItem } from '../../../../../../emails/redux/emails.slice';

/* eslint no-console: "warn" */

const http = new Http();

export default function useIntercomContacts() {
  const [intercomContacts, setIntercomContacts] = useState<ContactItem[]>([]);
  const [loading, setLoading] = useState(Loading.INITIAL);
  const [startingAfter, setStartingAfter] = useState('');
  const [loadingInitialIntercomContacts, setLoadingInitialIntercomContacts] =
    useState(Loading.INITIAL);

  const getIntercomContacts = async () => {
    if (loading === Loading.PENDING) {
      return null;
    }

    if (startingAfter) {
      setLoading(Loading.PENDING);
    } else {
      setLoadingInitialIntercomContacts(Loading.PENDING);
    }

    try {
      let url = `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/intercom/contacts`;

      if (startingAfter) {
        url += `?startingAfter=${startingAfter}`;
      }

      const response = await http.get(url);

      const { contacts, startingAfter: _startingAfter } = response.data;

      setIntercomContacts([...intercomContacts, ...contacts]);
      setStartingAfter(_startingAfter);

      if (startingAfter) {
        setLoading(Loading.SUCCESS);
      } else {
        setLoadingInitialIntercomContacts(Loading.SUCCESS);
      }
    } catch (error) {
      if (startingAfter) {
        setLoading(Loading.ERROR);
      } else {
        setLoadingInitialIntercomContacts(Loading.ERROR);
      }
    }
  };

  return {
    startingAfter,
    intercomContacts,
    getIntercomContacts,
    loadingInitialIntercomContacts,
    loadingIntercomContacts: loading,
  };
}
