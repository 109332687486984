import dayjs from 'dayjs';
import hexRgb from 'hex-rgb';
import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import Preloader from '../../../../shared/components/Preloader';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import MovingParticules from '../../../../shared/components/moving-particules/MovingParticules';
import { useNotification } from '../../../../shared/hooks/useNotification';
import GoogleIcon from '../../../../shared/svgs/GoogleIcon';
import ReviewRating from '../../../../shared/svgs/ReviewRating';
import { Loading } from '../../../../shared/types/review.types';
import { useAppSelector } from '../../../../store';
import {
  BackgroundType,
  BrandingSettings,
  CarouselWidgetSettings,
  EmbeddedWidgetSettings,
  ShadowSize,
  ShadowType,
  settingSelector,
} from '../../redux/widgetSettings.slice';
import ActionButtons from './ActionButtons';

const PlatformIconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  bottom: -5px;
  right: -5px;
  background-color: #fff;
  border: 2px solid #fff;
`;

const Wrapper = styled.div`
  margin-bottom: 30px;
`;

const Reviewer = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  img {
    width: 100%;
  }
`;

const PreviewSection = styled.div<{
  $widget: EmbeddedWidgetSettings | CarouselWidgetSettings;
}>`
  padding: 10px;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  background: linear-gradient(to top, #ef4444, #7c3aed, #334155);
  /* background: ${({
    $widget: { backgroundColor, backgroundColorType, backgroundColorGradient },
  }) => {
    if (backgroundColorType === BackgroundType.TRANSPARENT) {
      return 'transparent';
    } else if (backgroundColorType === BackgroundType.SOLID_COLOR) {
      return backgroundColor;
    } else if (backgroundColorType === BackgroundType.GRADIENT) {
      return `linear-gradient(to right, ${backgroundColorGradient});`;
    }
  }}; */
`;

const WidgetCard = styled.div<{
  $widget: EmbeddedWidgetSettings | CarouselWidgetSettings;
  $branding: BrandingSettings;
}>`
  padding: 20px;
  position: relative;
  margin-top: 10px;
  max-width: 300px;
  margin: auto;
  min-height: 300px;
  font-family: ${({ $branding }) =>
    $branding.useMyWebsiteFontFamily ? 'auto' : $branding.fontFamily};
  border-radius: ${({ $widget: { borderRadius } }) => borderRadius};
  ${({ $widget: { showBorder, borderColor, borderWidth } }) => {
    if (showBorder) {
      return css`
        border: ${borderWidth > 2 ? 2 : borderWidth}px solid ${borderColor};
      `;
    }
  }}
  /* box-shadow: ${({ $widget: { shadowType, shadowColor, shadowSize } }) => {
    if (shadowType === ShadowType.NORMAL) {
      if (shadowSize === ShadowSize.SMALL) {
        return `0px 10px 15px -3px ${shadowColor}`;
      } else if (shadowSize === ShadowSize.MEDIUM) {
        return `0px 10px 15px 5px ${shadowColor}`;
      } else if (shadowSize === ShadowSize.LARGE) {
        return `0px 10px 15px 9px ${shadowColor}`;
      }
    } else if (shadowType === ShadowType.FOCUS) {
      if (shadowSize === ShadowSize.SMALL) {
        return `0px 10px 0 -3px ${shadowColor}`;
      } else if (shadowSize === ShadowSize.MEDIUM) {
        return `0px 10px 0 5px ${shadowColor}`;
      } else if (shadowSize === ShadowSize.LARGE) {
        return `0px 10px 0 9px ${shadowColor}`;
      }
    }
    return '';
  }}; */
  background-color: ${({
    $widget: { reviewCardColorOpacity, reviewCardColor },
  }) => {
    return hexRgb(reviewCardColor, {
      alpha: reviewCardColorOpacity / 100,
      format: 'css',
    });

    // if (reviewCardBackgroundType === BackgroundType.TRANSPARENT) {
    //   return 'transparent';
    // } else {
    //   return reviewCardColor;
    // }
  }};
  z-index: 2;
`;

const ReadMore = styled.span<{ color: string }>`
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
  margin-left: 5px;
  color: ${({ color }) => color};
  &:hover {
    text-decoration: underline;
  }
`;

const ReviewerAvatar = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  position: relative;
`;
const ReviewerPic = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
`;

const ReviewerName = styled.span<{ color: string; $fontFamily: string }>`
  font-weight: bold;
  font-size: 14px;
  color: ${({ color }) => color};
  font-family: ${({ $fontFamily }) => $fontFamily};
`;
const ReviewerDate = styled.span<{ color: string; $fontFamily: string }>`
  font-size: 12px;
  color: ${({ color }) => color};
  display: block;
  font-family: ${({ $fontFamily }) => $fontFamily};
`;
const ReviewContent = styled.p`
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 25px;
  text-align: left;
  font-size: 14px;
  margin: 10px 0 0;
  text-align: left;
`;

const CarouselReviewCardPreview = ({
  widget,
  brandingSettings,
}: {
  brandingSettings: BrandingSettings;
  widget: CarouselWidgetSettings | EmbeddedWidgetSettings;
}) => {
  const {
    loadingSiteWidgetSettings,
    updateWidgetSettings: { saving },
  } = useAppSelector(settingSelector);
  const { showErrorToast } = useNotification();

  useEffect(() => {
    if (saving === Loading.ERROR) {
      showErrorToast('Something went wrong. Please try it again');
    }
  }, [saving]);

  const {
    hideSourceIcon,
    linkColor,
    showReviewDate,
    dateColor,
    textColor,
    starColor,
  } = widget;
  const { fontFamily, useMyWebsiteFontFamily, dateFormat } = brandingSettings;

  if (loadingSiteWidgetSettings === Loading.PENDING) {
    return <Preloader />;
  }

  return (
    <>
      <Wrapper>
        <ActionButtons />

        <PreviewSection $widget={widget}>
          <WidgetCard $widget={widget} $branding={brandingSettings}>
            <Reviewer>
              <FlexContainer
                gap="10px"
                alignItems="center"
                justifyContent="flex-start"
                style={{ width: '100%' }}
              >
                <ReviewerAvatar>
                  <ReviewerPic
                    src="/images/17.jpg"
                    alt=""
                    referrerPolicy="no-referrer"
                  />

                  {!hideSourceIcon && (
                    <PlatformIconWrapper>
                      <GoogleIcon />
                    </PlatformIconWrapper>
                  )}
                </ReviewerAvatar>

                <div>
                  <ReviewerName
                    color={linkColor!}
                    $fontFamily={useMyWebsiteFontFamily ? 'auto' : fontFamily}
                  >
                    Joyce Filpo
                  </ReviewerName>
                  {showReviewDate && (
                    <ReviewerDate
                      color={dateColor!}
                      $fontFamily={useMyWebsiteFontFamily ? 'auto' : fontFamily}
                    >
                      {dayjs().format(dateFormat)}
                    </ReviewerDate>
                  )}
                </div>
              </FlexContainer>
            </Reviewer>
            <ReviewRating rating={4} size={20} color={starColor!} />
            <ReviewContent>
              <span
                style={{
                  color: textColor!,
                  fontFamily: useMyWebsiteFontFamily ? 'auto' : fontFamily,
                }}
              >
                Have only been here twice briefly as we typically don't fly
                United internationally, but each visit has been a treat. The
                lounge itself is very spacious with multiple areas for seating,
                and it's worth going here for the a la carte dining...
              </span>
              <ReadMore color={textColor!}>Read more</ReadMore>
            </ReviewContent>
          </WidgetCard>

          <MovingParticules />
        </PreviewSection>
      </Wrapper>
    </>
  );
};

export default CarouselReviewCardPreview;
