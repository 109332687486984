import styled, { CSSProperties } from 'styled-components';
import FlexContainer from '../../../../../../../shared/components/layouts/flex-container';
import { useAppSelector } from '../../../../../../../store';
import {
  settingSelector,
  WidgetPosition,
} from '../../../../../../widgetSettings/redux/widgetSettings.slice';

const Wrapper = styled(FlexContainer)`
  position: absolute;
  width: max-content;
  padding: 5px 15px;

  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  &.bottom_right {
    right: 20px;
    bottom: 20px;
    @media (max-width: 644px) {
      padding: 12px 15px;
    }
  }

  &.bottom {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.bottom_left {
    left: 20px;
    bottom: 20px;
    @media (max-width: 644px) {
      padding: 12px 15px;
    }
  }

  &.left {
    top: 50%;
    transform: translateY(-50%);
    transform-origin: 0 50%;
    transform: rotate(-90deg) translate(-50%, 50%);
    border-radius: 0;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    &:hover {
      left: 0;
    }
  }

  &.right {
    top: 50%;
    border-radius: 0;
    right: -1px;
    transform-origin: top right;
    transform: rotate(-90deg) translate(50%, -99%);
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &:hover {
      right: 0;
    }
  }

  .active {
    /* color: #fff; */
  }

  .inactive {
    color: #888888;
  }

  .review-summary {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }
`;

interface AdvancedButtonProps {
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

export default function AdvancedButton({
  className,
  style,
  onClick,
}: AdvancedButtonProps) {
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { modalWidget } = siteWidgetSettings ?? {};
  const { desktopSettings, buttonBackgroundColor, buttonTextColor } =
    modalWidget ?? {};
  const { position } = desktopSettings ?? {};

  return (
    <Wrapper
      flexDirection={
        position === WidgetPosition.LEFT ? 'column-reverse' : 'column'
      }
      gap="0"
      className={position}
      style={{
        backgroundColor: buttonBackgroundColor,
        color: buttonTextColor,
      }}
      onClick={onClick}
    >
      <FlexContainer>
        <span className="active">★</span>
        <span className="active">★</span>
        <span className="active">★</span>
        <span className="active">★</span>
        <span className="active">★</span>
      </FlexContainer>
      <div className="review-summary">Based on 1K reviews</div>
    </Wrapper>
  );
}
