import ColorPicker from '../../../../../../../shared/components/colorPicker';
import { useAppSelector } from '../../../../../../../store';
import FormRow from '../../../../../../widgetSettings/components/form-row';
import { settingSelector } from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import useDispatchUpdateSiteWidgetSettings from '../../../../../../widgetSettings/shared/hooks/useDispatchUpdateSiteWidgetSettings';

export default function ColorSettings() {
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { dispatchUpdateSiteWidgetSettings } =
    useDispatchUpdateSiteWidgetSettings();

  return (
    <>
      <FormRow justifyContent="space-between" gap="10px">
        <span>Reviewer name</span>
        <ColorPicker
          color={siteWidgetSettings?.gridWidget.linkColor!}
          changeColor={(color: string) => {
            dispatchUpdateSiteWidgetSettings('linkColor', color, 'grid');
          }}
        />
      </FormRow>
      <FormRow justifyContent="space-between" gap="10px">
        <span>Star color</span>
        <ColorPicker
          color={siteWidgetSettings?.gridWidget.starColor!}
          changeColor={(color: string) => {
            dispatchUpdateSiteWidgetSettings('starColor', color, 'grid');
          }}
        />
      </FormRow>
      <FormRow justifyContent="space-between" gap="10px">
        Text color
        <ColorPicker
          color={siteWidgetSettings?.gridWidget.textColor!}
          changeColor={(color: string) => {
            dispatchUpdateSiteWidgetSettings('textColor', color, 'grid');
          }}
        />
      </FormRow>
      <FormRow justifyContent="space-between" gap="10px">
        <span>Date color</span>
        <ColorPicker
          color={siteWidgetSettings?.gridWidget.dateColor!}
          changeColor={(color: string) => {
            dispatchUpdateSiteWidgetSettings('dateColor', color, 'grid');
          }}
        />
      </FormRow>
      <FormRow justifyContent="space-between" gap="10px">
        <span>Button text color</span>
        <ColorPicker
          color={siteWidgetSettings?.gridWidget.buttonTextColor!}
          changeColor={(color: string) => {
            dispatchUpdateSiteWidgetSettings('buttonTextColor', color, 'grid');
          }}
        />
      </FormRow>
      <FormRow justifyContent="space-between" gap="10px">
        <span>Button color</span>
        <ColorPicker
          color={siteWidgetSettings?.gridWidget.buttonBackgroundColor!}
          changeColor={(color: string) => {
            dispatchUpdateSiteWidgetSettings(
              'buttonBackgroundColor',
              color,
              'grid'
            );
          }}
        />
      </FormRow>
    </>
  );
}
