import {
  ReviewerPlatform,
  ReviewStatus,
} from '../../../../../../../shared/types/review.types';
import { Review } from '../../../../../../manage-reviews/redux/reviews.slice';

const reviews: Review[] = [
  {
    instanceId: 'inst_123',
    content:
      "The product exceeded my expectations in every way possible. The quality is outstanding, shipping was incredibly fast, and the customer support team was exceptionally helpful when I had questions. Would definitely recommend to anyone looking for a reliable purchase experience. I was skeptical at first, but this product completely changed my mind. The build quality is exceptional, and it performs even better than advertised. After using it for a month, I can confidently say it's one of the best purchases I've made this year. The attention to small details really sets it apart from similar products.",

    rating: 5,
    status: ReviewStatus.PUBLISHED,
    reviewer: {
      _id: 'rev_123',
      instanceId: 'inst_123',
      email: 'john.smith@example.com',
      fullName: 'John Smith',
      avatar: 'https://i.pravatar.cc/150?img=1',
      created_at: '2024-03-01T10:00:00Z',
      updated_at: '2024-03-01T10:00:00Z',
    },
    verified: true,
    platform: ReviewerPlatform.WIDGET_REVIEW,
    helpfulCount: 12,
    pictures: ['https://picsum.photos/400/300?random=1'],
    reviewDate: '2024-03-15T14:30:00Z',
    _id: 'review_123',
    id: 'review_123',
    created_at: '2024-03-15T14:30:00Z',
    updated_at: '2024-03-15T14:30:00Z',
    product: null,
    reply: null,
    location: null,
    type: 'text',
  },
  {
    instanceId: 'inst_456',
    content:
      'Quality is great and the product works exactly as described. While delivery took a bit longer than expected, the wait was worth it given the excellent quality. The attention to detail is impressive, and I appreciate how well-made everything is. Would definitely recommend! Good value for money and excellent quality. The product arrived in perfect condition and works exactly as advertised. Setup was straightforward, and I particularly appreciate the detailed instructions that came with it.',

    rating: 4,
    status: ReviewStatus.PUBLISHED,
    reviewer: {
      _id: 'rev_456',
      instanceId: 'inst_456',
      email: 'sarah.j@example.com',
      fullName: 'Sarah Johnson',
      avatar: 'https://i.pravatar.cc/150?img=2',
      created_at: '2024-03-01T11:00:00Z',
      updated_at: '2024-03-01T11:00:00Z',
    },
    verified: true,
    platform: ReviewerPlatform.WIDGET_REVIEW,
    helpfulCount: 8,
    pictures: [],
    reviewDate: '2024-03-14T09:15:00Z',
    _id: 'review_456',
    id: 'review_456',
    created_at: '2024-03-14T09:15:00Z',
    updated_at: '2024-03-14T09:15:00Z',
    product: null,
    reply: null,
    location: null,
    type: 'text',
  },
  {
    instanceId: 'inst_789',
    content:
      'Absolutely love this product! The quality is outstanding and it arrived earlier than expected. The packaging was secure and professional, and the product itself exceeds all my expectations.',

    rating: 5,
    status: ReviewStatus.PUBLISHED,
    reviewer: {
      _id: 'rev_789',
      instanceId: 'inst_789',
      email: 'mike.brown@example.com',
      fullName: 'Mike Brown',
      avatar: 'https://i.pravatar.cc/150?img=3',
      created_at: '2024-03-02T09:00:00Z',
      updated_at: '2024-03-02T09:00:00Z',
    },
    verified: true,
    platform: ReviewerPlatform.WIDGET_REVIEW,
    helpfulCount: 15,
    pictures: ['https://picsum.photos/400/300?random=4'],
    reviewDate: '2024-03-13T16:45:00Z',
    _id: 'review_789',
    id: 'review_789',
    created_at: '2024-03-13T16:45:00Z',
    updated_at: '2024-03-13T16:45:00Z',
    product: null,
    reply: null,
    location: null,
    type: 'text',
  },
  {
    instanceId: 'inst_101',
    content:
      'Good value for money and excellent quality. The product arrived in perfect condition and works exactly as advertised. Setup was straightforward, and I particularly appreciate the detailed instructions that came with it.',

    rating: 4,
    status: ReviewStatus.PUBLISHED,
    reviewer: {
      _id: 'rev_101',
      instanceId: 'inst_101',
      email: 'emma.wilson@example.com',
      fullName: 'Emma Wilson',
      avatar: 'https://i.pravatar.cc/150?img=4',
      created_at: '2024-03-03T14:20:00Z',
      updated_at: '2024-03-03T14:20:00Z',
    },
    verified: true,
    platform: ReviewerPlatform.WIDGET_REVIEW,
    helpfulCount: 7,
    pictures: ['https://picsum.photos/400/300?random=6'],
    reviewDate: '2024-03-12T11:30:00Z',
    _id: 'review_101',
    id: 'review_101',
    created_at: '2024-03-12T11:30:00Z',
    updated_at: '2024-03-12T11:30:00Z',
    product: null,
    reply: null,
    location: null,
    type: 'text',
  },
  {
    instanceId: 'inst_102',
    content:
      'Perfect fit for what I needed. The customer service team was incredibly helpful.',

    rating: 5,
    status: ReviewStatus.PUBLISHED,
    reviewer: {
      _id: 'rev_102',
      instanceId: 'inst_102',
      email: 'david.lee@example.com',
      fullName: 'David Lee',
      avatar: 'https://i.pravatar.cc/150?img=5',
      created_at: '2024-03-04T08:15:00Z',
      updated_at: '2024-03-04T08:15:00Z',
    },
    verified: true,
    platform: ReviewerPlatform.WIDGET_REVIEW,
    helpfulCount: 9,
    pictures: [],
    reviewDate: '2024-03-11T13:20:00Z',
    _id: 'review_102',
    id: 'review_102',
    created_at: '2024-03-11T13:20:00Z',
    updated_at: '2024-03-11T13:20:00Z',
    product: null,
    reply: null,
    location: null,
    type: 'text',
  },
  {
    instanceId: 'inst_103',
    content:
      "I was skeptical at first, but this product completely changed my mind. The build quality is exceptional, and it performs even better than advertised. After using it for a month, I can confidently say it's one of the best purchases I've made this year. The attention to small details really sets it apart from similar products.",
    rating: 5,
    status: ReviewStatus.PUBLISHED,
    reviewer: {
      _id: 'rev_103',
      instanceId: 'inst_103',
      email: 'rachel.chen@example.com',
      fullName: 'Rachel Chen',
      avatar: 'https://i.pravatar.cc/150?img=6',
      created_at: '2024-03-05T10:30:00Z',
      updated_at: '2024-03-05T10:30:00Z',
    },
    verified: true,
    platform: ReviewerPlatform.WIDGET_REVIEW,
    helpfulCount: 11,
    pictures: [],
    reviewDate: '2024-03-10T15:45:00Z',
    _id: 'review_103',
    id: 'review_103',
    created_at: '2024-03-10T15:45:00Z',
    updated_at: '2024-03-10T15:45:00Z',
    product: null,
    reply: null,
    location: null,
    type: 'text',
  },
  {
    instanceId: 'inst_105',
    content:
      "This product has become an essential part of my daily routine. The build quality is exceptional, and the functionality is exactly what I needed. The company clearly put a lot of thought into the user experience. One small suggestion would be to include more detailed care instructions, but otherwise, it's perfect.",
    rating: 5,
    status: ReviewStatus.PUBLISHED,
    reviewer: {
      _id: 'rev_105',
      instanceId: 'inst_105',
      email: 'sophia.patel@example.com',
      fullName: 'Sophia Patel',
      avatar: 'https://i.pravatar.cc/150?img=8',
      created_at: '2024-03-07T15:20:00Z',
      updated_at: '2024-03-07T15:20:00Z',
    },
    verified: true,
    platform: ReviewerPlatform.WIDGET_REVIEW,
    helpfulCount: 14,
    pictures: [
      'https://picsum.photos/400/300?random=11',
      'https://picsum.photos/400/300?random=12',
    ],
    reviewDate: '2024-03-08T16:20:00Z',
    _id: 'review_105',
    id: 'review_105',
    created_at: '2024-03-08T16:20:00Z',
    updated_at: '2024-03-08T16:20:00Z',
    product: null,
    reply: null,
    location: null,
    type: 'text',
  },
  {
    instanceId: 'inst_106',
    content: 'Quick delivery and great packaging. Works as advertised.',
    rating: 4,
    status: ReviewStatus.PUBLISHED,
    reviewer: {
      _id: 'rev_106',
      instanceId: 'inst_106',
      email: 'tom.wilson@example.com',
      fullName: 'Tom Wilson',
      avatar: 'https://i.pravatar.cc/150?img=9',
      created_at: '2024-03-08T09:10:00Z',
      updated_at: '2024-03-08T09:10:00Z',
    },
    verified: true,
    platform: ReviewerPlatform.WIDGET_REVIEW,
    helpfulCount: 3,
    pictures: ['https://picsum.photos/400/300?random=13'],
    reviewDate: '2024-03-07T11:45:00Z',
    _id: 'review_106',
    id: 'review_106',
    created_at: '2024-03-07T11:45:00Z',
    updated_at: '2024-03-07T11:45:00Z',
    product: null,
    reply: null,
    location: null,
    type: 'text',
  },
];

export default reviews;
