import { SubscriptionPlan } from '../types/review.types';

type Params = {
  currentPlan: SubscriptionPlan;
  nextPlan: string;
};

export const QUOTA_EXHAUSTED =
  "You've reached the limit on the {{currentPlan}} plan. Please upgrade to the {{nextPlan}} plan or above to unlock more features.";

export const REQUIRE_HIGHER_PLAN =
  'Please upgrade to the {{nextPlan}} plan to use this feature.';

export const GENERIC_UPGRADE_MESSAGE =
  'This is a premium feature. Please, upgrade to Professional plan or above to unlock this feature.';

export const getUpgradeToNextPlanMessageForQuota = ({
  currentPlan,
  nextPlan,
}: Params) => {
  return QUOTA_EXHAUSTED.replace('{{currentPlan}}', currentPlan).replace(
    '{{nextPlan}}',
    nextPlan
  );
};

export const getNextHigherPlan = (plan: SubscriptionPlan) => {
  if (plan === SubscriptionPlan.FREE) {
    return 'Pro';
  }

  if ([SubscriptionPlan.PRO, SubscriptionPlan.ONETIME_PRO].includes(plan)) {
    return 'Advanced';
  }

  if (
    [SubscriptionPlan.ADVANCED, SubscriptionPlan.ONETIME_ADVANCED].includes(
      plan
    )
  ) {
    return 'Premium';
  }

  return '';
};
