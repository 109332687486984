import initials from 'initials';
import { FaCheck } from 'react-icons/fa6';
import { Avatar, Rate } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import generateColorHsl from '../../../../../shared/utils/generateColorHsl';
import isValidNumber from '../../../../../shared/utils/isValidNumber';
import { Review as ReviewType } from '../../../../manage-reviews/redux/reviews.slice';

/* eslint no-console: "warn" */

const Wrapper = styled(FlexContainer)`
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  color: #000;

  &:hover {
    color: #fff;
    background-color: #000;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  }

  .reviewer-name {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 2px;
  }

  svg.check-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
  }

  &:hover {
    svg.check-icon {
      display: block;
    }
  }

  ul.rs-rate {
    gap: 0 !important;
  }
`;

const AvatarWrapper = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
`;

const StyledAvatar = styled(Avatar)<{ color: string; avatar: string }>`
  color: #fff;
  background-color: ${({ color }) => color};
  font-size: 14px;
  font-weight: bolder;
`;

type Props = {
  review: ReviewType;
  onClick: (reviewId: string) => void;
};

export default function Review({ review, onClick }: Props) {
  const {
    rating,
    reviewer: { avatar, fullName },
    content,
  } = review;

  return (
    <Wrapper
      justifyContent="flex-start"
      alignItems="flex-start"
      gap="10px"
      role="presentation"
      onClick={() => onClick(review._id)}
    >
      <FaCheck size={20} color="#00ff00" className="check-icon" />
      <AvatarWrapper>
        <StyledAvatar
          circle
          color={avatar ? '#fff' : generateColorHsl(fullName)}
          src={avatar ?? ''}
          imgProps={{ referrerPolicy: 'no-referrer' }}
          avatar={avatar}
        >
          {initials(fullName).substring(0, 2).toUpperCase()}
        </StyledAvatar>
      </AvatarWrapper>
      <div className="review-content">
        <h4 className="reviewer-name">{fullName}</h4>

        <FlexContainer
          justifyContent="flex-start"
          gap="0"
          style={{ marginBottom: 10 }}
        >
          {rating && isValidNumber(rating as unknown as string) && (
            <Rate
              defaultValue={rating}
              allowHalf
              readOnly
              color="yellow"
              size="xs"
            />
          )}
        </FlexContainer>

        <span className="review-text">{content}</span>
      </div>
    </Wrapper>
  );
}
