import { useState } from 'react';
import { Input } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import FormRow from '../../../../widgetSettings/components/form-row';

const Wrapper = styled.div`
  width: 100%;
  h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 20px;
  }
`;

const Label = styled.label`
  margin-bottom: 5px;
`;

type Props = {
  onClose: () => void;
  isConnecting: boolean;
  onConnect: ({ apiKey, apiUrl }: { apiKey: string; apiUrl: string }) => void;
};

export default function ConnectActiveCampaign({
  onClose,
  onConnect,
  isConnecting,
}: Props) {
  const [apiUrl, setApiUrl] = useState('');
  const [apiKey, setApiKey] = useState('');

  const submitForm = () => {
    if (apiKey && apiUrl) {
      onConnect({ apiKey, apiUrl });
    }
  };

  return (
    <Wrapper>
      <h3>Connect ActiveCampaign</h3>

      <FormRow justifyContent="flex-start">
        <Label>API URL</Label>
        <Input
          value={apiUrl}
          onChange={setApiUrl}
          placeholder="https://123456demo.api-us1.com"
        />
      </FormRow>

      <FormRow justifyContent="flex-start">
        <Label>API Key</Label>
        <Input
          color="red"
          value={apiKey}
          onChange={setApiKey}
          placeholder="0a657e309924010983e8a746584da378720298399a0a9767822358968888db69ca109836a"
        />
      </FormRow>

      <FlexContainer justifyContent="flex-end" gap="10px">
        {!isConnecting && (
          <Button variant="tertiary" size="small" rounded onClick={onClose}>
            Cancel
          </Button>
        )}

        <Button
          rounded
          size="small"
          type="button"
          variant={`${isConnecting ? 'tertiary' : 'primary'}`}
          disabled={isConnecting}
          onClick={() => submitForm()}
        >
          {isConnecting ? 'Please wait...' : 'Connect'}
        </Button>
      </FlexContainer>
    </Wrapper>
  );
}
