import parseHtml from 'html-react-parser';
import { FaCircle } from 'react-icons/fa6';
import styled from 'styled-components';
import FlexContainer from '../../../../../../shared/components/layouts/flex-container';
import useTheme from '../../../../../../shared/hooks/useTheme';
import { useAppSelector } from '../../../../../../store';
import { emailsSelector } from '../../../../../emails/redux/emails.slice';
import { useIniviteCustomerContext } from './InviteCustomerProvider';

const Wrapper = styled.div<{ $pageBackgroundColor?: string }>`
  margin: 0 auto;
  background-color: ${({ $pageBackgroundColor }) => $pageBackgroundColor};
  padding: 20px;
  height: 100%;
`;

const Header = styled.div`
  background-color: white;
  border-bottom: 1px solid #eee;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  p {
    padding: 5px 15px;
    margin: 0;
  }
  p.subject {
    font-weight: 600;
    border-top: 1px solid #ededed;
    padding: 10px 15px;
  }
`;

const Content = styled.div`
  background-color: white;
  padding: 20px;
  border: 1px solid #ededed;
  max-width: 450px;
  margin: 70px auto 0;
  border-radius: 10px;
`;
const Button = styled(FlexContainer)`
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 16px;
`;

const ImageContainer = styled(FlexContainer)`
  margin-bottom: 30px;
`;
const UploadedImage = styled.img`
  max-height: 50px;
  object-fit: contain;
`;
const HtmlContentWrapper = styled.div`
  p {
    margin: 0;
  }
`;
const Info = styled.p`
  font-size: 12px;
  color: #666;
  margin: 0;
`;
const TestimonialLink = styled.p`
  font-size: 12px;
  color: #a78bfa;
  margin: 0;
`;
const Preview = styled.div`
  background-color: #f9fafb;
  height: 100%;
  border-radius: 10px;
`;
const Top = styled.div`
  padding: 10px 15px;
  border-bottom: 2px solid #f9fafb;
  span {
    font-size: 13px;
    font-weight: bold;
    color: #878787;
  }
`;

const OrderDetails = styled.div`
  margin: 10px 0;
  padding: 10px 0;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  img {
    max-width: 60px;
    max-height: 60px;
  }
`;

export function InviteCustomerPreview() {
  const {
    testimonialLink,
    formValues,
    formattedSubject,
    formattedMessage,
    toEmail,
    toName,
    ownerEmail,
    replyFromName,
    callToAction,
    feedbackMessageText,
  } = useIniviteCustomerContext();
  const { logo } = formValues;
  const { selectedContacts } = useAppSelector(emailsSelector);
  let toContact = toEmail ? `${toName} <${toEmail}>` : '';
  let orderNumber = null;
  let productImage = null;
  let productName = null;

  if (!!selectedContacts.length) {
    const {
      firstName,
      lastName,
      email,
      productImage: _productImage,
      productName: _productName,
      orderNumber: _orderNumber,
    } = selectedContacts[0];
    orderNumber = _orderNumber;
    productImage = _productImage;
    productName = _productName;
    toContact = `${firstName} ${lastName} <${email}>`;
  }

  const { pageBackgroundColor } = useTheme() ?? {};

  return (
    <Wrapper $pageBackgroundColor={pageBackgroundColor}>
      <Preview>
        <Header>
          <Top>
            <FlexContainer gap="10px" justifyContent="space-between">
              <FlexContainer gap="5px">
                <FaCircle color="#EF4544" size={13} />
                <FaCircle color="#EAB306" size={13} />
                <FaCircle color="#22C55E" size={13} />
              </FlexContainer>
              <span>New message</span>
              <span></span>
            </FlexContainer>
          </Top>
          <p>From: {ownerEmail ? `${replyFromName} <${ownerEmail}>` : ''}</p>
          <p>To: {toContact}</p>
          <p className="subject">{formattedSubject}</p>
        </Header>
        <Content>
          {logo && (
            <ImageContainer>
              <UploadedImage src={logo} alt="Uploaded Logo" />
            </ImageContainer>
          )}

          <HtmlContentWrapper>{parseHtml(formattedMessage)}</HtmlContentWrapper>

          {orderNumber && productName && (
            <OrderDetails>
              <p>Order Number: #{orderNumber}</p>
              <FlexContainer justifyContent="flex-start" gap="5pz">
                {productImage && <img src={productImage} alt={productName} />}
                <span>{productName}</span>
              </FlexContainer>
            </OrderDetails>
          )}

          <Button>{callToAction || 'Leave a review'}</Button>
          <Info>
            {feedbackMessageText ||
              'Button not working? Paste this URL in the browser'}
          </Info>
          <TestimonialLink>{testimonialLink}</TestimonialLink>
        </Content>
      </Preview>
    </Wrapper>
  );
}
