import { FaCircleInfo } from 'react-icons/fa6';
import { Button, Message } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import { ReviewsJetPlatform } from '../../../product/types';

const StyledMessage = styled(Message)`
  .rs-message-container {
    background-color: #f9f9f9;
  }
`;

export default function HowToInstallBanner({
  widget,
  setOpen,
  onClick,
}: {
  widget: string;
  onClick: () => void;
  setOpen: (platform: ReviewsJetPlatform) => void;
}) {
  return (
    <StyledMessage>
      <FlexContainer
        gap="20px"
        justifyContent="space-between"
        style={{ width: '100%' }}
      >
        <div>Click to learn how to install the {widget} Widget</div>
        <div>
          <Button block appearance="primary" color="violet" onClick={onClick}>
            <FlexContainer gap="10px">
              <FaCircleInfo />
              <span>How to install?</span>
            </FlexContainer>
          </Button>
        </div>
      </FlexContainer>
    </StyledMessage>
  );
}
