import dayjs from 'dayjs';
import styled from 'styled-components';
import FlexContainer from '../../../../../../../shared/components/layouts/flex-container';
import ReviewRating from '../../../../../../../shared/svgs/ReviewRating';
import getInitialAvatar from '../../../../../../../shared/utils/getInitialAvatar';
import getReviewerFullName from '../../../../../../../shared/utils/getReviewerFullName';
import { Review } from '../../../../../../manage-reviews/redux/reviews.slice';
import getReviewContent from '../../../../../../manage-reviews/utils/getReviewContent';
import {
  BrandingSettings,
  EmbeddedWidgetSettings,
  TranslationType,
} from '../../../../../../widgetSettings/redux/widgetSettings.slice';

const Reviewer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  img {
    width: 100%;
  }
`;
const ReviewTitle = styled.span<{ color: string; fontFamily: string }>`
  font-size: 15px;
  line-height: 20px;
  display: block;
  margin-top: 10px;
  font-weight: bold;
  color: ${({ color }) => color};
  font-family: ${({ fontFamily }) => fontFamily};
  text-align: left;

  @media (max-width: 644px) {
    font-size: 13px;
  }
`;

const ReviewerAvatar = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  text-align: center;
  position: relative;

  @media (max-width: 644px) {
    height: 30px;
    width: 30px;
  }
`;

const ReviewerInitial = styled.div<{ $backgroundColor: string }>`
  color: #fff;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  border-radius: 50%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};

  @media (max-width: 644px) {
    height: 30px;
    width: 30px;
  }
`;
const ReviewerName = styled.span<{ color: string; fontFamily: string }>`
  font-size: 15px;
  font-weight: bold;
  color: ${({ color }) => color};
  font-family: ${({ fontFamily }) => fontFamily};

  @media (max-width: 644px) {
    font-size: 13px;
  }
`;
const ReviewerDate = styled.span<{ color: string; fontFamily: string }>`
  font-size: 12px;
  color: ${({ color }) => color};
  display: block;
  font-family: ${({ fontFamily }) => fontFamily};
`;
const ReviewContent = styled.p<{ color: string; fontFamily: string }>`
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 25px;
  text-align: left;
  margin: 10px 0 0;
  font-size: 14px;
  color: ${({ color }) => color};
  font-family: ${({ fontFamily }) => fontFamily};

  @media (max-width: 644px) {
    font-size: 12px;
  }
`;
const ReadMore = styled.span<{ color: string }>`
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
  color: ${({ color }) => color};
  &:hover {
    text-decoration: underline;
  }
`;

const ReviewerPic = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
`;

type Props = {
  review: Review;
  translation: TranslationType;
  branding: BrandingSettings;
  widgetSettings: EmbeddedWidgetSettings;
};

const TextReview = ({
  review,
  widgetSettings,
  branding,
  translation,
}: Props) => {
  const { linkColor, dateColor, starColor, textColor, showReviewDate } =
    widgetSettings;
  const { verifiedReviewer, readMore } = translation;
  const {
    dateFormat = 'DD/MM/YYYY',
    showFullName = true,
    fontFamily,
    useMyWebsiteFontFamily,
  } = branding;
  const reviewerFullname = getReviewerFullName(
    showFullName,
    review.reviewer,
    verifiedReviewer
  );

  const initial = getInitialAvatar(reviewerFullname);
  const reviewDetails = getReviewContent(review.content, 30);

  let reviewDate = review.reviewDate;

  try {
    reviewDate = dayjs(review.reviewDate).format(dateFormat);

    if (reviewDate === 'Invalid Date') {
      reviewDate = review.reviewDate;
    }
  } catch (error) {
    reviewDate = review.reviewDate;
  }

  return (
    <>
      <Reviewer>
        <ReviewerAvatar>
          {review.reviewer.avatar ? (
            <ReviewerPic
              src={review.reviewer.avatar}
              alt={review.reviewer.fullName}
              referrerPolicy="no-referrer"
            />
          ) : (
            <ReviewerInitial
              $backgroundColor={
                getInitialAvatar(reviewerFullname).backgroundColor
              }
            >
              {initial.avatar}
            </ReviewerInitial>
          )}
        </ReviewerAvatar>
        <FlexContainer
          stack
          justifyContent="flex-start"
          alignItems="flex-start"
          gap="5px"
        >
          <ReviewerName
            color={linkColor}
            fontFamily={useMyWebsiteFontFamily ? '' : fontFamily}
          >
            {reviewerFullname}
          </ReviewerName>
          {/* {showReviewDate && ( */}
          <ReviewerDate
            color={dateColor}
            fontFamily={useMyWebsiteFontFamily ? '' : fontFamily}
          >
            {reviewDate}
          </ReviewerDate>
          {/* )} */}
        </FlexContainer>
      </Reviewer>
      <FlexContainer justifyContent="space-between">
        <ReviewRating size={20} rating={review.rating} color={starColor} />
      </FlexContainer>
      {review.title && (
        <ReviewTitle
          color={textColor}
          fontFamily={useMyWebsiteFontFamily ? '' : fontFamily}
        >
          {review.title}
        </ReviewTitle>
      )}

      <ReviewContent
        color={textColor}
        fontFamily={useMyWebsiteFontFamily ? '' : fontFamily}
      >
        <span
          dangerouslySetInnerHTML={{ __html: reviewDetails.reviewMessage }}
        />
        {reviewDetails.showReadMore && (
          <ReadMore color={textColor}>{readMore}</ReadMore>
        )}
      </ReviewContent>
    </>
  );
};

export default TextReview;
