import { MappedField } from '..';

function findKeyInMappedField(mappedFields: MappedField, header: string) {
  return Object.keys(mappedFields).find(
    // @ts-ignore
    (key) => mappedFields[key]?.value === header
  );
}

export default findKeyInMappedField;
