import { useEffect } from 'react';
import { batch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Preloader from '../../shared/components/Preloader';
import { Loading } from '../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../store';
import { getBusinessInfo } from '../settings/redux/actions';
import { getSite } from '../userData/redux/action';
import { accountSelector } from '../userData/redux/userData.slice';
import AskForReviews from './components/AskForReviews';
import ImportReviews from './components/import-reviews';
import InstallWidgets from './components/InstallWidgets';
import PersonalInformation from './components/PersonalInformation';
import StartFreeTrial from './components/StartFreeTrial';
import {
  OnboardingStep,
  setCurrentStep,
  setPersonalInformation,
} from './redux/onboarding.slice';
import updateOnboarding from './services/updateOnboarding';

/* eslint no-console: "warn" */

export default function Onboarding() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentStep: step, hasExistingTestimonials } = useAppSelector(
    (state) => state.onboarding
  );

  const { account, loading } = useAppSelector(accountSelector);
  const { ownerName = '', selectedSite } = account ?? {};
  const { onboarding, url = '' } = selectedSite ?? {};
  const {
    sellProduct,
    hasExistingReviews,
    reference,
    step: savedStep,
  } = onboarding ?? {};

  useEffect(() => {
    const handleUrlChange = () => {
      const params = new URLSearchParams(window.location.search);
      const urlStep = params.get('step');
      if (
        urlStep &&
        Object.values(OnboardingStep).includes(urlStep as OnboardingStep)
      ) {
        dispatch(setCurrentStep(urlStep as OnboardingStep));
      }
    };

    window.addEventListener('popstate', handleUrlChange);
    return () => window.removeEventListener('popstate', handleUrlChange);
  }, [step]);

  useEffect(() => {
    batch(() => {
      dispatch(getSite({ showPreloader: true }));
      dispatch(getBusinessInfo());
    });
  }, []);

  useEffect(() => {
    if (savedStep) {
      dispatch(setCurrentStep(savedStep));
    }
  }, [savedStep]);

  const updateStep = async (nextStep: OnboardingStep) => {
    dispatch(setCurrentStep(nextStep));

    await updateOnboarding({
      nextStep: nextStep,
    });
  };

  useEffect(() => {
    if (loading === Loading.SUCCESS) {
      dispatch(
        setPersonalInformation({
          firstName: ownerName.split(' ')[0],
          lastName: ownerName.split(' ')[1],
          websiteUrl: url,
          reference,
          sellProducts: !!sellProduct ? 'Yes' : 'No',
          hasExistingTestimonials: !!hasExistingReviews ? 'Yes' : 'No',
        })
      );
    }
  }, [loading, ownerName, url, sellProduct, hasExistingReviews, reference]);

  if (loading === Loading.PENDING) {
    return <Preloader />;
  }

  if (step === OnboardingStep.PersonalInformation) {
    return (
      <PersonalInformation
        onNext={(hasExistingTestimonials: string) => {
          if (hasExistingTestimonials === 'Yes') {
            updateStep(OnboardingStep.ImportReviews);
          } else {
            updateStep(OnboardingStep.AskForReviews);
          }
        }}
      />
    );
  }

  if (step === OnboardingStep.ImportReviews) {
    return (
      <ImportReviews
        onNext={() => updateStep(OnboardingStep.InstallWidget)}
        onPrevious={() => updateStep(OnboardingStep.PersonalInformation)}
      />
    );
  }

  if (step === OnboardingStep.AskForReviews) {
    return (
      <AskForReviews
        onNext={() => updateStep(OnboardingStep.InstallWidget)}
        onPrevious={() => updateStep(OnboardingStep.PersonalInformation)}
      />
    );
  }

  if (step === OnboardingStep.InstallWidget) {
    return (
      <InstallWidgets
        onNext={() => updateStep(OnboardingStep.StartFreeTrial)}
        onPrevious={() => {
          if (hasExistingTestimonials === 'Yes') {
            updateStep(OnboardingStep.ImportReviews);
          } else {
            updateStep(OnboardingStep.AskForReviews);
          }
        }}
      />
    );
  }

  if (step === OnboardingStep.StartFreeTrial) {
    return (
      <StartFreeTrial
        onComplete={() => {
          updateStep(OnboardingStep.Complete);
          navigate('/manage-reviews');
        }}
      />
    );
  }

  return null;
}
