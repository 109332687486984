import Papa from 'papaparse';
import { FaFileAlt } from 'react-icons/fa';
import { IoMdHelpBuoy } from 'react-icons/io';
import { Button as RsButton, Uploader } from 'rsuite';
import { FileType } from 'rsuite/esm/Uploader';
import styled from 'styled-components';
import Button from '../../../shared/components/Button';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import { REVIEWS_TEMPLATE_FILE } from '../../../shared/utils/constants';
import { useAppSelector } from '../../../store';
import { accountSelector } from '../../userData/redux/userData.slice';

function blobToFile(theBlob: any, fileName: string) {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}

const SubTitle = styled(FlexContainer)`
  margin-top: 25px;
  margin-bottom: 20px;
  button {
    padding: 0;
    margin-left: 3px;
    margin-right: 3px;
  }
`;

const UploaderWrapper = styled(FlexContainer)`
  display: flex !important;
  height: 200px;
  margin-bottom: 20px;
  button {
    padding: 0;
    margin-left: 3px;
    margin-right: 3px;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const UploadCsv = ({
  file,
  onUpload,
  onNextClick,
}: {
  file: FileType | null;
  onNextClick: () => void;
  onUpload: (file: FileType, parsedFile: Record<string, string>[]) => void;
}) => {
  const { account } = useAppSelector(accountSelector);

  const downloadTemplate = () => {
    window.open(REVIEWS_TEMPLATE_FILE, '_blank');
  };

  const handleUpload = (fileLists: FileType[]) => {
    const uploaded = fileLists.pop();

    if (uploaded?.blobFile) {
      Papa.parse(uploaded.blobFile, {
        header: true,
        complete: function (results) {
          // @ts-ignore
          onUpload(uploaded, results.data);
        },
      });
    }
  };

  return (
    <>
      <SubTitle justifyContent="center" alignItems="center" gap="5px">
        <IoMdHelpBuoy />
        <FlexContainer justifyContent="flex-start">
          Need Help?
          <RsButton appearance="link" onClick={downloadTemplate}>
            Download our CSV template
          </RsButton>
          to make sure your CSV is formatted correctly.
        </FlexContainer>
      </SubTitle>

      <Uploader
        action=""
        draggable
        removable
        multiple={false}
        autoUpload={false}
        fileListVisible={false}
        onChange={handleUpload}
        shouldUpload={() => false}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      >
        <UploaderWrapper stack justifyContent="center" alignItems="center">
          <FlexContainer>
            {file ? (
              <FlexContainer gap="5px">
                <FaFileAlt size={16} />
                <FlexContainer gap="5px">
                  <span>{file?.name}</span>
                  <span>-</span>
                  <RsButton appearance="link">Change</RsButton>
                </FlexContainer>
              </FlexContainer>
            ) : (
              <>
                <span>Drag and drop your CSV file here or</span>
                <RsButton appearance="link">
                  select a CSV file to upload.
                </RsButton>
              </>
            )}
          </FlexContainer>
        </UploaderWrapper>
      </Uploader>
      <ButtonWrapper>
        <Button
          disabled={!file}
          onClick={onNextClick}
          variant="secondary"
          rounded
        >
          <FlexContainer gap="10px">Next: Review CSV upload</FlexContainer>
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default UploadCsv;
