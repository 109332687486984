import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ simple?: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  margin-top: 10px;

  .ql-container {
    /* border: none; */
  }
  .ql-toolbar {
    border-left-style: none;
    border-right-style: none;
    border-top-style: none;
  }

  ${({ simple }) =>
    simple &&
    css`
      .ql-toolbar.ql-snow {
        display: none;
      }

      .ql-toolbar.ql-snow + .ql-container.ql-snow {
        border-top: 1px;
        border-top-style: solid;
        border-top-color: #ccc;
        height: 190px;
        overflow-y: auto;
        border-radius: 6px;
      }
    `}
`;

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ align: ['right', 'center', 'justify'] }],
    ['link'],
  ],
};

const formats = ['bold', 'italic', 'underline', 'list', 'link', 'align'];

interface IQuillEditor {
  simple?: boolean;
  content: string;
  onChange: (text: string) => void;
}

export const QuillEditor = ({
  simple = false,
  content,
  onChange,
}: IQuillEditor) => {
  const [code, setCode] = useState<string>(content);
  const handleContentChange = (content: string) => {
    setCode(content);
    onChange(content);
  };

  useEffect(() => {
    setCode(content);
  }, [content]);

  return (
    <Wrapper simple={simple}>
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={code}
        onChange={handleContentChange}
      />
    </Wrapper>
  );
};
