import MiniMasonry from 'minimasonry';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useAppSelector } from '../../../../../../../store';
import { settingSelector } from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import useUpdateWidgetPreview from '../../hooks/useUpdateWidgetPreview';
import ReviewCardV2 from './ReviewCardV2';
import reviews from './reviews';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
`;

const WidgetContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 25px 0 0;
  margin: auto;
  width: 95%;
  position: relative;
`;

export default function WidgetPreview() {
  const masonryContainerRef = useRef<HTMLDivElement>(null);
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { masonryWidget, brandingSettings, translation } = siteWidgetSettings!;

  useUpdateWidgetPreview();

  useEffect(() => {
    let masonry: any;
    let debounceTimer: NodeJS.Timeout;

    if (masonryContainerRef.current) {
      masonry = new MiniMasonry({
        // @ts-ignore
        container: masonryContainerRef.current,
        // baseWidth: 300,
        gutter: 10,
        surroundingGutter: true,
      });

      masonryContainerRef.current.querySelectorAll('img').forEach((img) => {
        img.addEventListener('load', () => {
          clearTimeout(debounceTimer);
          debounceTimer = setTimeout(() => {
            masonry.layout();
          }, 100);
        });
      });
    }

    return () => {
      clearTimeout(debounceTimer);
      if (masonry && masonry.destroy) {
        masonry.destroy();
      }
    };
  }, [masonryWidget]);

  return (
    <Wrapper>
      <WidgetContainer ref={masonryContainerRef}>
        {reviews.map((review, index) => {
          return (
            <ReviewCardV2
              key={review._id}
              review={review}
              widgetSettings={masonryWidget}
              branding={brandingSettings}
              translation={translation}
            />
          );
        })}
      </WidgetContainer>
    </Wrapper>
  );
}
