import { unwrapResult } from '@reduxjs/toolkit';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import {
  ModalBody,
  ModalHeader,
} from '../../../../../shared/components/modal/modal-parts';
import StyledText from '../../../../../shared/components/styled-text';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import LinkIcon from '../../../../../shared/svgs/link-icon';
import MobileIcon from '../../../../../shared/svgs/mobile-icon';
import { Loading } from '../../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { useGoogleConnectContext } from '../../../contexts/GoogleLocationContext';
import { addGoogleAccount } from '../../../redux/actions';
import { socialReviewsSelector } from '../../../redux/social-reviews.slice';

const Container = styled.div`
  padding-top: 40px;
`;
const MainWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;
const ImageWithTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
  margin-bottom: 30px;
`;
const Image = styled.img`
  max-width: 85px;
  max-height: 85px;
  background: #f5f5f5;
  border-radius: 10px;
  padding: 1px;
  border: 1px solid #c2c2c2;
`;
const IconWithTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;
const InfoText = styled(StyledText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #414141;
`;
const CheckBox = styled.input`
  width: 16px;
  height: 16px;
`;
const CheckBoxWithTextWrapper = styled.label`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;
const ConfirmText = styled(StyledText)`
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  line-height: 16px;
  color: #5f5f5f;
`;

const ConfirmButton = styled(Button)<{ disabled?: boolean }>`
  padding-left: 30px;
  padding-right: 30px;
  margin: 30px auto 0 0;
  background-color: #000;
  border-color: #000;
  width: 100%;
`;

interface Props {
  onGoback: () => void;
  onConfirmed: () => void;
  resetCloseButton?: boolean;
}

const ConfirmBusiness = ({
  onGoback,
  onConfirmed,
  resetCloseButton,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { showErrorToast } = useNotification();
  const { selectedBusiness } = useGoogleConnectContext();
  const { addingGoogleAccount } = useAppSelector(socialReviewsSelector);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  const isLoading = addingGoogleAccount === Loading.PENDING;

  const handleConfirm = () => {
    if (selectedBusiness) {
      dispatch(
        addGoogleAccount({
          dataId: selectedBusiness.dataId,
          placeId: selectedBusiness.placeId,
          language: selectedBusiness.language,
          pageTitle: selectedBusiness.title,
        })
      )
        .then(unwrapResult)
        .then(onConfirmed)
        .catch(() => {
          showErrorToast(t('reviews.import-source.google.error-adding'));
        });
    }
  };

  return (
    <>
      <ModalHeader
        onGoBackClick={onGoback}
        closeButton={false}
        title="Confirm your business"
        headerline={false}
        resetCloseButton={resetCloseButton}
      />
      <ModalBody>
        <Container>
          <MainWrapper>
            <ImageWithTextWrapper>
              <Image src={selectedBusiness?.thumbnail} />
              <FlexContainer
                stack
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <StyledText fontWeight={700} style={{ marginBottom: 10 }}>
                  {selectedBusiness?.title}
                </StyledText>
                <IconWithTextWrapper>
                  <LinkIcon />
                  <InfoText>{selectedBusiness?.address}</InfoText>
                </IconWithTextWrapper>

                <IconWithTextWrapper>
                  <MobileIcon />
                  <InfoText>{selectedBusiness?.phone ?? 'n/a'}</InfoText>
                </IconWithTextWrapper>
              </FlexContainer>
            </ImageWithTextWrapper>

            <Divider />

            <CheckBoxWithTextWrapper>
              <CheckBox
                type="checkbox"
                onChange={handleCheckBox}
                defaultChecked={isChecked}
              />
              <ConfirmText
                onClick={() => {
                  setIsChecked(!isChecked);
                }}
              >
                {t('reviews.import-source.confirm-review')}{' '}
                {selectedBusiness?.title}
              </ConfirmText>
            </CheckBoxWithTextWrapper>

            <ConfirmButton
              size="large"
              variant="primary"
              disabled={!isChecked || isLoading}
              onClick={handleConfirm}
            >
              <b>{isLoading ? 'Please wait' : 'Import reviews'}</b>
            </ConfirmButton>
          </MainWrapper>
        </Container>
      </ModalBody>
    </>
  );
};

export default ConfirmBusiness;
