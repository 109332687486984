import { useState } from 'react';
import { batch } from 'react-redux';
import styled from 'styled-components';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  settingSelector,
  setWidgetSettingsUpdated,
  updateSiteWidgetSettings,
} from '../../../../widgetSettings/redux/widgetSettings.slice';
import ReviewCardTemplate from './ReviewCardTemplate';

/* eslint no-console: "warn" */

export type Template = {
  nameColor: string;
  dateColor: string;
  starColor: string;
  textColor: string;
  bgColor: string;
  borderColor: string;
  name: string;
};

const templates: Template[] = [
  {
    nameColor: '#000000',
    dateColor: '#000',
    starColor: '#000',
    textColor: '#000',
    bgColor: '#ffffff',
    borderColor: '#e3e3e3',
    name: 'template-1',
  },

  {
    nameColor: '#002fda',
    dateColor: '#95a5a6',
    starColor: '#f79f1f',
    textColor: '#273c75',
    bgColor: '#f8f8f8',
    borderColor: '#f8f8f8',
    name: 'template-2',
  },
  {
    nameColor: '#ececec',
    dateColor: '#ececec',
    starColor: '#5ca0ff',
    textColor: '#ececec',
    bgColor: '#000',
    borderColor: '#000',
    name: 'template-3',
  },
  {
    nameColor: '#6baeff',
    dateColor: '#b2bec3',
    starColor: '#ffd700',
    textColor: '#ffffff',
    bgColor: '#0f1b36',
    borderColor: '#0f1b36',
    name: 'template-4',
  },
];

const Wrapper = styled.div`
  p {
    margin-bottom: 10px;
  }
`;

const TemplatesList = styled(FlexContainer)``;

type WidgetType = 'slider' | 'marquee' | 'masonry' | 'grid' | 'list';

const widgetPropertyMap = {
  slider: 'carouselSliderWidget',
  marquee: 'carouselMarqueeWidget',
  masonry: 'masonryWidget',
  grid: 'gridWidget',
  list: 'listWidget',
} as const;

interface TemplatesProps {
  widgetType: WidgetType;
}

export const Templates = ({ widgetType }: TemplatesProps) => {
  const dispatch = useAppDispatch();
  const [selectedTemplateName, setSelectedTemplateName] =
    useState<string>('template-1');

  const { siteWidgetSettings } = useAppSelector(settingSelector);

  const handleTemplateSelect = (selectedTemplate: Template) => {
    setSelectedTemplateName(selectedTemplate.name);

    batch(() => {
      const widgetProperty = widgetPropertyMap[widgetType];

      const updates = {
        [widgetProperty]: {
          ...siteWidgetSettings?.[widgetProperty],
          reviewCardColor: selectedTemplate.bgColor,
          reviewCardColorOpacity: 100,
          dateColor: selectedTemplate.dateColor,
          starColor: selectedTemplate.starColor,
          textColor: selectedTemplate.textColor,
          linkColor: selectedTemplate.nameColor,
          borderColor: selectedTemplate.borderColor,
          showBorder: true,
        },
      };

      dispatch(
        updateSiteWidgetSettings({
          ...siteWidgetSettings,
          ...updates,
        })
      );
      dispatch(setWidgetSettingsUpdated());
    });
  };

  return (
    <Wrapper>
      <p>Choose a template below:</p>

      <TemplatesList stack flexWrap="wrap" gap="10px">
        {templates.map((template) => (
          <ReviewCardTemplate
            key={template.name}
            onClick={() => handleTemplateSelect(template)}
            template={template}
            isSelected={selectedTemplateName === template.name}
          />
        ))}
      </TemplatesList>
    </Wrapper>
  );
};
