import { useState } from 'react';
import Http from '../../../../../../../services/http';
import { Loading } from '../../../../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../../../../shared/utils/removeTrailingSlash';
import { ContactItem } from '../../../../../../emails/redux/emails.slice';
/* eslint no-console: "warn" */

const http = new Http();

export default function useActiveCampaign() {
  const [activeCampaignContacts, setKActiveCampaignContacts] = useState<
    ContactItem[]
  >([]);
  const [loading, setLoading] = useState(Loading.INITIAL);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loadingInitialContacts, setLoadingInitialContacts] = useState(
    Loading.INITIAL
  );

  const getActiveCampaignContacts = async () => {
    if (loading === Loading.PENDING) {
      return null;
    }

    if (page > 1) {
      setLoading(Loading.PENDING);
    } else {
      setLoadingInitialContacts(Loading.PENDING);
    }

    try {
      let url = `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/active-campaign/contacts?page=${page}`;

      const response = await http.get(url);

      const {
        contacts,
        meta: { hasMore },
      } = response.data;

      setPage(page + 1);
      setKActiveCampaignContacts([...activeCampaignContacts, ...contacts]);
      setHasMore(hasMore);

      if (page > 1) {
        setLoading(Loading.SUCCESS);
      } else {
        setLoadingInitialContacts(Loading.SUCCESS);
      }
    } catch (error) {
      if (page > 1) {
        setLoading(Loading.ERROR);
      } else {
        setLoadingInitialContacts(Loading.ERROR);
      }
    }
  };

  return {
    activeCampaignContacts,
    getActiveCampaignContacts,
    hasMoreActiveCampaignContacts: hasMore,
    loadingActiveCampaignContacts: loading,
    loadingInitialCampaignContacts: loadingInitialContacts,
  };
}
