const getReviewContent = (content: string, limit = 30) => {
  let showReadMore = false;
  let reviewMessage = content;
  const contentWordsArr = content?.split(' ') ?? [];

  if (contentWordsArr.length > limit) {
    showReadMore = true;
    reviewMessage = `${contentWordsArr.slice(0, limit).join(' ')}...`;
  }

  return {
    showReadMore,
    reviewMessage,
  };
};

export default getReviewContent;
