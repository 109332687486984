import { axiosPrivate } from '../../../services/axiosPrivate';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { OnboardingStep } from '../redux/onboarding.slice';

type Params = {
  ownerName?: string;
  websiteUrl?: string;
  hasExistingTestimonials?: string;
  reference?: string;
  sellProducts?: string;
  nextStep: OnboardingStep;
};

export default async function updateOnboarding(payload: Params) {
  await axiosPrivate.post(
    `${removeTrailingSlash(
      process.env.REACT_APP_REVIEWS_JET_API!
    )}/onboarding/update`,
    payload
  );
}
