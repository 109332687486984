import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Button, Message } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import {
  ModalBody,
  ModalHeader,
} from '../../../../../shared/components/modal/modal-parts';
import OpenNewTab from '../../../../../shared/components/OpenNewTab';
import StyledText from '../../../../../shared/components/styled-text';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import { Loading } from '../../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../../shared/utils/removeTrailingSlash';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { TransformedProduct } from '../../../../product/types';
import { WIX_PRODUCT_PAGE_PATH } from '../../../../review/utils/constants';
import { accountSelector } from '../../../../userData/redux/userData.slice';
import { addAmazonProductPage } from '../../../redux/actions';
import { socialReviewsSelector } from '../../../redux/social-reviews.slice';

const ConfirmText = styled(StyledText)`
  font-size: 14px;
  color: #7b7b7b;
`;

const StyledLink = styled.a`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding: 7px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #f5f5f5;
  color: #444444;
  &:hover {
    color: #000;
    background-color: #f4f4f4;
  }
  span {
    display: inline-block;
    width: calc(95%);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const Wrapper = styled.div`
  padding-top: 20px;
`;
const StyledMessage = styled(Message)`
  margin-bottom: 20px;
`;

const ContinueButton = styled(Button)<{ disabled?: boolean }>`
  padding-left: 30px;
  padding-right: 30px;
`;

const Confirm = ({
  product,
  onConfirm,
  onPrevious,
  productPageUrl,
}: {
  onConfirm: () => void;
  onPrevious?: () => void;
  productPageUrl: string;
  product: TransformedProduct | null;
}) => {
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { plan } = selectedSite ?? {};
  // const ref = createRef<HTMLInputElement>();
  const { t } = useTranslation('common');
  const { addingAmazonProduct } = useAppSelector(socialReviewsSelector);

  const { showErrorToast, showSuccessToast, showWarningToast } =
    useNotification();

  const { selectedSite: site } = account ?? {};

  const addAamzonProduct = () => {
    try {
      dispatch(
        addAmazonProductPage({
          product,
          search: productPageUrl,
        })
      )
        .then(unwrapResult)
        .then(() => {
          showSuccessToast(
            `Your reviews are being imported! It'll just take 3 to 5 minutes. We'll ping you once they're in.`
          );
          onConfirm();
        })
        .catch(() => {
          showErrorToast('Something went wrong. Please try it again.');
        });
    } catch (error) {
      return showWarningToast(
        'Please add a valid Amazon product page URL that starts with "www".'
      );
    }
  };

  if (!site) {
    return null;
  }

  return (
    <>
      <ModalHeader
        title="Select a product"
        centerText={false}
        closeButton={false}
      />
      <ModalBody>
        <Wrapper>
          <div>
            <ConfirmText>
              Please confirm that you want to import reviews from the Amazon
              product page:
            </ConfirmText>
            <StyledLink
              href={decodeURIComponent(productPageUrl)}
              target="_blank"
            >
              <span>{decodeURIComponent(productPageUrl)}</span>
              <OpenNewTab />
            </StyledLink>
            <ConfirmText>to your Wix product page:</ConfirmText>

            <StyledLink
              href={`${removeTrailingSlash(
                site?.url
              )}/${WIX_PRODUCT_PAGE_PATH}/${product?.slug}`}
              target="_blank"
            >
              <span>
                {`${removeTrailingSlash(site?.url)}/${WIX_PRODUCT_PAGE_PATH}/${
                  product?.slug
                }`}
              </span>
              <OpenNewTab />
            </StyledLink>

            <FlexContainer
              justifyContent="flex-start"
              gap="15px"
              style={{ paddingTop: 20 }}
            >
              {addingAmazonProduct !== Loading.PENDING && (
                <Button onClick={onPrevious}>
                  <FlexContainer gap="5px">
                    <MdKeyboardArrowLeft />
                    Back
                  </FlexContainer>
                </Button>
              )}
              <ContinueButton
                appearance={
                  addingAmazonProduct === Loading.PENDING ? 'subtle' : 'primary'
                }
                size="md"
                onClick={addAamzonProduct}
              >
                {addingAmazonProduct === Loading.PENDING
                  ? t('shared.please-wait')
                  : 'Confirm & Import'}
              </ContinueButton>
            </FlexContainer>
          </div>
        </Wrapper>
      </ModalBody>
    </>
  );
};

export default Confirm;
