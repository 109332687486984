import { useState } from 'react';
import Http from '../../../../../../../services/http';
import { Loading } from '../../../../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../../../../shared/utils/removeTrailingSlash';
import { ContactItem } from '../../../../../../emails/redux/emails.slice';

/* eslint no-console: "warn" */

const http = new Http();

export default function useShopifyOrders() {
  const [hasNext, setHasNext] = useState(false);
  const [orders, setOrders] = useState<ContactItem[]>([]);
  const [cursor, setCursor] = useState<string | null>(null);

  const [loadingShopifyOrders, setLoadingShopifyOrders] = useState(
    Loading.INITIAL
  );
  const [loadingInitialShopifyOrders, setLoadingInitialShopifyOrders] =
    useState(Loading.INITIAL);

  const getShopifyOrders = async () => {
    try {
      if (cursor) {
        setLoadingShopifyOrders(Loading.PENDING);
      } else {
        setLoadingInitialShopifyOrders(Loading.PENDING);
      }

      let url = `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/shopify/orders`;

      if (cursor) {
        url += `?cursor=${cursor}`;
      }

      const response = await http.get(url);

      const { orders: _orders, next } = response.data;
      const hasNext = !!next?.hasNext;
      const nextCursor = next?.nextCursor;

      setHasNext(hasNext);
      setCursor(nextCursor);
      setOrders([...orders, ..._orders]);

      if (cursor) {
        setLoadingShopifyOrders(Loading.SUCCESS);
      } else {
        setLoadingInitialShopifyOrders(Loading.SUCCESS);
      }
    } catch (error) {
      if (cursor) {
        setLoadingShopifyOrders(Loading.ERROR);
      } else {
        setLoadingInitialShopifyOrders(Loading.ERROR);
      }
    }
  };

  return {
    getShopifyOrders,
    loadingShopifyOrders,
    shopifyOrders: orders,
    shopifyHasNext: hasNext,
    loadingInitialShopifyOrders,
  };
}
