import { unwrapResult } from '@reduxjs/toolkit';
import { useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { FaCode } from 'react-icons/fa6';
import { Divider, Drawer, Button as RsButton, Tabs, Toggle } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../../shared/components/Button';
import ColorPicker from '../../../../../shared/components/colorPicker';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import AppModal from '../../../../../shared/components/modal';
import useModal from '../../../../../shared/components/modal/useModal';
import useInstallation, {
  Widget,
} from '../../../../../shared/hooks/useInstallation';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import capitalize from '../../../../../shared/utils/capitalize';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { AuthTitle } from '../../../../external/components';
import { ReviewsJetPlatform } from '../../../../product/types';
import ModalWidgetOthers from '../../../../tutorials/components/others/modal-widget';
import PixpaTabWidget from '../../../../tutorials/components/pixpa/tab-widget';
import ShopifyTabWidget from '../../../../tutorials/components/shopify/tab-widget';
import SquarespaceTabWidget from '../../../../tutorials/components/squarespace/tab-widget';
import WebflowTabWidget from '../../../../tutorials/components/webflow/tab-widget';
import WixTabWidget from '../../../../tutorials/components/wix/tab-widget';
import WordpressTabWidget from '../../../../tutorials/components/wordpress/tab-widget';
import { accountSelector } from '../../../../userData/redux/userData.slice';
import FormRow from '../../../../widgetSettings/components/form-row';
import { updateWidgetsSettings } from '../../../../widgetSettings/redux/action';
import { settingSelector } from '../../../../widgetSettings/redux/widgetSettings.slice';
import useDispatchUpdateSiteWidgetSettings from '../../../../widgetSettings/shared/hooks/useDispatchUpdateSiteWidgetSettings';
import HowToInstall from '../../../../widgetSettings/widgets/HowToInstall';
import SaveButton from '../SaveButton';
import ButtonTemplates from './components/ButtonDesignSettings/ButtonTemplates';
import ContentSettings from './components/ContentSettings';
import WidgetPreview from './components/WidgetPreview';

/* eslint no-console: "warn" */

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-width: 550px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 7;
  padding: 20px;
  background-color: #f1f1f1;
`;

const BackBtn = styled(RsButton)`
  font-weight: bold;
`;

const TopContainer = styled(FlexContainer)`
  width: 100%;
  justify-content: space-between;
`;

const ActionsContainer = styled(FlexContainer)`
  width: 100%;
  gap: 10px;
`;

const BottomContainer = styled(FlexContainer)`
  width: 100%;
  padding-top: 30px;

  @media (max-width: 930px) {
    flex-direction: column;
  }
`;

const SettingsContainer = styled(FlexContainer)`
  min-width: 350px;
  max-width: 350px;
  height: 100%;
  flex: 1;
  @media (max-width: 930px) {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
`;

const PreviousWidgetContainer = styled.div`
  flex: 1;
  flex-grow: 1;
  min-width: 550px;
  height: calc(100vh - 100px);

  @media (max-width: 930px) {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
`;

const SettingsSection = styled.div`
  background-color: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  width: 100%;
`;

const SaveBtn = styled(Button)<{ $show: boolean }>`
  background-color: #000;
  color: #fff;
  border-color: #000;

  &:disabled {
    background-color: #909090;
    color: #fff;
    border-color: #909090;
  }
  /* visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')}; */
`;

const StyledAuthTitle = styled(AuthTitle)`
  font-size: 20px;
`;

const StyledTabs = styled(Tabs)`
  .rs-nav-pills {
    width: 100%;
  }

  .rs-nav-item {
    width: 50%;
    color: #000;
    font-weight: 600;
  }
`;

const SuccessMessage = styled.div`
  background: #f0f9ff;
  border: 1px solid #bae6fd;
  border-radius: 8px;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    color: #0ea5e9;
    font-size: 20px;
  }

  span {
    color: #0369a1;
    font-weight: 500;
  }
`;

type Props = {
  onBack: () => void;
};

export default function FloatingButton({ onBack }: Props) {
  const dispatch = useAppDispatch();
  const {
    updateWidgetSettings: { saving },
    isWidgetSettingsUpdated,
    siteWidgetSettings,
  } = useAppSelector(settingSelector);
  const { showSuccessToast } = useNotification();
  const { handleCloseModal, handleOpenModal, showModal } = useModal();
  const [open, setOpen] = useState<ReviewsJetPlatform | null>(null);
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { platform } = selectedSite ?? {};
  const { installationCode } = useInstallation(Widget.TAB_WIDGET);
  const { dispatchUpdateSiteWidgetSettings } =
    useDispatchUpdateSiteWidgetSettings();

  const updateSettings = () => {
    dispatch(updateWidgetsSettings())
      .then(unwrapResult)
      .then(() => {
        showSuccessToast('Settings successfully saved');
      })
      .catch(() => {});
  };

  const { modalWidget } = siteWidgetSettings ?? {};

  if (!modalWidget) {
    return null;
  }

  return (
    <>
      <Wrapper>
        <TopContainer>
          <FlexContainer
            justifyContent="flex-start"
            gap="5px"
            style={{ width: '100%' }}
          >
            <BackBtn appearance="subtle" onClick={onBack}>
              <FlexContainer gap="5px">
                <FaArrowLeft />
                <span>Back</span>
              </FlexContainer>
            </BackBtn>
            <StyledAuthTitle marginBottom="0">Floating Button</StyledAuthTitle>
          </FlexContainer>

          <ActionsContainer justifyContent="flex-end">
            {platform === ReviewsJetPlatform.WIX ? (
              <SuccessMessage>
                <span>✨ Already installed on your website</span>
              </SuccessMessage>
            ) : (
              <Button
                variant="primary"
                rounded
                onClick={() => {
                  if (platform === ReviewsJetPlatform.SHOPIFY) {
                    setOpen(ReviewsJetPlatform.SHOPIFY);
                  } else {
                    handleOpenModal();
                  }
                }}
              >
                <FlexContainer gap="5px">
                  <FaCode />
                  <b>Add to your website</b>
                </FlexContainer>
              </Button>
            )}
            <SaveButton />
          </ActionsContainer>
        </TopContainer>

        <BottomContainer
          justifyContent="flex-start"
          gap="20px"
          alignItems="flex-start"
        >
          <SettingsContainer stack gap="10px">
            <SettingsSection>
              <StyledTabs defaultActiveKey="1" appearance="pills">
                <Tabs.Tab eventKey="1" title="Floating Button">
                  <FormRow
                    justifyContent="space-between"
                    gap="5px"
                    marginTop="20px"
                  >
                    Display Floating Button on Desktop
                    <Toggle
                      size="md"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={!!modalWidget.desktopSettings.visible}
                      onChange={() => {
                        dispatchUpdateSiteWidgetSettings(
                          'desktopSettings.visible',
                          !modalWidget.desktopSettings.visible,
                          'modal'
                        );
                      }}
                    />
                  </FormRow>

                  <FormRow justifyContent="space-between" gap="5px">
                    Display Floating Button on Mobile
                    <Toggle
                      size="md"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={!!modalWidget.mobileSettings.visible}
                      onChange={() => {
                        dispatchUpdateSiteWidgetSettings(
                          'mobileSettings.visible',
                          !modalWidget.mobileSettings.visible,
                          'modal'
                        );
                      }}
                    />
                  </FormRow>
                  <Divider />

                  <ButtonTemplates />

                  <Divider />

                  <p style={{ marginBottom: 10, fontWeight: 600 }}>
                    Floating Button Settings
                  </p>
                  <FormRow justifyContent="space-between" gap="10px">
                    <span>Button text color</span>
                    <ColorPicker
                      color={siteWidgetSettings?.modalWidget.buttonTextColor!}
                      changeColor={(color: string) => {
                        dispatchUpdateSiteWidgetSettings(
                          'buttonTextColor',
                          color,
                          'modal'
                        );
                      }}
                    />
                  </FormRow>
                  <FormRow justifyContent="space-between" gap="10px">
                    <span>Button color</span>
                    <ColorPicker
                      color={
                        siteWidgetSettings?.modalWidget.buttonBackgroundColor!
                      }
                      changeColor={(color: string) => {
                        dispatchUpdateSiteWidgetSettings(
                          'buttonBackgroundColor',
                          color,
                          'modal'
                        );
                      }}
                    />
                  </FormRow>
                </Tabs.Tab>
                <Tabs.Tab eventKey="2" title="Modal Settings">
                  <ContentSettings />
                </Tabs.Tab>
              </StyledTabs>
            </SettingsSection>
          </SettingsContainer>
          <PreviousWidgetContainer>
            <WidgetPreview />
          </PreviousWidgetContainer>
        </BottomContainer>
      </Wrapper>

      <AppModal
        size="sm"
        shouldOpenModal={showModal}
        onModalClose={handleCloseModal}
        body={
          <HowToInstall
            hideGodaddy
            embedCode={installationCode}
            onClose={handleCloseModal}
            openTutorial={(platform: ReviewsJetPlatform) => setOpen(platform)}
          />
        }
      />
      <Drawer
        size="lg"
        open={!!open}
        onClose={() => setOpen(null)}
        className="tutorial-drawer"
      >
        <Drawer.Header>
          <Drawer.Title>{`How To Install the Floating Widget ${
            open !== ReviewsJetPlatform.OTHERS
              ? `on ${capitalize(open ?? '')}`
              : ``
          }`}</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setOpen(null)}>Close</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          {open === ReviewsJetPlatform.WORDPRESS && <WordpressTabWidget />}
          {open === ReviewsJetPlatform.WIX && <WixTabWidget />}
          {open === ReviewsJetPlatform.WEBFLOW && <WebflowTabWidget />}
          {open === ReviewsJetPlatform.SQUARESPACE && <SquarespaceTabWidget />}
          {open === ReviewsJetPlatform.PIXPA && <PixpaTabWidget />}
          {open === ReviewsJetPlatform.SHOPIFY && <ShopifyTabWidget />}
          {open === ReviewsJetPlatform.OTHERS && <ModalWidgetOthers />}
        </Drawer.Body>
      </Drawer>
    </>
  );
}
