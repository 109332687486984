import { useContext } from 'react';
import { BiSolidError } from 'react-icons/bi';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { Checkbox, Divider, InputPicker, Tag } from 'rsuite';
import styled from 'styled-components';
import { ImportReviewsContext } from '../..';
import FlexContainer from '../../../../../../../shared/components/layouts/flex-container';
import findKeyInMappedField from '../../../../../../csv-import/utils/findKeyInMappedField';

const Wrapper = styled.div<{ $mapped: boolean }>`
  margin-top: 20px;
  border-radius: 5px;
  background-color: #fff;
  border: 2px solid ${({ $mapped }) => (!!$mapped ? ' #fff' : ' #ffc7c7')};
`;

const FieldName = styled.span`
  font-weight: bold;
`;

const Header = styled(FlexContainer)<{ $mapped: boolean }>`
  width: 100%;
  padding: 10px 15px;
  background-color: ${({ $mapped }) => (!!$mapped ? '#fff' : '#fff1f1')};
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;
const Actions = styled(FlexContainer)`
  padding: 10px 15px;
  background-color: #fff;
`;

const Content = styled.div`
  padding: 10px 15px;
`;

const Label = styled.span`
  color: #636363;
  font-size: 13px;
  display: block;
  margin-bottom: 5px;
`;

const Required = styled.span`
  display: block;
  font-size: 16px;
  color: red;
`;

const StyledCheckbox = styled(Checkbox)`
  label {
    font-size: 13px;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 10px 0;
`;
const Value = styled.span`
  color: #6d6d6d;
`;

const data = [
  'title',
  'reviewer_name',
  'reviewer_email',
  'rating',
  'helpful_count',
  'review_text',
  'your_review_reply',
  'review_date',
  'product_id',
  'reviewer_location_country',
  'reviewer_location_city',
].map((item) => ({ label: item, value: item }));

const Field = ({
  header,
}: {
  header: { label: string; isSkipped: boolean };
}) => {
  const context = useContext(ImportReviewsContext);

  if (!context) {
    return null;
  }

  const {
    mappedFields,
    mappedFieldsList,
    parsedFile,
    handleMapping,
    handleToggleSkipColumn,
  } = context;

  const keyInMappedField = findKeyInMappedField(mappedFields, header.label);

  //@ts-ignore
  const isMapped = !!mappedFields[keyInMappedField]?.value;

  return (
    <Wrapper $mapped={isMapped || header.isSkipped}>
      <Header
        justifyContent="space-between"
        $mapped={isMapped || header.isSkipped}
      >
        <FieldName>{header.label}</FieldName>
        <FlexContainer gap="10px">
          {isMapped || header.isSkipped ? (
            <IoCheckmarkCircleSharp size={20} color="#4caf50" />
          ) : (
            <BiSolidError size={20} />
          )}

          <span>
            {isMapped ? (
              <Tag size="sm" color="green">
                <b>Mapped</b>
              </Tag>
            ) : header.isSkipped ? (
              'Will not be imported'
            ) : (
              'Unmapped'
            )}
          </span>
        </FlexContainer>
      </Header>
      <Actions justifyContent="space-between">
        <div>
          <FlexContainer
            alignItems="flex-start"
            justifyContent="flex-start"
            gap="5px"
          >
            <Label>Field mapped to</Label> <Required>*</Required>
          </FlexContainer>
          <InputPicker
            size="sm"
            cleanable
            data={data}
            style={{ width: 224 }}
            disabled={header.isSkipped}
            onChange={(value) => handleMapping(header.label, value)}
            value={isMapped ? keyInMappedField : null}
            disabledItemValues={mappedFieldsList}
          />
        </div>
        {!isMapped && (
          <Checkbox
            value="skipped"
            checked={header.isSkipped}
            onChange={() => handleToggleSkipColumn(header)}
          >
            {' '}
            Skip Column
          </Checkbox>
        )}
      </Actions>
      <StyledDivider />
      <Content>
        <Value>
          {/* @ts-ignore */}
          {parsedFile[0][header.label] ?? 'n/a'}
        </Value>
      </Content>
    </Wrapper>
  );
};

export default Field;
