import { BiEnvelopeOpen, BiSolidEnvelopeOpen } from 'react-icons/bi';
import { FaQuestionCircle, FaRegQuestionCircle } from 'react-icons/fa';
import { FaHeart, FaRegHeart, FaRegStar, FaStar } from 'react-icons/fa6';
import { IoIosArrowDown } from 'react-icons/io';
import { IoCloudDownloadOutline, IoCloudDownloadSharp } from 'react-icons/io5';
import { LuSettings } from 'react-icons/lu';
import {
  MdOutlineReviews,
  MdOutlineWidgets,
  MdReviews,
  MdWidgets,
} from 'react-icons/md';
import { RiMailSendFill, RiMailSendLine } from 'react-icons/ri';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Whisper } from 'rsuite';
import styled, { css } from 'styled-components';
import { ReviewsJetPlatform } from '../../../../features/product/types';
import {
  accountSelector,
  toggleFeedbackDrawer,
  userAccountConfigSelector,
} from '../../../../features/userData/redux/userData.slice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { toggleChangelogModal } from '../../../../store/app.slice';
import useReviewUs from '../../../hooks/useReviewUs';
import useTheme from '../../../hooks/useTheme';
import FlexContainer from '../flex-container';
import renderSpeaker from './renderSpeaker';

const Wrapper = styled.div<{
  $collapsed: boolean;
  $hideSidebar: boolean;
  $platform?: ReviewsJetPlatform;
}>`
  height: 100vh;
  flex: none;
  width: 85px;
  transition: width 0.15s;
  position: fixed;
  z-index: 9;
  ${({ $hideSidebar, $platform }) => {
    if ($hideSidebar) {
      return css`
        display: none;
      `;
    }

    if ($platform === ReviewsJetPlatform.SHOPIFY) {
      return css`
        border-right: 1px solid #e4e4e4;
        border-left: 1px solid #e4e4e4;
      `;
    } else {
      return css`
        border-right: 1px solid #e4e4e4;
      `;
    }
  }};
`;

const MenuWrapper = styled.div<{
  $menuItemHoverColor?: string;
  $menuItemHoverBgColor?: string;
}>`
  height: calc(100vh - 76px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  padding-bottom: 10px;

  a:hover {
    color: ${({ $menuItemHoverColor }) => $menuItemHoverColor};
    background-color: ${({ $menuItemHoverBgColor }) => $menuItemHoverBgColor};
  }
`;
const LogoWrapper = styled.div<{ collapsed: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  /* width: ${({ collapsed }) => (!!collapsed ? '77px;' : '265px;')}; */
  padding: 20px;
  border-bottom: 0px solid #e4e4e4;
  transition: width 0.15s;
  background-color: transparent;
`;
const StyledFlexContainer = styled(FlexContainer)<{ collapsed: boolean }>`
  color: #000;
  cursor: pointer;
  margin-bottom: ${({ collapsed }) => (collapsed ? '-10px' : '-9px')};
`;
const Divider = styled.hr`
  margin: 8px 0;
`;

const MainMenu = styled.div<{ $collapsed: boolean }>`
  display: flex;
  z-index: 10;
  align-items: center;
  flex-direction: column;
  padding: 0 8px 0;
  max-height: 85%;
  ${({ $collapsed }) =>
    !$collapsed &&
    css`
      overflow-y: auto;
      padding-bottom: 50px;
    `}
`;
const OtherMenu = styled.div<{ $collapsed: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 8px 0;
  position: relative;
`;
const MenuCategory = styled.div<{ $collapsed: boolean }>`
  color: #000;
  opacity: 1;
  font-weight: bold;
  color: #737373;
  font-size: 11px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  white-space: nowrap;
  text-transform: uppercase;
  /* border-bottom: 1px solid #e0e0e0; */
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
  &:hover {
    /* color: #324fbe; */
  }
  &.active {
    /* color: #324fbe; */
  }
  padding: 10px 0px 10px 10px;
  justify-content: ${({ $collapsed }) => ($collapsed ? 'center' : '')};
`;

const MenuItem = styled(Link)<{ $collapsed: boolean }>`
  position: relative;
  opacity: 1;
  color: #000000e3;
  font-size: ${({ $collapsed }) => (!!$collapsed ? '11px' : '14px')};
  display: flex;
  flex-direction: ${({ $collapsed }) => (!!$collapsed ? 'column' : 'row')};
  gap: ${({ $collapsed }) => (!!$collapsed ? '3px' : '10px')};
  align-items: center;
  white-space: nowrap;
  margin-bottom: 0px;
  border-left: 3px solid transparent;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
  &:hover {
    /* color: #324fbe;
    background-color: #ebeef9; */
  }
  &.active {
    color: #000000;
    /* background-color: #ebeef9; */
    font-weight: bold;
    border-color: #000;

    &:hover {
      color: #000;
      background-color: #eceff3;
    }
  }
  padding: ${({ $collapsed }) => (!!$collapsed ? '7px 0' : '7px 10px')};
  justify-content: ${({ $collapsed }) => ($collapsed ? 'center' : '')};
  ${({ $collapsed }) =>
    !!$collapsed &&
    css`
      width: 84px;
    `}/* ${({ $collapsed }) =>
    !!$collapsed &&
    css`
      &:hover::after {
        content: attr(data-title);
        padding: 5px;
        position: absolute;
        top: 3px;
        left: 40px;
        background: #000;
        color: #fff;
        z-index: 9999999;
        border-radius: 5px;
        white-space: nowrap;
        overflow: hidden;
        font-size: 12px;
      }
    `} */
`;

type Props = {
  hideSidebar: boolean;
  collapsedSidebar: boolean;
};

export default function CollapsedSidebar({
  collapsedSidebar,
  hideSidebar,
}: Props) {
  const reviewUs = useReviewUs();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { platform } = selectedSite ?? {};
  const siteConfig = useAppSelector(userAccountConfigSelector);
  const { menuItemHoverBgColor, menuItemHoverColor } = useTheme() ?? {};

  return (
    <Wrapper
      $collapsed={collapsedSidebar}
      $hideSidebar={hideSidebar}
      $platform={platform}
    >
      <LogoWrapper collapsed={collapsedSidebar}>
        <StyledFlexContainer
          justifyContent="flex-start"
          alignItems="center"
          gap="10px"
          role="presentation"
          // onClick={() => navigate('/manage-reviews')}
          collapsed={collapsedSidebar}
        >
          <Whisper
            placement="rightStart"
            trigger="click"
            speaker={(e, ref) =>
              renderSpeaker(
                {
                  ...e,
                  canLogout: !!siteConfig?.canLogout,
                  onClickAccountDetails: () =>
                    navigate('/settings?tab=account'),
                  onClickRoadmap: () =>
                    window.open(
                      'https://reviewsjet.featurebase.app/roadmap',
                      '_blank'
                    ),
                  onClickChangelog: () => dispatch(toggleChangelogModal(true)),
                  reviewUs,
                  help: () => dispatch(toggleFeedbackDrawer()),
                },
                ref
              )
            }
          >
            <FlexContainer gap="5px">
              <Avatar circle />
              <IoIosArrowDown />
            </FlexContainer>
          </Whisper>
        </StyledFlexContainer>
      </LogoWrapper>
      <MenuWrapper
        $menuItemHoverColor={menuItemHoverColor}
        $menuItemHoverBgColor={menuItemHoverBgColor}
      >
        <MainMenu $collapsed={collapsedSidebar}>
          {!collapsedSidebar && (
            <MenuCategory $collapsed={collapsedSidebar}>Manage</MenuCategory>
          )}

          <div>
            <MenuItem
              to="/manage-reviews"
              $collapsed={collapsedSidebar}
              className={pathname === '/manage-reviews' ? 'active' : ''}
              data-title="Manage reviews"
            >
              {pathname === '/manage-reviews' ? (
                <MdReviews size={18} />
              ) : (
                <MdOutlineReviews size={18} />
              )}
              Reviews
            </MenuItem>
            <MenuItem
              to="/share-review-form"
              $collapsed={collapsedSidebar}
              className={pathname === '/share-review-form' ? 'active' : ''}
              data-title="Links"
            >
              {pathname === '/share-review-form' ? (
                <RiMailSendFill size={18} />
              ) : (
                <RiMailSendLine size={18} />
              )}
              Collect
            </MenuItem>

            <MenuItem
              to="/import-reviews"
              $collapsed={collapsedSidebar}
              data-title="Import reviews"
              className={pathname === '/import-reviews' ? 'active' : ''}
            >
              {pathname === '/import-reviews' ? (
                <IoCloudDownloadSharp size={18} />
              ) : (
                <IoCloudDownloadOutline size={18} />
              )}
              Import
            </MenuItem>

            <MenuItem
              to="/emails"
              data-title="Emails"
              $collapsed={collapsedSidebar}
              className={pathname.includes('/emails') ? 'active' : ''}
            >
              {pathname === '/emails' ? (
                <BiSolidEnvelopeOpen size={18} />
              ) : (
                <BiEnvelopeOpen size={18} />
              )}
              Emails
            </MenuItem>

            <MenuItem
              to="/wall-of-love"
              $collapsed={collapsedSidebar}
              className={pathname === '/wall-of-love' ? 'active' : ''}
              data-title="Links"
            >
              {pathname === '/wall-of-love' ? (
                <FaHeart size={18} />
              ) : (
                <FaRegHeart size={18} />
              )}
              Love
            </MenuItem>

            {!collapsedSidebar && (
              <MenuCategory
                $collapsed={collapsedSidebar}
                className={pathname === '/reviews' ? 'active' : ''}
                data-title="Collect reviews"
              >
                {!collapsedSidebar && 'Collect'}
              </MenuCategory>
            )}
            {!collapsedSidebar && (
              <MenuCategory
                $collapsed={collapsedSidebar}
                className={pathname === '/emails' ? 'active' : ''}
              >
                {!collapsedSidebar && 'Widgets'}
              </MenuCategory>
            )}

            <MenuItem
              to="/questions"
              data-title="Questions"
              $collapsed={collapsedSidebar}
              className={pathname === '/questions' ? 'active' : ''}
            >
              {pathname === '/questions' ? (
                <FaQuestionCircle size={18} />
              ) : (
                <FaRegQuestionCircle size={18} />
              )}
              Q&A
            </MenuItem>

            <MenuItem
              to="/select-widgets"
              data-title="ReviewsJet widgets"
              $collapsed={collapsedSidebar}
              className={
                [
                  '/select-widgets',
                  '/widgets/masonry',
                  '/widgets/grid',
                  '/widgets/list',
                  '/widgets/slider',
                  '/widgets/marquee',
                  '/widgets/modal-widget',
                  '/widgets/product',
                ].includes(pathname)
                  ? 'active'
                  : ''
              }
            >
              {[
                '/select-widgets',
                '/widgets/masonry',
                '/widgets/grid',
                '/widgets/list',
                '/widgets/slider',
                '/widgets/marquee',
                '/widgets/modal-widget',
                '/widgets/product',
              ].includes(pathname) ? (
                <MdWidgets size={18} />
              ) : (
                <MdOutlineWidgets size={18} />
              )}

              {collapsedSidebar ? 'Widgets' : 'ReviewsJet widgets'}
            </MenuItem>
            {/* <MenuItem
          to="/widgets/wall-of-love"
          data-title="Wall of love widget"
          $collapsed={collapsedSidebar}
          className={pathname === '/widgets/wall-of-love' ? 'active' : ''}
        >
          <PiWallDuotone size={18} />
          {!collapsedSidebar && 'Wall of love'}
        </MenuItem>
        <MenuItem
          to="/widgets/carousel"
          $collapsed={collapsedSidebar}
          data-title="Carousel widget"
          className={pathname === '/widgets/carousel' ? 'active' : ''}
        >
          <MdOutlineViewCarousel size={18} />
          {!collapsedSidebar && 'Carousel'}
        </MenuItem>
        <MenuItem
          to="/widgets/modal-reviews"
          $collapsed={collapsedSidebar}
          data-title="Modal widget"
          className={
            pathname === '/widgets/modal-reviews' ? 'active' : ''
          }
        >
          <MdOutlineSmartScreen size={18} />
          {!collapsedSidebar && 'Modal widget'}
        </MenuItem>
        <MenuItem
          to="/widgets/product-widget"
          $collapsed={collapsedSidebar}
          data-title="Product reviews widget"
          className={
            pathname === '/widgets/product-widget' ? 'active' : ''
          }
        >
          <TbShirt size={18} />
          {!collapsedSidebar && 'Product reviews widget'}
        </MenuItem> */}

            {!collapsedSidebar && (
              <MenuCategory
                $collapsed={collapsedSidebar}
                className={
                  pathname === '/seo/business-rich-snippet' ? 'active' : ''
                }
              >
                {!collapsedSidebar && 'SEO'}
              </MenuCategory>
            )}

            <MenuItem
              to="/seo/business-rich-snippet"
              $collapsed={collapsedSidebar}
              data-title="Google Rich Snippets"
              className={
                pathname === '/seo/business-rich-snippet' ? 'active' : ''
              }
            >
              {pathname === '/seo/business-rich-snippet' ? (
                <FaStar size={18} />
              ) : (
                <FaRegStar size={18} />
              )}
              SEO
            </MenuItem>
          </div>
        </MainMenu>

        <OtherMenu $collapsed={collapsedSidebar}>
          <MenuItem
            to="/settings"
            $collapsed={collapsedSidebar}
            data-title="Settings"
            className={pathname === '/settings' ? 'active' : ''}
          >
            <LuSettings size={18} />
            Settings
          </MenuItem>
        </OtherMenu>
      </MenuWrapper>
    </Wrapper>
  );
}
