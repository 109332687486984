import styled from 'styled-components';

export type FlexContainerJustifyContent =
  | 'center'
  | 'space-between'
  | 'flex-start'
  | 'flex-end'
  | 'space-around'
  | 'space-evenly'
  | 'stretch';

export type FlexContainerWrap = 'nowrap' | 'wrap' | 'wrap-reverse' | 'unset';

export interface FlexContainerProps {
  stack?: boolean;
  flexWrap?: FlexContainerWrap;
  gap?: string;
  flexGrow?: number;
  alignItems?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'baseline'
    | 'stretch'
    | 'normal';
  justifyContent?: FlexContainerJustifyContent;
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
}

const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  position: relative;
  flex-direction: ${({ stack, flexDirection }) =>
    stack ? 'column' : flexDirection ? flexDirection : 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  gap: ${({ gap }) => gap};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  flex-grow: ${({ flexGrow }) => flexGrow};
`;

export default FlexContainer;
