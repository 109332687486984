import { ReviewsJetPlatform } from '../../features/product/types';
import { accountSelector } from '../../features/userData/redux/userData.slice';
import { useAppSelector } from '../../store';

/* eslint no-console: "warn" */

type Theme = {
  menuItemHoverColor: string;
  menuItemHoverBgColor: string;
  pageBackgroundColor: string;
};

export default function useTheme() {
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { platform } = selectedSite ?? {};

  let theme: Theme | null = null;

  switch (platform) {
    case ReviewsJetPlatform.SHOPIFY: {
      theme = {
        pageBackgroundColor: '#ECEFF3',
        menuItemHoverColor: '#324fbe',
        menuItemHoverBgColor: '#ebeef9',
      };

      break;
    }

    default: {
      theme = {
        pageBackgroundColor: '#ECEFF3',
        menuItemHoverColor: '#324fbe',
        menuItemHoverBgColor: '#ebeef9',
      };
      break;
    }
  }

  return theme;
}
