import { Icon } from '@rsuite/icons';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { FaArrowRightLong } from 'react-icons/fa6';
import { ImSpinner10 } from 'react-icons/im';
import { Button, Radio, RadioGroup } from 'rsuite';
import styled from 'styled-components';
// import { QuillEditor } from '../../../shared/components/QuillEditor';
// import {
//   ErroMessage,
//   StyledInput,
// } from '../../../shared/components/form/StyledInput';
// import FlexContainer from '../../../shared/components/layouts/flex-container';
// import { AIicon } from '../../../shared/svgs/ai-icon';
// import { Loading } from '../../../shared/types/review.types';
// import { useAppSelector } from '../../../store';
// import {
//   TemplateType,
//   testimonialSelector,
// } from '../../share-form/redux /testimonial.slice';
// import FormRow from '../../widgetSettings/components/form-row';
import {
  ErroMessage,
  StyledInput,
} from '../../../../../../../shared/components/form/StyledInput';
import FlexContainer from '../../../../../../../shared/components/layouts/flex-container';
import { QuillEditor } from '../../../../../../../shared/components/QuillEditor';
import { AIicon } from '../../../../../../../shared/svgs/ai-icon';
import { Loading } from '../../../../../../../shared/types/review.types';
import { useAppSelector } from '../../../../../../../store';
import {
  TemplateType,
  testimonialSelector,
} from '../../../../../../share-form/redux /testimonial.slice';
import FormRow from '../../../../../../widgetSettings/components/form-row';
import {
  InviteCustomerSteps,
  useIniviteCustomerContext,
} from '../InviteCustomerProvider';
import { SubmitButton } from './SubmitButton';

const TemplateSelector = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const SupportText = styled.p`
  font-size: 14px;
  color: #666;
  /* margin-top: px; */
`;
const InlineCode = styled.code`
  background-color: #f3f4f6;
  color: #ef4444;
  padding: 0.2rem;
  border-radius: 0.25rem;
  font-size: 12px;
`;

const Wrapper = styled.div`
  overflow: auto;
`;

const StyledSubmitButton = styled(SubmitButton)`
  width: 100%;
`;

export function MessageForm() {
  const { loadingTestimonialTemplates } = useAppSelector(testimonialSelector);
  const {
    control,
    errors,
    activeTemplate,
    handleActiveTemplate,
    setCurrentStep,
  } = useIniviteCustomerContext();

  const isFetchingTemplates = loadingTestimonialTemplates === Loading.PENDING;
  const [templateType, setTemplateType] = useState<string>('predefined');

  return (
    <Wrapper>
      <div style={{ marginBottom: 20 }}>
        <FormRow stack alignItems="flex-start">
          <Label htmlFor="chooseTemplateType">Message Template Type</Label>
          <RadioGroup
            inline
            name="radio-group-controlled"
            value={templateType}
            // @ts-ignore
            onChange={setTemplateType}
          >
            <Radio value="predefined">Predefined Messages</Radio>
            <Radio value="AI">
              <FlexContainer gap="5px">
                <AIicon /> <span>AI-Generated Messages</span>
              </FlexContainer>
            </Radio>
          </RadioGroup>
        </FormRow>
        <TemplateSelector>
          {['Default', 'Short', 'Friendly', 'Formal'].map((template) => (
            <Button
              key={template}
              disabled={isFetchingTemplates}
              active={activeTemplate.substring(3) === template}
              onClick={() =>
                handleActiveTemplate(
                  `${
                    templateType === 'AI' ? 'AI_' : ''
                  }${template}` as TemplateType
                )
              }
            >
              <FlexContainer gap="5px">
                {isFetchingTemplates &&
                  template === activeTemplate.substring(3) && (
                    <Icon as={ImSpinner10} pulse width={16} />
                  )}
                {template}
              </FlexContainer>
            </Button>
          ))}
          {/* <Dropdown
            appearance="ghost"
            color="blue"
            title="AI"
            icon={<AIicon />}
            loading={isFetchingTemplates}
          >
            {['Default', 'Short', 'Friendly', 'Formal'].map((template) => (
              <Dropdown.Item
                style={{ width: '100px' }}
                key={template}
                onClick={() =>
                  handleActiveTemplate(`AI_${template}` as TemplateType)
                }
              >
                {template}
              </Dropdown.Item>
            ))}
          </Dropdown> */}
        </TemplateSelector>
        <FormRow stack alignItems="flex-start">
          <label htmlFor="subject">Subject</label>
          <Controller
            name="subject"
            control={control}
            render={({ field }) => (
              <StyledInput
                id="subject"
                placeholder="Subject"
                error={Boolean(errors.subject)}
                {...field}
              />
            )}
          />
          {errors.subject && (
            <ErroMessage>{errors.subject.message}</ErroMessage>
          )}
        </FormRow>
        <FormRow stack alignItems="flex-start">
          <label htmlFor="message" style={{ marginBottom: '-9px' }}>
            Message
          </label>
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <QuillEditor
                simple
                content={field.value as string}
                onChange={(message) => {
                  field.onChange(message);
                }}
              />
            )}
          />
          {errors.message && (
            <ErroMessage>{errors.message.message}</ErroMessage>
          )}
        </FormRow>
        <SupportText>
          <p>Supported variables: </p>
          <p>
            <InlineCode>&#123;&#123; name &#125;&#125;</InlineCode> to display
            the customer's full name.
          </p>

          <p>
            <InlineCode>&#123;&#123; first_name &#125;&#125;</InlineCode> to
            display only the customer's first name.
          </p>
        </SupportText>
      </div>
      <StyledSubmitButton
        onClick={() => {
          // setCurrentStep(InviteCustomerSteps.LOGO);
          setCurrentStep(InviteCustomerSteps.ADVANCED);
        }}
      >
        <FlexContainer gap="5px">
          <span>Next</span> <FaArrowRightLong />
        </FlexContainer>
      </StyledSubmitButton>
    </Wrapper>
  );
}
