import { FaApple } from 'react-icons/fa6';
import styled, { CSSProperties } from 'styled-components';
import { AirbnbIcon } from '../../../../../../../shared/components/AirbnbIcon';
import { EtsyIcon } from '../../../../../../../shared/components/EtsyIcon';
import GoogleIcon from '../../../../../../../shared/components/GoogleIcon';
import FlexContainer from '../../../../../../../shared/components/layouts/flex-container';
import { ProductHuntIcon } from '../../../../../../../shared/components/ProductHuntIcon';
import TripadvisorLogo from '../../../../../../../shared/components/TripadvisorLogo';
import { TustpilotIcon } from '../../../../../../../shared/components/TustpilotIcon';
import YelpIcon from '../../../../../../../shared/components/YelpIcon';
import { useAppSelector } from '../../../../../../../store';
import {
  ReviewSource,
  settingSelector,
} from '../../../../../../widgetSettings/redux/widgetSettings.slice';

const Wrapper = styled(FlexContainer)`
  position: absolute;
  width: max-content;
  padding: 7px 15px;
  background-color: #000;
  color: #ffffff;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  &.bottom_right {
    right: 20px;
    bottom: 20px;
    @media (max-width: 644px) {
      padding: 12px 15px;
    }
  }

  &.bottom {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.bottom_left {
    left: 20px;
    bottom: 20px;
    @media (max-width: 644px) {
      padding: 12px 15px;
    }
  }

  &.left {
    top: 50%;
    transform: translateY(-50%);
    transform-origin: 0 50%;
    transform: rotate(-90deg) translate(-50%, 50%);
    border-radius: 0;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    &:hover {
      left: 0;
    }
  }

  &.right {
    top: 50%;
    border-radius: 0;
    right: -1px;
    transform-origin: top right;
    transform: rotate(-90deg) translate(50%, -99%);
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &:hover {
      right: 0;
    }
  }

  .active {
    /* color: #ffc800; */
  }

  .inactive {
    color: #888888;
  }

  .review-summary {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }
  .platform-logo {
    width: 30px;
    height: 30px;
    border-radius: 5px;
  }
`;

type Props = {
  className?: string;
  style?: CSSProperties;
  selectedReviewSource: ReviewSource;
  onClick?: () => void;
};

export default function ReviewSourceButton({
  selectedReviewSource,
  style,
  className,
  onClick,
}: Props) {
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { modalWidget } = siteWidgetSettings ?? {};
  const { desktopSettings, buttonBackgroundColor, buttonTextColor } =
    modalWidget ?? {};
  const { position } = desktopSettings ?? {};

  return (
    <Wrapper
      style={{
        backgroundColor: buttonBackgroundColor,
        color: buttonTextColor,
      }}
      className={position}
      onClick={onClick}
    >
      <FlexContainer justifyContent="flex-start" alignItems="center" gap="10px">
        {selectedReviewSource === ReviewSource.GOOGLE && (
          <GoogleIcon size={30} />
        )}
        {selectedReviewSource === ReviewSource.TRIPADVISOR && (
          <TripadvisorLogo size={30} />
        )}
        {selectedReviewSource === ReviewSource.YELP && <YelpIcon size={30} />}
        {selectedReviewSource === ReviewSource.AIRBNB && (
          <AirbnbIcon size={28} />
        )}
        {selectedReviewSource === ReviewSource.ETSY && <EtsyIcon size={30} />}
        {selectedReviewSource === ReviewSource.PRODUCT_HUNT && (
          <ProductHuntIcon size={30} />
        )}
        {selectedReviewSource === ReviewSource.TRUSTPILOT && (
          <TustpilotIcon size={30} />
        )}
        {selectedReviewSource === ReviewSource.APPLE_STORE && (
          <FaApple size={30} color="#6c6c6c" />
        )}
        {selectedReviewSource === ReviewSource.TOURRADAR && (
          <img
            className="platform-logo"
            src="/images/tourradar.jpg"
            alt="Tourradar"
          />
        )}
        <div>
          <div>
            <span className="active">★</span>
            <span className="active">★</span>
            <span className="active">★</span>
            <span className="active">★</span>
            <span className="active">★</span>
          </div>
          <div className="review-summary">Based on 1K reviews</div>
        </div>
      </FlexContainer>
    </Wrapper>
  );
}
