import Marquee from 'react-fast-marquee';
import styled, { css } from 'styled-components';
import hexToRgba from '../../../../../../../shared/utils/hexToRgba';
import { useAppSelector } from '../../../../../../../store';
import {
  EmbeddedWidgetSettings,
  settingSelector,
} from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import useUpdateWidgetPreview from '../../hooks/useUpdateWidgetPreview';
import SliderReviewCard from './SliderReviewCard';
import reviews from './reviews';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const WidgetContainer = styled.div<{ $carouselWidget: EmbeddedWidgetSettings }>`
  width: 90%;
  padding: 25px 0 0;

  .rfm-initial-child-container,
  .rfm-marquee {
    gap: 20px;
    align-items: stretch;
  }

  .rfm-initial-child-container {
    margin-right: 0px;
  }

  .rfm-marquee-container > .rfm-marquee:last-child {
    margin-left: 20px;
  }
`;

type ReviewHeaderProps = {
  $width?: string;
  $fluid?: boolean;
  $widgetSettings: EmbeddedWidgetSettings;
  $isMasonry?: boolean;
  $isLastItem?: boolean;
};

const Card = styled.div<ReviewHeaderProps>`
  width: 350px;
  position: relative;
  height: ${({ $widgetSettings }) =>
    `calc(100% - 42px - ${2 * $widgetSettings.borderWidth}px)`};
  border-radius: ${({ $widgetSettings }) => $widgetSettings.borderRadius};
  cursor: pointer;
  position: relative;
  margin: auto;
  background: ${({ $widgetSettings }) => {
    return hexToRgba(
      $widgetSettings.reviewCardColor,
      $widgetSettings.reviewCardColorOpacity / 100
    );
  }};
  border: ${({ $widgetSettings }) =>
    `${$widgetSettings.showBorder ? $widgetSettings.borderWidth ?? 0 : 0}px ${
      $widgetSettings.borderColor
    } solid`};
  ${({ $isLastItem }) =>
    $isLastItem &&
    css`
      margin-right: 0px !important;
    `}

  padding: 20px;
  box-sizing: unset !important;
  .review-pic {
    width: 100%;
    margin: 10px auto 0;
  }

  @media (max-width: 644px) {
    max-width: 200px;
  }
`;

export default function WidgetPreview() {
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const {
    carouselMarqueeWidget,
    brandingSettings,
    translation,
    carouselSliderWidget: { autoplay, autoPlaySpeed, pauseOnHover },
  } = siteWidgetSettings!;

  useUpdateWidgetPreview();

  return (
    <Wrapper>
      <WidgetContainer $carouselWidget={carouselMarqueeWidget}>
        <Marquee
          pauseOnHover
          autoFill
          direction="left"
          pauseOnClick
          play={true}
        >
          {reviews.map((review, index) => {
            const isLastItem = index === reviews.length - 1;

            return (
              <Card
                className={`review-card ${
                  isLastItem ? 'isLastItem' : 'isLastItem'
                }`}
                $isLastItem={isLastItem}
                data-review={JSON.stringify(review)}
                key={review.id}
                $widgetSettings={carouselMarqueeWidget}
              >
                <SliderReviewCard
                  review={review}
                  widgetSettings={carouselMarqueeWidget}
                  branding={brandingSettings}
                  translation={translation}
                />
              </Card>
            );
          })}
        </Marquee>
      </WidgetContainer>
    </Wrapper>
  );
}
