import { useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Divider, Tabs } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import ReviewsJetCrownLogo from '../../../../shared/components/ReviewsJetCrownLogo';
import { useAppDispatch } from '../../../../store';
import { AuthTitle } from '../../../external/components';
import { fetchWidgetSettings } from '../../../widgetSettings/redux/action';
import CarouselMarquee from './CarouselMarquee';
import CarouselSlider from './CarouselSlider';
import FloatingButton from './FloatingButton';
import Grid from './Grid';
import Masonry from './Masonry';
import Widget from './Widget';

/* eslint no-console: "warn" */

const Wrapper = styled.div<{ $display: boolean }>`
  width: 100%;
  padding: 20px 40px;
  max-width: 1230px;
  width: 100%;
  background-color: #fff;
  display: ${({ $display }) => ($display ? 'block' : 'none')};

  @media (max-width: 530px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0px;
  }

  .widget-type {
    color: #000;
    margin: 0 0 20px !important;
  }
`;

const StyledButton = styled(Button)`
  background-color: #000;
  color: #fff;
  border-color: #000;
  min-width: 150px;
  padding: 13px 15px;
  width: 100%;
`;

const StyledTabs = styled(Tabs)`
  margin-top: 20px;
`;

interface Props {
  onNext: () => void;
  onPrevious: () => void;
}

enum WidgetType {
  SLIDER = 'slider',
  MARQUEE = 'marquee',
  GRID = 'grid',
  MASONRY = 'masonry',
  FLOATING_BUTTON = 'floating-button',
}

const widgets: { title: string; image: string; type: WidgetType }[] = [
  {
    title: 'Carousel Slider',
    image: '/images/widgets/carousel.svg',
    type: WidgetType.SLIDER,
  },
  {
    title: 'Carousel Marquee',
    image: '/images/widgets/marquee.svg',
    type: WidgetType.MARQUEE,
  },
  {
    title: 'Masonry',
    image: '/images/widgets/masonry.svg',
    type: WidgetType.MASONRY,
  },
  {
    title: 'Grid',
    image: '/images/widgets/grid.svg',
    type: WidgetType.GRID,
  },
  {
    title: 'Floating Button',
    image: '/images/widgets/floating-button.svg',
    type: WidgetType.FLOATING_BUTTON,
  },
];

const BottomButtonWrapper = styled(FlexContainer)`
  width: 100%;
  justify-content: flex-end;
  gap: 10px;
`;

const StepIndicator = styled.p`
  color: #666;
  font-size: 14px;
  margin-bottom: 24px;
  margin-top: 8px;
`;

export default function InstallWidgets({ onNext }: Props) {
  const dispatch = useAppDispatch();
  const [selectedWidget, setSelectedWidget] = useState<WidgetType | null>(null);

  useEffect(() => {
    dispatch(fetchWidgetSettings());
  }, []);

  return (
    <>
      {selectedWidget === WidgetType.SLIDER && (
        <CarouselSlider onBack={() => setSelectedWidget(null)} />
      )}
      {selectedWidget === WidgetType.MARQUEE && (
        <CarouselMarquee onBack={() => setSelectedWidget(null)} />
      )}
      {selectedWidget === WidgetType.GRID && (
        <Grid onBack={() => setSelectedWidget(null)} />
      )}
      {selectedWidget === WidgetType.MASONRY && (
        <Masonry onBack={() => setSelectedWidget(null)} />
      )}
      {selectedWidget === WidgetType.FLOATING_BUTTON && (
        <FloatingButton onBack={() => setSelectedWidget(null)} />
      )}
      <Wrapper $display={selectedWidget === null}>
        <ReviewsJetCrownLogo />
        <AuthTitle marginBottom="0px">Install a widget</AuthTitle>

        <>
          <p className="widget-type">
            Step 3 of 3: Choose how to showcase your reviews
          </p>

          <FlexContainer flexWrap="wrap" gap="10px" justifyContent="flex-start">
            {widgets.map((widget) => (
              <Widget
                key={widget.title}
                title={widget.title}
                image={widget.image}
                onClick={() => setSelectedWidget(widget.type)}
              />
            ))}
          </FlexContainer>

          <Divider />

          <BottomButtonWrapper>
            {/* <Button variant="tertiary" rounded size="small" onClick={onPrevious}>
            Previous
          </Button> */}
            <StyledButton onClick={onNext} rounded>
              <FlexContainer gap="5px">
                <b>Complete onboarding</b>
                <MdKeyboardArrowRight />
              </FlexContainer>
            </StyledButton>
          </BottomButtonWrapper>
        </>
      </Wrapper>
    </>
  );
}
