import { Preloader as PreloaderWrapper, Puff } from 'react-preloader-icon';
import styled from 'styled-components';
import FlexContainer from './layouts/flex-container';
import StyledText from './styled-text';

type PreloaderProps = {
  size?: PreloaderSize;
  text?: string;
  position?: 'relative' | 'fixed';
};

export enum PreloaderSize {
  SMALL = 30,
  MEDIUM = 60,
  LARGE = 120,
}

const Wrapper = styled.div<{ position?: 'relative' | 'fixed' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ position }) => {
    if (position === 'fixed') {
      return `
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
      `;
    }
  }}
`;

const Text = styled(StyledText)`
  font-size: 14px;
`;

const Preloader = ({
  size = PreloaderSize.MEDIUM,
  text,
  position = 'fixed',
}: PreloaderProps) => {
  return (
    <Wrapper position={position}>
      <FlexContainer stack justifyContent="center" alignItems="center">
        <PreloaderWrapper
          use={Puff}
          size={size}
          strokeWidth={6}
          strokeColor="#262626"
          duration={2000}
        />
        {text && <Text>{text}</Text>}
      </FlexContainer>
    </Wrapper>
  );
};

export default Preloader;
