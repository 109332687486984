import { unwrapResult } from '@reduxjs/toolkit';
import { Controller, useForm } from 'react-hook-form';
import { Input, SelectPicker } from 'rsuite';
import styled, { css } from 'styled-components';
import Button from '../../../../../shared/components/Button';
import StyledText from '../../../../../shared/components/styled-text';
import {
  Loading,
  YelpLocationFormData,
} from '../../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { searchYelpBusiness } from '../../../../socialReviews/redux/actions';
import {
  resetYelpSearch,
  socialReviewsSelector,
} from '../../../../socialReviews/redux/social-reviews.slice';

const FormContainer = styled.form``;

const StyledInput = styled(Input)<{ error: boolean }>`
  background: #fff;
  margin-bottom: 20px;
  border-color: #cdcdcd;
  border-radius: 20px;
  margin-top: 5px;
  outline: none !important;
  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;

const StyledLabel = styled(StyledText)`
  font-size: 14px;
  color: #7b7b7b;
`;

const StyledPicker = styled(SelectPicker)`
  margin-bottom: 20px;
  border-color: #cdcdcd;
  margin-top: 5px;
  outline: none !important;

  .rs-picker-toggle {
    border-radius: 20px !important;
  }
`;

const SearchButton = styled(Button)`
  background-color: #000;
  color: #fff;
  border-color: #000;
  width: 100%;
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:disabled {
    background-color: #bbbbbb;
    color: #000;
    border-color: #bbbbbb;
  }
`;

type Props = {
  businessName: string;
  address: string;
  onNext: (businessName: string, address: string) => void;
};

export default function Form({ businessName, address, onNext }: Props) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<YelpLocationFormData>({
    defaultValues: {
      name: businessName ?? '',
      address: address ?? '',
    },
  });
  const dispatch = useAppDispatch();

  const onSubmit = handleSubmit((data) => {
    onNext(data.name, data.address);
    dispatch(resetYelpSearch());
    dispatch(searchYelpBusiness(data))
      .then(unwrapResult)
      .then(() => {
        // reset();
      })
      .catch(() => {});
  });

  const { searchingYelpBusiness } = useAppSelector(socialReviewsSelector);

  return (
    <>
      <FormContainer onSubmit={onSubmit}>
        <StyledLabel>Your business name (as shown on Yelp)</StyledLabel>

        <Controller
          name="name"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <StyledInput
              error={errors.name}
              placeholder="Monkey King Oakland"
              {...field}
            />
          )}
        />

        <StyledLabel>Your business address (as shown on Yelp)</StyledLabel>

        <Controller
          name="address"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <StyledInput
              error={errors.address}
              placeholder="3920 Piedmont Ave Oakland, CA 94611"
              {...field}
            />
          )}
        />

        <SearchButton
          type="submit"
          disabled={searchingYelpBusiness === Loading.PENDING}
        >
          {searchingYelpBusiness === Loading.PENDING ? (
            <b>Please wait...</b>
          ) : (
            <b>Search</b>
          )}
        </SearchButton>
      </FormContainer>
    </>
  );
}
