import { Placeholder } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import { IAppleStore, Loading } from '../../../../../shared/types/review.types';
import { useAppSelector } from '../../../../../store';
import { socialReviewsSelector } from '../../../../socialReviews/redux/social-reviews.slice';

/* eslint no-console: "warn" */

const Wrapper = styled.div``;

const ImageWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 3px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
  }
`;

const Businesses = styled(FlexContainer)`
  width: 100%;
  margin-top: 20px;
`;

const Business = styled(FlexContainer)`
  width: 100%;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #e2e2e2;

  &:hover {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  }

  .business-name {
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
  }

  .business-address {
    color: #666;
    font-size: 12px;
    margin: 0;
  }
`;

type Props = {
  onBack: () => void;
  onSelectApp: (app: IAppleStore) => void;
};

export default function AppleApps({ onBack, onSelectApp }: Props) {
  const { appleStores, loadingAppleStores } = useAppSelector(
    socialReviewsSelector
  );

  return (
    <Wrapper>
      {loadingAppleStores === Loading.PENDING && (
        <div style={{ marginTop: 20 }}>
          <Placeholder.Paragraph graph="image" rows={2} active />
        </div>
      )}

      {loadingAppleStores === Loading.SUCCESS && (
        <>
          {!appleStores.length && <p>No results found</p>}

          {!!appleStores.length && (
            <>
              <p>Select your Apple Store to import reviews</p>

              <Businesses
                stack
                gap="10px"
                justifyContent="flex-start"
                alignItems="flex-start"
                style={{ width: '100%' }}
              >
                {appleStores.map((app, i) => (
                  <Business
                    key={`app-${i}`}
                    gap="10px"
                    justifyContent="flex-start"
                    onClick={() => onSelectApp(app)}
                    role="presentation"
                  >
                    <ImageWrapper>
                      <img
                        src={app.logos[0].link}
                        alt={app.title}
                        referrerPolicy="no-referrer"
                      />
                    </ImageWrapper>
                    <div>
                      <p className="business-name">{app.title}</p>
                      <p className="business-address">{app.link}</p>
                      <p className="business-address">{app.link}</p>
                    </div>
                  </Business>
                ))}
              </Businesses>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
}
