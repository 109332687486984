import { FaArrowLeft } from 'react-icons/fa6';
import { Button } from 'rsuite';
import styled from 'styled-components';
import { InviteTypeEnum } from '..';
import FlexContainer from '../../../../../../shared/components/layouts/flex-container';
import { useAppDispatch } from '../../../../../../store';
import { setSelectedOrders } from '../../../../../emails/redux/emails.slice';
import { InviteCustomerForm } from './invite-customer-form';
import { InviteCustomerPreview } from './InviteCustomerPreview';
import { InviteCustomerProvider } from './InviteCustomerProvider';

/* eslint no-console: "warn" */

const Wrapper = styled.div`
  padding: 0;
  border: 2px solid red;
  background-color: red;
  width: 100%;
  height: 100%;
`;

const BackBtn = styled(Button)`
  font-weight: bold;
`;

const BackBtnWrapper = styled.div`
  padding-top: 30px;
  padding-left: 30px;
  position: relative;
  z-index: 99;
  max-width: 150px;
  background-color: #fff;
`;

const Content = styled(FlexContainer)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fff;
`;

const LeftSide = styled.div`
  width: 50%;
  height: 100%;

  @media (max-width: 1050px) {
    overflow-y: auto;
  }
`;

const RightSide = styled.div`
  width: 50%;
  height: 100%;
`;

export type AccountInfo = {
  fullName: string;
  email: string;
};

type Props = {
  onClose: () => void;
  inviteType: InviteTypeEnum;
};

export default function InviteCustomer({ inviteType, onClose }: Props) {
  const dispatch = useAppDispatch();

  return (
    <InviteCustomerProvider onClose={onClose}>
      <Wrapper>
        <BackBtnWrapper>
          <BackBtn
            appearance="subtle"
            onClick={() => {
              dispatch(setSelectedOrders([]));
              onClose();
            }}
          >
            <FlexContainer gap="5px">
              <FaArrowLeft />
              <span>Back</span>
            </FlexContainer>
          </BackBtn>
        </BackBtnWrapper>

        <Content justifyContent="space-between" alignItems="flex-start">
          <LeftSide>
            <InviteCustomerForm source={inviteType} />
          </LeftSide>
          <RightSide>
            <InviteCustomerPreview />
          </RightSide>
        </Content>
      </Wrapper>
    </InviteCustomerProvider>
  );
}
