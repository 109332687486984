import { AxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Form, InputPicker, Schema } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import ReviewsJetCrownLogo from '../../../shared/components/ReviewsJetCrownLogo';
import { useNotification } from '../../../shared/hooks/useNotification';
import { Loading } from '../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  AuthForm,
  AuthFormGroup,
  AuthLabel,
  AuthTextField,
  AuthTitle,
} from '../../external/components';
import { accountSelector } from '../../userData/redux/userData.slice';
import {
  OnboardingStep,
  setPersonalInformation,
} from '../redux/onboarding.slice';
import updateOnboarding from '../services/updateOnboarding';

/* eslint no-console: "warn" */

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 40px;
  max-width: 550px;
  width: 100%;
  max-height: 90%;
  overflow: auto;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  @media (max-width: 530px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0px;
    border: 0;
    box-shadow: none;
  }

  .rs-picker {
    width: 100%;
  }
  input[type='text'] {
    border-radius: 25px;
  }
`;

const Button = styled.button<{ $isSaving: boolean }>`
  width: 100%;
  border-radius: 25px;
  background-color: #000;
  color: #fff;
  padding: 13px 15px;
  opacity: ${({ $isSaving }) => ($isSaving ? 0.5 : 1)};
`;

const referenceOptions = [
  'Social Media (Facebook, Instagram, Twitter)',
  'Google Search',
  'Friend/Family Referral',
  'Blog Post',
  'LinkedIn',
  'Other',
].map((item) => ({ label: item, value: item }));

const { StringType, BooleanType } = Schema.Types;

const model = Schema.Model({
  firstName: StringType().isRequired('This field is required.'),
  lastName: StringType().isRequired('This field is required.'),
  website: StringType()
    .isRequired('This field is required.')
    .isURL('Enter a valid website URL'),
  hasExistingTestimonials: StringType().isRequired('This field is required'),
  reference: StringType().isRequired('This field is required'),
  sellProducts: StringType().isRequired('This field is required'),
});

type Props = {
  onNext: (hasExistingTestimonials: string) => void;
};

const StepIndicator = styled.p`
  color: #666;
  font-size: 14px;
  margin-bottom: 24px;
  margin-top: 8px;
`;

export default function PersonalInformation({ onNext }: Props) {
  const dispatch = useAppDispatch();
  const {
    firstName,
    lastName,
    websiteUrl,
    hasExistingTestimonials,
    reference,
    sellProducts,
  } = useAppSelector((state) => state.onboarding);
  const { account, loading } = useAppSelector(accountSelector);
  const { ownerName = '', selectedSite } = account ?? {};
  const { url = '', onboarding } = selectedSite ?? {};
  const { sellProduct, hasExistingReviews } = onboarding ?? {};

  const [saving, setSaving] = useState(Loading.INITIAL);

  const formRef = useRef<{ check: () => void }>(null);
  const [formValue, setFormValue] = useState({
    firstName,
    lastName,
    website: websiteUrl,
    hasExistingTestimonials: hasExistingTestimonials,
    reference: reference,
    sellProducts: sellProducts,
  });

  useEffect(() => {
    setFormValue({
      firstName,
      lastName,
      website: websiteUrl,
      reference,
      sellProducts,
      hasExistingTestimonials: hasExistingTestimonials,
    });
  }, [
    firstName,
    lastName,
    websiteUrl,
    hasExistingTestimonials,
    reference,
    sellProducts,
  ]);

  const { showErrorToast } = useNotification();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!formRef.current?.check()) {
      return;
    }

    dispatch(
      setPersonalInformation({
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        websiteUrl: formValue.website,
        reference: formValue.reference,
        sellProducts: formValue.sellProducts,
        hasExistingTestimonials: formValue.hasExistingTestimonials,
      })
    );

    try {
      setSaving(Loading.PENDING);

      await updateOnboarding({
        ownerName: `${formValue.firstName.trim()} ${formValue.lastName.trim()}`,
        websiteUrl: formValue.website,
        hasExistingTestimonials: formValue.hasExistingTestimonials,
        reference: formValue.reference,
        sellProducts: formValue.sellProducts,
        nextStep:
          formValue.hasExistingTestimonials === 'Yes'
            ? OnboardingStep.ImportReviews
            : OnboardingStep.AskForReviews,
      });

      setSaving(Loading.SUCCESS);
      onNext(formValue.hasExistingTestimonials);
    } catch (error) {
      setSaving(Loading.ERROR);

      if (error instanceof AxiosError) {
        showErrorToast(error.response?.data?.message || 'Something went wrong');
      } else {
        showErrorToast('Something went wrong');
      }
    }
  };

  return (
    <FlexContainer style={{ width: '100%' }}>
      <Wrapper>
        <ReviewsJetCrownLogo />

        <AuthTitle>Business Profile</AuthTitle>
        <StepIndicator>Step 1 of 3: Let's set up your account</StepIndicator>

        <AuthForm
          fluid
          ref={formRef}
          onChange={setFormValue}
          formValue={formValue}
          model={model}
        >
          <FlexContainer justifyContent="space-between" gap="15px">
            <AuthFormGroup controlId="firstName">
              <AuthLabel>First Name</AuthLabel>
              <AuthTextField
                name="firstName"
                type="text"
                autoComplete="true"
                placeholder="John"
              />
            </AuthFormGroup>

            <AuthFormGroup controlId="lastName">
              <AuthLabel>Last Name</AuthLabel>
              <AuthTextField
                name="lastName"
                type="text"
                autoComplete="true"
                placeholder="Doe"
              />
            </AuthFormGroup>
          </FlexContainer>
          <div style={{ height: 20 }}></div>

          <AuthFormGroup controlId="website">
            <AuthLabel>Website</AuthLabel>
            <AuthTextField
              name="website"
              type="text"
              autoComplete="true"
              placeholder="https://www.example.com"
            />
          </AuthFormGroup>
          <div style={{ height: 20 }}></div>

          <Form.Group controlId="inputPicker">
            <Form.ControlLabel>
              Do you sell products on your website?
            </Form.ControlLabel>
            <Form.Control
              name="sellProducts"
              accepter={InputPicker}
              data={['Yes', 'No'].map((item) => ({
                label: item,
                value: item,
              }))}
              cleanable={false}
            />
          </Form.Group>

          <Form.Group controlId="inputPicker">
            <Form.ControlLabel>Do you already have reviews?</Form.ControlLabel>
            <Form.Control
              name="hasExistingTestimonials"
              accepter={InputPicker}
              data={['Yes', 'No'].map((item) => ({
                label: item,
                value: item,
              }))}
              cleanable={false}
            />
          </Form.Group>

          <Form.Group controlId="inputPicker">
            <Form.ControlLabel>How did you hear about us?</Form.ControlLabel>
            <Form.Control
              name="reference"
              accepter={InputPicker}
              data={referenceOptions}
              cleanable={false}
            />
          </Form.Group>

          <Button
            type="submit"
            onClick={handleSubmit}
            disabled={saving === Loading.PENDING}
            $isSaving={saving === Loading.PENDING}
          >
            <FlexContainer
              justifyContent="center"
              alignItems="center"
              gap="5px"
            >
              {saving === Loading.PENDING ? (
                <b>Please wait...</b>
              ) : (
                <>
                  <b>Next</b> <MdKeyboardArrowRight />
                </>
              )}
            </FlexContainer>
          </Button>
        </AuthForm>
      </Wrapper>
    </FlexContainer>
  );
}
