import {
  ReviewerPlatform,
  ReviewStatus,
} from '../../../../../../../shared/types/review.types';
import { Review } from '../../../../../../manage-reviews/redux/reviews.slice';

const reviews: Review[] = [
  {
    instanceId: 'inst_123',
    content:
      'The product exceeded my expectations in every way possible. The quality is outstanding, shipping was incredibly fast, and the customer support team was exceptionally helpful when I had questions. Would definitely recommend to anyone looking for a reliable purchase experience.',

    rating: 5,
    status: ReviewStatus.PUBLISHED,
    reviewer: {
      _id: 'rev_123',
      instanceId: 'inst_123',
      email: 'john.smith@example.com',
      fullName: 'John Smith',
      avatar: 'https://i.pravatar.cc/150?img=1',
      created_at: '2024-03-01T10:00:00Z',
      updated_at: '2024-03-01T10:00:00Z',
    },
    verified: true,
    platform: ReviewerPlatform.WIDGET_REVIEW,
    helpfulCount: 12,
    pictures: [],
    reviewDate: '2024-03-15T14:30:00Z',
    _id: 'review_123',
    id: 'review_123',
    created_at: '2024-03-15T14:30:00Z',
    updated_at: '2024-03-15T14:30:00Z',
    product: null,
    reply: null,
    location: null,
    type: 'text',
  },
  {
    instanceId: 'inst_456',
    content:
      'Quality is great and the product works exactly as described. While delivery took a bit longer than expected, the wait was worth it given the excellent quality. The attention to detail is impressive, and I appreciate how well-made everything is. Would definitely recommend!',

    rating: 4,
    status: ReviewStatus.PUBLISHED,
    reviewer: {
      _id: 'rev_456',
      instanceId: 'inst_456',
      email: 'sarah.j@example.com',
      fullName: 'Sarah Johnson',
      avatar: 'https://i.pravatar.cc/150?img=2',
      created_at: '2024-03-01T11:00:00Z',
      updated_at: '2024-03-01T11:00:00Z',
    },
    verified: true,
    platform: ReviewerPlatform.WIDGET_REVIEW,
    helpfulCount: 8,
    pictures: [],
    reviewDate: '2024-03-14T09:15:00Z',
    _id: 'review_456',
    id: 'review_456',
    created_at: '2024-03-14T09:15:00Z',
    updated_at: '2024-03-14T09:15:00Z',
    product: null,
    reply: null,
    location: null,
    type: 'text',
  },
  {
    instanceId: 'inst_789',
    content:
      'Absolutely love this product! The quality is outstanding and it arrived earlier than expected. The packaging was secure and professional, and the product itself exceeds all my expectations. The customer service team was also very responsive when I reached out with questions.',

    rating: 5,
    status: ReviewStatus.PUBLISHED,
    reviewer: {
      _id: 'rev_789',
      instanceId: 'inst_789',
      email: 'mike.brown@example.com',
      fullName: 'Mike Brown',
      avatar: 'https://i.pravatar.cc/150?img=3',
      created_at: '2024-03-02T09:00:00Z',
      updated_at: '2024-03-02T09:00:00Z',
    },
    verified: true,
    platform: ReviewerPlatform.WIDGET_REVIEW,
    helpfulCount: 15,
    pictures: [],
    reviewDate: '2024-03-13T16:45:00Z',
    _id: 'review_789',
    id: 'review_789',
    created_at: '2024-03-13T16:45:00Z',
    updated_at: '2024-03-13T16:45:00Z',
    product: null,
    reply: null,
    location: null,
    type: 'text',
  },
  {
    instanceId: 'inst_101',
    content:
      'Good value for money and excellent quality. The product arrived in perfect condition and works exactly as advertised. Setup was straightforward, and I particularly appreciate the detailed instructions that came with it. Would definitely buy again from this seller.',
    rating: 4,
    status: ReviewStatus.PUBLISHED,
    reviewer: {
      _id: 'rev_101',
      instanceId: 'inst_101',
      email: 'emma.wilson@example.com',
      fullName: 'Emma Wilson',
      avatar: 'https://i.pravatar.cc/150?img=4',
      created_at: '2024-03-03T14:20:00Z',
      updated_at: '2024-03-03T14:20:00Z',
    },
    verified: true,
    platform: ReviewerPlatform.WIDGET_REVIEW,
    helpfulCount: 7,
    pictures: [],
    reviewDate: '2024-03-12T11:30:00Z',
    _id: 'review_101',
    id: 'review_101',
    created_at: '2024-03-12T11:30:00Z',
    updated_at: '2024-03-12T11:30:00Z',
    product: null,
    reply: null,
    location: null,
    type: 'text',
  },
  {
    instanceId: 'inst_102',
    content:
      "Perfect fit for what I needed. The customer service team was incredibly helpful when I had questions about the product specifications. The quality is excellent, and it's clear that a lot of attention went into both the product design and the user experience. Very satisfied with my purchase.",
    rating: 5,
    status: ReviewStatus.PUBLISHED,
    reviewer: {
      _id: 'rev_102',
      instanceId: 'inst_102',
      email: 'david.lee@example.com',
      fullName: 'David Lee',
      avatar: 'https://i.pravatar.cc/150?img=5',
      created_at: '2024-03-04T08:15:00Z',
      updated_at: '2024-03-04T08:15:00Z',
    },
    verified: true,
    platform: ReviewerPlatform.WIDGET_REVIEW,
    helpfulCount: 9,
    pictures: [],
    reviewDate: '2024-03-11T13:20:00Z',
    _id: 'review_102',
    id: 'review_102',
    created_at: '2024-03-11T13:20:00Z',
    updated_at: '2024-03-11T13:20:00Z',
    product: null,
    reply: null,
    location: null,
    type: 'text',
  },
  {
    instanceId: 'inst_104',
    content:
      'Just received my order yesterday. Setup was a breeze, and it worked perfectly right out of the box. The design is sleek and modern, exactly what I was looking for. Really happy with this purchase!',
    rating: 4,
    status: ReviewStatus.PUBLISHED,
    reviewer: {
      _id: 'rev_104',
      instanceId: 'inst_104',
      email: 'alex.martinez@example.com',
      fullName: 'Alex Martinez',
      avatar: 'https://i.pravatar.cc/150?img=7',
      created_at: '2024-03-06T12:45:00Z',
      updated_at: '2024-03-06T12:45:00Z',
    },
    verified: true,
    platform: ReviewerPlatform.WIDGET_REVIEW,
    helpfulCount: 6,
    pictures: ['https://picsum.photos/400/300?random=10'],
    reviewDate: '2024-03-09T09:30:00Z',
    _id: 'review_104',
    id: 'review_104',
    created_at: '2024-03-09T09:30:00Z',
    updated_at: '2024-03-09T09:30:00Z',
    product: null,
    reply: null,
    location: null,
    type: 'text',
  },
];

export default reviews;
