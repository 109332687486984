import { FaAirbnb, FaEtsy } from 'react-icons/fa6';
import styled from 'styled-components';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import AliExpressIcon from '../../../../shared/svgs/AliExpressIcon';
import { ReviewerPlatform } from '../../../../shared/types/review.types';
import ImportAppleReviews from './ImportAppleReviews';
import ImportGoogleReviews from './ImportGoogleReviews';
import ImportWithChromeExtension from './ImportWithChromeExtension';
import ImportYelpReviews from './ImportYelpReviews';
import ProducthuntReviews from './ProducthuntReviews';
import TripadvisorReviews from './TripadvisorReviews';
import TruspilotReviews from './TruspilotReviews';

const Item = styled(FlexContainer)`
  width: 100%;
  cursor: pointer;
  max-width: 150px;
  padding: 10px;
  border-radius: 6px;
  background-color: #fdfdfd;
  border: 1px solid #e2e2e2;
  gap: 5px;
  &:hover {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  }

  img {
    height: 20px;
  }

  span {
    font-weight: 600;
  }
`;

export default function SocialReviews() {
  return (
    <>
      <p style={{ marginBottom: '20px' }}>
        Seamlessly import your reviews from Google, Yelp, AliExpress, and more
      </p>
      <FlexContainer
        justifyContent="flex-start"
        gap="10px"
        alignItems="stretch"
        flexWrap="wrap"
      >
        <ImportGoogleReviews />
        <ImportYelpReviews />
        <ImportWithChromeExtension
          platform={ReviewerPlatform.ALI_EXPRESS}
          logo={<AliExpressIcon />}
        />

        <ImportWithChromeExtension
          platform={ReviewerPlatform.ETSY}
          logo={<FaEtsy size={24} color="#ED5601" />}
        />

        <ImportWithChromeExtension
          platform={ReviewerPlatform.TOUR_RADAR}
          logo={
            <img
              src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/logos/tourradar.png"
              alt="Tourradar reviews"
            />
          }
        />

        <ImportWithChromeExtension
          platform={ReviewerPlatform.AIRBNB}
          logo={<FaAirbnb size={24} color="#FF6154" />}
        />

        <TripadvisorReviews />
        <ProducthuntReviews />

        <TruspilotReviews />
        <ImportAppleReviews />
      </FlexContainer>
    </>
  );
}
