import { unwrapResult } from '@reduxjs/toolkit';
import { createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Message } from 'rsuite';
import styled, { css } from 'styled-components';
import Button from '../../../../../shared/components/Button';
import {
  ModalBody,
  ModalHeader,
} from '../../../../../shared/components/modal/modal-parts';
import StyledText from '../../../../../shared/components/styled-text';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import { Loading } from '../../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { accountSelector } from '../../../../userData/redux/userData.slice';
import { addAirbnbAccount } from '../../../redux/actions';
import { socialReviewsSelector } from '../../../redux/social-reviews.slice';
import { VALID_AIRBNB_DOMAINS } from '../../../utils/constants';

const StyledInput = styled(Input)<{ error: boolean }>`
  background: #fff;
  margin-bottom: 20px;
  border-color: #cdcdcd;
  margin-top: 5px;
  outline: none !important;
  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;
const StyledLabel = styled(StyledText)`
  font-size: 14px;
  color: #7b7b7b;
`;

const StyledMessage = styled(Message)`
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  padding-top: 20px;
`;

const ContinueButton = styled(Button)<{ disabled?: boolean }>`
  padding-left: 30px;
  padding-right: 30px;
  margin: 30px auto 0 0;
  background-color: #000;
  border-color: #000;
  width: 100%;
`;

const isValidAirbnbUrl = (url: string): boolean => {
  const airbnbUrlPattern = /^rooms\/(\d+)(\?.*)?$/;
  return airbnbUrlPattern.test(url);
};

const extractAirbnbId = (url: string): string | null => {
  const match = url.match(/(\d+)/);
  if (match && match.length > 0) {
    return match[0];
  } else {
    return null;
  }
};

const AddPropertyPage = ({ onConnected }: { onConnected: () => void }) => {
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { plan } = selectedSite ?? {};
  const ref = createRef<HTMLInputElement>();
  const { t } = useTranslation('common');
  const {
    get3rdPartyAccount: { data: integrations },
    addingAirbnbAccount,
  } = useAppSelector(socialReviewsSelector);
  const [reviewUrl, setReviewUrl] = useState('');
  const { showWarningToast, showSuccessToast, showErrorToast } =
    useNotification();

  const connectAirbnb = () => {
    if (ref.current) {
      ref.current.style.borderColor = '#cdcdcd';

      if (!reviewUrl) {
        ref.current.style.borderColor = 'red';
        return showWarningToast('Please add a valid Airbnb property page URL.');
      }

      try {
        const urlObject = new URL(reviewUrl);
        const domain = urlObject.hostname
          .replace(/www\./g, '')
          .replace(/nl\./g, '')
          .replace(/fr\./g, '')
          .replace(/is\./g, '')
          .replace(/ja\./g, '')
          .replace(/sv\./g, '')
          .replace(/ar\./g, '')
          .replace(/pt\./g, '')
          .replace(/hr\./g, '')
          .replace(/ms\./g, '')
          .replace(/id\./g, '')
          .replace(/cs\./g, '')
          .replace(/da\./g, '')
          .replace(/uk\./g, '')
          .replace(/ru\./g, '')
          .replace(/es\./g, '')
          .replace(/it\./g, '');

        if (!VALID_AIRBNB_DOMAINS.includes(domain)) {
          ref.current.style.borderColor = 'red';
          return showWarningToast('Please add a valid airbnb page URL');
        }

        const search = extractAirbnbId(reviewUrl);
        if (search == null) {
          ref.current.style.borderColor = 'red';
          return showWarningToast(
            'Please add a valid Airbnb property page URL.'
          );
        }

        const alreadyAdded = (integrations?.airbnb_reviews ?? []).some(
          (i) => i.propertyId === search
        );

        if (alreadyAdded) {
          ref.current.style.borderColor = 'red';
          return showWarningToast('Property page already added.');
        }

        dispatch(addAirbnbAccount({ search }))
          .then(unwrapResult)
          .then(() => {
            showSuccessToast(
              `Your reviews are being imported! It'll just take 3 to 5 minutes. We'll ping you once they're in.`
            );
            onConnected();
          })
          .catch(() => {
            showErrorToast('Something went wrong. Please try it again.');
          });
      } catch (error) {
        ref.current.style.borderColor = 'red';
        return showWarningToast('Please add a valid Airbnb property page URL.');
      }
    }
  };

  return (
    <>
      <ModalHeader
        title="Add your Airbnb page"
        centerText={false}
        closeButton={false}
      />
      <ModalBody>
        <Wrapper>
          <div>
            <StyledLabel>Your link to Airbnb property page</StyledLabel>
            <StyledInput
              ref={ref}
              error={null}
              onChange={(url: string) => {
                setReviewUrl(url);
                if (ref.current) {
                  ref.current.style.borderColor = '#cdcdcd';
                }
              }}
              placeholder="https://www.airbnb.com/rooms/67690262?adults=1&children=0&infants=0&check_in"
            />
            <ContinueButton
              variant="primary"
              size="large"
              onClick={connectAirbnb}
            >
              <p>
                {' '}
                {addingAirbnbAccount === Loading.PENDING
                  ? 'Please wait...'
                  : 'Import reviews'}
              </p>
            </ContinueButton>
          </div>
        </Wrapper>
      </ModalBody>
    </>
  );
};

export default AddPropertyPage;
