const YelpIcon = ({ size = 30 }: { size?: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
    >
      <linearGradient
        id="a"
        x1="1.323"
        x2="44.983"
        y1="5.864"
        y2="47.991"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#f52537" />
        <stop offset=".293" stopColor="#f32536" />
        <stop offset=".465" stopColor="#ea2434" />
        <stop offset=".605" stopColor="#dc2231" />
        <stop offset=".729" stopColor="#c8202c" />
        <stop offset=".841" stopColor="#ae1e25" />
        <stop offset=".944" stopColor="#8f1a1d" />
        <stop offset="1" stopColor="#7a1818" />
      </linearGradient>
      <path
        fill="url(#a)"
        d="M10.7 32.7c-.5 0-.9-.3-1.2-.8-.2-.4-.3-1-.4-1.7-.2-2.2 0-5.5.7-6.5.3-.5.8-.7 1.2-.7.3 0 .6.1 7.1 2.8l1.9.8c.7.3 1.1 1 1.1 1.8s-.5 1.4-1.2 1.6l-2.7.9c-6 1.8-6.2 1.8-6.5 1.8zM24 36.3c0 6.3 0 6.5-.1 6.8-.2.5-.6.8-1.1.9-1.6.3-6.6-1.6-7.7-2.8-.2-.3-.3-.5-.4-.8 0-.2 0-.4.1-.6.1-.3.3-.6 4.8-5.9l1.3-1.6c.4-.6 1.3-.7 2-.5.7.3 1.2.9 1.1 1.6v2.9zm-1.2-13.4c-.3.1-1.3.4-2.5-1.6 0 0-8.1-12.9-8.3-13.3-.1-.4 0-1 .4-1.4 1.2-1.3 7.7-3.1 9.4-2.7.6.1.9.5 1.1 1 .1.6.9 12.5 1 15.2.2 2.4-.8 2.7-1.1 2.8zm4.4 3c-.4-.6-.4-1.4 0-1.9l1.7-2.3c3.6-5 3.8-5.3 4.1-5.4.4-.3.9-.3 1.4-.1 1.4.7 4.4 5.1 4.6 6.7v.1c0 .6-.2 1-.6 1.3-.3.2-.5.3-7.4 1.9-1.1.3-1.7.4-2 .5v-.1c-.6.3-1.4-.1-1.8-.7zm11.7 8.5c-.2 1.6-3.5 5.8-5.1 6.4-.5.2-1 .2-1.4-.2-.3-.2-.5-.6-4.1-6.4l-1.1-1.7c-.4-.6-.3-1.4.2-2.1.5-.6 1.2-.8 1.9-.6l2.7.9c6 2 6.2 2 6.4 2.2.4.5.6 1 .5 1.5z"
      />
    </svg>
  );
};

export default YelpIcon;
