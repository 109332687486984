import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import { Breadcrumb } from 'rsuite';
import styled from 'styled-components';
import { InviteTypeEnum } from '../..';
import FlexContainer from '../../../../../../../shared/components/layouts/flex-container';
import {
  InviteCustomerSteps,
  useIniviteCustomerContext,
} from '../InviteCustomerProvider';
import getPageAttributes from '../utils/getPageAttributes';
import { AdvancedForm } from './AdvancedForm';
import { default as ContactsList } from './ContactsList';
import { CustomerForm } from './CustomerForm';
import { MessageForm } from './MessageForm';
import { SendForm } from './SendForm';

/* eslint no-console: "warn" */

const StyledBreadcrumb = styled(Breadcrumb)`
  margin-bottom: 50px;
`;

const Wrapper = styled(FlexContainer)`
  max-width: 512px;
  width: 100%;
  margin: 100px auto 0;
  padding: 20px;
`;

const StyledBody = styled.div`
  overflow: hidden !important;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  justify-content: space-between;
  padding-bottom: 40px;
  width: 100%;

  @media (max-width: 1050px) {
    min-height: unset;
  }
`;
const Title = styled.h1`
  font-size: 24px;
  color: #333;
  line-height: 30px;
  margin-bottom: 30px;
`;
const StyledBreadcrumbItem = styled(Breadcrumb.Item)<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'pointer' : 'default')};
`;

const getHeaders = (source: InviteTypeEnum | null) => {
  const attributes = getPageAttributes(source);
  const component =
    source === InviteTypeEnum.SINGLE ? (
      <CustomerForm />
    ) : (
      <ContactsList source={source} />
    );

  const headers = [
    {
      id: 0,
      name: attributes.breadcrumb,
      key: InviteCustomerSteps.CUSTOMER,
      title: attributes.title,
      component,
    },
    {
      id: 1,
      name: 'Message',
      key: InviteCustomerSteps.MESSAGE,
      title: 'Pick a template or write a message',
      component: <MessageForm />,
    },
    // {
    //   id: 2,
    //   name: 'Logo',
    //   key: InviteCustomerSteps.LOGO,
    //   title: 'Confirm your logo',
    //   component: <LogoForm />,
    // },
    {
      id: 2,
      name: 'Advanced',
      key: InviteCustomerSteps.ADVANCED,
      title: 'Advanced settings',
      component: <AdvancedForm />,
    },
    {
      id: 3,
      name: 'Send',
      key: InviteCustomerSteps.SEND,
      title: 'Ready to send?',
      component: <SendForm />,
    },
  ];

  return headers;
};

export const InviteCustomerForm = ({
  source,
}: {
  source: InviteTypeEnum | null;
}) => {
  const headers = getHeaders(source);
  const { currentStep, setCurrentStep } = useIniviteCustomerContext();
  const currentOptions = headers.find((h) => h.key === currentStep)!;

  return (
    <Wrapper>
      <div style={{ width: '100%' }}>
        <StyledBreadcrumb separator={<AngleRightIcon />}>
          {headers.map((header, index) => (
            <StyledBreadcrumbItem
              key={currentOptions.id}
              active={currentStep === header.key}
              disabled={index <= currentOptions.id}
              onClick={() =>
                index <= currentOptions.id && setCurrentStep(header.key)
              }
            >
              {header.name}
            </StyledBreadcrumbItem>
          ))}
        </StyledBreadcrumb>
        <Title>{currentOptions.title}</Title>
        <StyledBody>{currentOptions.component}</StyledBody>
      </div>
    </Wrapper>
  );
};
