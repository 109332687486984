import { useCallback, useState } from 'react';
import Http from '../../../../../../../services/http';
import { Loading } from '../../../../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../../../../shared/utils/removeTrailingSlash';
import { ContactItem } from '../../../../../../emails/redux/emails.slice';

const http = new Http();

export default function useWixOrders() {
  const [cursor, setCursor] = useState<string | null>(null);
  const [loadingContacts, setLoadingContacts] = useState(Loading.INITIAL);
  const [loadingInitialContacts, setLoadingInitialContacts] = useState(
    Loading.INITIAL
  );
  const [orders, setOrders] = useState<ContactItem[]>([]);
  const [hasNext, setHasNext] = useState(false);

  const getWixOrders = useCallback(async () => {
    if (loadingContacts === Loading.PENDING) {
      return null;
    }

    if (cursor) {
      setLoadingContacts(Loading.PENDING);
    } else {
      setLoadingInitialContacts(Loading.PENDING);
    }

    try {
      let url = `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/wix/orders`;

      if (cursor) {
        url += `?cursor=${cursor}`;
      }

      const response = await http.get(url);

      const { hasNext, cursor: _cursor, orders: _orders } = response.data;

      setHasNext(hasNext);
      setCursor(_cursor);
      setOrders([...orders, ..._orders]);

      if (cursor) {
        setLoadingContacts(Loading.SUCCESS);
      } else {
        setLoadingInitialContacts(Loading.SUCCESS);
      }
    } catch (error) {
      if (cursor) {
        setLoadingContacts(Loading.ERROR);
      } else {
        setLoadingInitialContacts(Loading.ERROR);
      }
    }
  }, [cursor]);

  return {
    hasNext,
    orders,
    getWixOrders,
    loadingContacts,
    loadingInitialContacts,
  };
}
