import { useEffect, useMemo } from 'react';
import { LuUser2 } from 'react-icons/lu';
import styled from 'styled-components';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import useModal from '../../../../../shared/components/modal/useModal';
import Preloader from '../../../../../shared/components/Preloader';
import { Loading } from '../../../../../shared/types/review.types';
import { getLimit } from '../../../../../shared/utils/usage-limits';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { emailsSelector } from '../../../../emails/redux/emails.slice';
import { listIntegrations } from '../../../../settings/components/Integrations/redux/actions';
import { integrationsSelector } from '../../../../settings/components/Integrations/redux/integrations.slice';
import { accountSelector } from '../../../../userData/redux/userData.slice';
import ActiveCampaignContacts from './invite-customer/ActiveCampaignContacts';
import KlaviyoContacts from './invite-customer/KlaviyoContacts';
import MailChimpContacts from './invite-customer/MailChimpContacts';
import SendgridContacts from './invite-customer/SendgridContacts';

/* eslint no-console: "warn" */

const CardsWrapper = styled(FlexContainer)`
  gap: 15px;
  flex-wrap: wrap;
  padding-top: 20px;
  .card {
    border: 1px solid #ddd;
    border-radius: 10px;
    max-width: 170px;
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 20px;
    gap: 10px;
    cursor: pointer;

    @media (max-width: 1050px) {
      max-width: 170px;
    }

    &:hover {
      background-color: #fff;
      border: 1px solid #fff;
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    }

    .logo {
      width: 50px;
      max-height: 50px;

      img {
        width: 100%;
      }
    }

    p {
      color: #656565;
      font-size: 13px;
    }
  }
`;

export enum InviteTypeEnum {
  SINGLE = 'single',
  ORDERS = 'orders',
  MAILCHIMP = 'mailchimp',
  KLAVIYO = 'klaviyo',
  INTERCOM = 'intercom',
  ACTIVECAMPAIGN = 'activecampaign',
  SENDGRID = 'sendgrid',
}

type Props = {
  setInviteType: (type: InviteTypeEnum) => void;
  onNext: (inviteType: InviteTypeEnum) => void;
};

export default function SendEmail({ setInviteType, onNext }: Props) {
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { plan } = selectedSite ?? {};
  const {
    stats: { loading, totalSentThisMonth },
  } = useAppSelector(emailsSelector);

  const emailsCredit = getLimit(plan)?.reviewsRequests;

  const canRequest = useMemo(() => {
    return emailsCredit > totalSentThisMonth;
  }, [emailsCredit, totalSentThisMonth]);
  const {
    handleCloseModal: handleCloseCreditLimitModal,
    handleOpenModal: handleOpenCreditLimitModal,
    showModal: showCreditLmitModal,
  } = useModal();
  const { loading: loadingIntegrations } = useAppSelector(integrationsSelector);

  useEffect(() => {
    dispatch(listIntegrations({ showPreloader: true }));
  }, []);

  if (loadingIntegrations === Loading.PENDING) {
    return <Preloader />;
  }

  return (
    <>
      <p>Email your existing customers and invite them to leave a review.</p>

      <CardsWrapper alignItems="stretch" justifyContent="flex-start">
        <div
          className="card"
          role="presentation"
          onClick={() => {
            if (canRequest) {
              setInviteType(InviteTypeEnum.SINGLE);
            } else {
              handleOpenCreditLimitModal();
            }
          }}
        >
          <LuUser2 size={50} />
          <p>Invite a single customer</p>
        </div>

        <MailChimpContacts onNext={onNext} />
        <KlaviyoContacts onNext={onNext} />
        <ActiveCampaignContacts onNext={onNext} />
        <SendgridContacts onNext={onNext} />

        {/* <div
          className="card"
          role="presentation"
          onClick={() =>
            window.open('https://reviewsjet.featurebase.app/', '_blank')
          }
        >
          <FaPlus size={50} />
          <p>Request an integration</p>
        </div> */}
      </CardsWrapper>
    </>
  );
}
