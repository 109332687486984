import { unwrapResult } from '@reduxjs/toolkit';
import styled from 'styled-components';
import Button from '../../../../shared/components/Button';
import { useNotification } from '../../../../shared/hooks/useNotification';
import { Loading } from '../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { updateWidgetsSettings } from '../../../widgetSettings/redux/action';
import { settingSelector } from '../../../widgetSettings/redux/widgetSettings.slice';

const Wrapper = styled(Button)<{ $show: boolean }>`
  width: 150px;
  background-color: #000;
  color: #fff;
  border-color: #000;

  &:disabled {
    background-color: #909090;
    color: #fff;
    border-color: #909090;
  }
  /* visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')}; */
`;

export default function SaveButton() {
  const dispatch = useAppDispatch();
  const { showSuccessToast } = useNotification();

  const {
    updateWidgetSettings: { saving },
    isWidgetSettingsUpdated,
  } = useAppSelector(settingSelector);

  const updateSettings = () => {
    dispatch(updateWidgetsSettings())
      .then(unwrapResult)
      .then(() => {
        showSuccessToast('Settings successfully saved');
      })
      .catch(() => {});
  };

  return (
    <Wrapper
      $show={isWidgetSettingsUpdated}
      rounded
      onClick={updateSettings}
      disabled={saving === Loading.PENDING}
    >
      <b>{saving === Loading.PENDING ? 'Saving...' : 'Save settings'}</b>
    </Wrapper>
  );
}
