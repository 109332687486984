import { Controller } from 'react-hook-form';
import { FaArrowRightLong } from 'react-icons/fa6';
import styled from 'styled-components';
import {
  ErroMessage,
  StyledInput,
} from '../../../../../../../shared/components/form/StyledInput';
import FlexContainer from '../../../../../../../shared/components/layouts/flex-container';
import FormRow from '../../../../../../widgetSettings/components/form-row';
import {
  InviteCustomerSteps,
  useIniviteCustomerContext,
} from '../InviteCustomerProvider';
import { SubmitButton } from './SubmitButton';

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

export function CustomerForm() {
  const { control, errors, trigger, setCurrentStep } =
    useIniviteCustomerContext();

  return (
    <>
      <div>
        <FormRow stack alignItems="flex-start">
          <Label htmlFor="name">
            Name <sup>*</sup>
          </Label>
          <Controller
            name="toName"
            rules={{ required: true }}
            control={control}
            render={({ field }: any) => (
              <StyledInput
                id="toName"
                placeholder="Tim Cook"
                error={Boolean(errors.toName)}
                {...field}
              />
            )}
          />
          {errors.toName && <ErroMessage>{errors.toName.message}</ErroMessage>}
        </FormRow>
        <FormRow stack alignItems="flex-start">
          <Label htmlFor="emailAddress">
            Email Address <sup>*</sup>
          </Label>
          <Controller
            name="toEmail"
            rules={{ required: true }}
            control={control}
            render={({ field }: any) => (
              <StyledInput
                id="emailAddress"
                placeholder="tim@apple.com"
                error={Boolean(errors.toEmail)}
                {...field}
              />
            )}
          />
          {errors.toEmail && (
            <ErroMessage>{errors.toEmail.message}</ErroMessage>
          )}
        </FormRow>
      </div>
      <SubmitButton
        onClick={async () => {
          const isValid = await trigger(['toName', 'toEmail'], {
            shouldFocus: true,
          });
          if (isValid) {
            setCurrentStep(InviteCustomerSteps.MESSAGE);
          }
        }}
      >
        <FlexContainer gap="5px">
          <span>Next</span> <FaArrowRightLong />
        </FlexContainer>
      </SubmitButton>
    </>
  );
}
