import { unwrapResult } from '@reduxjs/toolkit';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Schema } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../shared/components/Button';
import ReviewsJetCrownLogo from '../../../shared/components/ReviewsJetCrownLogo';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../shared/hooks/useNotification';
import useQuery from '../../../shared/hooks/useQuery';
import { Loading } from '../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  setPassword,
  updatePasswordWithToken,
  verifyPasswordResetToken,
} from '../../userData/redux/action';
import {
  AuthForm,
  AuthFormGroup,
  AuthIconWrapper,
  AuthTextField,
  AuthTitle,
} from '../components';

/* eslint no-console: "warn" */

const StyledFlexContainer = styled(FlexContainer)`
  padding: 20px 0 0;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 40px;
  max-width: 440px;
  max-height: 90%;
  overflow: auto;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  @media (max-width: 530px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0px;
    border: 0;
    box-shadow: none;
  }

  input {
    border-radius: 25px;
  }

  .forgot-password {
    color: #8b8e99;
    /* text-decoration: underline !important;
    text-underline-offset: 4px; */
  }
`;

const StyledButton = styled(Button)`
  background-color: #000;
  color: #fff;
  border-radius: 25px;
  width: 100%;
  border-color: #000;
`;

const Intro = styled.p`
  margin-bottom: 30px;
  color: #737373;
  font-size: 15px;
`;

const { StringType } = Schema.Types;

const model = Schema.Model({
  password: StringType()
    .isRequired('This field is required.')
    .addRule((password) => {
      let strength = 0;

      if (password.match(/[a-z]+/)) {
        strength += 1;
      }

      if (password.match(/[0-9]+/)) {
        strength += 1;
      }

      if (password.length >= 5) {
        strength += 1;
      }

      if (strength < 3) {
        return false;
      }

      return true;
    }, 'Password should contain at least a letter and a number.'),
  verifyPassword: StringType()
    .addRule((value, data) => {
      if (value !== data.password) {
        return false;
      }

      return true;
    }, 'The two passwords do not match')
    .isRequired('This field is required.'),
});

const SetPasswordPage = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();
  const hasPassword = query.get('p');
  const dispatch = useAppDispatch();
  const [submitted, setSubmitted] = useState(false);
  const { showSuccessToast, showErrorToast } = useNotification();
  const [visible, setVisible] = useState(false);
  const formRef = useRef<{ check: () => void }>(null);
  const {
    updatePassword: { loading },
    verifyPasswordResetToken: { loading: verifyingToken },
  } = useAppSelector((state) => state.userData);

  const [formValue, setFormValue] = useState({
    password: '',
  });

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  const handleSubmit = () => {
    if (token) {
      resetPasswordWithToken();
    } else if (hasPassword) {
      resetPassword();
    }
  };

  const resetPasswordWithToken = useCallback(() => {
    if (!formRef.current?.check()) {
      return;
    }

    if (!token) {
      return;
    }

    dispatch(updatePasswordWithToken({ password: formValue.password, token }))
      .then(unwrapResult)
      .then(() => {
        showSuccessToast('Password updated! Please login now.');
        setSubmitted(true);
      })
      .catch(() => {
        showErrorToast('An error occurred. Please try it again.');
      });
  }, [formValue, token]);

  const resetPassword = useCallback(() => {
    if (!formRef.current?.check()) {
      return;
    }

    if (!hasPassword) {
      return;
    }

    dispatch(setPassword({ password: formValue.password }))
      .then(unwrapResult)
      .then(() => {
        showSuccessToast('Password updated! Please login now.');
        setSubmitted(true);
        navigate('/manage-reviews');
      })
      .catch(() => {
        showErrorToast('An error occurred. Please try it again.');
      });
  }, [formValue, hasPassword]);

  useEffect(() => {
    if (!hasPassword) {
      dispatch(verifyPasswordResetToken(token));
    }
  }, [token, hasPassword]);

  if (verifyingToken === Loading.PENDING) {
    return <p>Please wait....</p>;
  }

  // if (verifyingToken === Loading.ERROR) {
  //   return (
  //     <Wrapper style={{ maxWidth: 650 }}>
  //       <ErrorPage
  //         onRetryClick={() => {
  //           if (token) {
  //             dispatch(verifyPasswordResetToken(token));
  //           }
  //         }}
  //         showFooter={false}
  //       />

  //       <StyledFlexContainer gap="10px">
  //         <Link to="/login">Go back to sign-in</Link>
  //       </StyledFlexContainer>
  //     </Wrapper>
  //   );
  // }

  return (
    <Wrapper>
      <ReviewsJetCrownLogo />
      {submitted ? (
        <>
          <AuthTitle marginBottom="10px">Success!</AuthTitle>
          <p>Your password has been successfully updated. You may login now.</p>
          <StyledFlexContainer gap="10px" justifyContent="flex-start">
            <Link to="/login">Go back to sign-in</Link>
          </StyledFlexContainer>
        </>
      ) : (
        <>
          <AuthTitle marginBottom="10px">Forgot password</AuthTitle>
          <Intro>
            Enter the email address associated with your ReviewsJet account.
            We'll send you instructions to reset your password.
          </Intro>
          <AuthForm
            fluid
            ref={formRef}
            onChange={setFormValue}
            formValue={formValue}
            model={model}
          >
            <AuthFormGroup controlId="password">
              <AuthIconWrapper onClick={toggleVisibility}>
                {visible ? <EyeIcon /> : <EyeSlashIcon />}
              </AuthIconWrapper>
              <AuthTextField
                isPassword
                name="password"
                type={visible ? 'text' : 'password'}
                autoComplete="off"
                placeholder="Enter new password"
              />
            </AuthFormGroup>

            <div style={{ height: 20 }}></div>

            <AuthFormGroup controlId="verifyPassword">
              <AuthTextField
                isPassword
                name="verifyPassword"
                type={visible ? 'text' : 'password'}
                autoComplete="off"
                placeholder="Confirm Password"
              />
            </AuthFormGroup>

            <Form.Group>
              <FlexContainer
                justifyContent="space-between"
                style={{ marginTop: 20 }}
              >
                <StyledButton
                  size="large"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loading === Loading.PENDING}
                >
                  {loading === Loading.PENDING ? (
                    <b>Please wait...</b>
                  ) : (
                    <FlexContainer gap="5px">
                      <b>Reset password</b>
                    </FlexContainer>
                  )}
                </StyledButton>
              </FlexContainer>
            </Form.Group>
          </AuthForm>
        </>
      )}
    </Wrapper>
  );
};

export default SetPasswordPage;
