import styled from 'styled-components';
import FlexContainer from '../../../../shared/components/layouts/flex-container';

const Wrapper = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 6px;
  max-width: 375px;
  height: 347px;
  border: 5px solid #f4f4f4;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-color: #dedede;

    .widget-info {
      background-color: #dedede;
      color: #454545;
    }
  }

  .img-wrapper {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;

    -webkit-border-top-left-radius: 6px;
    -webkit-border-top-right-radius: 6px;
    -moz-border-radius-topleft: 6px;
    -moz-border-radius-topright: 6px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      -webkit-border-top-left-radius: 1px;
      -webkit-border-top-right-radius: 1px;
      -moz-border-radius-topleft: 1px;
      -moz-border-radius-topright: 1px;
      border-top-left-radius: 1px;
      border-top-right-radius: 1px;
      image-rendering: auto;
    }
  }

  .widget-info {
    padding: 10px;
    font-family: ubuntu;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 600;
    color: #626262;
    width: 100%;
  }
`;

type Props = {
  image: string;
  title: string;
  onClick: () => void;
};

export default function Widget({ image, title, onClick }: Props) {
  return (
    <Wrapper
      stack
      justifyContent="flex-start"
      alignItems="flex-start"
      onClick={onClick}
      role="presentation"
    >
      <div className="img-wrapper">
        <img src={image} alt="" />
      </div>
      <div className="widget-info">
        <p>{title}</p>
      </div>
    </Wrapper>
  );
}
