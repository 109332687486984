import FontPicker from 'font-picker-react';
import { Divider, InputPicker, Toggle } from 'rsuite';
import styled from 'styled-components';
import DATE_FORMATS from '../../../../../../../shared/utils/dateFormats';
import { useAppSelector } from '../../../../../../../store';
import FormRow from '../../../../../../widgetSettings/components/form-row';
import { settingSelector } from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import useDispatchUpdateSiteWidgetSettings from '../../../../../../widgetSettings/shared/hooks/useDispatchUpdateSiteWidgetSettings';

const BlockLabel = styled.label`
  margin-bottom: 10px;
  display: block;
`;

const FontPickerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  #font-picker {
    box-shadow: none;
    border: 1px solid #d9d9d9;
    border-radius: 5px;

    &.expanded {
      border-bottom: 0;

      -webkit-border-bottom-right-radius: 0px;
      -webkit-border-bottom-left-radius: 0px;
      -moz-border-radius-bottomright: 0px;
      -moz-border-radius-bottomleft: px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;

      .font-list {
        display: block;
        margin-left: -1px;
        width: 200px;
      }
    }

    button.dropdown-button {
      background: transparent;
    }
    .font-list {
      display: none;
      box-shadow: none;
      background-color: #fff;
      border-left: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      -webkit-border-bottom-left-radius: 5px;
      -moz-border-radius-bottomright: 5px;
      -moz-border-radius-bottomleft: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      li {
        color: rgb(50, 79, 190);
        button.active-font {
          background-color: rgb(235, 238, 249);
        }
        button:hover {
          background-color: rgb(235, 238, 249);
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  padding-top: 10px;
`;

export default function FontSettings() {
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { dispatchUpdateSiteWidgetSettings } =
    useDispatchUpdateSiteWidgetSettings();

  return (
    <Wrapper>
      <FormRow justifyContent="space-between" gap="10px">
        Use my website's font family.
        <Toggle
          size="md"
          checked={
            !!siteWidgetSettings?.brandingSettings.useMyWebsiteFontFamily
          }
          checkedChildren="Yes"
          unCheckedChildren="No"
          onClick={() => {
            dispatchUpdateSiteWidgetSettings(
              'useMyWebsiteFontFamily',
              !siteWidgetSettings?.brandingSettings.useMyWebsiteFontFamily
            );
          }}
        />
      </FormRow>
      {!siteWidgetSettings?.brandingSettings.useMyWebsiteFontFamily && (
        <FormRow justifyContent="space-between" gap="10px">
          <span>Use font family</span>
          <FontPickerWrapper>
            <FontPicker
              apiKey={process.env.REACT_APP_GOOGLE_FONTS_KEY!}
              activeFontFamily={
                !siteWidgetSettings?.brandingSettings.useMyWebsiteFontFamily
                  ? siteWidgetSettings?.brandingSettings.fontFamily
                  : ''
              }
              onChange={(nextFont) => {
                dispatchUpdateSiteWidgetSettings('fontFamily', nextFont.family);
              }}
            />
          </FontPickerWrapper>
        </FormRow>
      )}

      <Divider />

      <FormRow justifyContent="space-between" gap="10px">
        <BlockLabel>Date format</BlockLabel>
        <div>
          <InputPicker
            data={DATE_FORMATS}
            value={siteWidgetSettings?.brandingSettings.dateFormat}
            style={{ width: 224 }}
            cleanable={false}
            placement="topStart"
            onChange={(format: string) => {
              dispatchUpdateSiteWidgetSettings('dateFormat', format);
            }}
          />
        </div>
      </FormRow>
    </Wrapper>
  );
}
