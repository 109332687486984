import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';
import { useEffect, useRef } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import styled, { css } from 'styled-components';
import { useAppSelector } from '../../../../../../../store';
import {
  EmbeddedWidgetSettings,
  settingSelector,
} from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import useUpdateWidgetPreview from '../../hooks/useUpdateWidgetPreview';
import SliderReviewCard from './SliderReviewCard';
import reviews from './reviews';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const WidgetContainer = styled.div<{ $carouselWidget: EmbeddedWidgetSettings }>`
  width: 90%;
  padding: 25px 0 0;
  margin-bottom: 20px;
  .swiper-button-prev,
  .swiper-button-next {
    top: 155px !important;
    color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    padding: 10px;
    background: #0000006e;
  }
`;

const GlideWrapper = styled.div<{ $autoplay: boolean }>`
  ${({ $autoplay }) =>
    $autoplay
      ? css`
          .glide__arrows {
            display: none;
          }
        `
      : css`
          &:hover {
            .glide__arrows {
              display: block;
            }
          }
        `}

  ${({ $autoplay }) =>
    !$autoplay &&
    css`
      padding: 0 2em;
    `}

  .glide__arrow {
    @media (max-width: 644px) {
      padding: 0;
    }
  }
`;

const GlideSlides = styled.ul`
  align-items: stretch;
`;

const GlideArrows = styled.div`
  /* display: none; */

  @media (max-width: 644px) {
    display: block;
  }
`;

const GlideArrow = styled.button<{ autoplay: boolean }>`
  border-radius: 100%;
  width: 50px;
  height: 50px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  &.glide__arrow--left {
    left: ${({ autoplay }) => (!!autoplay ? '1.7em' : '5px')};
  }
  &.glide__arrow--right {
    right: ${({ autoplay }) => (!!autoplay ? '1.7em' : '5px')};
  }

  @media (max-width: 644px) {
    width: 30px;
    height: 30px;
  }
`;

const GlideSlide = styled.li<{ $widgetSettings: EmbeddedWidgetSettings }>`
  height: unset;
  cursor: pointer;
  width: 100%;
  padding: 20px;
`;

export default function WidgetPreview() {
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const {
    carouselSliderWidget,
    brandingSettings,
    translation,
    carouselSliderWidget: { autoplay, autoPlaySpeed, pauseOnHover },
  } = siteWidgetSettings!;
  const sliderRef = useRef(null);
  const glideRef = useRef<typeof Glide | null>(null);

  useUpdateWidgetPreview();

  useEffect(() => {
    const sliderRefCurrent = sliderRef.current;

    if (sliderRefCurrent) {
      glideRef.current = new Glide(sliderRefCurrent, {
        type: 'carousel',
        perView: 3,
        gap: 20,
        autoplay: autoplay ? autoPlaySpeed : 0,
        hoverPause: pauseOnHover,
        peek: autoplay ? 40 : 0,
        breakpoints: {
          600: { perView: 1 },
          1000: { perView: 2 },
          1147: { perView: 3 },
        },
      });

      glideRef.current.mount();
    }

    return () => {
      glideRef.current.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoPlaySpeed, autoplay, pauseOnHover]);

  useEffect(() => {
    glideRef.current.play();
  }, []);

  return (
    <Wrapper>
      <WidgetContainer $carouselWidget={carouselSliderWidget}>
        <GlideWrapper className="glide" $autoplay={autoplay} ref={sliderRef}>
          <div className="glide__track" data-glide-el="track">
            <GlideSlides className="glide__slides">
              {reviews.map((review, index) => (
                <GlideSlide
                  className="glide__slide review-card"
                  data-review={JSON.stringify(review)}
                  key={review.id}
                  $widgetSettings={carouselSliderWidget}
                >
                  <SliderReviewCard
                    review={review}
                    widgetSettings={carouselSliderWidget}
                    branding={brandingSettings}
                    translation={translation}
                  />
                </GlideSlide>
              ))}
            </GlideSlides>
          </div>

          <GlideArrows className="glide__arrows" data-glide-el="controls">
            <GlideArrow
              autoplay={autoplay}
              className="glide__arrow glide__arrow--left"
              data-glide-dir="<"
            >
              <FaAngleLeft color="#000" size={20} />
            </GlideArrow>
            <GlideArrow
              autoplay={autoplay}
              className="glide__arrow glide__arrow--right"
              data-glide-dir=">"
            >
              <FaAngleRight color="#000" size={20} />
            </GlideArrow>
          </GlideArrows>
        </GlideWrapper>
      </WidgetContainer>
    </Wrapper>
  );
}
