import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IoCopyOutline } from 'react-icons/io5';
import styled from 'styled-components';
import { useNotification } from '../../../../shared/hooks/useNotification';

const Button = styled.button`
  background-color: transparent;
  height: 25px;
  width: 22px;
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #000000;
  border-radius: 6px;
  margin: 20px 0;
  color: #fff;

  span {
    font-size: 18px;
    font-weight: 600;
  }
`;

type Props = {
  url?: string;
};

export default function ShareLink({ url }: Props) {
  const { showInfoToast } = useNotification();

  return (
    <div>
      <p>Share your link on social media, your website, or anywhere else</p>

      <LinkWrapper>
        <span>{url}</span>
        <CopyToClipboard text={url}>
          <Button onClick={() => showInfoToast('Copied to clipboard')}>
            <IoCopyOutline size={20} />
          </Button>
        </CopyToClipboard>
      </LinkWrapper>
      <p>
        To disable video reviews, <a href="/">click here</a>.
      </p>
    </div>
  );
}
