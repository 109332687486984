import { useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Divider } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import Preloader from '../../../../shared/components/Preloader';
import ReviewsJetCrownLogo from '../../../../shared/components/ReviewsJetCrownLogo';
import { Loading } from '../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../shared/utils/removeTrailingSlash';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { AuthTitle } from '../../../external/components';
import { accountSelector } from '../../../userData/redux/userData.slice';
import DownloadQRCode from './DownloadQRCode';
import SendEmail, { InviteTypeEnum } from './SendEmail';
import InviteCustomer from './SendEmail/invite-customer';
import ShareLink from './ShareLink';

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 40px;
  max-width: 820px;
  width: 100%;
  max-height: 98%;
  overflow: auto;
  background-color: #fff;

  @media (max-width: 530px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0px;
  }

  .rs-picker {
    width: 100%;
    border-radius: 6px !important;
  }
`;

const StyledButton = styled(Button)`
  background-color: #000;
  color: #fff;
  border-color: #000;
  min-width: 150px;
`;

interface Props {
  onNext: () => void;
  onPrevious: () => void;
}

export default function AskForReviews({ onNext, onPrevious }: Props) {
  const dispatch = useAppDispatch();
  const { loading, account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { siteName } = selectedSite ?? {};
  const [inviteType, setInviteType] = useState<InviteTypeEnum | null>(null);

  const url = `${removeTrailingSlash(
    process.env.REACT_APP_REVIEWSJET_WEBSITE!
  )}/w/${siteName}`;

  if (loading === Loading.PENDING) {
    return <Preloader />;
  }

  if (inviteType) {
    return (
      <InviteCustomer
        inviteType={inviteType}
        onClose={() => setInviteType(null)}
      />
    );
  }

  return (
    <Wrapper>
      <ReviewsJetCrownLogo />
      <AuthTitle marginBottom="0px">Ask for reviews</AuthTitle>

      <ShareLink url={url} />

      <Divider>OR</Divider>

      <DownloadQRCode url={url} />

      <Divider>OR</Divider>

      <SendEmail
        setInviteType={setInviteType}
        onNext={(inviteType: InviteTypeEnum) => setInviteType(inviteType)}
      />

      <Divider />
      <FlexContainer justifyContent="flex-end" gap="10px">
        <StyledButton onClick={onNext} rounded>
          <FlexContainer gap="5px">
            <b>Next </b>
            <MdKeyboardArrowRight />
          </FlexContainer>
        </StyledButton>
      </FlexContainer>
    </Wrapper>
  );
}
