import { useCallback, useState } from 'react';
import { batch } from 'react-redux';
import { getSite } from '../../features/userData/redux/action';
import { selectedSiteSelector } from '../../features/userData/redux/userData.slice';
import { axiosPrivate } from '../../services/axiosPrivate';
import { useAppDispatch, useAppSelector } from '../../store';
import { toggleUpgradeModal } from '../../store/app.slice';
import { Loading } from '../types/review.types';
import removeTrailingSlash from '../utils/removeTrailingSlash';
import { useNotification } from './useNotification';

/* eslint no-console: "warn" */

const useUpgradeLink = () => {
  const dispatch = useAppDispatch();
  const { showErrorToast, showSuccessToast } = useNotification();
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { instanceId, platform, stripe } = selectedSite ?? {};
  const { upgradePlanId, billingCycle } = useAppSelector((state) => state.app);
  const [generatingUpgradeLink, setGeneratingUpgradeLink] = useState(
    Loading.INITIAL
  );
  const { subscriptionId } = stripe ?? {};

  const handleGenerateWixUpgradeLink = useCallback(
    async (plan?: string | null, cycle?: string | null) => {
      try {
        setGeneratingUpgradeLink(Loading.PENDING);
        const {
          data: { checkoutUrl },
        } = await axiosPrivate.post(
          `${removeTrailingSlash(
            process.env.REACT_APP_REVIEWS_JET_API!
          )}/wix/upgrade-link`,
          {
            instanceId,
            productId: plan ?? upgradePlanId,
            billingCycle: cycle ?? billingCycle,
          }
        );

        setGeneratingUpgradeLink(Loading.SUCCESS);
        window.open(checkoutUrl, '_blank');
      } catch (error: any) {
        setGeneratingUpgradeLink(Loading.ERROR);

        let errorMessage = '';

        if (error.response) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = error.message;
        }

        showErrorToast(errorMessage);
      }
    },
    [upgradePlanId, billingCycle]
  );

  const handleGenerateStripeUpgradeLink = useCallback(
    async (plan?: string | null, cycle?: string | null) => {
      try {
        setGeneratingUpgradeLink(Loading.PENDING);
        const {
          data: { confirmationUrl },
        } = await axiosPrivate.post(
          `${removeTrailingSlash(
            process.env.REACT_APP_REVIEWS_JET_API!
          )}/account/initiate-payment`,
          { plan: plan ?? upgradePlanId, cycle: cycle ?? billingCycle }
        );

        setGeneratingUpgradeLink(Loading.SUCCESS);
        window.open(confirmationUrl, '_blank');
      } catch (error: any) {
        setGeneratingUpgradeLink(Loading.ERROR);

        let errorMessage = '';

        if (error.response) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = error.message;
        }

        showErrorToast(errorMessage);
      }
    },
    [upgradePlanId, billingCycle]
  );

  const handleChangeStripeSubscription = useCallback(async () => {
    try {
      setGeneratingUpgradeLink(Loading.PENDING);
      await axiosPrivate.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/stripe/change-subscription`,
        { plan: upgradePlanId, cycle: billingCycle }
      );

      setGeneratingUpgradeLink(Loading.SUCCESS);
      showSuccessToast('Subscription changed successfully');

      batch(() => {
        dispatch(toggleUpgradeModal(null));
        dispatch(getSite({ showPreloader: true })).catch(() => {});
      });
    } catch (error: any) {
      setGeneratingUpgradeLink(Loading.ERROR);

      let errorMessage = '';

      if (error.response) {
        errorMessage = error.response.data.error;
      } else {
        errorMessage = error.message;
      }

      showErrorToast(errorMessage);
    }
  }, [upgradePlanId, billingCycle, subscriptionId]);

  const handleUpgrade = useCallback(async () => {
    if (subscriptionId) {
      await handleChangeStripeSubscription();
    } else {
      await handleGenerateStripeUpgradeLink();
    }
  }, [platform, upgradePlanId, billingCycle, subscriptionId]);

  return {
    handleUpgrade,
    generatingUpgradeLink,
    handleGenerateWixUpgradeLink,
    handleGenerateStripeUpgradeLink,
  };
};

export default useUpgradeLink;
