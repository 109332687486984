import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { accountSelector } from '../../features/userData/redux/userData.slice';
import { useAppSelector } from '../../store';
import { Loading } from '../types/review.types';

const useToggleSidebar = () => {
  const { pathname } = useLocation();
  const { loading, account } = useAppSelector(accountSelector);
  const [hideSidebar, setHideSidebar] = useState(true);
  const [collapsedSidebar, setCollapsedSidbar] = useState(true);

  useEffect(() => {
    if (loading === Loading.PENDING) {
      return setHideSidebar(true);
    }

    setHideSidebar(
      [
        '/plans',
        '/dashboard',
        '/account',
        '/successful-payment',
        '/share-review',
        '/invite-single-customer',
        '/get-started',
        '/shopify/successful-payment',
      ].includes(pathname)
    );
  }, [pathname, loading, account]);

  return { hideSidebar, collapsedSidebar, setCollapsedSidbar };
};

export default useToggleSidebar;
