import { SyntheticEvent } from 'react';
import {
  TbBorderCornerIos,
  TbBorderCornerPill,
  TbBorderCornerRounded,
  TbBorderCornerSquare,
} from 'react-icons/tb';
import { Divider, InputNumber, Toggle } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../../../../../shared/components/Button';
import { ColorPicker } from '../../../../../../../../shared/components/colorPicker';
import FlexContainer from '../../../../../../../../shared/components/layouts/flex-container';
import { useAppSelector } from '../../../../../../../../store';
import { BorderRadius } from '../../../../../../../review/utils/constants';
import FormRow from '../../../../../../../widgetSettings/components/form-row';
import { settingSelector } from '../../../../../../../widgetSettings/redux/widgetSettings.slice';
import useDispatchUpdateSiteWidgetSettings from '../../../../../../../widgetSettings/shared/hooks/useDispatchUpdateSiteWidgetSettings';

const Wrapper = styled.div`
  padding-top: 15px;
`;

const ButtonGroup = styled(FlexContainer)`
  width: 100%;
  button.rs-btn-block {
    width: 25%;
  }
`;

const StyledButton = styled(Button)`
  padding: 5px 10px;
`;

export default function BorderSettings() {
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { dispatchUpdateSiteWidgetSettings } =
    useDispatchUpdateSiteWidgetSettings();

  if (!siteWidgetSettings) {
    return null;
  }

  return (
    <Wrapper>
      <FormRow justifyContent="space-between" gap="10px">
        Show border
        <Toggle
          size="md"
          checked={!!siteWidgetSettings?.carouselSliderWidget.showBorder}
          checkedChildren="Yes"
          unCheckedChildren="No"
          onClick={() => {
            dispatchUpdateSiteWidgetSettings(
              'showBorder',
              !siteWidgetSettings?.carouselSliderWidget.showBorder,
              'slider'
            );
          }}
        />
      </FormRow>
      {siteWidgetSettings?.carouselSliderWidget.showBorder && (
        <>
          <Divider />
          <FlexContainer justifyContent="space-between">
            <FormRow justifyContent="flex-start" gap="10px">
              Border color
              <ColorPicker
                color={siteWidgetSettings?.carouselSliderWidget.borderColor}
                changeColor={(color: string) =>
                  dispatchUpdateSiteWidgetSettings(
                    'borderColor',
                    color,
                    'slider'
                  )
                }
              />
            </FormRow>
            <FormRow justifyContent="flex-end" gap="10px">
              <span>Border width</span>
              <InputNumber
                max={2}
                min={1}
                value={siteWidgetSettings.carouselSliderWidget.borderWidth}
                size="sm"
                style={{ width: 100 }}
                postfix="px"
                onChange={(
                  value: number | string | null,
                  event: SyntheticEvent<Element, Event>
                ) => {
                  dispatchUpdateSiteWidgetSettings(
                    'borderWidth',
                    value,
                    'slider'
                  );
                }}
              />
            </FormRow>
          </FlexContainer>
        </>
      )}
      <Divider />
      Rounded corners
      <FormRow justifyContent="space-between" gap="10px" marginTop="10px">
        <ButtonGroup gap="5px">
          <StyledButton
            rounded
            size="small"
            onClick={() => {
              dispatchUpdateSiteWidgetSettings('borderRadius', '', 'slider');
            }}
            variant={
              siteWidgetSettings?.carouselSliderWidget.borderRadius === ''
                ? 'primary'
                : 'tertiary'
            }
          >
            <FlexContainer gap="5px">
              <TbBorderCornerSquare />
              None
            </FlexContainer>
          </StyledButton>
          <StyledButton
            rounded
            size="small"
            onClick={() => {
              dispatchUpdateSiteWidgetSettings(
                'borderRadius',
                BorderRadius.SMALL,
                'slider'
              );
            }}
            variant={
              siteWidgetSettings?.carouselSliderWidget.borderRadius ===
              BorderRadius.SMALL
                ? 'primary'
                : 'tertiary'
            }
          >
            <FlexContainer gap="5px">
              <TbBorderCornerRounded />
              Small
            </FlexContainer>
          </StyledButton>
          <StyledButton
            rounded
            size="small"
            onClick={() => {
              dispatchUpdateSiteWidgetSettings(
                'borderRadius',
                BorderRadius.MEDIUM,
                'slider'
              );
            }}
            variant={
              siteWidgetSettings?.carouselSliderWidget.borderRadius ===
              BorderRadius.MEDIUM
                ? 'primary'
                : 'tertiary'
            }
          >
            <FlexContainer gap="5px">
              <TbBorderCornerIos />
              Medium
            </FlexContainer>
          </StyledButton>
          <StyledButton
            rounded
            size="small"
            onClick={() => {
              dispatchUpdateSiteWidgetSettings(
                'borderRadius',
                BorderRadius.LARGE,
                'slider'
              );
            }}
            variant={
              siteWidgetSettings?.carouselSliderWidget.borderRadius ===
              BorderRadius.LARGE
                ? 'primary'
                : 'tertiary'
            }
          >
            <FlexContainer gap="5px">
              <TbBorderCornerPill />
              Large
            </FlexContainer>
          </StyledButton>
        </ButtonGroup>
      </FormRow>
    </Wrapper>
  );
}
