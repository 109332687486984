import { useCallback, useEffect, useState } from 'react';
import { FileType } from 'rsuite/esm/Uploader';
import { MappedField, ReservedFields } from '..';
import findKeyInMappedField from '../utils/findKeyInMappedField';
import {
  clearPropertyValue,
  updatePropertyValue,
} from '../utils/handleUpdateMappedField';

export enum Step {
  UPLOAD_CSV,
  MAP_FIELDS,
  PREVIEW_REVIEWS,
  FINAL,
}

export default function useCsvImport() {
  const [step, setStep] = useState(Step.UPLOAD_CSV);
  const [parsedFile, setParsedFile] = useState<Record<string, string>[]>(
    [] as any
  );
  const [headers, setHeaders] = useState<
    { label: string; isSkipped: boolean }[]
  >([]);
  const [mappedFieldsList, setMappedFieldsList] = useState<string[]>([]);
  const [uploadedFile, setUploadedFile] = useState<FileType | null>(null);
  const [unmappedFields, setUnmappedFields] = useState<string[]>([]);

  const [mappedFields, setMappedFields] = useState<MappedField>({
    title: {
      value: '',
      skipped: false,
    },
    reviewer_name: {
      value: '',
      skipped: false,
    },
    rating: {
      value: '',
      skipped: false,
    },
    helpful_count: {
      value: '',
      skipped: false,
    },
    review_text: {
      value: '',
      skipped: false,
    },
    your_review_reply: {
      value: '',
      skipped: false,
    },
    review_date: {
      value: '',
      skipped: false,
    },
    product_id: {
      value: '',
      skipped: false,
    },
    reviewer_email: {
      value: '',
      skipped: false,
    },
    reviewer_location_country: {
      value: '',
      skipped: false,
    },
    reviewer_location_city: {
      value: '',
      skipped: false,
    },
  });

  const setMapping = useCallback(
    (parsedFile: Record<string, string>[]) => {
      const headers = Object.keys(parsedFile[0] as any).map((key) =>
        key.trim()
      ) as ReservedFields[];
      let updatedMapped = mappedFields;

      if (headers.includes('title')) {
        updatedMapped = {
          ...updatedMapped,
          title: { value: 'title', skipped: false },
        };
      }

      if (headers.includes('product_id')) {
        updatedMapped = {
          ...updatedMapped,
          product_id: { value: 'product_id', skipped: false },
        };
      }

      if (headers.includes('reviewer_name')) {
        updatedMapped = {
          ...updatedMapped,
          reviewer_name: { value: 'reviewer_name', skipped: false },
        };
      }
      if (headers.includes('reviewer_email')) {
        updatedMapped = {
          ...updatedMapped,
          reviewer_email: { value: 'reviewer_email', skipped: false },
        };
      }
      if (headers.includes('rating')) {
        updatedMapped = {
          ...updatedMapped,
          rating: { value: 'rating', skipped: false },
        };
      }
      if (headers.includes('helpful_count')) {
        updatedMapped = {
          ...updatedMapped,
          helpful_count: { value: 'helpful_count', skipped: false },
        };
      }
      if (headers.includes('review_text')) {
        updatedMapped = {
          ...updatedMapped,
          review_text: { value: 'review_text', skipped: false },
        };
      }
      if (headers.includes('your_review_reply')) {
        updatedMapped = {
          ...updatedMapped,
          your_review_reply: { value: 'your_review_reply', skipped: false },
        };
      }
      if (headers.includes('review_date')) {
        updatedMapped = {
          ...updatedMapped,
          review_date: { value: 'review_date', skipped: false },
        };
      }
      if (headers.includes('reviewer_location_country')) {
        updatedMapped = {
          ...updatedMapped,
          reviewer_location_country: {
            value: 'reviewer_location_country',
            skipped: false,
          },
        };
      }
      if (headers.includes('reviewer_location_city')) {
        updatedMapped = {
          ...updatedMapped,
          reviewer_location_city: {
            value: 'reviewer_location_city',
            skipped: false,
          },
        };
      }

      setMappedFields(updatedMapped);
      setHeaders(
        headers.map((header) => ({
          label: header.trim(),
          isSkipped: false,
        }))
      );
    },
    [mappedFields]
  );

  const handleMapping = useCallback(
    (field: string, mapTo: ReservedFields) => {
      const keyInMappedField = findKeyInMappedField(mappedFields, field);

      let updatedMappedFields = clearPropertyValue(
        mappedFields,
        keyInMappedField
      );

      const updatedMappedFields2 = updatePropertyValue(
        updatedMappedFields,
        mapTo,
        field
      );

      setMappedFields(updatedMappedFields2);
    },
    [mappedFields]
  );

  const handleToggleSkipColumn = useCallback(
    (header: { label: string; isSkipped: boolean }) => {
      const updatedHeaderIndex = headers.findIndex(
        (_header) => _header.label === header.label
      );

      if (updatedHeaderIndex !== -1) {
        const updatedHeaders = [
          ...headers.slice(0, updatedHeaderIndex),
          { label: header.label, isSkipped: !header.isSkipped },
          ...headers.slice(updatedHeaderIndex + 1),
        ];

        setHeaders(updatedHeaders);
      }
    },
    [headers]
  );

  const handleSkipUnmappedFields = useCallback(() => {
    const skipped: string[] = [];

    headers.forEach((header) => {
      const keyInMappedField = findKeyInMappedField(mappedFields, header.label);

      // @ts-ignore
      const isMapped = !!mappedFields[keyInMappedField]?.value;

      if (!isMapped && !header.isSkipped) {
        skipped.push(header.label);
      }
    });

    const updatedHeaders = headers.map(({ label, isSkipped }) => {
      if (!isSkipped && skipped.includes(label)) {
        return {
          label,
          isSkipped: true,
        };
      }

      return { label, isSkipped };
    });

    setHeaders(updatedHeaders);
  }, [headers, mappedFields]);

  useEffect(() => {
    const list: string[] = [];

    Object.keys(mappedFields).forEach((field) => {
      // @ts-ignore
      if (!!mappedFields[field]?.value) {
        list.push(field);
      }
    });

    setMappedFieldsList(list);
  }, [mappedFields]);

  useEffect(() => {
    const unmapped: string[] = [];

    headers.forEach((header) => {
      const keyInMappedField = findKeyInMappedField(mappedFields, header.label);

      // @ts-ignore
      const isMapped = !!mappedFields[keyInMappedField]?.value;

      if (!isMapped && !header.isSkipped) {
        unmapped.push(header.label);
      }
      setUnmappedFields(unmapped);
    });
  }, [headers, mappedFields]);

  return {
    step,
    uploadedFile,
    setUploadedFile,
    setStep,
    parsedFile,
    setParsedFile,
    headers,
    setHeaders,
    mappedFieldsList,
    setMappedFieldsList,
    unmappedFields,
    setUnmappedFields,
    mappedFields,
    setMapping,
    handleMapping,
    handleToggleSkipColumn,
    handleSkipUnmappedFields,
  };
}
