import styled from 'styled-components';
import { Template } from '.';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';

/* eslint no-console: "warn" */

const Wrapper = styled.div`
  padding: 20px;
  border-radius: 10px;
  /* border: 1px solid #e0e0e0; */
  width: 100%;
  cursor: pointer;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  .reviewer-name-elt {
    font-size: 13px;
    font-weight: 500;
    display: block;
  }

  .review-date-elt {
    font-size: 11px;
    display: block;
  }

  .review-text-elt {
    font-size: 13px;
    display: block;
    line-height: 20px;
    margin: 0;
  }
`;

const Image = styled(FlexContainer)`
  background-color: #efefef;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 600;
`;

const Ratings = styled(FlexContainer)`
  font-size: 16px;
  display: block;
  margin: 2px 0;
`;
const OuterWrapper = styled(FlexContainer)<{ $selected: boolean }>`
  padding: 5px;
  border-radius: 10px;
  border: 2px solid transparent;
  border-color: ${({ $selected }) => ($selected ? '#00acff7a' : 'transparent')};
  &:hover {
    border-color: #00acff7a;
  }
`;

type TemplateProps = {
  template: Template;
  onClick: (selectedTemplate: Template) => void;
  isSelected: boolean;
};

export default function ReviewCardTemplate({
  template,
  onClick,
  isSelected,
}: TemplateProps) {
  return (
    <OuterWrapper
      onClick={() => {
        onClick(template);
      }}
      $selected={isSelected}
    >
      <Wrapper
        style={{
          backgroundColor: template.bgColor,
          border: `1px solid ${template.borderColor}`,
        }}
      >
        <FlexContainer justifyContent="flex-start" gap="10px">
          <Image>JD</Image>
          <div>
            <span
              className="reviewer-name-elt"
              style={{ color: template.nameColor }}
            >
              John D.
            </span>
            <span
              className="review-date-elt"
              style={{ color: template.dateColor }}
            >
              12/12/2024
            </span>
          </div>
        </FlexContainer>
        <Ratings justifyContent="flex-start" gap="5px">
          <span style={{ color: template.starColor }}>★★★★★</span>
        </Ratings>

        <p className="review-text-elt" style={{ color: template.textColor }}>
          Excellent product and fast shipping! The quality exceeded my
          expectations, and customer service was very helpful when...
        </p>
      </Wrapper>
    </OuterWrapper>
  );
}
