import styled from 'styled-components';
import Button from '../../../../../../shared/components/Button';
import FlexContainer from '../../../../../../shared/components/layouts/flex-container';

const Wrapper = styled(FlexContainer)`
  margin-top: 20px;
  h3 {
    font-size: 24px;
    color: #464646;
    margin-bottom: -20px;
    font-family: ubuntu;
  }
  img {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    perspective: 1px;
  }
  p {
    font-size: 15px;
    margin-bottom: 20px;
    text-align: center;
    color: #464646;
    line-height: 25px;
  }
`;

const ButtonsWrapper = styled(FlexContainer)`
  width: 100%;
`;

const compliments = [
  "Aren't you amazing!",
  'Look at you being awesome!',
  "You're just fantastic!",
  "Ain't you something special!",
  "Aren't you incredible!",
  "You're crushing it!",
  "You're absolutely awesome!",
  "Well, aren't you the best!",
  "You're killing it!",
  "Aren't you just amazing!",
  "You're a total rockstar!",
  "You're on fire!",
  "Is there anything you can't do?",
  "You're unstoppable!",
  'Pure excellence!',
  "Aren't you just the greatest!",
  "You're one in a million!",
  'Can you be any more awesome?',
  "You're absolutely nailing it!",
  'Look at you go!',
];

const StyledButton = styled(Button)`
  width: 50%;
`;

function getRandomCompliment() {
  const randomIndex = Math.floor(Math.random() * compliments.length);
  return compliments[randomIndex];
}

export default function SuccessFeedback({
  onShareMore,
  onNextStep,
}: {
  onShareMore: () => void;
  onNextStep: () => void;
}) {
  return (
    <Wrapper stack gap="30px" alignItems="center">
      <img src="/images/you-are-awesome.gif" alt="" />
      <h3>{getRandomCompliment()}</h3>
      <p>
        You'll get an email notification once they leave a review. <br />
        What would you like to do next?
      </p>

      <ButtonsWrapper gap="10px">
        <StyledButton rounded variant="tertiary" onClick={onShareMore}>
          <b>Send More Invites</b>
        </StyledButton>
        <StyledButton rounded variant="primary" onClick={onNextStep}>
          <b>Next step: Install a widget</b>
        </StyledButton>
      </ButtonsWrapper>
    </Wrapper>
  );
}
