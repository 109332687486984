import { useState } from 'react';
import Http from '../../../../../services/http';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import { Loading } from '../../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../../shared/utils/removeTrailingSlash';
import { useAppDispatch } from '../../../../../store';
import { listIntegrations } from '../redux/actions';
import { IntegrationPlatform } from '../redux/integrations.slice';
import getPlatformName from '../utils/getPlatformName';

/* eslint no-console: "warn" */

const http = new Http();

export default function useIntegration() {
  const dispatch = useAppDispatch();
  const [isOpenDisconnectModal, setIsOpenDisconnectModal] = useState(false);
  const [connectingActiveCampaign, setConnectingActiveCampaign] = useState(
    Loading.INITIAL
  );
  const [connectingSendGrid, setConnectingSendGrid] = useState(Loading.INITIAL);
  const [
    isOpenConnectActiveCampaignModal,
    setIsOpenConnectActiveCampaignModal,
  ] = useState(false);
  const [isOpenConnectSendGridModal, setIsOpenConnectSendGridModal] =
    useState(false);
  const [disconnecting, setDisconnecting] = useState(Loading.INITIAL);
  const { showErrorToast, showSuccessToast } = useNotification();

  const onDisconnect = async (platform: IntegrationPlatform) => {
    try {
      setDisconnecting(Loading.PENDING);

      await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/integrations/disconnect`,
        { platform }
      );

      setIsOpenDisconnectModal(false);
      showSuccessToast(
        `${getPlatformName(platform)} successfully disconnected`
      );
      setDisconnecting(Loading.SUCCESS);
      dispatch(listIntegrations({ showPreloader: false }));
    } catch (error) {
      setDisconnecting(Loading.ERROR);
      showErrorToast(`Failed to disconnect ${getPlatformName(platform)}`);
    }
  };

  const onConnectActiveCampaign = async ({
    apiKey,
    apiUrl,
  }: {
    apiKey: string;
    apiUrl: string;
  }) => {
    try {
      setConnectingActiveCampaign(Loading.PENDING);

      await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/active-campaign/connect`,
        { apiKey, apiUrl }
      );

      setIsOpenConnectActiveCampaignModal(false);
      showSuccessToast(`ActiveCampaign successfully connected`);
      setConnectingActiveCampaign(Loading.SUCCESS);
      dispatch(listIntegrations({ showPreloader: false }));
    } catch (error) {
      setConnectingActiveCampaign(Loading.ERROR);
      showErrorToast(`Failed to connect ActiveCampaign`);
    }
  };

  const onConnectSendGrid = async (apiKey: string) => {
    try {
      setConnectingActiveCampaign(Loading.PENDING);

      await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/sendgrid/connect`,
        { apiKey }
      );

      setIsOpenConnectSendGridModal(false);
      showSuccessToast(`SendGrid successfully connected`);
      setConnectingSendGrid(Loading.SUCCESS);
      dispatch(listIntegrations({ showPreloader: false }));
    } catch (error) {
      setConnectingSendGrid(Loading.ERROR);
      showErrorToast(`Failed to connect SendGrid`);
    }
  };

  return {
    isOpenDisconnectModal,
    setIsOpenDisconnectModal,
    disconnecting,
    onDisconnect,
    isOpenConnectActiveCampaignModal,
    setIsOpenConnectActiveCampaignModal,
    onConnectActiveCampaign,
    connectingActiveCampaign,
    onConnectSendGrid,
    isOpenConnectSendGridModal,
    connectingSendGrid,
    setIsOpenConnectSendGridModal,
  };
}
