import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { IoCopyOutline } from 'react-icons/io5';
import { TiTickOutline } from 'react-icons/ti';
import { Button, Divider } from 'rsuite';
import styled from 'styled-components';
import StyledText from '../../../../shared/components/styled-text';
import SyntaxHighlighter from '../../../../shared/components/SyntaxHighlighter';
import useInstallation, {
  Widget,
} from '../../../../shared/hooks/useInstallation';
import { useAppDispatch } from '../../../../store';
import { toggleFeedbackDrawer } from '../../../userData/redux/userData.slice';

const Message = styled.div`
  border-left: 4px solid #5bc0de;
  background-color: #e2f2fb;
  padding: 20px;
  margin-bottom: 50px;
  font-size: 14px;
  border-radius: 5px;

  .message-title {
    color: #4a90e2;
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 30px;
    font-family: 'Ubuntu', sans-serif;
  }

  p {
    margin: 0 0 20px;
  }

  .contact-us {
    background-color: #fff;
    border: 1px solid #4a90e2;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
  }

  .contact-us:hover {
    background-color: #4a90e2;
    color: #fff;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 900px;
  margin: auto;
  pre {
    margin: 0 0 20px !important;
    border: 1px solid #dedede;
    border-radius: 10px;
  }
  img.screenshot {
    margin-bottom: 20px;
    margin-top: 10px;
    border: 1px solid #dedede;
    padding: 2px;
    border-radius: 10px;
    width: 100%;
  }
`;

const PageTitle = styled(StyledText)`
  font-size: 25px;
  margin-bottom: 40px;
  margin-top: 50px;
  font-weight: bold;
  text-align: center;
`;

const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: #f3f3f3;
  justify-content: flex-start;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 20px;
`;
const StepNumber = styled.div`
  height: 100%;
  width: 80px;
  background-color: #cfe4ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-weight: bold;
`;

const StepDesc = styled.div`
  margin-bottom: 10px;
`;
const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  margin: auto;
  gap: 5px;
  margin-top: 10px;
`;
const StepWrapper = styled.div`
  margin-bottom: 30px;
`;

const WebflowTabWidget = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const [copied, setCopied] = useState(false);
  const { installationCode } = useInstallation(Widget.TAB_WIDGET);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Wrapper>
      {/* <PageTitle>How To Install the Floating Widget on Webflow</PageTitle> */}

      <StepWrapper>
        <Step>
          <StepNumber>Step 1</StepNumber>
          <div>Copy the installation code</div>
        </Step>
        <StepDesc>
          <strong>Installation code:</strong>
        </StepDesc>

        <SyntaxHighlighter code={installationCode} />
        <CopyToClipboard text={installationCode}>
          <StyledButton
            color={copied ? 'green' : 'ghost'}
            appearance="ghost"
            onClick={() => {
              setCopied(true);
              setTimeout(() => setCopied(false), 3000);
            }}
          >
            {copied ? (
              <>
                <TiTickOutline />
                {t('reviews.import-modal.copied-to-clipboard')}
              </>
            ) : (
              <>
                <IoCopyOutline /> {t('shared.copy-installation-code')}
              </>
            )}
          </StyledButton>
        </CopyToClipboard>
      </StepWrapper>

      <StepWrapper>
        <Step>
          <StepNumber>Step 2</StepNumber>
          <div>Use the installation code</div>
        </Step>
        <StepDesc>
          Head to the <strong>Site settings</strong> of your website:
        </StepDesc>
        <img
          src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/wix-dashboard/webflow/1.png?v=1.0.0"
          alt=""
          className="screenshot"
        />
        <div style={{ paddingBottom: 30 }}></div>
        <StepDesc>
          Select <strong>Custom code</strong>:
        </StepDesc>
        <img
          src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/wix-dashboard/webflow/2.png?v=1.0.0"
          alt=""
          className="screenshot"
        />
        <div style={{ paddingBottom: 30 }}></div>
        <StepDesc>
          Paste the installation code in <strong>Head code</strong>. Save the
          settings and publish your website.
        </StepDesc>
        <img
          src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/wix-dashboard/webflow/3.jpeg?v=1.0.0"
          alt=""
          className="screenshot"
        />
      </StepWrapper>

      <Divider />

      <p style={{ textAlign: 'center' }}>
        Congratulations! Your Floating Widget should now be successfully
        integrated and visibly displayed on your website.
      </p>

      <Divider />

      <Message>
        <div className="message-title">
          Having trouble installing the Floating Widget?
        </div>
        <p>Click the button below to contact us now.</p>
        <button
          className="contact-us"
          onClick={() => dispatch(toggleFeedbackDrawer())}
        >
          Contact Us
        </button>
      </Message>
    </Wrapper>
  );
};

export default WebflowTabWidget;
