import { useState } from 'react';
import { Divider, InputNumber, Tabs, Toggle } from 'rsuite';
import styled from 'styled-components';
import { useAppSelector } from '../../../../../../../store';
import FormRow from '../../../../../../widgetSettings/components/form-row';
import { settingSelector } from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import useDispatchUpdateSiteWidgetSettings from '../../../../../../widgetSettings/shared/hooks/useDispatchUpdateSiteWidgetSettings';
import BackgroundSettings from './BackgroundSettings';
import BorderSettings from './BorderSettings';

const SettingsSection = styled.div<{ $isOpen: boolean }>`
  background-color: #fff;
  border-radius: 5px;
  width: 100%;

  .content {
    padding-top: 20px;
  }
`;

const StyledTabs = styled(Tabs)`
  .rs-nav-pills {
    width: 100%;
  }

  .rs-nav-item {
    width: 50%;
    color: #000;
    font-weight: 600;
  }
`;

export default function DesignSettings() {
  const [isOpen, setIsOpen] = useState(true);
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { carouselSliderWidget: widgetSettings } = siteWidgetSettings ?? {};
  const { dispatchUpdateSiteWidgetSettings } =
    useDispatchUpdateSiteWidgetSettings();

  return (
    <SettingsSection $isOpen={isOpen}>
      {isOpen && (
        <div className="content">
          <FormRow
            justifyContent="space-between"
            gap="5px"
            marginBottom={!!widgetSettings?.autoplay ? '10px' : '0'}
          >
            Auto Play
            <Toggle
              size="md"
              checked={!!widgetSettings?.autoplay}
              checkedChildren="Yes"
              unCheckedChildren="No"
              onClick={() => {
                dispatchUpdateSiteWidgetSettings(
                  'autoplay',
                  !widgetSettings?.autoplay,
                  'slider'
                );
              }}
            />
          </FormRow>

          {widgetSettings?.autoplay && (
            <FormRow justifyContent="space-between" gap="5px" marginBottom="0">
              <span>Speed</span>
              <InputNumber
                type="number"
                step={1000}
                min={2000}
                max={10000}
                size="sm"
                value={widgetSettings?.autoPlaySpeed}
                onChange={(value) => {
                  dispatchUpdateSiteWidgetSettings(
                    'autoPlaySpeed',
                    value,
                    'slider'
                  );
                }}
                style={{ width: 100 }}
              />
            </FormRow>
          )}

          <Divider />

          <StyledTabs defaultActiveKey="1" appearance="subtle">
            <Tabs.Tab eventKey="1" title="Background">
              <BackgroundSettings />
            </Tabs.Tab>
            <Tabs.Tab eventKey="2" title="Border">
              <BorderSettings />
            </Tabs.Tab>
          </StyledTabs>
        </div>
      )}
    </SettingsSection>
  );
}
