import { unwrapResult } from '@reduxjs/toolkit';
import { ChangeEvent, useState } from 'react';
import { FaStar } from 'react-icons/fa6';
import styled from 'styled-components';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import StyledText from '../../../../../shared/components/styled-text';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import MobileIcon from '../../../../../shared/svgs/mobile-icon';
import { Loading } from '../../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { addYelpAccount } from '../../../../socialReviews/redux/actions';
import {
  socialReviewsSelector,
  YelpBusiness,
} from '../../../../socialReviews/redux/social-reviews.slice';

const BackBtn = styled(Button)`
  margin-bottom: 20px;
  background-color: #000;
  color: #fff;
  border-color: #000;
`;

const Wrapper = styled.div`
  .business-img {
    max-width: 85px;
    max-height: 85px;
    background: rgb(245, 245, 245);
    border-radius: 10px;
    padding: 1px;
    border: 1px solid rgb(194, 194, 194);
    object-fit: cover;
  }
  .business-name {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .business-address,
  .business-phone {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;

const ConfirmButton = styled(Button)<{ disabled?: boolean }>`
  background-color: #000;
  color: #fff;
  border-color: #000;
  width: 100%;
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;

  &:disabled {
    background-color: #bbbbbb;
    color: #000;
    border-color: #bbbbbb;
  }
`;

const CheckBox = styled.input`
  width: 16px;
  height: 16px;
`;

const InfoText = styled(StyledText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #414141;
`;

const CheckBoxWithTextWrapper = styled.label`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;
const ConfirmText = styled(StyledText)`
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  line-height: 16px;
  color: #5f5f5f;
  line-height: 23px;
`;

const IconWithTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

export default function ConfirmBusiness({
  selectedBusiness,
  onBack,
  onNext,
}: {
  onNext: () => void;
  selectedBusiness: YelpBusiness | null;
  onBack: () => void;
}) {
  const dispatch = useAppDispatch();

  const { showErrorToast } = useNotification();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  const { addingYelpAccount } = useAppSelector(socialReviewsSelector);

  const handleConfirm = () => {
    if (selectedBusiness) {
      dispatch(
        addYelpAccount({
          placeId: selectedBusiness.placeId,
          pageTitle: selectedBusiness.name,
        })
      )
        .then(unwrapResult)
        .then(onNext)
        .catch(() => {
          showErrorToast('Something went wrong');
        });
    }
  };

  if (!selectedBusiness) {
    return null;
  }

  return (
    <Wrapper>
      <FlexContainer
        gap="10px"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <img
          className="business-img"
          src={selectedBusiness.image ?? ''}
          alt={selectedBusiness.name}
          referrerPolicy="no-referrer"
        />
        <div>
          <p className="business-name">{selectedBusiness.name}</p>

          <IconWithTextWrapper>
            <FaStar size={16} />
            <InfoText>{`${selectedBusiness?.rating} over ${
              selectedBusiness?.reviews
            } review${+selectedBusiness?.reviews > 1 ? 's' : ''}`}</InfoText>
          </IconWithTextWrapper>

          <IconWithTextWrapper>
            <MobileIcon />
            <InfoText>{selectedBusiness?.phone ?? 'n/a'}</InfoText>
          </IconWithTextWrapper>
        </div>
      </FlexContainer>

      <CheckBoxWithTextWrapper>
        <CheckBox
          type="checkbox"
          onChange={handleCheckBox}
          defaultChecked={isChecked}
        />
        <ConfirmText
          onClick={() => {
            setIsChecked(!isChecked);
          }}
        >
          I confirm that I own the right the use reviews from{' '}
          <b>{selectedBusiness.name}</b>
        </ConfirmText>
      </CheckBoxWithTextWrapper>

      <ConfirmButton
        disabled={!isChecked || addingYelpAccount === Loading.PENDING}
        onClick={handleConfirm}
      >
        <b>
          {addingYelpAccount === Loading.PENDING
            ? 'Please wait...'
            : 'Import reviews'}
        </b>
      </ConfirmButton>
    </Wrapper>
  );
}
