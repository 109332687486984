import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InviteTypeEnum } from '..';
import { axiosPrivate } from '../../../../../../services/axiosPrivate';
import AppModal from '../../../../../../shared/components/modal';
import useModal from '../../../../../../shared/components/modal/useModal';
import usePolling from '../../../../../../shared/hooks/usePolling';
import removeTrailingSlash from '../../../../../../shared/utils/removeTrailingSlash';
import { useAppSelector } from '../../../../../../store';
import AddIntegrationModal from '../../../../../settings/components/Integrations/components/AddIntegrationModal';
import { isKlaviyoConnectedSelector } from '../../../../../settings/components/Integrations/redux/integrations.slice';
import { accountSelector } from '../../../../../userData/redux/userData.slice';

/* eslint no-console: "warn" */

const Wrapper = styled.div``;

type Props = {
  onNext: (inviteType: InviteTypeEnum) => void;
};

export default function KlaviyoContacts({ onNext }: Props) {
  const navigate = useNavigate();
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { _id: siteId } = selectedSite ?? {};
  const isKlaviyoConnected = useAppSelector(isKlaviyoConnectedSelector);
  const { handleCloseModal, handleOpenModal, showModal } = useModal();

  const { setIntervalSeconds, setIsEnabled } = usePolling(
    useCallback(async () => {
      let url = `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/klaviyo/check-installation?siteId=${siteId}`;

      const { data } = await axiosPrivate.get(url);

      if (data === 'success') {
        setIsEnabled(false);
        onNext(InviteTypeEnum.KLAVIYO);
      }
    }, [siteId])
  );

  const openWindow = () => {
    const width = 500;
    const height = 600;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const left = (screenWidth - width) / 2;
    const top = (screenHeight - height) / 2;

    setIsEnabled(true);
    setIntervalSeconds(2);

    let url = `${removeTrailingSlash(
      process.env.REACT_APP_REVIEWS_JET_API!
    )}/klaviyo/connect?siteId=${siteId}`;

    window.open(
      url,
      '_blank',
      `width=${width},height=${height},top=${top},left=${left}`
    );
  };

  return (
    <>
      <Wrapper>
        <div
          className="card"
          role="presentation"
          onClick={() => {
            if (isKlaviyoConnected) {
              navigate('/invite-single-customer?source=klaviyo');
            } else {
              handleOpenModal();
            }
          }}
        >
          <div className="logo">
            <img src="/images/klaviyo-2.jpg" alt="" />
          </div>
          <p>Invite Klaviyo contacts</p>
        </div>
      </Wrapper>

      <AppModal
        size="sm"
        shouldOpenModal={showModal}
        onModalClose={handleCloseModal}
        body={
          <AddIntegrationModal
            onClose={handleCloseModal}
            onConnect={openWindow}
            logo={
              <div className="logo">
                <img
                  src="/images/klaviyo-2.jpg"
                  alt=""
                  style={{ width: 150 }}
                />
              </div>
            }
            integration="Klaviyo"
          />
        }
      />
    </>
  );
}
