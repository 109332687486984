function hexToRgba(hex: string, alpha = 1.0) {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse r, g, b values
  let r, g, b;

  if (hex.length === 3) {
    // For 3-digit hex codes
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
  } else if (hex.length === 6) {
    // For 6-digit hex codes
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  } else {
    throw new Error('Invalid HEX color.');
  }

  // Ensure alpha is between 0 and 1
  if (alpha < 0 || alpha > 1) {
    throw new Error('Alpha value must be between 0 and 1.');
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export default hexToRgba;
