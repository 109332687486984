import { IntegrationPlatform } from '../redux/integrations.slice';

const getPlatformName = (platform: IntegrationPlatform) => {
  switch (platform) {
    case IntegrationPlatform.ACTIVE_CAMPAIGN:
      return 'ActiveCampaign';
    case IntegrationPlatform.HUBSPOT:
      return 'HubSpot';

    case IntegrationPlatform.INTERCOM:
      return 'Intercom';

    case IntegrationPlatform.KLAVIYO:
      return 'Klaviyo';

    case IntegrationPlatform.MAILCHIMP:
      return 'MailChimp';

    case IntegrationPlatform.SENDGRID:
      return 'SendGrid';

    default: {
      return '';
    }
  }
};

export default getPlatformName;
