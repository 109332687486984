import dayjs from 'dayjs';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import hexToRgba from '../../../../../../../shared/utils/hexToRgba';
import { Review } from '../../../../../../manage-reviews/redux/reviews.slice';
import {
  BrandingSettings,
  EmbeddedWidgetSettings,
  TranslationType,
} from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import TextReview from './TextReview';

type Props = {
  review: Review;
  translation: TranslationType;
  branding: BrandingSettings;
  widgetSettings: EmbeddedWidgetSettings;
};

type ReviewHeaderProps = {
  $fluid?: boolean;
  $widgetSettings: EmbeddedWidgetSettings;
  $isMasonry?: boolean;
  $isLastItem?: boolean;
  $allowClick?: boolean;
};

const Card = styled.div<ReviewHeaderProps>`
  position: absolute;
  border-radius: ${({ $widgetSettings }) => $widgetSettings.borderRadius};
  ${({ $allowClick }) =>
    !!$allowClick &&
    css`
      cursor: pointer;
    `}
  /* margin: auto; */
  background: ${({ $widgetSettings }) => {
    return hexToRgba(
      $widgetSettings.reviewCardColor,
      $widgetSettings.reviewCardColorOpacity / 100
    );
  }};
  border: ${({ $widgetSettings }) =>
    `${$widgetSettings.showBorder ? $widgetSettings.borderWidth ?? 0 : 0}px ${
      $widgetSettings.borderColor
    } solid`};

  @media (max-width: 644px) {
    width: 100%;
  }
`;

const ReviewCardV2 = forwardRef<HTMLVideoElement, Props>((props, ref) => {
  const { review, widgetSettings, branding, translation } = props;

  const { dateFormat = 'DD/MM/YYYY' } = branding;

  let reviewDate = review.reviewDate;

  try {
    reviewDate = dayjs(review.reviewDate).format(dateFormat);

    if (reviewDate === 'Invalid Date') {
      reviewDate = review.reviewDate;
    }
  } catch (error) {
    reviewDate = review.reviewDate;
  }

  return (
    <Card $widgetSettings={widgetSettings} className="review-card">
      <TextReview
        branding={branding}
        review={review}
        translation={translation}
        widgetSettings={widgetSettings}
      />
    </Card>
  );
});

export default ReviewCardV2;
