import { MdKeyboardArrowRight } from 'react-icons/md';
import { Divider } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import ReviewsJetCrownLogo from '../../../../shared/components/ReviewsJetCrownLogo';
import { AuthTitle } from '../../../external/components';
import ImportWithCSV from './ImportWithCSV';
import SocialReviews from './SocialReviews';

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 40px;
  max-width: 800px;
  width: 100%;
  max-height: 98%;
  overflow: auto;
  background-color: #fff;

  @media (max-width: 530px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0px;
  }

  .rs-picker {
    width: 100%;
    border-radius: 6px !important;
  }
`;

const StyledButton = styled(Button)`
  background-color: #000;
  color: #fff;
  border-color: #000;
  width: 100%;
  padding: 13px 15px;
`;

const StepIndicator = styled.p`
  color: #666;
  font-size: 14px;
  margin-bottom: 24px;
  margin-top: 8px;
`;

interface Props {
  onNext: () => void;
  onPrevious: () => void;
}

export default function ImportReviews({ onNext, onPrevious }: Props) {
  return (
    <Wrapper>
      <ReviewsJetCrownLogo />
      <AuthTitle marginBottom="0px">
        Get started with your existing reviews
      </AuthTitle>

      <StepIndicator>Step 2 of 3: Bring your existing reviews</StepIndicator>

      <SocialReviews />
      <Divider>Or import your existing reviews with a CSV file</Divider>
      <ImportWithCSV />

      <Divider />
      <FlexContainer justifyContent="flex-end" gap="10px">
        {/* <Button variant="tertiary" rounded size="small" onClick={onPrevious}>
          Previous
        </Button> */}
        <StyledButton onClick={onNext} rounded>
          <FlexContainer gap="5px">
            <b>Next </b>
            <MdKeyboardArrowRight />
          </FlexContainer>
        </StyledButton>
      </FlexContainer>
    </Wrapper>
  );
}
