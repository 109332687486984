import Http from '../../../../../../../services/http';
import { useAppSelector } from '../../../../../../../store';
import { ReviewsJetPlatform } from '../../../../../../product/types';
import { accountSelector } from '../../../../../../userData/redux/userData.slice';
import useShopifyOrders from './useShopifyOrders';
import useWixOrders from './useWixOrders';

const http = new Http();

/* eslint no-console: "warn" */

export default function useOrders() {
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { platform } = selectedSite ?? {};

  const {
    getWixOrders,
    hasNext,
    loadingContacts,
    loadingInitialContacts,
    orders,
  } = useWixOrders();

  const {
    shopifyOrders,
    shopifyHasNext,
    getShopifyOrders,
    loadingShopifyOrders,
    loadingInitialShopifyOrders,
  } = useShopifyOrders();

  return {
    orders: platform === ReviewsJetPlatform.WIX ? orders : shopifyOrders,
    getOrders:
      platform === ReviewsJetPlatform.WIX ? getWixOrders : getShopifyOrders,
    hasNext: platform === ReviewsJetPlatform.WIX ? hasNext : shopifyHasNext,
    loadingInitialOrderContacts:
      platform === ReviewsJetPlatform.WIX
        ? loadingInitialContacts
        : loadingInitialShopifyOrders,
    loadingOrders:
      platform === ReviewsJetPlatform.WIX
        ? loadingContacts
        : loadingShopifyOrders,
  };
}
