import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ImportReviewsContext } from '../..';
import Http from '../../../../services/http';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import { Loading } from '../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../shared/utils/removeTrailingSlash';
import { sanitizeReviews } from '../../../../shared/utils/sanitizeText';
import Review from './Review';

const ButtonWrapper = styled(FlexContainer)`
  margin-bottom: 20px;
`;

const Title = styled.h3`
  font-size: 18px;
`;

const http = new Http();

const PreviewReviews = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(Loading.INITIAL);
  const context = useContext(ImportReviewsContext);
  const { parsedFile, mappedFields } = context ?? {};

  const submitReviews = async (reviews: any) => {
    setErrors([]);
    setSubmitting(Loading.PENDING);

    try {
      const {
        data: { errors },
      } = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/reviews/bulk/import`,
        { reviews: sanitizeReviews(reviews) }
      );

      setErrors(errors);
      setSubmitting(Loading.SUCCESS);
      navigate('/manage-reviews');
    } catch (error) {
      setSubmitting(Loading.ERROR);
    }
  };

  const displayReviews = 3;

  const totalReviews = parsedFile?.length ?? 0;
  const remainingReviews = totalReviews - displayReviews;

  const reviews = useMemo(() => {
    if (!parsedFile || !mappedFields) {
      return [];
    }

    return (
      parsedFile
        // .filter((review) => review.rating && review.reviewer_name)
        .map((row) => {
          let data: {
            rating?: string;
            review_date?: string;
            review_text?: string;
            helpful_count?: string;
            reviewer_name?: string;
            your_review_reply?: string;
            product_id?: string;
            title?: string;
            reviewer_location_city?: string;
            reviewer_location_country?: string;
          } = {};

          if (
            !mappedFields.product_id.skipped &&
            mappedFields.product_id.value
          ) {
            data.product_id = row[mappedFields.product_id.value];
          }

          if (!mappedFields.title.skipped && mappedFields.title.value) {
            data.title = row[mappedFields.title.value];
          }

          if (
            !mappedFields.helpful_count.skipped &&
            mappedFields.helpful_count.value
          ) {
            data.helpful_count = row[mappedFields.helpful_count.value];
          }

          if (!mappedFields.rating.skipped && mappedFields.rating.value) {
            data.rating = row[mappedFields.rating.value];
          }

          if (
            !mappedFields.review_date.skipped &&
            mappedFields.review_date.value
          ) {
            data.review_date = row[mappedFields.review_date.value];
          }

          if (
            !mappedFields.review_text.skipped &&
            mappedFields.review_text.value
          ) {
            data.review_text = row[mappedFields.review_text.value];
          }

          if (
            !mappedFields.reviewer_name.skipped &&
            mappedFields.reviewer_name.value
          ) {
            data.reviewer_name = row[mappedFields.reviewer_name.value];
          }

          if (
            !mappedFields.your_review_reply.skipped &&
            mappedFields.your_review_reply.value
          ) {
            data.your_review_reply = row[mappedFields.your_review_reply.value];
          }

          if (
            !mappedFields.reviewer_location_city.skipped &&
            mappedFields.reviewer_location_city.value
          ) {
            data.reviewer_location_city =
              row[mappedFields.reviewer_location_city.value];
          }

          if (
            !mappedFields.reviewer_location_country.skipped &&
            mappedFields.reviewer_location_country.value
          ) {
            data.reviewer_location_country =
              row[mappedFields.reviewer_location_country.value];
          }

          return data;
        })
    );
  }, [parsedFile, mappedFields]);

  if (!context) {
    return null;
  }

  if (!!errors.length) {
    return (
      <>
        {errors?.map((review, index) => (
          <Review key={index} review={review} />
        ))}
      </>
    );
  }

  return (
    <>
      <ButtonWrapper justifyContent="space-between" alignItems="flex-end">
        <div>
          <Title>Preview</Title>{' '}
          <p>Here’s a preview of the reviews to be imported.</p>
        </div>
        <Button
          rounded
          variant={submitting === Loading.PENDING ? 'tertiary' : 'primary'}
          disabled={submitting === Loading.PENDING}
          onClick={() => submitReviews(reviews)}
        >
          {submitting === Loading.PENDING ? 'Please wait...' : 'Import reviews'}
        </Button>
      </ButtonWrapper>

      <FlexContainer stack gap="20px" style={{ width: '100%' }}>
        {reviews?.slice(0, displayReviews)?.map((review, index) => (
          <Review key={index} review={review} />
        ))}
      </FlexContainer>

      <p style={{ marginTop: '15px' }}>
        and {remainingReviews} more review{remainingReviews > 1 ? 's' : ''}.
      </p>
    </>
  );
};

export default PreviewReviews;
