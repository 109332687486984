import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { IoCopyOutline } from 'react-icons/io5';
import { TiTickOutline } from 'react-icons/ti';
import { Button, Divider } from 'rsuite';
import styled from 'styled-components';
import StyledText from '../../../../shared/components/styled-text';
import SyntaxHighlighter from '../../../../shared/components/SyntaxHighlighter';
import useInstallation, {
  Widget,
} from '../../../../shared/hooks/useInstallation';
import { WidgetType } from '../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  accountSelector,
  toggleFeedbackDrawer,
} from '../../../userData/redux/userData.slice';

const Message = styled.div`
  border-left: 4px solid #5bc0de;
  background-color: #e2f2fb;
  padding: 20px;
  margin-bottom: 50px;
  font-size: 14px;
  border-radius: 5px;

  .message-title {
    color: #4a90e2;
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 30px;
    font-family: 'Ubuntu', sans-serif;
  }

  p {
    margin: 0 0 20px;
  }

  .contact-us {
    background-color: #fff;
    border: 1px solid #4a90e2;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
  }

  .contact-us:hover {
    background-color: #4a90e2;
    color: #fff;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 900px;
  margin: auto;
  pre {
    margin: 0 0 20px !important;
    border: 1px solid #dedede;
    border-radius: 10px;
  }
  img.screenshot {
    margin-bottom: 20px;
    margin-top: 10px;
    border: 1px solid #dedede;
    padding: 2px;
    border-radius: 10px;
    width: 100%;
  }
`;

const PageTitle = styled(StyledText)`
  font-size: 25px;
  margin-bottom: 40px;
  margin-top: 50px;
  font-weight: bold;
  text-align: center;
`;

const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: #f3f3f3;
  justify-content: flex-start;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 20px;
`;
const StepNumber = styled.div`
  height: 100%;
  width: 80px;
  background-color: #cfe4ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-weight: bold;
`;

const StepDesc = styled.div`
  margin-bottom: 10px;
`;
const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  margin: auto;
  gap: 5px;
  margin-top: 10px;
`;
const StepWrapper = styled.div`
  margin-bottom: 30px;
`;

const PixpaCarouselWidget = ({ widgetType }: { widgetType: WidgetType }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { account } = useAppSelector(accountSelector);
  const [copied, setCopied] = useState(false);
  const { installationCode } = useInstallation(Widget.CAROUSEL);
  const { selectedSite: site } = account ?? {};

  const embedCode = `${installationCode}
  <${
    widgetType === 'marquee' ? 'reviewsjet-marquee' : 'reviewsjet-slider'
  } id="${site?._id}" count="15" platform="others"></${
    widgetType === 'marquee' ? 'reviewsjet-marquee' : 'reviewsjet-slider'
  }>`;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Wrapper>
      {/* <PageTitle>How To Install the Carousel Widget on Pixpa</PageTitle> */}

      <StepWrapper>
        <Step>
          <StepNumber>Step 1</StepNumber>
          <div>Copy the installation code</div>
        </Step>
        <SyntaxHighlighter code={embedCode} />
        <CopyToClipboard text={embedCode}>
          <StyledButton
            color={copied ? 'green' : 'ghost'}
            appearance="ghost"
            onClick={() => {
              setCopied(true);
              setTimeout(() => setCopied(false), 3000);
            }}
          >
            {copied ? (
              <>
                <TiTickOutline />
                {t('reviews.import-modal.copied-to-clipboard')}
              </>
            ) : (
              <>
                <IoCopyOutline /> {t('shared.copy-installation-code')}
              </>
            )}
          </StyledButton>
        </CopyToClipboard>
      </StepWrapper>

      <StepWrapper>
        <Step>
          <StepNumber>Step 2</StepNumber>
          <div>Use the installation code</div>
        </Step>

        <StepDesc>
          Add a <strong>Section</strong> to the page:
        </StepDesc>
        <img
          src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/wix-dashboard/pixpa/2.png?v=1.0.0"
          alt=""
          className="screenshot"
        />

        <div style={{ paddingBottom: 30 }}></div>

        <StepDesc>
          Add a <strong>Text</strong> to the page:
        </StepDesc>
        <img
          src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/wix-dashboard/pixpa/3.png?v=1.0.0"
          alt=""
          className="screenshot"
        />

        <div style={{ paddingBottom: 30 }}></div>
        <StepDesc>
          Select <strong>More Snippets</strong>
        </StepDesc>
        <img
          src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/wix-dashboard/pixpa/4.png?v=1.0.0"
          alt=""
          className="screenshot"
        />

        <div style={{ paddingBottom: 30 }}></div>
        <StepDesc>
          Drag-and-drop the <strong>HTML, JS, CSS</strong> card right below the{' '}
          <strong>Our Reviews</strong> title
        </StepDesc>
        <img
          src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/wix-dashboard/pixpa/5.png?v=1.0.0"
          alt=""
          className="screenshot"
        />
        <div style={{ paddingBottom: 30 }}></div>
        <StepDesc>
          Click <strong>Code Block</strong> and paste in the JavaScript and the
          Markup codes.
        </StepDesc>
        <img
          src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/wix-dashboard/pixpa/6.png?v=1.0.0"
          alt=""
          className="screenshot"
        />

        <div style={{ paddingBottom: 30 }}></div>
        <StepDesc>
          click <strong>OK</strong> and <strong>Save</strong>
        </StepDesc>
        <img
          src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/wix-dashboard/pixpa/7.png?v=1"
          alt=""
          className="screenshot"
        />
      </StepWrapper>

      <Divider />

      <p style={{ textAlign: 'center' }}>
        Congratulations! Your Carousel Widget should now be successfully
        integrated and visibly displayed on your website.
      </p>

      <Divider />

      <Message>
        <div className="message-title">
          Having trouble installing the Carousel Widget?
        </div>
        <p>Click the button below to contact us now.</p>
        <button
          className="contact-us"
          onClick={() => dispatch(toggleFeedbackDrawer())}
        >
          Contact Us
        </button>
      </Message>
    </Wrapper>
  );
};

export default PixpaCarouselWidget;
