import { useState } from 'react';
import styled from 'styled-components';
import { useAppSelector } from '../../../../../../../store';
import {
  ModalReviewWidgetType,
  settingSelector,
} from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import AdvancedButton from '../WidgetButtons/AdvancedButton';
import DefaultButton from '../WidgetButtons/DefaultButton';
import ReviewSourceButton from '../WidgetButtons/ReviewSourceButton';
import ReviewModal from './ReviewModal';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  position: relative;
`;

export default function WidgetPreview() {
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { modalWidget } = siteWidgetSettings ?? {};
  const { desktopSettings } = modalWidget ?? {};
  const { variant, reviewSource } = desktopSettings ?? {};
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Wrapper>
      {variant === ModalReviewWidgetType.DEFAULT && (
        <DefaultButton onClick={() => setIsOpen(true)} />
      )}
      {variant === ModalReviewWidgetType.ADVANCED && (
        <AdvancedButton onClick={() => setIsOpen(true)} />
      )}
      {variant === ModalReviewWidgetType.WITH_REVIEW_SOURCE && reviewSource && (
        <ReviewSourceButton
          selectedReviewSource={reviewSource}
          onClick={() => setIsOpen(true)}
        />
      )}

      <ReviewModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </Wrapper>
  );
}
