import { Divider, Slider } from 'rsuite';
import styled from 'styled-components';
import ColorPicker from '../../../../../../../../shared/components/colorPicker';
import { useAppSelector } from '../../../../../../../../store';
import FormRow from '../../../../../../../widgetSettings/components/form-row';
import { settingSelector } from '../../../../../../../widgetSettings/redux/widgetSettings.slice';
import useDispatchUpdateSiteWidgetSettings from '../../../../../../../widgetSettings/shared/hooks/useDispatchUpdateSiteWidgetSettings';

const Wrapper = styled.div`
  padding-top: 15px;
`;

export default function BackgroundSettings() {
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { dispatchUpdateSiteWidgetSettings } =
    useDispatchUpdateSiteWidgetSettings();

  if (!siteWidgetSettings) {
    return null;
  }

  return (
    <Wrapper>
      <FormRow justifyContent="flex-start" gap="10px">
        Color
        <ColorPicker
          color={siteWidgetSettings.carouselMarqueeWidget.reviewCardColor}
          changeColor={(color: string) =>
            dispatchUpdateSiteWidgetSettings(
              'reviewCardColor',
              color,
              'marquee'
            )
          }
        />
      </FormRow>

      <Divider />

      <FormRow justifyContent="flex-start" gap="10px">
        <div style={{ width: '100%' }}>
          Transparency (
          <span>{`${siteWidgetSettings.carouselMarqueeWidget.reviewCardColorOpacity}%`}</span>
          )
          <Slider
            progress
            style={{ marginTop: 16 }}
            value={
              siteWidgetSettings.carouselMarqueeWidget.reviewCardColorOpacity
            }
            onChange={(value) => {
              dispatchUpdateSiteWidgetSettings(
                'reviewCardColorOpacity',
                value,
                'marquee'
              );
            }}
          />
        </div>
      </FormRow>
    </Wrapper>
  );
}
