import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InviteTypeEnum } from '..';
import AppModal from '../../../../../../shared/components/modal';
import useModal from '../../../../../../shared/components/modal/useModal';
import { useAppSelector } from '../../../../../../store';
import AddIntegrationModal from '../../../../../settings/components/Integrations/components/AddIntegrationModal';
import { isSendgridConnectedSelector } from '../../../../../settings/components/Integrations/redux/integrations.slice';

/* eslint no-console: "warn" */

const Wrapper = styled.div``;

type Props = {
  onNext: (inviteType: InviteTypeEnum) => void;
};

export default function SendgridContacts({ onNext }: Props) {
  const navigate = useNavigate();
  const isSendgridConnected = useAppSelector(isSendgridConnectedSelector);
  const { handleCloseModal, handleOpenModal, showModal } = useModal();

  return (
    <>
      <Wrapper>
        <div
          className="card"
          role="presentation"
          onClick={() => {
            if (isSendgridConnected) {
              onNext(InviteTypeEnum.SENDGRID);
            } else {
              handleOpenModal();
            }
          }}
        >
          <div className="logo">
            <img src="/images/sendgrid.png" alt="" />
          </div>
          <p>Invite SendGrid contacts</p>
        </div>
      </Wrapper>

      <AppModal
        size="sm"
        shouldOpenModal={showModal}
        onModalClose={handleCloseModal}
        body={
          <AddIntegrationModal
            onClose={handleCloseModal}
            onConnect={() => {}}
            logo={
              <div className="logo">
                <img src="/images/sendgrid.png" alt="" style={{ width: 150 }} />
              </div>
            }
            integration="SendGrid"
          />
        }
      />
    </>
  );
}
