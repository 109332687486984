import { useState } from 'react';
import { Input } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import FormRow from '../../../../widgetSettings/components/form-row';

const Wrapper = styled.div`
  width: 100%;
  h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 20px;
  }
`;

const Label = styled.label`
  margin-bottom: 5px;
`;

type Props = {
  onClose: () => void;
  isConnecting: boolean;
  onConnect: (apiKey: string) => void;
};

export default function ConnectSendGrid({
  onClose,
  onConnect,
  isConnecting,
}: Props) {
  const [apiKey, setApiKey] = useState('');

  const submitForm = () => {
    if (apiKey) {
      onConnect(apiKey);
    }
  };

  return (
    <Wrapper>
      <h3>Connect ActiveCampaign</h3>

      <FormRow justifyContent="flex-start">
        <Label>API Key</Label>
        <Input
          color="red"
          value={apiKey}
          onChange={setApiKey}
          placeholder="YK.lYIK1P4KE_0vvB09iqdoKKh1.U9UZL89FJZpt8skNu093SUoLLpS0L85P-hKjHHcW09U"
        />
      </FormRow>

      <FlexContainer justifyContent="flex-end" gap="10px">
        {!isConnecting && (
          <Button variant="tertiary" size="small" rounded onClick={onClose}>
            Cancel
          </Button>
        )}

        <Button
          rounded
          size="small"
          type="button"
          variant={`${isConnecting ? 'tertiary' : 'primary'}`}
          disabled={isConnecting}
          onClick={() => submitForm()}
        >
          {isConnecting ? 'Please wait...' : 'Connect'}
        </Button>
      </FlexContainer>
    </Wrapper>
  );
}
