import { capitalize } from 'lodash';
import { useState } from 'react';
import { IoColorPaletteOutline, IoSettingsOutline } from 'react-icons/io5';
import { Breadcrumb, Button, Divider, Drawer, Tabs } from 'rsuite';
import styled from 'styled-components';
import ContactSupport from '../../../../shared/components/ContactSupport';
import AppModal from '../../../../shared/components/modal';
import useModal from '../../../../shared/components/modal/useModal';
import NavLink from '../../../../shared/components/NavLink';
import useInstallation, {
  Widget,
} from '../../../../shared/hooks/useInstallation';
import { useAppSelector } from '../../../../store';
import { ReviewsJetPlatform } from '../../../product/types';
import GoDaddyCarouselWidget from '../../../tutorials/components/godaddy/carousel-widget';
import OthersCarouselWidget from '../../../tutorials/components/others/carousel-widget';
import PixpaCarouselWidget from '../../../tutorials/components/pixpa/carousel-widget';
import ShopifyCarouselWidget from '../../../tutorials/components/shopify/carousel-widget';
import SquarespaceCarouselWidget from '../../../tutorials/components/squarespace/carousel-widget';
import WebflowCarouselWidget from '../../../tutorials/components/webflow/carousel-widget';
import WixCarouselWidget from '../../../tutorials/components/wix/carousel-widget';
import WordpressCarouselWidget from '../../../tutorials/components/wordpress/carousel-widget';
import { accountSelector } from '../../../userData/redux/userData.slice';
import { settingSelector } from '../../redux/widgetSettings.slice';
import CarouselReviewCardPreview from '../../shared/components/CarouselReviewCardPreview';
import HowToInstallBanner from '../../shared/components/HowToInstallBanner';
import HowToInstall from '../HowToInstall';
import MoreCustomization from './AdvancedSettings';
import BasicSettings from './BasicSettings';

const Wrapper = styled.div`
  padding: 0px 30px 20px;
  max-width: 1100px;
  margin: auto;
`;

const Title = styled.h1`
  font-size: 25px;
  margin-top: 0;
  color: #000;
`;

const PageTitle = styled(Title)`
  color: #2c2c2c;
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 10px;
`;

const CodeWrapper = styled.div`
  max-width: 100%;
  pre {
    margin: 0 0 20px !important;
    padding: 10px !important;
  }
`;

const Slider = () => {
  const { account } = useAppSelector(accountSelector);
  const { installationCode } = useInstallation(Widget.CAROUSEL);
  const { selectedSite } = account ?? {};
  const { _id: siteId } = selectedSite ?? {};
  const embedCode = `${installationCode}
<reviewsjet-slider id="${siteId}" count="3" platform="others"></reviewsjet-slider>`;

  const { handleCloseModal, handleOpenModal, showModal } = useModal();
  const [open, setOpen] = useState<ReviewsJetPlatform | null>(null);
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { carouselSliderWidget, brandingSettings } = siteWidgetSettings ?? {};

  const { platform } = selectedSite ?? {};

  if (!carouselSliderWidget || !brandingSettings) {
    return null;
  }

  return (
    <>
      <ContactSupport />
      <Wrapper>
        <PageTitle>Carousel Slider</PageTitle>
        <Breadcrumb separator=">">
          <Breadcrumb.Item href="/select-widgets" as={NavLink}>
            Widgets
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Carousel slider</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <CodeWrapper>
            <HowToInstallBanner
              widget="Carousel Slider"
              setOpen={(value) => setOpen(value)}
              onClick={() => {
                if (platform === ReviewsJetPlatform.WIX) {
                  setOpen(ReviewsJetPlatform.WIX);
                } else if (platform === ReviewsJetPlatform.SHOPIFY) {
                  setOpen(ReviewsJetPlatform.SHOPIFY);
                } else {
                  handleOpenModal();
                }
              }}
            />
            <Divider />

            <Tabs defaultActiveKey="1" appearance="subtle">
              <Tabs.Tab
                eventKey="1"
                title="Basic Settings"
                icon={<IoSettingsOutline size={20} />}
              >
                <BasicSettings />
                <Divider>Preview</Divider>
                <CarouselReviewCardPreview
                  key="preview-1"
                  widget={carouselSliderWidget}
                  brandingSettings={brandingSettings}
                />
              </Tabs.Tab>
              <Tabs.Tab
                eventKey="2"
                title="Advanced Settings"
                icon={<IoColorPaletteOutline size={20} />}
              >
                <MoreCustomization />
                <Divider>Preview</Divider>
                <CarouselReviewCardPreview
                  key="preview-2"
                  widget={carouselSliderWidget}
                  brandingSettings={brandingSettings}
                />
              </Tabs.Tab>
              {/* <Tabs.Tab
                eventKey="3"
                title="Video Reviews"
                icon={<BsCameraVideo size={20} />}
              >
                <ReviewQuestionsSettings />
                <Divider>Preview</Divider>
                <ReviewQuestionsPreview settings={carouselSliderWidget} />
              </Tabs.Tab>
              <Tabs.Tab
                eventKey="4"
                title="Thank You Settings"
                icon={<FaRegHeart size={20} />}
              >
                <ThankYouPageSettings />
                <Divider>Preview</Divider>
                <ThankYouPagePreview />
              </Tabs.Tab> */}
            </Tabs>
          </CodeWrapper>
        </div>
        <AppModal
          size="sm"
          shouldOpenModal={showModal}
          onModalClose={handleCloseModal}
          body={
            <HowToInstall
              embedCode={embedCode}
              onClose={handleCloseModal}
              openTutorial={(platform: ReviewsJetPlatform) => setOpen(platform)}
            />
          }
        />
        <Drawer
          size="lg"
          open={!!open}
          onClose={() => setOpen(null)}
          className="tutorial-drawer"
        >
          <Drawer.Header>
            <Drawer.Title>{`How To Install the Carousel Widget ${
              open !== ReviewsJetPlatform.OTHERS
                ? `on ${capitalize(open ?? '')}`
                : ``
            }`}</Drawer.Title>
            <Drawer.Actions>
              <Button onClick={() => setOpen(null)}>Close</Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            {open === ReviewsJetPlatform.WORDPRESS && (
              <WordpressCarouselWidget widgetType="slider" />
            )}
            {open === ReviewsJetPlatform.WIX && (
              <WixCarouselWidget widgetType="slider" />
            )}
            {open === ReviewsJetPlatform.WEBFLOW && (
              <WebflowCarouselWidget widgetType="slider" />
            )}
            {open === ReviewsJetPlatform.SQUARESPACE && (
              <SquarespaceCarouselWidget widgetType="slider" />
            )}
            {open === ReviewsJetPlatform.GO_DADDY && (
              <GoDaddyCarouselWidget widgetType="slider" />
            )}

            {open === ReviewsJetPlatform.PIXPA && (
              <PixpaCarouselWidget widgetType="slider" />
            )}
            {open === ReviewsJetPlatform.SHOPIFY && (
              <ShopifyCarouselWidget widgetType="slider" count={3} />
            )}
            {open === ReviewsJetPlatform.OTHERS && (
              <OthersCarouselWidget widgetType="slider" />
            )}
          </Drawer.Body>
        </Drawer>
      </Wrapper>
    </>
  );
};

export default Slider;
