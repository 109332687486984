import { FaArrowRightLong } from 'react-icons/fa6';
import { IoIosRocket } from 'react-icons/io';
import styled from 'styled-components';
import { emailsSelector } from '../../../../../../emails/redux/emails.slice';
import { useIniviteCustomerContext } from '../InviteCustomerProvider';
import { SubmitButton } from './SubmitButton';
import { Loading } from '../../../../../../../shared/types/review.types';
import { useAppSelector } from '../../../../../../../store';
import FlexContainer from '../../../../../../../shared/components/layouts/flex-container';

const Description = styled.p`
  color: #666;
  line-height: 1.5;
`;

export function SendForm() {
  const params = new URLSearchParams(location.search);
  const source = params.get('source');
  const { sendingSingleReviewRquest } = useAppSelector(emailsSelector);
  const { formValues, submitForm, submittingBulkRequest } =
    useIniviteCustomerContext();
  const { toEmail } = formValues;
  const isSubmitting = [
    sendingSingleReviewRquest,
    submittingBulkRequest,
  ].includes(Loading.PENDING);

  return (
    <>
      <Description>
        You're about to invite {source ? `your contacts` : toEmail} to leave a
        testimonial. They'll receive an email with a link to your form
        immediately.
      </Description>
      <SubmitButton disabled={isSubmitting} onClick={submitForm}>
        <FlexContainer gap="5px">
          {isSubmitting ? (
            <>
              <span>Sending...</span> <FaArrowRightLong />
            </>
          ) : (
            <>
              <span>Request Reviews</span> <IoIosRocket size={20} />
            </>
          )}
        </FlexContainer>
      </SubmitButton>
    </>
  );
}
