import { useEffect } from 'react';

const fonts = [
  'https://fonts.googleapis.com/css2?family=Barlow:wght@400&display=swap',
  'https://fonts.googleapis.com/css2?family=DM+Sans:wght@400&display=swap',
  'https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400&display=swap',
  'https://fonts.googleapis.com/css2?family=Heebo:wght@400&display=swap',
  'https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@400&display=swap',
  'https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400&display=swap',
  'https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap',
  'https://fonts.googleapis.com/css2?family=Kanit:wght@400&display=swap',
  'https://fonts.googleapis.com/css2?family=Oswald:wght@400&display=swap',
  'https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400&display=swap',
  'https://fonts.googleapis.com/css2?family=Ubuntu:wght@400&display=swap',
  'https://fonts.googleapis.com/css2?family=Work+Sans:wght@400&display=swap',
];

const PreloadFonts = () => {
  useEffect(() => {
    fonts.forEach((fontUrl) => {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.href = fontUrl;
      link.as = 'style';
      link.onload = () => {
        link.rel = 'stylesheet'; // Change rel to 'stylesheet' after preload
      };
      document.head.appendChild(link);
    });

    return () => {
      fonts.forEach((fontUrl) => {
        const existingLink = document.querySelector(`link[href="${fontUrl}"]`);
        if (existingLink) {
          document.head.removeChild(existingLink);
        }
      });
    };
  }, []);

  return null;
};

export default PreloadFonts;
