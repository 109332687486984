import { unwrapResult } from '@reduxjs/toolkit';
import { createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Message } from 'rsuite';
import styled, { css } from 'styled-components';
import {
  ModalBody,
  ModalHeader,
} from '../../../../../shared/components/modal/modal-parts';
import StyledText from '../../../../../shared/components/styled-text';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import {
  Loading,
  SubscriptionPlan,
} from '../../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { accountSelector } from '../../../../userData/redux/userData.slice';
import { addTripadvisorAccount } from '../../../redux/actions';
import { socialReviewsSelector } from '../../../redux/social-reviews.slice';
import { VALID_TRIPADVISOR_DOMAINS } from '../../../utils/constants';

const StyledInput = styled(Input)<{ error: boolean }>`
  background: #fff;
  margin-bottom: 20px;
  border-color: #cdcdcd;
  margin-top: 5px;
  outline: none !important;
  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;
const StyledLabel = styled(StyledText)`
  font-size: 14px;
  color: #7b7b7b;
`;

const Wrapper = styled.div`
  padding-top: 20px;
`;
const StyledMessage = styled(Message)`
  margin-bottom: 20px;
`;

const ContinueButton = styled(Button)<{ disabled?: boolean }>`
  padding-left: 30px;
  padding-right: 30px;
  margin-right: auto;
`;

const AddProductPage = ({ onConnected }: { onConnected: () => void }) => {
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { plan } = selectedSite ?? {};
  const ref = createRef<HTMLInputElement>();
  const { t } = useTranslation('common');
  const {
    get3rdPartyAccount: { data: integrations },
    addingTripadvisorAccount,
  } = useAppSelector(socialReviewsSelector);
  const [reviewUrl, setReviewUrl] = useState('');
  const { showErrorToast, showSuccessToast, showWarningToast } =
    useNotification();

  const connectTripadvisor = () => {
    if (ref.current) {
      ref.current.style.borderColor = '#cdcdcd';

      if (!reviewUrl) {
        ref.current.style.borderColor = 'red';
        return showWarningToast('Please add a valid page ID.');
      }

      try {
        const urlObject = new URL(reviewUrl);
        const trustpilotDomain = urlObject.hostname;

        if (!VALID_TRIPADVISOR_DOMAINS.includes(trustpilotDomain)) {
          ref.current.style.borderColor = 'red';
          return showWarningToast(
            'Please add a valid Tripadvisor business page URL'
          );
        }

        const search = encodeURIComponent(reviewUrl);

        const alreadyAdded = (integrations?.tripadvisor_reviews ?? []).some(
          (i) => i.pageId === search
        );

        if (alreadyAdded) {
          ref.current.style.borderColor = 'red';
          return showWarningToast('Business page already added.');
        }

        dispatch(addTripadvisorAccount({ search }))
          .then(unwrapResult)
          .then(() => {
            showSuccessToast(
              `Your reviews are being imported! It'll just take 3 to 5 minutes. We'll ping you once they're in.`
            );
            onConnected();
          })
          .catch(() => {
            showErrorToast('Something went wrong. Please try it again.');
          });
      } catch (error) {
        ref.current.style.borderColor = 'red';
        return showWarningToast(
          'Please add a valid Tripadvisor business page URL.'
        );
      }
    }
  };

  return (
    <>
      <ModalHeader
        title="Add your Tripadvisor page"
        centerText={false}
        closeButton={false}
      />
      <ModalBody>
        <Wrapper>
          <div>
            {plan === SubscriptionPlan.FREE && (
              <StyledMessage type="info" bordered showIcon>
                <span>
                  <b>Current plan: FREE</b>. A maximum of <b>15 reviews</b> will
                  be imported.
                </span>
              </StyledMessage>
            )}
            <StyledLabel>Link to Tripadvisor page</StyledLabel>
            <StyledInput
              ref={ref}
              error={null}
              onChange={(url: string) => {
                setReviewUrl(url);
                if (ref.current) {
                  ref.current.style.borderColor = '#cdcdcd';
                }
              }}
              placeholder="https://www.tripadvisor.com/Restaurant_Review-g186346-d720029-Reviews-Melton_s-York_North_Yorkshire_England.html"
            />
            <ContinueButton
              appearance={
                addingTripadvisorAccount === Loading.PENDING
                  ? 'subtle'
                  : 'primary'
              }
              size="md"
              onClick={connectTripadvisor}
            >
              {addingTripadvisorAccount === Loading.PENDING
                ? t('shared.please-wait')
                : 'Import reviews'}
            </ContinueButton>
          </div>
        </Wrapper>
      </ModalBody>
    </>
  );
};

export default AddProductPage;
