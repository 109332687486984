import { unwrapResult } from '@reduxjs/toolkit';
import { createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Message } from 'rsuite';
import styled, { css } from 'styled-components';
import {
  ModalBody,
  ModalHeader,
} from '../../../../../shared/components/modal/modal-parts';
import StyledText from '../../../../../shared/components/styled-text';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import { Loading } from '../../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { accountSelector } from '../../../../userData/redux/userData.slice';
import { addCapterraAccount } from '../../../redux/actions';
import { socialReviewsSelector } from '../../../redux/social-reviews.slice';

const StyledInput = styled(Input)<{ error: boolean }>`
  background: #fff;
  margin-bottom: 20px;
  border-color: #cdcdcd;
  margin-top: 5px;
  outline: none !important;
  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;
const StyledLabel = styled(StyledText)`
  font-size: 14px;
  color: #7b7b7b;
`;

const Wrapper = styled.div`
  padding-top: 20px;
`;

const ContinueButton = styled(Button)<{ disabled?: boolean }>`
  padding-left: 30px;
  padding-right: 30px;
  margin-right: auto;
`;
const StyledMessage = styled(Message)`
  margin-bottom: 20px;
`;

const isValidCapterraUrl = (url: string): boolean => {
  const regex = /^https:\/\/www\.capterra\.com\/p\/\d+\/[\w-]+\/?$/;
  return regex.test(url);
};

const AddProductPage = ({ onConnected }: { onConnected: () => void }) => {
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { plan } = selectedSite ?? {};
  const ref = createRef<HTMLInputElement>();
  const { t } = useTranslation('common');
  const { addingCapterraAccount } = useAppSelector(socialReviewsSelector);
  const [reviewUrl, setReviewUrl] = useState('');
  const { showErrorToast, showSuccessToast, showWarningToast } =
    useNotification();

  const connectCapterra = () => {
    if (ref.current) {
      ref.current.style.borderColor = '#cdcdcd';

      if (!reviewUrl || !isValidCapterraUrl(reviewUrl)) {
        ref.current.style.borderColor = 'red';
        return showWarningToast(
          'Please add a valid capterra business page URL.'
        );
      }

      dispatch(
        addCapterraAccount({
          search: reviewUrl,
        })
      )
        .then(unwrapResult)
        .then(() => {
          showSuccessToast(
            `Your reviews are being imported! It'll just take 3 to 5 minutes. We'll ping you once they're in.`
          );
          onConnected();
        })
        .catch(() => {
          showErrorToast('Something went wrong. Please try it again.');
        });
    }
  };

  return (
    <>
      <ModalHeader
        title="Add your product page"
        centerText={false}
        closeButton={false}
      />
      <ModalBody>
        <Wrapper>
          <div>
            <StyledLabel>Your link to Capterra product page</StyledLabel>
            <StyledInput
              ref={ref}
              error={null}
              onChange={(url: string) => {
                setReviewUrl(url);
                if (ref.current) {
                  ref.current.style.borderColor = '#cdcdcd';
                }
              }}
              placeholder="https://www.capterra.com/p/10017012/ReviewsJet/"
            />
            <ContinueButton
              appearance={
                addingCapterraAccount === Loading.PENDING ? 'subtle' : 'primary'
              }
              size="md"
              onClick={connectCapterra}
            >
              {addingCapterraAccount === Loading.PENDING
                ? t('shared.please-wait')
                : 'Import reviews'}
            </ContinueButton>
          </div>
        </Wrapper>
      </ModalBody>
    </>
  );
};

export default AddProductPage;
