import { unwrapResult } from '@reduxjs/toolkit';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';
import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Schema } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../shared/components/Button';
import ReviewsJetCrownLogo from '../../../shared/components/ReviewsJetCrownLogo';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../shared/hooks/useNotification';
import { Loading } from '../../../shared/types/review.types';
import setSession from '../../../shared/utils/setSession';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  OnboardingStep,
  setPersonalInformation,
} from '../../onboarding/redux/onboarding.slice';
import { login } from '../../userData/redux/action';
import {
  AuthForm,
  AuthFormGroup,
  AuthIconWrapper,
  AuthTextField,
  AuthTitle,
} from '../components';
import OtherPlatforms from './OtherPlatforms';

/* eslint no-console: "warn" */

const StyledFlexContainer = styled(FlexContainer)`
  border-top: 1px solid #eeeeee;
  padding: 10px 0;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 40px;
  max-width: 440px;
  max-height: 90%;
  overflow: auto;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  @media (max-width: 530px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0px;
    border: 0;
    box-shadow: none;
  }

  input {
    border-radius: 25px;
  }

  .forgot-password {
    color: #8b8e99;
    /* text-decoration: underline !important;
    text-underline-offset: 4px; */
  }
`;

const StyledButton = styled(Button)`
  background-color: #000;
  color: #fff;
  border-radius: 25px;
  width: 100%;
  border-color: #000;
`;

const Intro = styled.p`
  margin-bottom: 30px;
  color: #737373;
  font-size: 15px;
`;

const { StringType } = Schema.Types;

const model = Schema.Model({
  email: StringType()
    .isEmail('Please enter a valid email address.')
    .isRequired('This field is required.'),
  password: StringType().isRequired('This field is required.'),
});

const LoginPage = () => {
  const navigate = useNavigate();
  const { showErrorToast } = useNotification();
  const dispatch = useAppDispatch();
  const captchaRef = useRef<any>(null);
  const formRef = useRef<{ check: () => void }>(null);
  const [visible, setVisible] = useState(false);
  const {
    login: { loading },
  } = useAppSelector((state) => state.userData);

  const [formValue, setFormValue] = useState({
    email: '',
    password: '',
  });

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  const handleSubmit = () => {
    if (!formRef.current?.check()) {
      return;
    }

    dispatch(login(formValue))
      .then(unwrapResult)
      .then((res) => {
        const {
          ownerName,
          confirmationCode,
          jwtAccessToken,
          jwtRefreshToken,
          selectedSite: { onboarding, url = '' },
        } = res;

        if (ownerName) {
          const [firstName = '', lastName = ''] = ownerName.split(' ');
          dispatch(
            setPersonalInformation({
              firstName,
              lastName,
              websiteUrl: url,
            })
          );
        }

        const { step: onboardingStep } = onboarding ?? {};

        if (onboardingStep && onboardingStep !== OnboardingStep.Complete) {
          // navigate(`/onboarding?step=${onboardingStep}`);
          navigate('/onboarding');
        }

        if (confirmationCode) {
          navigate(`/account-creation-success/${confirmationCode}`);
        } else {
          setSession({ jwtAccessToken, jwtRefreshToken });

          navigate('/manage-reviews');
        }
      })
      .catch(() => {
        showErrorToast('Invalid email and password combination');
      });
  };

  return (
    <>
      <Helmet>
        <title>Sign In to ReviewsJet | Manage Your Online Reviews</title>
        <meta
          name="description"
          content="Sign in to ReviewsJet to manage all your reviews from Google, Yelp, Trustpilot, Etsy and more in one place. Monitor, respond, and grow your online reputation effortlessly."
        />
      </Helmet>

      <Wrapper>
        <a href={process.env.REACT_APP_REVIEWSJET_WEBSITE!}>
          <ReviewsJetCrownLogo />
        </a>
        <AuthTitle marginBottom="10px">Sign in to ReviewsJet</AuthTitle>
        <Intro>Please enter your email and password to sign in.</Intro>
        <AuthForm
          fluid
          ref={formRef}
          onChange={setFormValue}
          formValue={formValue}
          model={model}
        >
          <AuthFormGroup controlId="email">
            {/* <AuthLabel>Email</AuthLabel> */}
            <AuthTextField
              name="email"
              type="text"
              autoComplete="true"
              placeholder="Enter your email"
            />
          </AuthFormGroup>
          <div style={{ height: 20 }}></div>
          <AuthFormGroup controlId="password" marginBottom="0px">
            <AuthIconWrapper isLoginPage onClick={toggleVisibility}>
              {visible ? <EyeIcon /> : <EyeSlashIcon />}
            </AuthIconWrapper>
            {/* <AuthLabel>Password</AuthLabel> */}
            <AuthTextField
              name="password"
              type={visible ? 'text' : 'password'}
              autoComplete="true"
              placeholder="Enter your password"
            />
          </AuthFormGroup>

          <Form.Group>
            <FlexContainer
              justifyContent="space-between"
              style={{ marginTop: 20 }}
            >
              <StyledButton
                size="large"
                type="submit"
                onClick={handleSubmit}
                disabled={loading === Loading.PENDING}
              >
                {loading === Loading.PENDING ? (
                  <b>Please wait...</b>
                ) : (
                  <FlexContainer gap="5px">
                    <b>Sign in</b>
                  </FlexContainer>
                )}
              </StyledButton>
            </FlexContainer>
          </Form.Group>
          <FlexContainer justifyContent="space-between">
            <FlexContainer
              justifyContent="flex-start"
              alignItems="center"
              gap="5px"
            >
              <span>New to ReviewsJet?</span>
              <Link to="/signup">Sign up</Link>
            </FlexContainer>
            <Link className="forgot-password" to="/forgot-password">
              Forgot password?
            </Link>
          </FlexContainer>
        </AuthForm>
        <OtherPlatforms isLogin />
      </Wrapper>
    </>
  );
};

export default LoginPage;
