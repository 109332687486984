import { useEffect, useState } from 'react';
import { ReviewsJetPlatform } from '../../features/product/types';
import { accountSelector } from '../../features/userData/redux/userData.slice';
import { useAppSelector } from '../../store';
import {
  EMBEDDED_DEVELOPMENT_JS,
  EMBEDDED_PRODUCTION_JS,
  GOOGLE_SNIPPET_JS,
  PRODUCT_WIDGET_DEVELOPMENT_CSS,
  PRODUCT_WIDGET_DEVELOPMENT_JS,
  PRODUCT_WIDGET_PRODUCTION_CSS,
  PRODUCT_WIDGET_PRODUCTION_JS,
  TAB_WIDGET_DEVELOPMENT_CSS,
  TAB_WIDGET_DEVELOPMENT_JS,
  TAB_WIDGET_PRODUCTION_CSS,
  TAB_WIDGET_PRODUCTION_JS,
} from '../utils/installations';

export enum Widget {
  TAB_WIDGET = 'tab-widget',
  EMBEDDED = 'embedded-widget',
  CAROUSEL = 'carousel-widget',
  RICH_SNIPPET = 'rich-snippet',
  WIX_PRODUCT_WIDGET = 'wix-product-widget',
}

const useInstallation = (widget: Widget) => {
  const { account } = useAppSelector(accountSelector);
  const [installationCode, setInstallationCode] = useState('');
  const { selectedSite: site } = account ?? {};

  useEffect(() => {
    if (site) {
      switch (widget) {
        case Widget.TAB_WIDGET: {
          setInstallationCode(
            `<link rel='stylesheet' href='${
              process.env.REACT_APP_NODE_ENV === 'production'
                ? TAB_WIDGET_PRODUCTION_CSS
                : TAB_WIDGET_DEVELOPMENT_CSS
            }' />
<script id="reviewsjet-modal-widget" async="true" src='${
              process.env.REACT_APP_NODE_ENV === 'production'
                ? TAB_WIDGET_PRODUCTION_JS
                : TAB_WIDGET_DEVELOPMENT_JS
            }' data-id="${site?._id}" data-platform="${
              ReviewsJetPlatform.OTHERS
            }"></script>`
          );

          break;
        }
        case Widget.EMBEDDED: {
          setInstallationCode(
            `<script async="true" src='${
              process.env.REACT_APP_NODE_ENV === 'production'
                ? EMBEDDED_PRODUCTION_JS
                : EMBEDDED_DEVELOPMENT_JS
            }'></script>`
          );
          break;
        }

        case Widget.CAROUSEL: {
          setInstallationCode(
            `<script async="true" src='${
              process.env.REACT_APP_NODE_ENV === 'production'
                ? EMBEDDED_PRODUCTION_JS
                : EMBEDDED_DEVELOPMENT_JS
            }'></script>`
          );
          break;
        }
        case Widget.WIX_PRODUCT_WIDGET: {
          setInstallationCode(
            `<link rel='stylesheet' href='${
              process.env.REACT_APP_NODE_ENV === 'production'
                ? PRODUCT_WIDGET_PRODUCTION_CSS
                : PRODUCT_WIDGET_DEVELOPMENT_CSS
            }' /><script id="reviewsjet-product-widget" async="true" src='${
              process.env.REACT_APP_NODE_ENV === 'production'
                ? PRODUCT_WIDGET_PRODUCTION_JS
                : PRODUCT_WIDGET_DEVELOPMENT_JS
            }' data-id="${site?._id}" data-platform="${
              ReviewsJetPlatform.OTHERS
            }"></script><script>(function(){function registerReviewsJetListener() {window.wixDevelopersAnalytics.register('reviewsJetEvents', (eventName, eventParams) => {if (eventName === 'productPageLoaded') {setTimeout(()=> {try {window.initReviewsJetProductWidget(eventParams.productId);} catch{}}, 1000)}});}window.wixDevelopersAnalytics ? registerReviewsJetListener() : window.addEventListener('wixDevelopersAnalyticsReady', registerReviewsJetListener);}());</script>`
          );
          break;
        }

        case Widget.RICH_SNIPPET: {
          setInstallationCode(
            `<script async="true" 
src='${GOOGLE_SNIPPET_JS}' 
id="reviewjet-rich-snippet" data-id="${site?._id}"></script>`
          );
          break;
        }

        default:
          break;
      }
    }
  }, [site]);

  return { installationCode };
};

export default useInstallation;
