import { Divider } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../shared/components/layouts/flex-container';

const Platform = styled(FlexContainer)`
  justify-content: center;
  border: 1px solid #d4d4d4;
  padding: 8px 10px;
  width: 124px;
  border-radius: 124px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }

  span {
    font-size: 12px;
    font-weight: 500;
    color: #555d6f;
  }
  img {
    width: 24px;
    height: 24px;
  }
`;

export default function OtherPlatforms({ isLogin }: { isLogin?: boolean }) {
  return (
    <>
      <Divider>Or {isLogin ? 'Sign in' : 'Sign up'} using</Divider>

      <FlexContainer justifyContent="center" gap="10px">
        <Platform
          gap="6px"
          role="presentation"
          onClick={() =>
            window.open('https://apps.shopify.com/reviewsjet', '_blank')
          }
        >
          <img src="/images/shopify-tea-bag-logo.svg" alt="Shopify" />
          <span>Shopify</span>
        </Platform>

        <Platform
          gap="6px"
          role="presentation"
          onClick={() =>
            window.open('https://www.wix.com/app-market/reviewsjet', '_blank')
          }
        >
          <img src="/images/wix-black-logo.svg" alt="Wix" />
          <span>Wix</span>
        </Platform>
      </FlexContainer>
    </>
  );
}
