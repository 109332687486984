import { useContext } from 'react';
import { FaCircleInfo } from 'react-icons/fa6';
import { Divider, Message } from 'rsuite';
import styled from 'styled-components';
import { ImportReviewsContext } from '../..';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import Field from './Field';

const ButtonWrapper = styled.div`
  text-align: right;
`;

const SubTitle = styled.div`
  button {
    padding: 0;
    margin-left: 3px;
    margin-right: 3px;
  }
`;

const StyledMessage = styled(Message)`
  margin-bottom: 20px;

  .rs-message-container {
    background-color: #f9f9f9;
  }
`;

const Wrapper = styled.div`
  margin-bottom: 200px;
`;

const Unmapped = styled.span<{ $total: number }>`
  color: ${({ $total }) => ($total > 0 ? 'crimson' : 'green')};
`;

const MapFields = ({ onComplete }: { onComplete: () => void }) => {
  const context = useContext(ImportReviewsContext);

  if (!context) {
    return null;
  }

  const { headers, unmappedFields, handleSkipUnmappedFields } = context;

  return (
    <Wrapper>
      <StyledMessage>
        <FlexContainer gap="10px" justifyContent="flex-start">
          <FaCircleInfo size={20} />
          <span>
            Need help? Send your import file to{' '}
            <a href="mailto:hello@reviewsjet.com">hello@reviewsjet.com</a>, and
            we’ll handle it for you.
          </span>
        </FlexContainer>
      </StyledMessage>
      <Divider />
      <FlexContainer justifyContent="space-between">
        <FlexContainer
          gap="10px"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Unmapped $total={unmappedFields.length}>
            {!!unmappedFields.length
              ? `${unmappedFields.length} Unmapped`
              : 'You are good to go!'}
          </Unmapped>{' '}
          {!!unmappedFields.length && (
            <>
              <span>-</span>
              <Button
                rounded
                variant="tertiary"
                size="small"
                onClick={handleSkipUnmappedFields}
              >
                Skip Unmapped
              </Button>
            </>
          )}
        </FlexContainer>
        <ButtonWrapper>
          <Button
            rounded
            variant="secondary"
            disabled={!!unmappedFields.length}
            onClick={onComplete}
          >
            Next: Preview reviews
          </Button>
        </ButtonWrapper>
      </FlexContainer>
      <Divider />

      <SubTitle>
        Let's map the columns in your uploaded csv to your desired fields
      </SubTitle>

      {headers.map((header, index) => (
        <Field header={header} key={index} />
      ))}
    </Wrapper>
  );
};

export default MapFields;
