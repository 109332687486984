import dayjs from 'dayjs';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Checkbox, Dropdown, IconButton, Popover, Whisper } from 'rsuite';
import styled, { css } from 'styled-components';
import FlexContainer from '../../../../../../../../shared/components/layouts/flex-container';
import { ContactItem } from '../../../../../../../emails/redux/emails.slice';

/* eslint no-console: "warn" */

const Wrapper = styled(FlexContainer)<{ $hasOrder: boolean }>`
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dedede;
  padding-left: 10px;
  padding-right: 20px;

  &:last-child {
    border: 0;
  }

  .rs-checkbox {
    flex: 1;
  }

  ${({ $hasOrder }) =>
    !!$hasOrder
      ? css`
          .rs-checkbox-control {
            top: 11px;
          }
        `
      : css`
          .rs-checkbox-control {
            top: 15px;
          }
        `}
`;

const DropdownMenu = styled(Dropdown.Menu)`
  width: 280px;
  max-height: 300px;
  overflow: auto;

  .rs-dropdown-item {
    cursor: auto;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: #fff;
    }
  }
`;

const ProductDetails = styled(FlexContainer)`
  flex: 1;
  font-size: 12px;
  .product-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 200px;
    font-weight: bold;
    font-size: 13px;
  }
`;

const ProductWrapper = styled(FlexContainer)`
  padding: 10px;
  border-bottom: 1px solid #dedede;
  &:last-child {
    border: 0;
  }

  .product-img {
    width: 48px;
    height: 48px;

    img {
      width: 100%;
      object-fit: cover;
    }
  }
`;

const Product = ({
  image,
  productName,
}: {
  productName: string;
  image: string;
}) => {
  return (
    <ProductWrapper justifyContent="flex-start" gap="10px">
      {image && (
        <div className="product-img">
          <img src={image} alt="" />
        </div>
      )}

      <ProductDetails stack alignItems="flex-start" justifyContent="flex-start">
        <div className="product-name">{productName}</div>
        {/* <div className="">SKU: 0009</div>
        <div className="">Color: Blue</div> */}
      </ProductDetails>
    </ProductWrapper>
  );
};

type Props = {
  contact: ContactItem;
  selected: boolean;
  onSelect: (order: ContactItem) => void;
};

export default function Contact({ contact, selected, onSelect }: Props) {
  const {
    orderNumber,
    firstName,
    lastName,
    email,
    price,
    productName,
    productImage,
    createdDate,
  } = contact;

  const renderMenu = ({ left, top, className }: any, ref: any) => {
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <DropdownMenu>
          <Dropdown.Item eventKey={1}>
            <Product productName={productName} image={productImage} />
          </Dropdown.Item>
        </DropdownMenu>
      </Popover>
    );
  };

  return (
    <Wrapper
      $hasOrder={!!orderNumber}
      gap="10px"
      justifyContent="flex-start"
      role="presentation"
    >
      <Checkbox
        checked={selected}
        onChange={() => {
          onSelect(contact);
        }}
      >
        <div>
          <FlexContainer
            stack
            gap="5px"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {orderNumber && <div>Order: {orderNumber}</div>}

            <div className="">{`${firstName} ${lastName}`}</div>
            <div>{`${firstName || lastName ? '(' : ''}${email}${
              firstName || lastName ? ')' : ''
            }`}</div>
            {orderNumber && (
              <>
                <div className="">Total: {price}</div>
                <div className="">
                  Date: {dayjs(createdDate).format('MMM DD, YYYY')}
                </div>
              </>
            )}
          </FlexContainer>
        </div>
      </Checkbox>

      {orderNumber && (
        <Whisper placement="bottomEnd" trigger="click" speaker={renderMenu}>
          <IconButton
            appearance="default"
            icon={<BsThreeDotsVertical />}
            circle
          />
        </Whisper>
      )}
    </Wrapper>
  );
}
