import { useEffect, useState } from 'react';
import { ContactItem } from '../../../../../../emails/redux/emails.slice';
import useActiveCampaign from './useActiveCampaign';
import useIntercomContacts from './useIntercomContacts';
import useKlaviyoContacts from './useKlaviyoContacts';
import useMailChimpContacts from './useMailChimpContacts';
import useOrders from './useOrders';
import useSendGrid from './useSendGrid';

/* eslint no-console: "warn" */

export type ContactsSource =
  | 'orders'
  | 'mailchimp'
  | 'intercom'
  | 'klaviyo'
  | 'activecampaign'
  | 'sendgrid';

export type MailChimpList = {
  id: string;
  name: string;
  stats: { member_count: number };
};

export default function useContacts(source: ContactsSource) {
  const [contacts, setContacts] = useState<ContactItem[]>([]);

  const {
    hasNext,
    orders,
    getOrders,
    loadingInitialOrderContacts,
    loadingOrders,
  } = useOrders();

  const {
    mailchimpLists,
    getMailchimpLists,
    loadingMailchimpLists,
    onSelectMailChimpList,
    mailchimpContacts,
    loadingMailChimpContacts,
    mailchimpOffset,
    mailchimpListId,
    getMailChimpContacts,
    loadingInitialMailchimpContacts,
  } = useMailChimpContacts();

  const {
    getIntercomContacts,
    intercomContacts,
    loadingIntercomContacts,
    startingAfter,
    loadingInitialIntercomContacts,
  } = useIntercomContacts();

  const {
    getKlaviyoContacts,
    klaviyoContacts,
    loadingInitialKlaviyoContacts,
    loadingKlaviyoContacts,
    klaviyoNextCursor,
  } = useKlaviyoContacts();

  const {
    activeCampaignContacts,
    getActiveCampaignContacts,
    hasMoreActiveCampaignContacts,
    loadingActiveCampaignContacts,
    loadingInitialCampaignContacts,
  } = useActiveCampaign();

  const {
    sendGridContacts,
    getSendGridContacts,
    hasMoreSendGridContacts,
    loadingInitialSendGridContacts,
    loadingSendGridContacts,
  } = useSendGrid();

  useEffect(() => {
    switch (source) {
      case 'orders':
        setContacts(orders);
        break;

      case 'mailchimp':
        setContacts(mailchimpContacts);
        break;

      case 'intercom':
        setContacts(intercomContacts);
        break;

      case 'klaviyo':
        setContacts(klaviyoContacts);
        break;

      case 'activecampaign':
        setContacts(activeCampaignContacts);
        break;

      case 'sendgrid':
        setContacts(sendGridContacts);
        break;

      default:
        break;
    }
  }, [
    source,
    orders,
    mailchimpContacts,
    intercomContacts,
    klaviyoContacts,
    activeCampaignContacts,
    sendGridContacts,
  ]);

  return {
    hasNext,
    contacts,
    mailchimpLists,
    getMailchimpLists,
    loadingMailchimpLists,
    onSelectMailChimpList,
    loadingMailChimpContacts,
    getIntercomContacts,
    loadingIntercomContacts,
    loadingInitialIntercomContacts,
    startingAfter,
    mailchimpOffset,
    mailchimpListId,
    getMailChimpContacts,
    loadingInitialMailchimpContacts,
    loadingKlaviyoContacts,
    getKlaviyoContacts,
    klaviyoNextCursor,
    loadingInitialKlaviyoContacts,
    getOrders,
    loadingInitialOrderContacts,
    loadingOrders,
    hasMoreActiveCampaignContacts,
    loadingActiveCampaignContacts,
    loadingInitialCampaignContacts,
    getActiveCampaignContacts,
    hasMoreSendGridContacts,
    loadingSendGridContacts,
    loadingInitialSendGridContacts,
    getSendGridContacts,
  };
}
