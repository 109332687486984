import { unwrapResult } from '@reduxjs/toolkit';
import { Controller, useForm } from 'react-hook-form';
import { Input, SelectPicker } from 'rsuite';
import styled, { css } from 'styled-components';
import Button from '../../../../../shared/components/Button';
import StyledText from '../../../../../shared/components/styled-text';
import {
  GoogleConnectFormData,
  Loading,
} from '../../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getGoogleBusinesses } from '../../../../socialReviews/redux/actions';
import {
  initGoogleBusinessesSearch,
  socialReviewsSelector,
} from '../../../../socialReviews/redux/social-reviews.slice';
import { googlePageLanguages } from '../../../../socialReviews/utils/google-page-languages';

const FormContainer = styled.form`
  padding-top: 20px;
`;

const StyledInput = styled(Input)<{ error: boolean }>`
  background: #fff;
  margin-bottom: 20px;
  border-color: #cdcdcd;
  border-radius: 20px;
  margin-top: 5px;
  outline: none !important;
  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;

const StyledLabel = styled(StyledText)`
  font-size: 14px;
  color: #7b7b7b;
`;

const StyledPicker = styled(SelectPicker)`
  margin-bottom: 20px;
  border-color: #cdcdcd;
  margin-top: 5px;
  outline: none !important;

  .rs-picker-toggle {
    border-radius: 20px !important;
  }
`;

const SearchButton = styled(Button)`
  background-color: #000;
  color: #fff;
  border-color: #000;
  width: 100%;
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:disabled {
    background-color: #bbbbbb;
    color: #000;
    border-color: #bbbbbb;
  }
`;

const data = googlePageLanguages.map((item) => ({
  label: item.language_name,
  value: item.language_code,
}));

type Props = {
  businessName: string;
  language: string;
  onNext: (businessName: string, language: string) => void;
};

export default function Form({ businessName, language, onNext }: Props) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<GoogleConnectFormData>({
    defaultValues: {
      businessAddress: businessName ?? '',
      gpsCoordinates: '',
      hl: language || 'en',
    },
  });
  const dispatch = useAppDispatch();

  const onSubmit = handleSubmit((data) => {
    onNext(data.businessAddress, data.hl);
    dispatch(initGoogleBusinessesSearch());
    dispatch(getGoogleBusinesses(data))
      .then(unwrapResult)
      .catch(() => {});
  });

  const { loadingGoogleBusinesses } = useAppSelector(socialReviewsSelector);

  return (
    <>
      <FormContainer onSubmit={onSubmit}>
        <StyledLabel>Business name and city (as shown on Google)</StyledLabel>

        <Controller
          name="businessAddress"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <StyledInput
              error={errors.businessAddress}
              placeholder="E.g. Apple Inc. San Francisco"
              {...field}
            />
          )}
        />

        <StyledLabel>In what language are your Google reviews? </StyledLabel>
        <Controller
          name="hl"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <StyledPicker
              className="lomse"
              block
              cleanable={false}
              data={data}
              {...field}
            />
          )}
        />

        <SearchButton
          type="submit"
          disabled={loadingGoogleBusinesses === Loading.PENDING}
        >
          {loadingGoogleBusinesses === Loading.PENDING ? (
            <b>Please wait...</b>
          ) : (
            <b>Search</b>
          )}
        </SearchButton>
      </FormContainer>
    </>
  );
}
