import { useEffect } from 'react';
import hexToRgba from '../../../../../../shared/utils/hexToRgba';
import { useAppSelector } from '../../../../../../store';
import { settingSelector } from '../../../../../widgetSettings/redux/widgetSettings.slice';

export default function useUpdateWidgetPreview() {
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { carouselSliderWidget } = siteWidgetSettings!;

  useEffect(() => {
    const reviewCards = document.getElementsByClassName('review-card');
    Array.from(reviewCards).forEach((card) => {
      const backgroundColor = hexToRgba(
        carouselSliderWidget.reviewCardColor,
        carouselSliderWidget.reviewCardColorOpacity / 100
      );

      const borderStyle = carouselSliderWidget.showBorder
        ? `${carouselSliderWidget.borderWidth ?? 0}px ${
            carouselSliderWidget.borderColor
          } solid`
        : 'none';

      // @ts-ignore
      Object.assign(card.style, {
        borderRadius: carouselSliderWidget.borderRadius,
        background: backgroundColor,
        border: borderStyle,
      });
    });
  }, [
    carouselSliderWidget.borderRadius,
    carouselSliderWidget.reviewCardColor,
    carouselSliderWidget.reviewCardColorOpacity,
    carouselSliderWidget.showBorder,
    carouselSliderWidget.borderWidth,
    carouselSliderWidget.borderColor,
  ]);

  useEffect(() => {
    const fullRatingStars = document.getElementsByClassName('full-rating');
    Array.from(fullRatingStars).forEach((star) => {
      star.setAttribute('fill', carouselSliderWidget.starColor);
    });
  }, [carouselSliderWidget.starColor]);

  useEffect(() => {
    const reviewerNames = document.getElementsByClassName('reviewer-name');
    Array.from(reviewerNames).forEach((name) => {
      // @ts-ignore
      name.style.color = carouselSliderWidget.linkColor;
    });
  }, [carouselSliderWidget.linkColor]);

  useEffect(() => {
    const reviewContents = document.getElementsByClassName('review-content');
    Array.from(reviewContents).forEach((content) => {
      // @ts-ignore
      content.style.color = carouselSliderWidget.textColor;
    });
  }, [carouselSliderWidget.textColor]);

  useEffect(() => {
    const reviewDates = document.getElementsByClassName('review-date');
    Array.from(reviewDates).forEach((date) => {
      // @ts-ignore
      date.style.color = carouselSliderWidget.dateColor;
    });
  }, [carouselSliderWidget.dateColor]);
}
