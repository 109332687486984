import { FaCode } from 'react-icons/fa6';
import { List } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../shared/components/Button';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import Tag from '../../../shared/components/Tag';
import { ReviewsJetPlatform } from '../../product/types';

const Instruction = styled.p`
  margin-bottom: 20px;
`;

const Title = styled.h4`
  color: #000;
  font-weight: bold;
  margin: 0%;
  font-size: 18px;
  margin-bottom: 0px;
`;
const CustomList = styled(List)`
  .title {
    font-weight: bold;
  }
`;

const HowToInstall = ({
  onClose,
  openTutorial,
  hideGodaddy = false,
}: {
  embedCode?: string;
  onClose: () => void;
  hideGodaddy?: boolean;
  openTutorial: (platform: ReviewsJetPlatform) => void;
}) => {
  return (
    <>
      <Title>Installation</Title>
      <Instruction>
        To install this widget, choose one of the options below:
      </Instruction>

      <CustomList>
        <List.Item key="wordpress">
          <FlexContainer justifyContent="space-between">
            <FlexContainer gap="5px">
              <span className="title">Wordpress</span>
              <Tag color="green">Coming soon</Tag>
            </FlexContainer>
            <Button
              variant="secondary"
              size="small"
              rounded
              onClick={() => {
                onClose();
                openTutorial(ReviewsJetPlatform.WORDPRESS);
              }}
            >
              Install
            </Button>
          </FlexContainer>
        </List.Item>
        <List.Item key="webflow">
          <FlexContainer justifyContent="space-between">
            <span className="title">Webflow</span>
            <Button
              variant="secondary"
              size="small"
              rounded
              onClick={() => {
                onClose();
                openTutorial(ReviewsJetPlatform.WEBFLOW);
              }}
            >
              Install
            </Button>
          </FlexContainer>
        </List.Item>
        <List.Item key="squarespace">
          <FlexContainer justifyContent="space-between">
            <span className="title">Squarespace</span>
            <Button
              variant="secondary"
              size="small"
              rounded
              onClick={() => {
                onClose();
                openTutorial(ReviewsJetPlatform.SQUARESPACE);
              }}
            >
              Install
            </Button>
          </FlexContainer>
        </List.Item>
        {!hideGodaddy && (
          <List.Item key="godaddy">
            <FlexContainer justifyContent="space-between">
              <span className="title">GoDaddy</span>
              <Button
                variant="secondary"
                size="small"
                rounded
                onClick={() => {
                  onClose();
                  openTutorial(ReviewsJetPlatform.GO_DADDY);
                }}
              >
                Install
              </Button>
            </FlexContainer>
          </List.Item>
        )}

        <List.Item key="pixpa">
          <FlexContainer justifyContent="space-between">
            <span className="title">Pixpa</span>
            <Button
              variant="secondary"
              size="small"
              rounded
              onClick={() => {
                onClose();
                openTutorial(ReviewsJetPlatform.PIXPA);
              }}
            >
              Install
            </Button>
          </FlexContainer>
        </List.Item>
        <List.Item key="html">
          <FlexContainer justifyContent="space-between">
            <FlexContainer gap="5px">
              <FaCode size={20} />
              <span className="title">HTML</span>
            </FlexContainer>

            <Button
              variant="secondary"
              size="small"
              rounded
              onClick={() => {
                onClose();
                openTutorial(ReviewsJetPlatform.OTHERS);
              }}
            >
              Install
            </Button>
          </FlexContainer>
        </List.Item>
      </CustomList>

      <FlexContainer justifyContent="flex-end" style={{ marginTop: 20 }}>
        <Button variant="tertiary" onClick={onClose} rounded size="small">
          Close
        </Button>
      </FlexContainer>
    </>
  );
};

export default HowToInstall;
