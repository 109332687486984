import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum OnboardingStep {
  PersonalInformation = 'personal-information',
  ImportReviews = 'import-reviews',
  AskForReviews = 'ask-for-reviews',
  InstallWidget = 'install-widget',
  StartFreeTrial = 'start-free-trial',
  Complete = 'complete',
}

interface OnboardingState {
  currentStep: OnboardingStep;
  isComplete: boolean;
  firstName: string;
  lastName: string;
  websiteUrl: string;
  hasExistingTestimonials: string;
  reference: string;
  sellProducts: string;
}

type SetPersonalInformationPayload = {
  firstName: string;
  lastName: string;
  websiteUrl: string;
  hasExistingTestimonials?: string;
  reference?: string;
  sellProducts?: string;
};

const initialState: OnboardingState = {
  currentStep: OnboardingStep.PersonalInformation,
  isComplete: false,
  firstName: '',
  lastName: '',
  websiteUrl: '',
  reference: '',
  sellProducts: '',
  hasExistingTestimonials: '',
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<OnboardingStep>) => {
      state.currentStep = action.payload;
    },
    setPersonalInformation: (
      state,
      action: PayloadAction<SetPersonalInformationPayload>
    ) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.websiteUrl = action.payload.websiteUrl;
      state.hasExistingTestimonials =
        action.payload.hasExistingTestimonials ?? '';
      state.reference = action.payload.reference ?? '';
      state.sellProducts = action.payload.sellProducts ?? '';
    },
    setComplete: (state, action: PayloadAction<boolean>) => {
      state.isComplete = action.payload;
    },
  },
});

export const { setCurrentStep, setComplete, setPersonalInformation } =
  onboardingSlice.actions;

export default onboardingSlice.reducer;
