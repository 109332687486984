import { Controller } from 'react-hook-form';
import { FaArrowRightLong } from 'react-icons/fa6';
import styled from 'styled-components';
// import {
//   ErroMessage,
//   StyledInput,
// } from '../../../shared/components/form/StyledInput';
// import FlexContainer from '../../../shared/components/layouts/flex-container';
// import FormRow from '../../widgetSettings/components/form-row';
import {
  ErroMessage,
  StyledInput,
} from '../../../../../../../shared/components/form/StyledInput';
import FlexContainer from '../../../../../../../shared/components/layouts/flex-container';
import FormRow from '../../../../../../widgetSettings/components/form-row';
import {
  InviteCustomerSteps,
  useIniviteCustomerContext,
} from '../InviteCustomerProvider';
import { SubmitButton } from './SubmitButton';

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 500px;
  justify-content: space-between;
  padding-bottom: 40px;
  width: 100%;
`;
const FormWrapper = styled.div`
  h5 {
    margin-bottom: 10px;
  }
`;

export function AdvancedForm() {
  const { control, errors, trigger, setCurrentStep } =
    useIniviteCustomerContext();

  return (
    <Wrapper>
      <FormWrapper>
        <h5>Email Settings</h5>
        <FormRow stack alignItems="flex-start">
          <Label htmlFor="emailAddress">Reply-to email</Label>
          <Controller
            name="replyToEmail"
            rules={{ required: true }}
            control={control}
            render={({ field }: any) => (
              <StyledInput
                id="replyToEmail"
                placeholder="Email Address"
                error={Boolean(errors.replyToEmail)}
                {...field}
              />
            )}
          />
          {errors.replyToEmail && (
            <ErroMessage>{errors.replyToEmail.message}</ErroMessage>
          )}
        </FormRow>
        <FormRow stack alignItems="flex-start">
          <Label htmlFor="replyFromName">From name</Label>
          <Controller
            name="replyFromName"
            rules={{ required: true }}
            control={control}
            render={({ field }: any) => (
              <StyledInput
                id="replyFromName"
                placeholder="Name"
                error={Boolean(errors.replyFromName)}
                {...field}
              />
            )}
          />
          {errors.replyFromName && (
            <ErroMessage>{errors.replyFromName.message}</ErroMessage>
          )}
        </FormRow>

        <h5 style={{ marginTop: 50 }}>Translation</h5>
        <FormRow stack alignItems="flex-start">
          <Label htmlFor="callToAction">Button</Label>
          <Controller
            name="callToAction"
            rules={{ required: true }}
            control={control}
            render={({ field }: any) => (
              <StyledInput
                id="callToAction"
                placeholder="Leave a review"
                error={Boolean(errors.callToAction)}
                {...field}
              />
            )}
          />
          {errors.callToAction && (
            <ErroMessage>{errors.callToAction.message}</ErroMessage>
          )}
        </FormRow>

        <FormRow stack alignItems="flex-start">
          <Label htmlFor="callToAction">Text for Button Not Working</Label>
          <Controller
            name="feedbackMessageText"
            rules={{ required: true }}
            control={control}
            render={({ field }: any) => (
              <StyledInput
                id="feedbackMessageText"
                placeholder="Button not working? Paste this URL in the browser"
                error={Boolean(errors.feedbackMessageText)}
                {...field}
              />
            )}
          />
          {errors.feedbackMessageText && (
            <ErroMessage>{errors.feedbackMessageText.message}</ErroMessage>
          )}
        </FormRow>
      </FormWrapper>
      <SubmitButton
        onClick={async () => {
          const isValid = await trigger(
            [
              'replyToEmail',
              'replyFromName',
              'callToAction',
              'feedbackMessageText',
            ],
            {
              shouldFocus: true,
            }
          );
          if (isValid) {
            setCurrentStep(InviteCustomerSteps.SEND);
          }
        }}
      >
        <FlexContainer gap="5px">
          <span>Next</span> <FaArrowRightLong />
        </FlexContainer>
      </SubmitButton>
    </Wrapper>
  );
}
