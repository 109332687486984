import { useState } from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Drawer } from 'rsuite';
import styled, { css } from 'styled-components';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import TripadvisorLogo from '../../../../../shared/components/TripadvisorLogo';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import { ReviewerPlatform } from '../../../../../shared/types/review.types';
import { useAppDispatch } from '../../../../../store';
import { AuthTitle } from '../../../../external/components';
import { YelpBusiness } from '../../../../socialReviews/redux/social-reviews.slice';
import {
  OnboardingStep,
  setCurrentStep,
} from '../../../redux/onboarding.slice';
import updateOnboarding from '../../../services/updateOnboarding';
import ListReviews from '../ListReviews';
import Form from './Form';

const Item = styled(FlexContainer)`
  width: 100%;
  cursor: pointer;
  max-width: 150px;
  padding: 10px;
  border-radius: 6px;
  background-color: #fdfdfd;
  border: 1px solid #e2e2e2;
  gap: 5px;
  &:hover {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  }

  img {
    height: 20px;
  }

  span {
    font-weight: 600;
  }
`;

const DrawerTitle = styled(Drawer.Title)<{ $showInstallWidget: boolean }>`
  h2 {
    line-height: 45px;
  }
  .drawer-title {
    ${({ $showInstallWidget }) =>
      $showInstallWidget &&
      css`
        margin-bottom: -20px;
      `}
  }

  .next-step {
    font-family: 'Wix Madefor Text', sans-serif;
    font-size: 14px;
    font-weight: normal;
  }
`;

const BackBtn = styled(Button)`
  background-color: #000;
  color: #fff;
  border-color: #000;
  height: 32px;
  line-height: 20px;
`;

const NextStepBtn = styled(Button)`
  background-color: #000;
  color: #fff;
  border-color: #000;
`;

const DrawerActions = styled(Drawer.Actions)`
  button {
    margin-top: 5px;
  }
`;

enum Step {
  FORM = 'form',
  RESULTS = 'results',
  CONFIRM = 'confirm',
  LIST_REVIEWS = 'list-reviews',
}

export default function TripadvisorReviews() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(Step.FORM);
  const [showInstallWidget, setShowInstallWidget] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState<YelpBusiness | null>(
    null
  );
  const [businessName, setBusinessName] = useState('');
  const [address, setAddress] = useState('');
  const { showSuccessToast } = useNotification();

  const onPublishReviewsComplete = async () => {
    setOpen(false);
    showSuccessToast(
      'Your reviews have been published successfully! Moving to next step.'
    );

    setTimeout(async () => {
      dispatch(setCurrentStep(OnboardingStep.InstallWidget));

      await updateOnboarding({
        nextStep: OnboardingStep.InstallWidget,
      });
    }, 3000);
  };

  return (
    <>
      <Item onClick={() => setOpen(true)}>
        <TripadvisorLogo size={24} />
        <span>Tripadvisor</span>
      </Item>

      <Drawer backdrop="static" open={open} onClose={() => setOpen(false)}>
        <Drawer.Header>
          <DrawerTitle $showInstallWidget={showInstallWidget}>
            <FlexContainer
              gap="10px"
              justifyContent="flex-start"
              alignItems="center"
            >
              {[Step.FORM, Step.RESULTS, Step.CONFIRM].includes(step) && (
                <BackBtn
                  rounded
                  size="small"
                  onClick={() => {
                    if (step === Step.FORM) {
                      setOpen(false);
                    } else {
                      setStep(Step.FORM);
                    }
                  }}
                >
                  <FlexContainer>
                    <MdKeyboardArrowLeft />
                    Back
                  </FlexContainer>
                </BackBtn>
              )}
              <AuthTitle marginBottom="0px">
                {step === Step.FORM && 'Tripadvisor reviews'}
                {step === Step.RESULTS && 'Search results'}
                {step === Step.CONFIRM && 'Confirm business'}
                {step === Step.LIST_REVIEWS && (
                  <>
                    <div className="drawer-title">Publish reviews</div>
                    {showInstallWidget && (
                      <span className="next-step">
                        Finished publishing! Click <b>Install Widget</b> to
                        continue
                      </span>
                    )}
                  </>
                )}
              </AuthTitle>
            </FlexContainer>
          </DrawerTitle>

          {step === Step.LIST_REVIEWS && showInstallWidget && (
            <DrawerActions>
              <NextStepBtn
                onClick={onPublishReviewsComplete}
                size="small"
                rounded
              >
                Install Widget
              </NextStepBtn>
            </DrawerActions>
          )}
        </Drawer.Header>

        <Drawer.Body>
          {step === Step.FORM && (
            <Form
              onNext={() => {
                setStep(Step.LIST_REVIEWS);
              }}
            />
          )}

          {step === Step.LIST_REVIEWS && (
            <ListReviews
              platform={ReviewerPlatform.TRIPADVISOR}
              onReadyForNextStep={() => setShowInstallWidget(true)}
              onNext={() => {
                setOpen(false);
                showSuccessToast(
                  'Your Tripadvisor reviews have been published successfully! Moving to next step.'
                );

                setTimeout(async () => {
                  dispatch(setCurrentStep(OnboardingStep.InstallWidget));

                  await updateOnboarding({
                    nextStep: OnboardingStep.InstallWidget,
                  });
                }, 3000);
              }}
            />
          )}
        </Drawer.Body>
      </Drawer>
    </>
  );
}
