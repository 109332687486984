import { useEffect, useState } from 'react';
import { BiEnvelopeOpen, BiSolidEnvelopeOpen } from 'react-icons/bi';
import { FaQuestionCircle, FaRegQuestionCircle } from 'react-icons/fa';
import { FaHeart, FaRegHeart, FaRegStar, FaStar } from 'react-icons/fa6';
import { IoIosArrowDown } from 'react-icons/io';
import { IoCloudDownloadOutline, IoCloudDownloadSharp } from 'react-icons/io5';
import { LuSettings } from 'react-icons/lu';
import {
  MdOutlineReviews,
  MdOutlineWidgets,
  MdReviews,
  MdWidgets,
} from 'react-icons/md';
import { RiMailSendFill, RiMailSendLine } from 'react-icons/ri';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Divider, Button as RsButton, Whisper } from 'rsuite';
import styled, { css } from 'styled-components';
import { plansSelector } from '../../../../features/plans/redux/plans.slice';
import {
  accountSelector,
  toggleFeedbackDrawer,
  userAccountConfigSelector,
} from '../../../../features/userData/redux/userData.slice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  toggleChangelogModal,
  toggleUpgradeModal,
} from '../../../../store/app.slice';
import useReviewUs from '../../../hooks/useReviewUs';
import useTheme from '../../../hooks/useTheme';
import { SubscriptionPlan } from '../../../types/review.types';
import PremiumFeature from '../../PremiumFeature';
import FlexContainer from '../flex-container';
import renderSpeaker from './renderSpeaker';

const Wrapper = styled.div<{
  $hideSidebar: boolean;
}>`
  height: 100vh;
  flex: none;
  width: 255px;
  transition: width 0.15s;
  position: fixed;
  z-index: 9;
  background-color: #ffffff;

  ${({ $hideSidebar }) => {
    if ($hideSidebar) {
      return css`
        display: none;
      `;
    }
  }};
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 20px;
  border-bottom: 0px solid #e4e4e4;
  transition: width 0.15s;
  background-color: transparent;

  .plan-name {
    font-size: 12px;
    color: #6f6f6f;
  }
  .owner-name {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
const StyledFlexContainer = styled(FlexContainer)`
  color: #000;
  width: 100%;
  cursor: pointer;
  margin-bottom: -9px;
`;
const Header = styled.div`
  padding-left: 15px;
  button {
    font-size: 13px;
    font-weight: bold;
  }
`;

const SidebarInner = styled.div`
  padding: 0 17px 0;

  .section-title {
    display: block;
    font-size: 11px;
    margin-bottom: 10px;
    font-weight: 900;
    color: #9c9c9c;
    padding-left: 10px;
  }
`;

const MenuItem = styled(Link)`
  display: block;
  color: #737373;
  padding: 6px 10px;
  display: flex;
  margin-bottom: 1px;
  justify-content: flex-start;
  gap: 10px;
  font-weight: 600;
  border-radius: 8px;
  &.active {
    color: #000;
    background-color: #eceff3;

    &:hover {
      color: #000;
      background-color: #eceff3;
    }
  }
  &:hover {
    color: #737373;
    background-color: #eceff3;
  }
`;
const StyledDivider = styled(Divider)`
  margin: 10px 0;
`;

const OtherMenu = styled.div`
  bottom: 0;
  width: 100%;
  position: absolute;
  border-top: 1px solid #e5e5ea;

  a {
    padding: 20px 25px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #737373;
  }
`;

type Props = {
  hideSidebar: boolean;
};

export default function ExpandedSidebar({ hideSidebar }: Props) {
  const reviewUs = useReviewUs();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { account } = useAppSelector(accountSelector);
  const { selectedSite, ownerName, ownerEmail } = account ?? {};
  const { plan, planCycle } = selectedSite ?? {};
  const siteConfig = useAppSelector(userAccountConfigSelector);
  const { menuItemHoverBgColor, menuItemHoverColor } = useTheme() ?? {};
  const firstNameArr = ownerName?.split(' ') ?? [];
  const firstName = firstNameArr[0];
  const { loading: loadingPlans, data: plans } = useAppSelector(plansSelector);
  const [planDescription, setPlanDescription] = useState('');

  useEffect(() => {
    if (plan === SubscriptionPlan.FREE) {
      setPlanDescription('Basic plan');
    } else if (plan === SubscriptionPlan.PRO) {
      setPlanDescription('Pro plan');
    } else if (plan === SubscriptionPlan.ADVANCED) {
      setPlanDescription('Advanced plan');
    }
  }, [plan]);

  return (
    <Wrapper $hideSidebar={hideSidebar}>
      <LogoWrapper>
        <StyledFlexContainer
          justifyContent="flex-start"
          alignItems="center"
          gap="10px"
          role="presentation"
          // onClick={() => navigate('/manage-reviews')}
        >
          <Whisper
            placement="rightStart"
            trigger="click"
            speaker={(e, ref) =>
              renderSpeaker(
                {
                  ...e,
                  canLogout: !!siteConfig?.canLogout,
                  onClickAccountDetails: () =>
                    navigate('/settings?tab=account'),
                  onClickRoadmap: () =>
                    window.open(
                      'https://reviewsjet.featurebase.app/roadmap',
                      '_blank'
                    ),
                  onClickChangelog: () => dispatch(toggleChangelogModal(true)),
                  reviewUs,
                  help: () => dispatch(toggleFeedbackDrawer()),
                },
                ref
              )
            }
          >
            <FlexContainer
              gap="5px"
              justifyContent="space-between"
              style={{ width: '100%' }}
            >
              <FlexContainer gap="10px">
                <Avatar circle />
                <FlexContainer
                  stack
                  gap="0px"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <b className="owner-name">{firstName ?? ownerEmail}</b>
                  <div className="plan-name">{planDescription}</div>
                </FlexContainer>
              </FlexContainer>

              <IoIosArrowDown />
            </FlexContainer>
          </Whisper>
        </StyledFlexContainer>
      </LogoWrapper>

      <Header>
        {plan !== SubscriptionPlan.ADVANCED && (
          <RsButton
            appearance="link"
            color="green"
            onClick={() => {
              dispatch(toggleUpgradeModal(SubscriptionPlan.ADVANCED));
            }}
          >
            <FlexContainer gap="5px">
              <PremiumFeature />
              Upgrade
            </FlexContainer>
          </RsButton>
        )}
      </Header>
      <StyledDivider />

      <SidebarInner>
        <span className="section-title">MANAGE</span>
        <MenuItem
          to="/manage-reviews"
          className={pathname === '/manage-reviews' ? 'active' : ''}
        >
          {pathname === '/manage-reviews' ? (
            <MdReviews size={18} />
          ) : (
            <MdOutlineReviews size={18} />
          )}
          Reviews
        </MenuItem>
      </SidebarInner>
      <StyledDivider />
      <SidebarInner>
        <span className="section-title">COLLECT</span>
        <MenuItem
          to="/import-reviews"
          className={pathname === '/import-reviews' ? 'active' : ''}
        >
          {' '}
          {pathname === '/import-reviews' ? (
            <IoCloudDownloadSharp size={18} />
          ) : (
            <IoCloudDownloadOutline size={18} />
          )}
          Import reviews
        </MenuItem>
        <MenuItem
          to="/share-review-form"
          className={pathname === '/share-review-form' ? 'active' : ''}
        >
          {pathname === '/share-review-form' ? (
            <RiMailSendFill size={18} />
          ) : (
            <RiMailSendLine size={18} />
          )}
          Ask for reviews
        </MenuItem>
        <MenuItem
          to="/emails"
          className={pathname.includes('/emails') ? 'active' : ''}
        >
          {' '}
          {pathname === '/emails' ? (
            <BiSolidEnvelopeOpen size={18} />
          ) : (
            <BiEnvelopeOpen size={18} />
          )}
          Email history
        </MenuItem>
      </SidebarInner>
      <StyledDivider />
      <SidebarInner>
        <span className="section-title">DISPLAY</span>

        <MenuItem
          to="/wall-of-love"
          className={pathname === '/wall-of-love' ? 'active' : ''}
        >
          {pathname === '/wall-of-love' ? (
            <FaHeart size={18} />
          ) : (
            <FaRegHeart size={18} />
          )}
          Wall of Love
        </MenuItem>

        <MenuItem
          to="/select-widgets"
          className={
            [
              '/select-widgets',
              '/widgets/masonry',
              '/widgets/grid',
              '/widgets/list',
              '/widgets/slider',
              '/widgets/marquee',
              '/widgets/modal-widget',
              '/widgets/product',
            ].includes(pathname)
              ? 'active'
              : ''
          }
        >
          {' '}
          {[
            '/select-widgets',
            '/widgets/masonry',
            '/widgets/grid',
            '/widgets/list',
            '/widgets/slider',
            '/widgets/marquee',
            '/widgets/modal-widget',
            '/widgets/product',
          ].includes(pathname) ? (
            <MdWidgets size={18} />
          ) : (
            <MdOutlineWidgets size={18} />
          )}
          Widgets
        </MenuItem>
      </SidebarInner>
      <StyledDivider />
      <SidebarInner>
        <span className="section-title">EXTRA</span>

        <MenuItem
          to="/questions"
          className={pathname === '/questions' ? 'active' : ''}
        >
          {pathname === '/questions' ? (
            <FaQuestionCircle size={18} />
          ) : (
            <FaRegQuestionCircle size={18} />
          )}
          QAs
        </MenuItem>
        <MenuItem
          to="/seo/business-rich-snippet"
          className={pathname === '/seo/business-rich-snippet' ? 'active' : ''}
        >
          {pathname === '/seo/business-rich-snippet' ? (
            <FaStar size={18} />
          ) : (
            <FaRegStar size={18} />
          )}
          Rich Snippet
        </MenuItem>
      </SidebarInner>
      <OtherMenu>
        <Link
          to="/settings"
          data-title="Settings"
          className={pathname === '/settings' ? 'active' : ''}
        >
          <LuSettings size={18} />
          Settings
        </Link>
      </OtherMenu>
    </Wrapper>
  );
}
