import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import { Loading } from '../../../../../shared/types/review.types';
import useIntegration from '../hooks/useIntegration';
import ConnectActiveCampaign from './ConnectActiveCampaign';
import ConnectSendGrid from './ConnectSendGrid';

/* eslint no-console: "warn" */

const Title = styled.h3`
  font-size: 20px;
  margin-top: 0;
  line-height: 30px;
  margin-bottom: 20px;
`;

const InnerWrapper = styled(FlexContainer)`
  width: 100%;
  margin-top: 10px;

  .left {
    max-width: 350px;
    flex: 1;

    .desc {
      margin-bottom: 20px;
    }
    button {
      margin-bottom: 20px;
    }
  }

  .right {
    flex: 1;
    max-width: 210px;
  }
`;

const Wrapper = styled(FlexContainer)``;

export default function AddIntegrationModal({
  onClose,
  onConnect,
  integration,
  logo,
}: {
  integration: string;
  logo: ReactNode;
  onClose: () => void;
  onConnect: () => void;
}) {
  const navigate = useNavigate();
  const [connect, setConnect] = useState(false);
  const simpleConnect = ['ActiveCampaign', 'SendGrid'].includes(integration);

  const {
    onConnectActiveCampaign,
    connectingActiveCampaign,
    connectingSendGrid,
    onConnectSendGrid,
  } = useIntegration();

  const handleClick = () => {
    if (simpleConnect) {
      setConnect(true);
    } else {
      onClose();
      onConnect();
    }
  };

  return (
    <Wrapper
      stack
      justifyContent="flex-start"
      alignItems="flex-start"
      gap="20px"
    >
      {!connect ? (
        <>
          <InnerWrapper justifyContent="flex-start" gap="20px">
            <div className="left">
              <Title>
                Connect your <br /> {integration} account
              </Title>
              <p className="desc">
                Connect your {integration} account to send review requests to
                your contacts.
              </p>

              {/* <p className="desc">Click the button below to start.</p> */}

              <Button rounded onClick={handleClick}>
                Connect
              </Button>
            </div>
            <FlexContainer className="right" gap="20px">
              {logo}
            </FlexContainer>
          </InnerWrapper>

          <FlexContainer justifyContent="flex-end" style={{ width: '100%' }}>
            <Button rounded size="small" variant="tertiary" onClick={onClose}>
              Not now
            </Button>
          </FlexContainer>
        </>
      ) : (
        <>
          {integration === 'ActiveCampaign' && (
            <ConnectActiveCampaign
              onClose={onClose}
              onConnect={({ apiKey, apiUrl }) => {
                onConnectActiveCampaign({ apiKey, apiUrl });
                onClose();
                navigate('/invite-single-customer?source=activecampaign');
              }}
              isConnecting={connectingActiveCampaign === Loading.PENDING}
            />
          )}
          {integration === 'SendGrid' && (
            <ConnectSendGrid
              onClose={onClose}
              onConnect={(apiKey) => {
                onConnectSendGrid(apiKey);
                onClose();
                navigate('/invite-single-customer?source=sendgrid');
              }}
              isConnecting={connectingSendGrid === Loading.PENDING}
            />
          )}
        </>
      )}
    </Wrapper>
  );
}
