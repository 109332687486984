import dayjs from 'dayjs';
import styled from 'styled-components';
import FlexContainer from '../../../../../../../shared/components/layouts/flex-container';
import ReviewRating from '../../../../../../../shared/svgs/ReviewRating';
import getReviewerFullName from '../../../../../../../shared/utils/getReviewerFullName';
import { Review } from '../../../../../../manage-reviews/redux/reviews.slice';
import {
  BrandingSettings,
  EmbeddedWidgetSettings,
  TranslationType,
} from '../../../../../../widgetSettings/redux/widgetSettings.slice';

const Content = styled.div`
  padding: 20px;
`;

const Wrapper = styled.div<{ $widgetSettings: EmbeddedWidgetSettings }>`
  margin: auto;
  border-radius: ${({ $widgetSettings }) => $widgetSettings.borderRadius};
  .review-pic {
    width: 100%;
    cursor: pointer;
    -webkit-border-top-left-radius: ${({ $widgetSettings }) =>
      `calc(${$widgetSettings.borderRadius} / 2)`};
    -webkit-border-top-right-radius: ${({ $widgetSettings }) =>
      `calc(${$widgetSettings.borderRadius} / 2)`};
    -moz-border-radius-topleft: ${({ $widgetSettings }) =>
      `calc(${$widgetSettings.borderRadius} / 2)`};
    -moz-border-radius-topright: ${({ $widgetSettings }) =>
      `calc(${$widgetSettings.borderRadius} / 2)`};
    border-top-left-radius: ${({ $widgetSettings }) =>
      `calc(${$widgetSettings.borderRadius} / 2)`};
    border-top-right-radius: ${({ $widgetSettings }) =>
      `calc(${$widgetSettings.borderRadius} / 2)`};
  }
`;

const ReviewerName = styled.h4<{ color: string; fontFamily: string }>`
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 15px;
  color: ${({ color }) => color};
  font-family: ${({ fontFamily }) => fontFamily};
`;
const ReviewerDate = styled.span<{ color: string; fontFamily: string }>`
  font-size: 12px;
  color: ${({ color }) => color};
  display: block;
  font-family: ${({ fontFamily }) => fontFamily};
`;
const ReviewContent = styled.p<{ color: string; fontFamily: string }>`
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 25px;
  text-align: left;
  font-size: 14px;
  margin: 10px 0 0;
  text-align: left;
  color: ${({ color }) => color};
  font-family: ${({ fontFamily }) => fontFamily};
`;

const Title = styled.p`
  text-align: left;
  /* font-weight: bold; */
  font-size: 15px;
  margin: 0 0 10px;
  font-weight: 500;
`;

type Props = {
  review: Review;
  translation: TranslationType;
  branding: BrandingSettings;
  widgetSettings: EmbeddedWidgetSettings;
};

export default function TextReview(props: Props) {
  const { review, widgetSettings, branding, translation } = props;

  const { linkColor, dateColor, starColor, textColor, showReviewDate } =
    widgetSettings;
  const { verifiedReviewer } = translation;
  const {
    dateFormat = 'DD/MM/YYYY',
    showFullName = true,
    fontFamily,
    useMyWebsiteFontFamily,
  } = branding;
  const reviewerFullname = getReviewerFullName(
    showFullName,
    review.reviewer,
    verifiedReviewer
  );

  let reviewDate = review.reviewDate;

  try {
    reviewDate = dayjs(review.reviewDate).format(dateFormat);

    if (reviewDate === 'Invalid Date') {
      reviewDate = review.reviewDate;
    }
  } catch (error) {
    reviewDate = review.reviewDate;
  }

  return (
    <Wrapper $widgetSettings={widgetSettings}>
      {review.pictures[0] && (
        <img
          loading="lazy"
          className="review-pic"
          src={review.pictures[0]}
          alt=""
        />
      )}

      <Content>
        <FlexContainer justifyContent="flex-start" gap="10px">
          <ReviewerName
            color={linkColor}
            fontFamily={useMyWebsiteFontFamily ? '' : fontFamily}
          >
            {reviewerFullname}
          </ReviewerName>
        </FlexContainer>

        <FlexContainer justifyContent="space-between">
          <ReviewRating rating={review.rating} color={starColor} size={15} />
          {showReviewDate && (
            <ReviewerDate
              color={dateColor}
              fontFamily={useMyWebsiteFontFamily ? '' : fontFamily}
            >
              {reviewDate}
            </ReviewerDate>
          )}
        </FlexContainer>

        <ReviewContent
          color={textColor}
          fontFamily={useMyWebsiteFontFamily ? '' : fontFamily}
        >
          <Title>{review.title}</Title>
          <span dangerouslySetInnerHTML={{ __html: review.content }} />
        </ReviewContent>
      </Content>
    </Wrapper>
  );
}
