import millify from 'millify';
import { useEffect } from 'react';
import { Placeholder } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../../../../../shared/components/Button';
import FlexContainer from '../../../../../../../../shared/components/layouts/flex-container';
import { Loading } from '../../../../../../../../shared/types/review.types';
import { MailChimpList } from '../../hooks/useContacts';

const ItemWrapper = styled(FlexContainer)`
  border-bottom: 1px solid red;
  padding: 10px 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dedede;

  &:last-child {
    border: 0;
  }

  .name {
    font-weight: bold;
  }
  .total {
    font-size: 13px;
    color: #707070;
  }
`;

const Wrapper = styled.div`
  max-height: 380px;
  overflow-y: auto;
`;

const ListPlaceholder = () => {
  return <Placeholder.Paragraph rows={5} active />;
};

type Props = {
  loading: Loading;
  mailchimpLists: MailChimpList[];
  onSelectList: (listId: string) => void;
  getMailchimpLists: () => void;
};

export default function MailchimpLists({
  loading,
  onSelectList,
  mailchimpLists,
  getMailchimpLists,
}: Props) {
  useEffect(() => {
    getMailchimpLists();
  }, []);

  if (loading === Loading.PENDING) {
    return <ListPlaceholder />;
  }

  return (
    <Wrapper>
      {!mailchimpLists.length && <p>No audiences found.</p>}

      {mailchimpLists.map(
        ({ id, name, stats: { member_count: memberCount } }) => (
          <ItemWrapper
            key={id}
            role="presentation"
            onClick={() => onSelectList(id)}
          >
            <FlexContainer stack gap="5px" alignItems="flex-start">
              <span className="name">{name}</span>
              <span className="total">
                {millify(memberCount)} contact{memberCount > 1 ? 's' : ''}
              </span>
            </FlexContainer>
            <Button variant="secondary" size="small" rounded>
              Select
            </Button>
          </ItemWrapper>
        )
      )}
    </Wrapper>
  );
}
