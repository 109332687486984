import { useEffect, useState } from 'react';

export default function usePolling(callback: () => void) {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [intervalSeconds, setIntervalSeconds] = useState<number>(1);

  useEffect(() => {
    let intervalId: number | undefined;

    if (isEnabled) {
      intervalId = window.setInterval(() => {
        callback();
      }, intervalSeconds * 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isEnabled, intervalSeconds, callback]);

  return { setIsEnabled, setIntervalSeconds };
}
