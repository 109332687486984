import { Input } from 'rsuite';
import styled, { css } from 'styled-components';

export const StyledInput = styled(Input)<{ error: boolean }>`
  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;

export const ErroMessage = styled.span`
  display: block;
  color: red;
`;
