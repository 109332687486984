import { useState } from 'react';
import { FiBell, FiClock, FiX } from 'react-icons/fi';
import styled from 'styled-components';
import Button from '../../../shared/components/Button';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import BillingCycleSwitcher from '../../../shared/components/modal/Upgrade/BillingCycleSwitcher';
import ReviewsJetCrownLogo from '../../../shared/components/ReviewsJetCrownLogo';
import useUpgradeLink from '../../../shared/hooks/useUpgradeLink';
import {
  Loading,
  PlanCycle,
  SubscriptionPlan,
} from '../../../shared/types/review.types';
import USAGE from '../../../shared/utils/usage-limits';
import { AuthTitle } from '../../external/components';
import { PLAN_PRICES } from '../../review/utils/constants';
import { OnboardingStep } from '../redux/onboarding.slice';
import updateOnboarding from '../services/updateOnboarding';

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 40px;
  max-width: 1200px;
  width: 100%;
  max-height: 98%;
  overflow: auto;
  background-color: #fff;

  @media (max-width: 530px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0px;
  }

  .rs-picker {
    width: 100%;
    border-radius: 6px !important;
  }
`;

const StyledButton = styled(Button)`
  background-color: #000;
  color: #fff;
  border-color: #000;
  width: 100%;
`;

const FreePlanButton = styled(StyledButton)`
  background-color: #fff;
  color: #000;
  border-color: #000;
`;

const PlanOptions = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  margin: 25px 0;
  flex-wrap: wrap;
  animation: fadeIn 0.8s ease-out;
  align-items: flex-start;
`;

const HeaderSection = styled.div`
  text-align: center;
  margin-bottom: 40px;
  animation: fadeIn 0.6s ease-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const SubTitle = styled.p`
  color: #4a5568;
  font-size: 16px;
  margin: 7px 0 0 0;
  line-height: 1.6;
  max-width: 600px;
  margin: 12px auto 0;
`;

const TrialInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  margin: 22px 0;
  padding: 10px;
  background: linear-gradient(to right, #f8fafc, #f1f5f9);
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
  animation: slideUp 0.6s ease-out;

  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 20px;
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #4a5568;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 12px;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.8);
    transform: translateY(-2px);
  }

  svg {
    color: #10b981;
    font-size: 20px;
  }
`;

const PopularBadge = styled.span`
  background: #000;
  color: white;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 20px;
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
`;

const PlanCard = styled.div<{ isPopular?: boolean }>`
  width: 280px;
  padding: 32px 28px;
  border: 1px solid #e2e8f0;
  border-radius: 16px;
  background: #fff;
  transition: all 0.3s ease;
  position: relative;
  margin-top: ${(props) => (props.isPopular ? '-40px' : '0')};
  box-shadow: ${({ isPopular }) =>
    isPopular ? '0px 10px 15px -3px rgba(0,0,0,0.1)' : 'none'};

  &:hover {
    border-color: #000;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.06);
    transform: translateY(-4px);
  }

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const PlanName = styled.h3`
  margin: 0 0 8px 0;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Ubuntu', sans-serif;
  text-align: center;
  /* text-transform: uppercase; */
`;

const PriceContainer = styled.div`
  margin: 16px 0;
  font-size: 14px;
  text-align: center;
`;

const Price = styled.span`
  /* font-size: 24px;
  font-weight: 600; */
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 2.2rem;
  letter-spacing: -2px;
  display: inline-block;
`;

const BillingCycle = styled.span`
  color: #666;
  font-size: 14px;
`;

const FeatureTitle = styled.div`
  font-weight: 500;
  color: #1a202c;
  margin-bottom: 12px;
  font-size: 14px;
  margin-top: 15px;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 24px 0;

  li {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    color: #4a5568;
    font-size: 14px;
    padding: 4px 0;
    transition: all 0.2s ease;

    &:hover {
      transform: translateX(4px);
      color: #2d3748;
    }
  }
`;

const CheckIcon = styled.span`
  color: #10b981;
`;

const BillingCycleSwitcherWrapper = styled(FlexContainer)`
  justify-content: center;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const FooterNote = styled.div`
  text-align: center;
  margin-top: 32px;
  padding: 20px;
  color: #64748b;
  font-size: 14px;
  background: #f8fafc;
  border-radius: 12px;
  line-height: 1.6;
  animation: fadeIn 1s ease-out;

  b {
    color: #4a5568;
  }
`;

interface Props {
  onComplete: () => void;
}

export default function StartFreeTrial({ onComplete }: Props) {
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>(
    SubscriptionPlan.PRO
  );

  const [billingCycle, setBillingCycle] = useState<PlanCycle>(
    PlanCycle.MONTHLY
  );
  const { handleGenerateStripeUpgradeLink, generatingUpgradeLink } =
    useUpgradeLink();

  const handleUpgrade = async (plan: SubscriptionPlan) => {
    await updateOnboarding({
      nextStep: OnboardingStep.Complete,
    });

    setSelectedPlan(plan);
    handleGenerateStripeUpgradeLink(plan, billingCycle);
  };

  return (
    <Wrapper>
      <HeaderSection>
        <ReviewsJetCrownLogo />
        <AuthTitle marginBottom="12px">
          Final Step: Choose Your Plan 🚀
        </AuthTitle>
        <SubTitle>
          Try all premium features free for 14 days, no risk involved.
        </SubTitle>

        <TrialInfo>
          <InfoItem>
            <FiClock size={20} />
            14-day free trial
          </InfoItem>

          <InfoItem>
            <FiBell size={20} />
            Trial end reminder
          </InfoItem>
          <InfoItem>
            <FiX size={20} />
            Cancel anytime
          </InfoItem>
        </TrialInfo>
      </HeaderSection>

      <BillingCycleSwitcherWrapper justifyContent="center">
        <BillingCycleSwitcher
          cycle={billingCycle}
          switchCycle={setBillingCycle}
        />
      </BillingCycleSwitcherWrapper>

      <PlanOptions>
        <PlanCard>
          <PlanName>Basic</PlanName>
          <PriceContainer>
            <Price>
              $
              {billingCycle === PlanCycle.MONTHLY
                ? PLAN_PRICES.FREE.monthly
                : PLAN_PRICES.FREE.anually}
            </Price>
          </PriceContainer>
          <FreePlanButton rounded onClick={onComplete}>
            Continue Free
          </FreePlanButton>
          <FeatureTitle>Free forever</FeatureTitle>
          <FeatureList>
            <li>
              <CheckIcon>✓</CheckIcon> Review to image
            </li>
            <li>
              <CheckIcon>✓</CheckIcon> {USAGE.free.reviews} text reviews
            </li>
            <li>
              <CheckIcon>✓</CheckIcon> {USAGE.free.videoReviews} video reviews
            </li>
            <li>
              <CheckIcon>✓</CheckIcon> 50 review requests /mo
            </li>
          </FeatureList>
        </PlanCard>

        <PlanCard
          isPopular
          style={{
            zIndex: 1,
          }}
        >
          <PopularBadge>MOST POPULAR</PopularBadge>
          <PlanName>Pro Plan</PlanName>
          <PriceContainer>
            <Price>
              $
              {billingCycle === PlanCycle.MONTHLY
                ? PLAN_PRICES.PRO.monthly
                : PLAN_PRICES.PRO.anually}
            </Price>
            <BillingCycle>
              /{billingCycle === PlanCycle.MONTHLY ? 'month' : 'year'}
            </BillingCycle>
          </PriceContainer>
          <StyledButton
            rounded
            onClick={() => handleUpgrade(SubscriptionPlan.PRO)}
            disabled={generatingUpgradeLink === Loading.PENDING}
          >
            {generatingUpgradeLink === Loading.PENDING &&
            selectedPlan === SubscriptionPlan.PRO ? (
              <FlexContainer
                justifyContent="center"
                alignItems="center"
                gap="8px"
              >
                <b>Please wait...</b>
              </FlexContainer>
            ) : (
              <b>Start Pro Trial</b>
            )}
          </StyledButton>
          <FeatureTitle>Everything in Free, plus:</FeatureTitle>
          <FeatureList>
            <li>
              <CheckIcon>✓</CheckIcon> <b>{USAGE.professional.reviews}</b> text
              reviews
            </li>
            <li>
              <CheckIcon>✓</CheckIcon>{' '}
              <b>{USAGE.professional.reviewsRequests}</b> review requests /mo
            </li>
            <li>
              <CheckIcon>✓</CheckIcon> Auto-publish reviews
            </li>
            <li>
              <CheckIcon>✓</CheckIcon> Respond to reviews
            </li>
            <li>
              <CheckIcon>✓</CheckIcon> Full widget customization
            </li>
          </FeatureList>
        </PlanCard>

        <PlanCard>
          <PlanName>Advanced Plan</PlanName>
          <PriceContainer>
            <Price>
              $
              {billingCycle === PlanCycle.MONTHLY
                ? PLAN_PRICES.ADVANCED.monthly
                : PLAN_PRICES.ADVANCED.anually}
            </Price>
            <BillingCycle>
              /{billingCycle === PlanCycle.MONTHLY ? 'month' : 'year'}
            </BillingCycle>
          </PriceContainer>
          <StyledButton
            rounded
            onClick={() => handleUpgrade(SubscriptionPlan.ADVANCED)}
            disabled={generatingUpgradeLink === Loading.PENDING}
          >
            {generatingUpgradeLink === Loading.PENDING &&
            selectedPlan === SubscriptionPlan.ADVANCED ? (
              <FlexContainer
                justifyContent="center"
                alignItems="center"
                gap="8px"
              >
                <b>Please wait...</b>
              </FlexContainer>
            ) : (
              <FlexContainer justifyContent="center" gap="5px">
                <span>Start Advanced Trial</span>🚀
              </FlexContainer>
            )}
          </StyledButton>
          <FeatureTitle>Everything in Pro, plus:</FeatureTitle>
          <FeatureList>
            <li>
              <CheckIcon>✓</CheckIcon>{' '}
              <b>
                {USAGE.advanced.reviews === -1
                  ? 'Unlimited'
                  : USAGE.advanced.reviews}
              </b>{' '}
              text reviews
            </li>
            <li>
              <CheckIcon>✓</CheckIcon> <b>{USAGE.advanced.videoReviews}</b>
              video reviews
            </li>
            <li>
              <CheckIcon>✓</CheckIcon> <b>{USAGE.advanced.reviewsRequests}</b>{' '}
              review requests /mo
            </li>
            <li>
              <CheckIcon>✓</CheckIcon> Google Rich Snippets
            </li>
          </FeatureList>
        </PlanCard>
      </PlanOptions>

      <FooterNote>
        <b>Peace of mind:</b> We'll send you a friendly reminder 3 days before
        your trial ends.
        <br />
        No surprises, no commitments - cancel with one click at any time.
      </FooterNote>
    </Wrapper>
  );
}
