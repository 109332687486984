import { Icon } from '@rsuite/icons';
import { useEffect } from 'react';
import Confetti from 'react-confetti';
import { hotjar } from 'react-hotjar';
import { useTranslation } from 'react-i18next';
import { FaSpinner } from 'react-icons/fa';
import { IoMdHelpCircleOutline } from 'react-icons/io';
import { batch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalAudioPlayer } from 'react-use-audio-player';
import { Button } from 'rsuite';
import styled from 'styled-components';
import { fetchReviews } from './features/manage-reviews/redux/action';
import FeedbackDrawer from './features/review/components/feedback-drawer';
import {
  CHANGE_LOG_LINK,
  HIDE_CHANGE_LOG,
} from './features/review/utils/constants';
import ConnectAirbnb from './features/socialReviews/components/Modals/ConnectAirbnb';
import ConnectAmazonProduct from './features/socialReviews/components/Modals/ConnectAmazonProduct';
import ConnectApple from './features/socialReviews/components/Modals/ConnectApple';
import ConnectCapterra from './features/socialReviews/components/Modals/ConnectCapterra';
import DisplaySiteIdPage from './features/socialReviews/components/Modals/ConnectEtsy/DisplaySiteIdPage';
import ConnectGoogle from './features/socialReviews/components/Modals/ConnectGoogle';
import ConnectProducthunt from './features/socialReviews/components/Modals/ConnectProducthunt';
import ConnectTripadvisor from './features/socialReviews/components/Modals/ConnectTripadvisor';
import ConnectTrustpilot from './features/socialReviews/components/Modals/ConnectTrustpilot';
import ConnectYelp from './features/socialReviews/components/Modals/ConnectYelp';
import {
  toggleAirbnbReviewModal,
  toggleAliExpressReviewModal,
  toggleAmazonReviewModal,
  toggleAppleReviewModal,
  toggleCapterraReviewModal,
  toggleEtsyReviewModal,
  toggleGoogleReviewModal,
  toggleProducthuntReviewModal,
  toggleTourradarReviewModal,
  toggleTripadvisorReviewModal,
  toggleTrustpilotReviewModal,
  toggleYelpReviewModal,
} from './features/socialReviews/redux/social-reviews.slice';
import {
  CelebrationType,
  accountSelector,
  setCelebration,
  toggleFeedbackDrawer,
} from './features/userData/redux/userData.slice';
import AppRoutes from './routes';
import OpenNewTab from './shared/components/OpenNewTab';
import PreloadFonts from './shared/components/PreloadFonts';
import ThankYouModal from './shared/components/ThankYouModal';
import WhatIsNew from './shared/components/WhatIsNew';
import FlexContainer from './shared/components/layouts/flex-container';
import AppModal from './shared/components/modal';
import Upgrade from './shared/components/modal/Upgrade';
import useModal from './shared/components/modal/useModal';
import { MAIN_WRAPPER } from './shared/constants/DataQA';
import useThirdPartyModals from './shared/hooks/useThirdPartyModals';
import useUpgradeLink from './shared/hooks/useUpgradeLink';
import { Loading, ReviewerPlatform } from './shared/types/review.types';
import localStorage from './shared/utils/localStorage';
import { useAppDispatch, useAppSelector } from './store';
import { toggleChangelogModal, toggleUpgradeModal } from './store/app.slice';

/* eslint no-console: "warn" */

const MainWrapper = styled.div`
  height: 100vh;
`;
const ConfettiWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  /* z-index: 9999; */
  top: 0;
  cursor: pointer;
`;

const HelpIconWrapper = styled(FlexContainer)`
  color: #fff;
  background-color: #030303;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50px;
  cursor: pointer;
  z-index: 1049;
  padding: 10px 15px;

  span {
    font-weight: bold;
  }
`;

const DiscountMessage = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #333;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  font-size: 13px;

  @media (max-width: 1050px) {
    text-align: center;
    max-width: 350px;
  }
`;

const App = () => {
  const navigate = useNavigate();
  const { selectedPlan, upgradePlanId, showChangelogModal } = useAppSelector(
    (state) => state.app
  );
  const dispatch = useAppDispatch();
  const { loading, account, openFeedback, celebrate } =
    useAppSelector(accountSelector);
  const { i18n } = useTranslation('common');
  const { selectedSite, source, ownerEmail } = account ?? {};
  const { _id: siteId, plan, planCycle, platform } = selectedSite ?? {};
  const { load, stop } = useGlobalAudioPlayer();
  const { handleCloseModal, handleOpenModal, showModal } = useModal();
  const {
    googleReviewsModalOpen,
    yelpReviewsModalOpen,
    appleReviewsModalOpen,
    producthuntReviewsModalOpen,
    trustpilotReviewsModalOpen,
    capterraReviewsModalOpen,
    yelpModalSize,
    googleModalSize,
    appleModalSize,
    producthuntModalSize,
    trustpilotModalSize,
    capterraModalSize,
    tripadvisorReviewsModalOpen,
    airbnbModalSize,
    airbnbReviewsModalOpen,
    etsyModalSize,
    etsyReviewsModalOpen,
    setYelpModalSize,
    setGoogleModalSize,
    setAppleModalSize,
    setProducthuntModalSize,
    setTrustpilotModalSize,
    setCapterraModalSize,
    tripadvisorModalSize,
    setTripadvisorModalSize,
    setAirbnbModalSize,
    amazonModalSize,
    setAmazonModalSize,
    amazonReviewModalOpen,
    aliExpressReviewsModalOpen,
    aliExpressModalSize,
    tourradarReviewsModalOpen,
  } = useThirdPartyModals();

  const { generatingUpgradeLink, handleUpgrade } = useUpgradeLink();

  const location = useLocation();
  const isOnboarding = location.pathname.includes('/onboarding');

  useEffect(() => {
    i18n.changeLanguage(account?.locale ?? 'en');
  }, [account]);

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === 'production') {
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === 'production') {
      hotjar.initialize({ id: 3265162, sv: 6 });

      if (hotjar.initialized()) {
        if (siteId) {
          hotjar.identify(siteId, {
            email: ownerEmail,
            plan,
            planCycle,
          });
        }
      }
    }
  }, [siteId, plan, planCycle, ownerEmail]);

  useEffect(() => {
    if (celebrate !== null) {
      load('./sounds/clap.wav', {
        autoplay: true,
        initialVolume: 0.11,
      });
    }
  }, [load, celebrate]);

  useEffect(() => {
    if (
      loading === Loading.SUCCESS &&
      celebrate === CelebrationType.CONFETTI_WITH_A_WELCOME_MESSAGE
    ) {
      handleOpenModal();
    }
  }, [celebrate, loading]);

  return (
    <>
      {/* {plan === SubscriptionPlan.FREE && (
        <>
          {platform === ReviewsJetPlatform.WIX && (
            <a
              href="https://www.wix.com/apps/upgrade/05fa010a-3645-4572-a479-5645b485a0ad"
              target="_blank"
            >
              <DiscountMessage
                role="presentation"
                onClick={() => showInfoToast('Copied to clipboard')}
              >
                The November Sale is here! Grow your reviews and credibility
                with 50% off at checkout!
              </DiscountMessage>
            </a>
          )}
          {platform === ReviewsJetPlatform.OTHERS && (
            <CopyToClipboard text="NOVEMBERSALE">
              <DiscountMessage
                role="presentation"
                onClick={() => showInfoToast('Copied to clipboard')}
              >
                Use code <b>NOVEMBERSALE</b> at checkout for 50% off!
              </DiscountMessage>
            </CopyToClipboard>
          )}
        </>
      )} */}

      <MainWrapper className="main-app-wrapper" data-qa={MAIN_WRAPPER}>
        <AppRoutes />
        <FeedbackDrawer
          openDrawer={openFeedback}
          onDrawerClose={() => dispatch(toggleFeedbackDrawer())}
        />
        {siteId && (
          <AppModal
            size="lg"
            backdrop="static"
            shouldOpenModal={!!selectedPlan}
            onModalClose={() => dispatch(toggleUpgradeModal(null))}
            body={<Upgrade />}
            footer={
              <FlexContainer justifyContent="space-between">
                <span>
                  Click{' '}
                  <span
                    onClick={() => {
                      dispatch(toggleUpgradeModal(null));
                      navigate('/settings?tab=account');
                    }}
                    style={{ cursor: 'pointer', color: '#1675e0' }}
                    role="presentation"
                  >
                    here
                  </span>{' '}
                  to manage your subscription.
                </span>
                <FlexContainer gap="10px" justifyContent="flex-end">
                  {generatingUpgradeLink !== Loading.PENDING && (
                    <Button
                      appearance="subtle"
                      onClick={() => dispatch(toggleUpgradeModal(null))}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    appearance={
                      generatingUpgradeLink === Loading.PENDING
                        ? 'subtle'
                        : 'ghost'
                    }
                    color="blue"
                    onClick={handleUpgrade}
                    disabled={
                      generatingUpgradeLink === Loading.PENDING ||
                      upgradePlanId === 'basic'
                    }
                  >
                    <FlexContainer gap="5px">
                      {generatingUpgradeLink === Loading.PENDING ? (
                        <>
                          <Icon as={FaSpinner} pulse />
                          <span>Please wait...</span>
                        </>
                      ) : (
                        <>
                          <span>Continue</span> <OpenNewTab />
                        </>
                      )}
                    </FlexContainer>
                  </Button>
                </FlexContainer>
              </FlexContainer>
            }
          />
        )}
        <AppModal
          size={googleModalSize}
          onModalClose={() => dispatch(toggleGoogleReviewModal())}
          shouldOpenModal={googleReviewsModalOpen}
          body={
            <ConnectGoogle
              onConnected={() => setGoogleModalSize('xs')}
              onComplete={() => {
                dispatch(
                  fetchReviews({
                    payload: { pageNumber: 1 },
                    showPreloader: true,
                  })
                );
                dispatch(toggleGoogleReviewModal());
                navigate('/manage-reviews');
              }}
            />
          }
        />
        <AppModal
          size={yelpModalSize}
          onModalClose={() => dispatch(toggleYelpReviewModal())}
          shouldOpenModal={yelpReviewsModalOpen}
          body={
            <ConnectYelp
              onConnected={() => setYelpModalSize('xs')}
              closeModal={() => dispatch(toggleYelpReviewModal())}
              onComplete={() => {
                dispatch(
                  fetchReviews({
                    payload: { pageNumber: 1 },
                    showPreloader: true,
                  })
                );
                navigate('/manage-reviews');
              }}
            />
          }
        />
        <AppModal
          size={appleModalSize}
          onModalClose={() => dispatch(toggleAppleReviewModal())}
          shouldOpenModal={appleReviewsModalOpen}
          body={
            <ConnectApple
              onConnected={() => setAppleModalSize('xs')}
              onComplete={() => {
                setAppleModalSize('sm');
                dispatch(
                  fetchReviews({
                    payload: { pageNumber: 1 },
                    showPreloader: true,
                  })
                );
                dispatch(toggleAppleReviewModal());
                navigate('/manage-reviews');
              }}
            />
          }
        />
        <AppModal
          size={producthuntModalSize}
          onModalClose={() => dispatch(toggleProducthuntReviewModal())}
          shouldOpenModal={producthuntReviewsModalOpen}
          body={
            <ConnectProducthunt
              onConnected={() => setProducthuntModalSize('xs')}
              onComplete={() => {
                setProducthuntModalSize('sm');
                dispatch(toggleProducthuntReviewModal());
                dispatch(
                  fetchReviews({
                    payload: { pageNumber: 1 },
                    showPreloader: true,
                  })
                );
                navigate('/manage-reviews');
              }}
            />
          }
        />
        <AppModal
          size={trustpilotModalSize}
          onModalClose={() => dispatch(toggleTrustpilotReviewModal())}
          shouldOpenModal={trustpilotReviewsModalOpen}
          body={
            <ConnectTrustpilot
              onConnected={() => setTrustpilotModalSize('xs')}
              onComplete={() => {
                setTrustpilotModalSize('sm');
                dispatch(toggleTrustpilotReviewModal());
                dispatch(
                  fetchReviews({
                    payload: { pageNumber: 1 },
                    showPreloader: true,
                  })
                );
                navigate('/manage-reviews');
              }}
            />
          }
        />
        <AppModal
          size={tripadvisorModalSize}
          onModalClose={() => dispatch(toggleTripadvisorReviewModal())}
          shouldOpenModal={tripadvisorReviewsModalOpen}
          body={
            <ConnectTripadvisor
              onConnected={() => setTripadvisorModalSize('xs')}
              onComplete={() => {
                setTripadvisorModalSize('sm');
                dispatch(toggleTripadvisorReviewModal());
                dispatch(
                  fetchReviews({
                    payload: { pageNumber: 1 },
                    showPreloader: true,
                  })
                );
                navigate('/manage-reviews');
              }}
            />
          }
        />
        <AppModal
          size={capterraModalSize}
          onModalClose={() => dispatch(toggleCapterraReviewModal())}
          shouldOpenModal={capterraReviewsModalOpen}
          body={
            <ConnectCapterra
              onConnected={() => setCapterraModalSize('xs')}
              onComplete={() => {
                setCapterraModalSize('sm');
                dispatch(toggleCapterraReviewModal());
                dispatch(
                  fetchReviews({
                    payload: { pageNumber: 1 },
                    showPreloader: true,
                  })
                );
                navigate('/manage-reviews');
              }}
            />
          }
        />
        <AppModal
          size={airbnbModalSize}
          onModalClose={() => dispatch(toggleAirbnbReviewModal())}
          shouldOpenModal={airbnbReviewsModalOpen}
          body={
            <ConnectAirbnb
              onConnected={() => setAirbnbModalSize('xs')}
              onComplete={() => {
                setAirbnbModalSize('sm');
                dispatch(toggleAirbnbReviewModal());
                dispatch(
                  fetchReviews({
                    payload: { pageNumber: 1 },
                    showPreloader: true,
                  })
                );
                navigate('/manage-reviews');
              }}
            />
          }
        />
        <AppModal
          size={amazonModalSize}
          onModalClose={() => {
            batch(() => {
              dispatch(toggleAmazonReviewModal());
              dispatch(
                fetchReviews({
                  payload: { pageNumber: 1 },
                  showPreloader: false,
                })
              );
            });
          }}
          shouldOpenModal={amazonReviewModalOpen}
          body={
            <ConnectAmazonProduct
              onConnected={() => setAmazonModalSize('xs')}
              onComplete={() => {
                setAmazonModalSize('sm');
                dispatch(toggleAmazonReviewModal());
                dispatch(
                  fetchReviews({
                    payload: { pageNumber: 1 },
                    showPreloader: true,
                  })
                );
                navigate('/manage-reviews');
              }}
            />
          }
        />
        <AppModal
          size="sm"
          onModalClose={() => dispatch(toggleEtsyReviewModal())}
          shouldOpenModal={etsyReviewsModalOpen}
          body={<DisplaySiteIdPage platform={ReviewerPlatform.ETSY} />}
        />
        <AppModal
          size="sm"
          onModalClose={() => dispatch(toggleAliExpressReviewModal())}
          shouldOpenModal={aliExpressReviewsModalOpen}
          body={<DisplaySiteIdPage platform={ReviewerPlatform.ALI_EXPRESS} />}
        />
        <AppModal
          size="sm"
          onModalClose={() => dispatch(toggleTourradarReviewModal())}
          shouldOpenModal={tourradarReviewsModalOpen}
          body={<DisplaySiteIdPage platform={ReviewerPlatform.TOUR_RADAR} />}
        />
      </MainWrapper>
      <AppModal
        size="sm"
        onModalClose={() => {
          stop();
          handleCloseModal();
          dispatch(setCelebration(null));
        }}
        shouldOpenModal={showModal}
        body={
          <ThankYouModal
            source={source}
            closeModal={() => {
              stop();
              handleCloseModal();
              dispatch(setCelebration(null));
            }}
          />
        }
      />

      {celebrate !== null && (
        <ConfettiWrapper
          role="presentation"
          onClick={() => {
            stop();
            dispatch(setCelebration(null));
          }}
        >
          <FlexContainer style={{ height: '100vh' }}>
            <Confetti
              gravity={0.25}
              // width="1000"
              // height="1000"
              style={{ margin: '0 auto' }}
            />
          </FlexContainer>
        </ConfettiWrapper>
      )}

      <AppModal
        size="sm"
        title="What's New?"
        onModalClose={() => {
          dispatch(toggleChangelogModal(false));
          localStorage.set(HIDE_CHANGE_LOG, true);
        }}
        shouldOpenModal={showChangelogModal}
        classNames="dark"
        body={<WhatIsNew />}
        footer={
          <Button
            size="sm"
            appearance="link"
            style={{ color: '#fff' }}
            onClick={() => window.open(CHANGE_LOG_LINK, '_blank')}
          >
            View all changes
          </Button>
        }
      />

      {ownerEmail && !isOnboarding && (
        <HelpIconWrapper
          gap="5px"
          onClick={() => dispatch(toggleFeedbackDrawer())}
        >
          <IoMdHelpCircleOutline size={23} />
          <span>Help</span>
        </HelpIconWrapper>
      )}
      <PreloadFonts />
    </>
  );
};

export default App;
