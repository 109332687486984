import styled from 'styled-components';
import FlexContainer from '../../../../../../../../shared/components/layouts/flex-container';
import { ContactItem } from '../../../../../../../emails/redux/emails.slice';

const Wrapper = styled(FlexContainer)`
  width: 100%;
  border: 1px solid #dedede;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;

  .email {
    color: #777;
  }
  .email,
  .order-number {
    font-size: 12px;
  }
`;

type Props = { contact: ContactItem };

export default function SelectedContact(props: Props) {
  const {
    contact: { firstName, lastName, email, orderNumber },
  } = props;

  return (
    <Wrapper
      stack
      justifyContent="flex-start"
      alignItems="flex-start"
      gap="5px"
    >
      <div className="name">{`${firstName} ${lastName}`}</div>
      <div className="email">{email}</div>
      {orderNumber && <div className="order-number">Order: #{orderNumber}</div>}
    </Wrapper>
  );
}
