import Papa from 'papaparse';
import { createContext, useState } from 'react';
import { FaFileAlt } from 'react-icons/fa';
import { FaArrowRightLong } from 'react-icons/fa6';
import { IoMdHelpBuoy } from 'react-icons/io';
import { Button as RsButton, Uploader } from 'rsuite';
import { FileType } from 'rsuite/esm/Uploader';
import styled from 'styled-components';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import { REVIEWS_TEMPLATE_FILE } from '../../../../../shared/utils/constants';
import { ImportReviewsContextType, Step } from '../../../../csv-import';
import useCsvImport from '../../../../csv-import/hooks/useCsvImport';
import ReviewCSVUpload from './ProcessCSV/ReviewCSVUpload';

/* eslint no-console: "warn" */

const SubTitle = styled(FlexContainer)`
  margin-top: 10px;
  margin-bottom: 20px;
  button {
    padding: 0;
    margin-left: 3px;
    margin-right: 3px;
  }
`;

const Wrapper = styled.div`
  .rs-uploader-draggable
    .rs-uploader-trigger-customize
    .rs-uploader-trigger-btn {
    background-color: #fcfcfc;
  }
`;

const UploaderWrapper = styled(FlexContainer)`
  display: flex !important;
  height: 200px;
  margin-bottom: 20px;
  button {
    padding: 0;
    margin-left: 3px;
    margin-right: 3px;
  }
`;

export const ImportReviewsContext =
  createContext<ImportReviewsContextType | null>(null);

export default function ImportWithCSV() {
  const {
    step,
    setStep,
    parsedFile,
    setParsedFile,
    headers,
    handleMapping,
    setHeaders,
    mappedFields,
    mappedFieldsList,
    setMappedFieldsList,
    unmappedFields,
    setUnmappedFields,
    handleToggleSkipColumn,
    handleSkipUnmappedFields,
    uploadedFile,
    setMapping,
    setUploadedFile,
  } = useCsvImport();

  const downloadTemplate = () => {
    window.open(REVIEWS_TEMPLATE_FILE, '_blank');
  };
  const [open, setOpen] = useState(false);

  const handleUpload = (fileLists: FileType[]) => {
    const uploaded = fileLists.pop();

    if (uploaded?.blobFile) {
      Papa.parse(uploaded.blobFile, {
        header: true,
        complete: function (results) {
          const parsedData = results.data as Record<string, string>[];

          setUploadedFile(uploaded);
          setMapping(parsedData);
          setParsedFile(parsedData);
        },
      });
    }
  };

  return (
    <ImportReviewsContext.Provider
      value={{
        headers,
        parsedFile,
        mappedFields,
        handleMapping,
        unmappedFields,
        mappedFieldsList,
        handleToggleSkipColumn,
        handleSkipUnmappedFields,
      }}
    >
      <Wrapper>
        <SubTitle justifyContent="flex-start" alignItems="center" gap="5px">
          <IoMdHelpBuoy />
          <FlexContainer justifyContent="flex-start">
            Need Help?
            <RsButton appearance="link" onClick={downloadTemplate}>
              Download our CSV template
            </RsButton>
            to make sure your CSV is formatted correctly.
          </FlexContainer>
        </SubTitle>

        <Uploader
          action=""
          draggable
          removable
          multiple={false}
          autoUpload={false}
          fileListVisible={false}
          onChange={handleUpload}
          shouldUpload={() => false}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        >
          <UploaderWrapper stack justifyContent="center" alignItems="center">
            <FlexContainer>
              {uploadedFile ? (
                <FlexContainer gap="5px">
                  <FaFileAlt size={16} />
                  <FlexContainer gap="5px">
                    <span>{uploadedFile?.name}</span>
                    <span>-</span>
                    <RsButton appearance="link">Change</RsButton>
                  </FlexContainer>
                </FlexContainer>
              ) : (
                <>
                  <span>Drag and drop your CSV file here or</span>
                  <RsButton appearance="link">
                    select a CSV file to upload.
                  </RsButton>
                </>
              )}
            </FlexContainer>
          </UploaderWrapper>
        </Uploader>

        {uploadedFile && (
          <FlexContainer justifyContent="flex-end">
            <Button
              variant="primary"
              rounded
              size="small"
              onClick={() => {
                setOpen(true);
                setStep(Step.MAP_FIELDS);
              }}
            >
              <FlexContainer gap="10px">
                Review CSV upload
                <FaArrowRightLong />
              </FlexContainer>
            </Button>
          </FlexContainer>
        )}
      </Wrapper>
      <ReviewCSVUpload
        onClose={() => setOpen(false)}
        open={open}
        step={step}
        setStep={setStep}
      />
    </ImportReviewsContext.Provider>
  );
}
