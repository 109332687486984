import axios from 'axios';
import mem from 'mem';
import localStorage from '../shared/utils/localStorage';
import removeTrailingSlash from '../shared/utils/removeTrailingSlash';
import setSession from '../shared/utils/setSession';

const refreshTokenFn = async () => {
  const session = localStorage.get('session');

  try {
    const response = await axios.post('/app/refresh-token', {
      refreshToken: session?.jwtRefreshToken,
    });

    const { data } = response;

    if (!data?.accessToken) {
      localStorage.remove('session');
      window.location.replace(
        `${removeTrailingSlash(process.env.REACT_APP_DASHBOARD_URL!)}/login`
      );
    } else {
      const { accessToken: jwtAccessToken } = data;
      setSession({ jwtAccessToken, jwtRefreshToken: session?.jwtRefreshToken });
    }

    return data.accessToken;
  } catch (error) {
    localStorage.remove('session');
    window.location.replace(
      `${removeTrailingSlash(process.env.REACT_APP_DASHBOARD_URL!)}/login`
    );
  }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});
