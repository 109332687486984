import { FaEdit } from 'react-icons/fa';
import styled from 'styled-components';
import Button from '../../../../../../../shared/components/Button';
import FlexContainer from '../../../../../../../shared/components/layouts/flex-container';
import AppModal from '../../../../../../../shared/components/modal';
import useModal from '../../../../../../../shared/components/modal/useModal';
import TranslationForm from './TranslationForm';

const Wrapper = styled.div`
  padding: 10px;
  text-align: center;
  button {
    margin-top: 15px;
  }
`;

export default function Translations() {
  const { showModal, handleOpenModal, handleCloseModal } = useModal();

  return (
    <>
      <Wrapper>
        <p>Click below to update translations:</p>

        <Button
          variant="primary"
          onClick={handleOpenModal}
          rounded
          size="small"
        >
          <FlexContainer gap="5px">
            <FaEdit />
            Update translations
          </FlexContainer>
        </Button>
      </Wrapper>

      <AppModal
        size="sm"
        onModalClose={handleCloseModal}
        shouldOpenModal={showModal}
        body={<TranslationForm onClose={handleCloseModal} />}
      />
    </>
  );
}
