import { useToaster } from 'rsuite';
import AppNotification from '../components/notifications';

export const useNotification = () => {
  const toaster = useToaster();

  const showSuccessToast = (message: string | JSX.Element) => {
    toaster.push(
      <AppNotification
        className="custom-toast"
        type="success"
        message={message}
      />,
      {
        placement: 'bottomCenter',
        duration: 4000,
      }
    );
  };

  const showErrorToast = (message: string | JSX.Element) => {
    toaster.push(
      <AppNotification
        className="custom-toast"
        type="error"
        message={message}
      />,
      {
        placement: 'bottomCenter',
        duration: 4000,
      }
    );
  };

  const showWarningToast = (message: string | JSX.Element) => {
    toaster.push(
      <AppNotification
        className="custom-toast"
        type="warning"
        message={message}
      />,
      {
        placement: 'bottomCenter',
        duration: 4000,
      }
    );
  };

  const showInfoToast = (message: string | JSX.Element) => {
    toaster.push(
      <AppNotification
        className="custom-toast"
        type="info"
        message={message}
      />,
      {
        placement: 'bottomCenter',
        duration: 4000,
      }
    );
  };

  return {
    showSuccessToast,
    showErrorToast,
    showWarningToast,
    showInfoToast,
  };
};
