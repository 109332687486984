import { MappedField, ReservedFields } from '..';

export const clearPropertyValue = (
  mappedFields: MappedField,
  keyInMappedField?: string
) => {
  let updatedMappedFields = mappedFields;

  if (keyInMappedField === 'product_id') {
    updatedMappedFields = {
      ...updatedMappedFields,
      product_id: { value: '', skipped: false },
    };
  }

  if (keyInMappedField === 'reviewer_email') {
    updatedMappedFields = {
      ...updatedMappedFields,
      reviewer_email: { value: '', skipped: false },
    };
  }

  if (keyInMappedField === 'helpful_count') {
    updatedMappedFields = {
      ...updatedMappedFields,
      helpful_count: { value: '', skipped: false },
    };
  }

  if (keyInMappedField === 'rating') {
    updatedMappedFields = {
      ...updatedMappedFields,
      rating: { value: '', skipped: false },
    };
  }

  if (keyInMappedField === 'review_date') {
    updatedMappedFields = {
      ...updatedMappedFields,
      review_date: { value: '', skipped: false },
    };
  }

  if (keyInMappedField === 'review_text') {
    updatedMappedFields = {
      ...updatedMappedFields,
      review_text: { value: '', skipped: false },
    };
  }

  if (keyInMappedField === 'reviewer_name') {
    updatedMappedFields = {
      ...updatedMappedFields,
      reviewer_name: { value: '', skipped: false },
    };
  }

  if (keyInMappedField === 'your_review_reply') {
    updatedMappedFields = {
      ...updatedMappedFields,
      your_review_reply: { value: '', skipped: false },
    };
  }

  if (keyInMappedField === 'title') {
    updatedMappedFields = {
      ...updatedMappedFields,
      title: { value: '', skipped: false },
    };
  }

  if (keyInMappedField === 'reviewer_location_country') {
    updatedMappedFields = {
      ...updatedMappedFields,
      reviewer_location_country: { value: '', skipped: false },
    };
  }

  if (keyInMappedField === 'reviewer_location_city') {
    updatedMappedFields = {
      ...updatedMappedFields,
      reviewer_location_city: { value: '', skipped: false },
    };
  }

  return updatedMappedFields;
};

export const updatePropertyValue = (
  mappedFields: MappedField,
  mapTo: ReservedFields,
  field: string
) => {
  let updatedMappedFields = mappedFields;

  if (mapTo === 'helpful_count') {
    updatedMappedFields = {
      ...mappedFields,
      helpful_count: { value: field, skipped: false },
    };
  }

  if (mapTo === 'rating') {
    updatedMappedFields = {
      ...mappedFields,
      rating: { value: field, skipped: false },
    };
  }

  if (mapTo === 'review_date') {
    updatedMappedFields = {
      ...mappedFields,
      review_date: { value: field, skipped: false },
    };
  }

  if (mapTo === 'review_text') {
    updatedMappedFields = {
      ...mappedFields,
      review_text: { value: field, skipped: false },
    };
  }

  if (mapTo === 'reviewer_name') {
    updatedMappedFields = {
      ...mappedFields,
      reviewer_name: { value: field, skipped: false },
    };
  }

  if (mapTo === 'your_review_reply') {
    updatedMappedFields = {
      ...mappedFields,
      your_review_reply: { value: field, skipped: false },
    };
  }

  if (mapTo === 'title') {
    updatedMappedFields = {
      ...mappedFields,
      title: { value: field, skipped: false },
    };
  }

  if (mapTo === 'reviewer_email') {
    updatedMappedFields = {
      ...mappedFields,
      reviewer_email: { value: field, skipped: false },
    };
  }

  if (mapTo === 'reviewer_location_country') {
    updatedMappedFields = {
      ...mappedFields,
      reviewer_location_country: { value: field, skipped: false },
    };
  }

  if (mapTo === 'reviewer_location_city') {
    updatedMappedFields = {
      ...mappedFields,
      reviewer_location_city: { value: field, skipped: false },
    };
  }

  return updatedMappedFields;
};
