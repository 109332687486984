import { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { FiBell, FiCreditCard, FiX } from 'react-icons/fi';
import { GoDash } from 'react-icons/go';
import styled from 'styled-components';
import { PLAN_PRICES } from '../../../../features/review/utils/constants';
import { accountSelector } from '../../../../features/userData/redux/userData.slice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setBillingData } from '../../../../store/app.slice';
import { PlanCycle, SubscriptionPlan } from '../../../types/review.types';
import USAGE from '../../../utils/usage-limits';
import FlexContainer from '../../layouts/flex-container';
import BillingCycleSwitcher from './BillingCycleSwitcher';
import PlanCard from './PlanCard';

const Wrapper = styled.div`
  max-height: 535px;
`;

const Table = styled.table`
  margin-top: 20px;
  width: 100%;
  .section {
    color: #000;
    font-size: 15px;
    font-weight: bold;
    background-color: rgb(250, 250, 252);
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    padding: 10px 0 10px 10px;
  }

  th {
    font-size: 15px;
  }

  td {
    padding: 10px 0 10px 10px;
    border-bottom: 1px solid #f1f1f1;
  }

  th {
    padding: 0px 0 30px 15px;
    text-align: left;
  }
  label {
    font-size: 14px;
    font-weight: 500;
  }
  .price {
    color: #000;
    font-weight: bold;
    font-size: 17px;
  }
  .per-month {
    font-size: 14px;
    color: #000;
    font-weight: bold;
  }
  .billed-annually {
    font-size: 12px;
    margin-top: -5px;
    font-weight: 500;
  }
  .discount {
    font-weight: 600;
    background-color: #f0faf8;
    color: #226453;
    border: 1px solid #d4e5e1;
    border-radius: 5px;
  }
  .rs-radio-tile-content {
    padding-top: 5px;
  }
  .rs-radio-tile-label {
    font-size: 15px;
  }
  .original-price {
    font-size: 13px;
    text-decoration: line-through;
  }
`;

const StyledFlexContainer = styled(FlexContainer)`
  @media only screen and (max-width: 910px) {
    display: block;

    .cycle-switcher {
      justify-content: center !important;
      margin-bottom: 15px;
    }
    .price-cards {
      justify-content: center !important;
    }
  }

  @media only screen and (max-width: 690px) {
    .price-cards {
      flex-direction: column;
    }
  }
`;

const TrialBanner = styled.div`
  background: linear-gradient(to right, #f8fafc, #f1f5f9);
  padding: 16px 24px;
  border-radius: 12px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const BannerContent = styled.div`
  flex: 1;
`;

const BannerTitle = styled.h3`
  margin: 0 0 4px 0;
  font-size: 16px;
  font-weight: 600;
  color: #1a202c;
  line-height: 20px;
`;

const BannerText = styled.p`
  margin: 0;
  color: #4a5568;
  font-size: 14px;
  line-height: 1.5;
`;

const BannerBadges = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 20px;
  background: white;
  font-size: 13px;
  color: #4a5568;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
`;

const Upgrade = () => {
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { plan } = selectedSite ?? {};
  const { selectedPlan: _selectedPlan } = useAppSelector((state) => state.app);
  const [cycle, setCycle] = useState<PlanCycle>(PlanCycle.YEARLY);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>(
    _selectedPlan ?? SubscriptionPlan.PRO
  );

  useEffect(() => {
    dispatch(
      setBillingData({
        upgradePlanId: selectedPlan,
        billingCycle:
          selectedPlan === SubscriptionPlan.FREE ? 'NO_CYCLE' : cycle,
      })
    );
  }, [selectedPlan, cycle]);

  return (
    <Wrapper>
      {plan === SubscriptionPlan.FREE && (
        <TrialBanner>
          <BannerContent>
            <BannerTitle>Try Premium Features for 14 Days</BannerTitle>
            <BannerText>
              First 14 days free, with a reminder 3 days before trial ends.
            </BannerText>
          </BannerContent>
          <BannerBadges>
            <Badge>
              <FiCreditCard size={14} />
              No charge for 14 days
            </Badge>
            <Badge>
              <FiBell size={14} />
              Trial reminder
            </Badge>
            <Badge>
              <FiX size={14} />
              Cancel anytime
            </Badge>
          </BannerBadges>
        </TrialBanner>
      )}

      <Table>
        <thead>
          <tr>
            <th colSpan={4}>
              <StyledFlexContainer
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <BillingCycleSwitcher
                  cycle={cycle}
                  switchCycle={(cycle) => setCycle(cycle)}
                />

                <FlexContainer
                  justifyContent="flex-end"
                  alignItems="stretch"
                  gap="10px"
                  className="price-cards"
                >
                  <PlanCard
                    key="Basic"
                    unit={cycle === PlanCycle.MONTHLY ? 'month' : 'year'}
                    isCurrentPlan={plan === SubscriptionPlan.FREE}
                    planName="Basic"
                    price={'0'}
                    isBasicPlan={true}
                    isSelectedPlan={selectedPlan === SubscriptionPlan.FREE}
                    selectPlan={() => setSelectedPlan(SubscriptionPlan.FREE)}
                  />

                  <PlanCard
                    key="Pro"
                    cycle={cycle}
                    unit={cycle === PlanCycle.MONTHLY ? 'month' : 'year'}
                    isCurrentPlan={plan === SubscriptionPlan.PRO}
                    planName="Pro"
                    monthlyPrice={PLAN_PRICES.PRO.monthly}
                    price={
                      cycle === PlanCycle.MONTHLY
                        ? PLAN_PRICES.PRO.monthly
                        : PLAN_PRICES.PRO.anually
                    }
                    isSelectedPlan={selectedPlan === SubscriptionPlan.PRO}
                    selectPlan={() => setSelectedPlan(SubscriptionPlan.PRO)}
                  />

                  <PlanCard
                    key="Advanced"
                    cycle={cycle}
                    unit={cycle === PlanCycle.MONTHLY ? 'month' : 'year'}
                    isCurrentPlan={plan === SubscriptionPlan.ADVANCED}
                    planName="Advanced"
                    monthlyPrice={PLAN_PRICES.ADVANCED.monthly}
                    price={
                      cycle === PlanCycle.MONTHLY
                        ? PLAN_PRICES.ADVANCED.monthly
                        : PLAN_PRICES.ADVANCED.anually
                    }
                    isSelectedPlan={selectedPlan === SubscriptionPlan.ADVANCED}
                    selectPlan={() =>
                      setSelectedPlan(SubscriptionPlan.ADVANCED)
                    }
                  />
                </FlexContainer>
              </StyledFlexContainer>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={4} className="section">
              Usage limit
            </td>
          </tr>
          <tr>
            <td>Display text reviews</td>
            <td>
              {USAGE.free.reviews === -1 ? 'Unlimited' : USAGE.free.reviews}
            </td>
            <td>
              {USAGE.professional.reviews === -1
                ? 'Unlimited'
                : USAGE.professional.reviews}
            </td>
            <td>
              {USAGE.advanced.reviews === -1
                ? 'Unlimited'
                : USAGE.advanced.reviews}
            </td>
          </tr>

          <tr>
            <td>
              <FlexContainer justifyContent="flex-start" gap="5px">
                <span>Display video reviews</span>
              </FlexContainer>
            </td>
            <td>
              {USAGE.free.videoReviews === -1
                ? 'Unlimited'
                : USAGE.free.videoReviews}
            </td>
            <td>
              {USAGE.professional.videoReviews === -1
                ? 'Unlimited'
                : USAGE.professional.videoReviews}
            </td>
            <td>
              {USAGE.advanced.videoReviews === -1
                ? 'Unlimited'
                : USAGE.advanced.videoReviews}
            </td>
          </tr>

          <tr>
            <td>Request reviews from your customers</td>
            <td>
              {USAGE.free.reviewsRequests === -1
                ? 'Unlimited'
                : USAGE.free.reviewsRequests}{' '}
              / month
            </td>
            <td>
              {USAGE.professional.reviewsRequests === -1
                ? 'Unlimited'
                : USAGE.professional.reviewsRequests}{' '}
              / month
            </td>
            <td>
              {USAGE.advanced.reviewsRequests === -1
                ? 'Unlimited'
                : USAGE.advanced.reviewsRequests}{' '}
              / month
            </td>
          </tr>

          <tr>
            <td>Testimonials imports</td>
            <td>Limited</td>
            <td>Unlimited</td>
            <td>Unlimited</td>
          </tr>

          <tr>
            <td colSpan={4} className="section">
              Features
            </td>
          </tr>
          <tr>
            <td>Google Rich Snippets</td>
            <td>
              <GoDash size={15} />
            </td>
            <td>
              <GoDash size={15} />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Full widget customization</td>
            <td>
              <GoDash size={15} />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>
              <FlexContainer gap="5px" justifyContent="flex-start">
                Respond to customer reviews
              </FlexContainer>
            </td>
            <td>
              <GoDash size={15} />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>

          <tr>
            <td>Auto-publish reviews</td>
            <td>
              <GoDash size={15} />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>

          <tr>
            <td>Widget content translation</td>
            <td>
              <GoDash size={15} />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Testimonial imports</td>
            <td>Limited</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Support for mobile devices</td>
            <td>Limited</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>
              <FlexContainer gap="5px" justifyContent="flex-start">
                Turn reviews into stunning cards
              </FlexContainer>
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Site-wide product review display</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>

          <tr>
            <td>Review requests</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>

          <tr>
            <td colSpan={4} className="section">
              Widgets
            </td>
          </tr>
          <tr>
            <td>Remove ReviewsJet branding</td>
            <td>
              <GoDash size={15} />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          {/* <tr>
            <td>Carousel Slider</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Carousel Marquee</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Masonry widget</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Grid widget</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>List widget</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Modal widget</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Product widget</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr> */}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default Upgrade;
