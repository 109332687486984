const sanitizeReviews = (reviews: any[]) => {
  return reviews.map((review) => ({
    ...review,
    review_text: sanitizeText(review.review_text),
    rating: Math.min(Number(review.rating) || 5, 5), // Cap at 5 and default to 5 if invalid
  }));
};

function sanitizeText(text: string) {
  return text
    .replace(/�/g, "'") // Replace with apostrophe
    .replace(/[\uFFFD]/g, '') // Remove replacement character
    .replace(/[\u0080-\u009F]/g, '') // Remove control characters
    .trim();
}

export { sanitizeReviews, sanitizeText };
