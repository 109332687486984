import dayjs from 'dayjs';
import { ForwardedRef, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import hexToRgba from '../../../../../../../shared/utils/hexToRgba';
import { Review } from '../../../../../../manage-reviews/redux/reviews.slice';
import {
  BrandingSettings,
  EmbeddedWidgetSettings,
  TranslationType,
} from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import TextReview from './TextReview';

type Props = {
  width?: string;
  fluid?: boolean;
  review: Review;
  translation: TranslationType;
  branding: BrandingSettings;
  widgetSettings: EmbeddedWidgetSettings;
};

type ReviewHeaderProps = {
  $width?: string;
  $fluid?: boolean;
  $widgetSettings: EmbeddedWidgetSettings;
  $isMasonry?: boolean;
  $isLastItem?: boolean;
  $allowClick?: boolean;
};

const Card = styled.div<ReviewHeaderProps>`
  width: 95%;
  ${({ $isMasonry, $fluid }) =>
    $isMasonry
      ? css`
          min-height: ${$fluid ? '350px' : '370px'};
        `
      : css`
          height: 100%;
        `}
  border-radius: ${({ $widgetSettings }) => $widgetSettings.borderRadius};
  ${({ $allowClick }) =>
    !!$allowClick &&
    css`
      cursor: pointer;
    `}
  position: relative;
  margin: auto;
  background: ${({ $widgetSettings }) => {
    return hexToRgba(
      $widgetSettings.reviewCardColor,
      $widgetSettings.reviewCardColorOpacity / 100
    );
  }};
  border: ${({ $widgetSettings }) =>
    `${$widgetSettings.showBorder ? $widgetSettings.borderWidth ?? 0 : 0}px ${
      $widgetSettings.borderColor
    } solid`};
  width: ${({ $width }) => $width ?? ''};

  ${({ $isMasonry, $width, $widgetSettings }) => {
    if ($isMasonry) {
      return css`
        width: calc(${$width} - ${2 * $widgetSettings.borderWidth}px);

        @media (min-width: 645px) and (max-width: 1000px) {
          width: calc(48% - ${2 * $widgetSettings.borderWidth}px);
        }

        @media (max-width: 644px) {
          width: 100%;
        }
      `;
    }
  }}
  ${({ $fluid, $width }) =>
    !!$fluid &&
    css`
      @media (max-width: 644px) {
        width: 100%;
        min-width: ${$width};
      }

      @media (min-width: 645px) and (max-width: 1000px) {
        width: 100%;
        min-width: ${$width};
      }
    `}
    ${({ $isLastItem }) =>
    $isLastItem &&
    css`
      margin-right: 20px;
    `}
`;

const ReviewCard = forwardRef<HTMLVideoElement, Props>(
  (
    { width, review, fluid = false, widgetSettings, branding, translation },
    ref: ForwardedRef<HTMLVideoElement>
  ) => {
    const { hideSourceIcon } = widgetSettings;
    const { verifiedReviewer } = translation;
    const { dateFormat = 'DD/MM/YYYY' } = branding;

    let reviewDate = review.reviewDate;

    try {
      reviewDate = dayjs(review.reviewDate).format(dateFormat);

      if (reviewDate === 'Invalid Date') {
        reviewDate = review.reviewDate;
      }
    } catch (error) {
      reviewDate = review.reviewDate;
    }

    return (
      <Card $width={width} $widgetSettings={widgetSettings} $fluid={fluid}>
        <TextReview
          branding={branding}
          hideSourceIcon={hideSourceIcon}
          review={review}
          translation={translation}
          verifiedReviewer={verifiedReviewer}
          widgetSettings={widgetSettings}
        />
      </Card>
    );
  }
);

export default ReviewCard;
