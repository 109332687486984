import { Tabs } from 'rsuite';
import styled from 'styled-components';
import ColorSettings from './ColorSettings';
import FontSettings from './FontSettings';
import Translations from './Translations';

const SettingsSection = styled.div`
  background-color: #fff;
  border-radius: 5px;
  width: 100%;

  .content {
    .subtle-tabs .rs-nav-item {
      width: 33% !important;
    }
  }
`;

const StyledTabs = styled(Tabs)`
  .rs-nav-pills {
    width: 100%;
  }

  .rs-nav-item {
    width: 33%;
    color: #000;
    font-weight: 600;
  }
`;

export default function ContentSettings() {
  return (
    <SettingsSection>
      <div className="content">
        <StyledTabs
          className="subtle-tabs"
          defaultActiveKey="1"
          appearance="subtle"
        >
          <Tabs.Tab eventKey="1" title="Colors">
            <ColorSettings />
          </Tabs.Tab>
          <Tabs.Tab eventKey="2" title="Font">
            <FontSettings />
          </Tabs.Tab>
          <Tabs.Tab eventKey="3" title="Translations">
            <Translations />
          </Tabs.Tab>
        </StyledTabs>
      </div>
    </SettingsSection>
  );
}
