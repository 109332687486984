import { BiSolidError } from 'react-icons/bi';
import { FaLocationDot } from 'react-icons/fa6';
import { Divider, Rate } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import isValidNumber from '../../../../shared/utils/isValidNumber';
import { sanitizeText } from '../../../../shared/utils/sanitizeText';

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #e5e5ea;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;

  .review-date {
    font-size: 13px;
    color: #686868;
  }
`;

const Reviewer = styled.h3`
  margin-bottom: 0;
  font-weight: bold;
  font-size: 17px;
  line-height: 25px;
`;

const ReplyWrapper = styled.div`
  /* padding-left: 50px; */
`;

const Error = styled(FlexContainer)`
  color: crimson;
`;

const HelpfulWrapper = styled.div`
  text-align: right;
`;

const Review = ({
  review,
}: {
  review: {
    rating?: string;
    review_date?: string;
    review_text?: string;
    helpful_count?: string;
    reviewer_name?: string;
    your_review_reply?: string;
    reviewer_location_country?: string;
    reviewer_location_city?: string;
  };
}) => {
  return (
    <Wrapper>
      {!review.reviewer_name ? (
        <Error justifyContent="flex-start" gap="5px">
          <BiSolidError /> Reviewer name is missing
        </Error>
      ) : (
        <Reviewer>{review.reviewer_name}</Reviewer>
      )}
      {(review.reviewer_location_city || review.reviewer_location_country) && (
        <FlexContainer justifyContent="flex-start" gap="5px">
          <FaLocationDot />
          {review.reviewer_location_city && (
            <span className="city">{review.reviewer_location_city}</span>
          )}
          {review.reviewer_location_country && (
            <span className="city">{review.reviewer_location_country}</span>
          )}
        </FlexContainer>
      )}

      {review.review_date ? (
        <span className="review-date">{review.review_date}</span>
      ) : (
        <Error justifyContent="flex-start" gap="5px">
          <BiSolidError /> Review date is missing
        </Error>
      )}
      {review.rating ? (
        <Rate
          defaultValue={+review.rating > 5 ? 5 : +review.rating}
          allowHalf
          readOnly
          color="yellow"
          size="sm"
        />
      ) : review.rating && !isValidNumber(review.rating) ? (
        <Error justifyContent="flex-start" gap="5px">
          <BiSolidError />
          Review rating is not valid number
        </Error>
      ) : (
        <Error justifyContent="flex-start" gap="5px">
          <BiSolidError />
          Review rating is missing
        </Error>
      )}
      {review.review_text ? (
        <p>{sanitizeText(review.review_text)}</p>
      ) : (
        <Error justifyContent="flex-start" gap="5px">
          <BiSolidError />
          review content is missing
        </Error>
      )}

      {review.helpful_count && isValidNumber(review.helpful_count) ? (
        <HelpfulWrapper>
          <b>{review.helpful_count} people found it helpful</b>
        </HelpfulWrapper>
      ) : review.helpful_count && !isValidNumber(review.helpful_count) ? (
        <Error justifyContent="flex-start" gap="5px">
          <BiSolidError />
          helpful_count is not a valid number.
        </Error>
      ) : null}

      {review.your_review_reply && (
        <>
          <Divider />

          <ReplyWrapper>
            <b>Your reply:</b>
            <p>{review.your_review_reply}</p>
          </ReplyWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default Review;
