import { zodResolver } from '@hookform/resolvers/zod';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Control,
  FieldErrors,
  useForm,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z, ZodType } from 'zod';
import { axiosPrivate } from '../../../../../../services/axiosPrivate';
import AppModal from '../../../../../../shared/components/modal';
import useModal from '../../../../../../shared/components/modal/useModal';
import useConfetti from '../../../../../../shared/hooks/useConfetti';
import { useNotification } from '../../../../../../shared/hooks/useNotification';
import { Loading } from '../../../../../../shared/types/review.types';
import decodeHtmlEntities from '../../../../../../shared/utils/decodeHtmlEntities';
import removeTrailingSlash from '../../../../../../shared/utils/removeTrailingSlash';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import {
  emailsSelector,
  setSelectedOrders,
} from '../../../../../emails/redux/emails.slice';
import {
  getTestimonialTemplate,
  sendSingleReviewRequest,
} from '../../../../../share-form/redux /action';
import {
  initialTemplates,
  TemplateType,
} from '../../../../../share-form/redux /testimonial.slice';
import { accountSelector } from '../../../../../userData/redux/userData.slice';
import {
  setCurrentStep as handleSetCurrentStep,
  OnboardingStep,
} from '../../../../redux/onboarding.slice';
import updateOnboarding from '../../../../services/updateOnboarding';
import SuccessFeedback from './SuccessFeedback';

/* eslint no-console: "warn" */

export enum InviteCustomerSteps {
  CUSTOMER = 'customer',
  MESSAGE = 'message',
  LOGO = 'logo',
  ADVANCED = 'advanced',
  SEND = 'send',
}

export type InviteCustomerFormValues = {
  toName: string;
  toEmail: string;
  logo?: string;
  subject: string;
  message: string;
  replyToEmail?: string;
  replyFromName?: string;
  callToAction?: string;
  feedbackMessageText?: string;
};

const formSchema: ZodType<InviteCustomerFormValues> = z.object({
  toName: z.string().min(2, { message: 'Name is required' }),
  toEmail: z.string().email('Enter a valid email address'),
  logo: z.string().optional(),
  subject: z.string(),
  message: z.string(),
  replyToEmail: z.string(),
  replyFromName: z.string(),
  callToAction: z.string().min(2, { message: 'Text is required' }),
  feedbackMessageText: z.string().min(10, { message: 'Text is required' }),
});

export type InviteType = {
  ownerEmail: string;
  testimonialLink: string;
  formattedSubject: string;
  formattedMessage: string;
  toEmail: string;
  toName: string;
  currentStep: InviteCustomerSteps;
  setCurrentStep: Dispatch<SetStateAction<InviteCustomerSteps>>;
  formValues: InviteCustomerFormValues;
  control: Control<InviteCustomerFormValues>;
  setValue: UseFormSetValue<InviteCustomerFormValues>;
  trigger: UseFormTrigger<InviteCustomerFormValues>;
  errors: FieldErrors<InviteCustomerFormValues>;
  activeTemplate: TemplateType;
  handleActiveTemplate: (template: TemplateType) => void;
  submitForm: () => void;
  replyFromName?: string;
  callToAction?: string;
  feedbackMessageText?: string;
  submittingBulkRequest?: Loading;
};

export const InviteCustomerContext = createContext<InviteType | null>(null);

export const InviteCustomerProvider = ({
  children,
  onClose,
}: {
  onClose: () => void;
  children: ReactNode;
}) => {
  const [submittingBulkRequest, setSubmittingBulkRequest] = useState(
    Loading.INITIAL
  );
  const dispatch = useAppDispatch();
  const { selectedContacts } = useAppSelector(emailsSelector);
  const { showSuccessToast, showErrorToast } = useNotification();
  const { account } = useAppSelector(accountSelector);
  const { data: businessInfo } = useAppSelector((state) => state.businessInfo);
  const navigate = useNavigate();
  const { realisticLook } = useConfetti();
  const {
    ownerEmail,
    personalEmail,
    ownerName = '',
    selectedSite,
  } = account || {};
  const { siteName } = selectedSite ?? {};
  const { handleCloseModal, handleOpenModal, showModal } = useModal();

  const [activeTemplate, setActiveTemplate] = useState<TemplateType>('Default');
  const [currentStep, setCurrentStep] = useState<InviteCustomerSteps>(
    InviteCustomerSteps.CUSTOMER
  );

  const defaultValues = {
    toName: '',
    toEmail: '',
    subject: '',
    message: '',
    replyToEmail: '',
    replyFromName: '',
    callToAction: 'Leave a review',
    feedbackMessageText: 'Button not working? Paste this URL in the browser',
  };

  const {
    trigger,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<InviteCustomerFormValues>({
    mode: 'onBlur',
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const handleNextStep = async () => {
    dispatch(handleSetCurrentStep(OnboardingStep.InstallWidget));

    await updateOnboarding({
      nextStep: OnboardingStep.InstallWidget,
    });
    onClose();
  };

  const formValues = watch();
  const {
    logo,
    toEmail,
    toName,
    message,
    subject,
    replyFromName,
    replyToEmail,
    callToAction,
    feedbackMessageText,
  } = formValues;

  const testimonialLink = `${process.env.REACT_APP_REVIEWSJET_WEBSITE}/w/${siteName}`;
  const ownerFirstName = businessInfo?.name
    ? businessInfo.name
    : ownerName.split(' ')[0];
  let fullName = toName;
  let customerFirstName = toName ? toName.split(' ')[0] : ownerFirstName;

  if (!!selectedContacts.length) {
    const { firstName, lastName } = selectedContacts[0];
    customerFirstName = firstName;
    fullName = `${firstName} ${lastName}`;
  }

  const formattedSubject = subject
    .replaceAll(/{{first_name}}/g, customerFirstName)
    .replaceAll(/{{ first_name}}/g, customerFirstName)
    .replaceAll(/{{first_name }}/g, customerFirstName)
    .replaceAll(/{{ first_name }}/g, customerFirstName)
    .replaceAll(/{{name}}/g, fullName)
    .replaceAll(/{{ name}}/g, fullName)
    .replaceAll(/{{name }}/g, fullName)
    .replaceAll(/{{ name }}/g, fullName);

  const formattedMessage = message
    .replaceAll(/{{first_name}}/g, customerFirstName)
    .replaceAll(/{{ first_name}}/g, customerFirstName)
    .replaceAll(/{{first_name }}/g, customerFirstName)
    .replaceAll(/{{ first_name }}/g, customerFirstName)
    .replaceAll(/{{name}}/g, fullName)
    .replaceAll(/{{ name}}/g, fullName)
    .replaceAll(/{{name }}/g, fullName)
    .replaceAll(/{{ name }}/g, fullName);

  const handleActiveTemplate = async (template: TemplateType) => {
    setActiveTemplate(template);
    if (template.startsWith('AI')) {
      dispatch(getTestimonialTemplate(template))
        .then(unwrapResult)
        .then((data) => {
          const { subject, message } = data.template;
          setValue('subject', subject);
          setValue(
            'message',
            message
              .replaceAll(/{{owner_name}}/g, ownerFirstName)
              .replaceAll(/{{ owner_name}}/g, ownerFirstName)
              .replaceAll(/{{ owner_name }}/g, ownerFirstName)
              .replaceAll(/{{owner_name }}/g, ownerFirstName)
          );
          showSuccessToast('Template generated successfully');
        })
        .catch((e) => {
          showErrorToast('Failed to fetch template');
        });
    } else {
      setValue('subject', initialTemplates[template].subject);
      setValue(
        'message',
        initialTemplates[template].message
          .replaceAll(/{{owner_name}}/g, ownerFirstName)
          .replaceAll(/{{ owner_name}}/g, ownerFirstName)
          .replaceAll(/{{ owner_name }}/g, ownerFirstName)
          .replaceAll(/{{owner_name }}/g, ownerFirstName)
      );
    }
  };

  const submitBulkReviewRequest = async () => {
    const contacts = selectedContacts.map((contact) => ({
      toEmail: contact.email,
      toName: `${contact.firstName} ${contact.lastName}`,
      subject: decodeHtmlEntities(formattedSubject),
      message: formattedMessage,
      testimonialLink,
      replyToEmail,
      replyFromName: ownerFirstName,
      callToAction,
      productId: contact.productId ?? '',
      feedbackMessageText,
      logo: businessInfo?.logo ?? '',
      productName: contact.productName,
      productImage: contact.productImage,
      orderNumber: contact.orderNumber,
    }));

    try {
      setSubmittingBulkRequest(Loading.PENDING);

      await axiosPrivate.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/emails/bulk-requests`,
        { contacts }
      );

      realisticLook();
      handleOpenModal();
      showSuccessToast('Review requests sent successfully');
      setSubmittingBulkRequest(Loading.SUCCESS);
      dispatch(setSelectedOrders([]));
    } catch (error) {
      // @ts-ignore
      const errorMessage = error.message;
      showErrorToast(errorMessage ?? 'Failed to send review request');
      setSubmittingBulkRequest(Loading.ERROR);
    }
  };

  const submitForm = async () => {
    if (!!selectedContacts.length) {
      return submitBulkReviewRequest();
    }

    const formData = new FormData();
    logo && formData.append('logo', logo);
    formData.append('message', formattedMessage);
    formData.append('subject', decodeHtmlEntities(formattedSubject));
    formData.append('replyFromName', ownerFirstName);
    formData.append('toEmail', toEmail);
    formData.append('toName', toName);
    formData.append('testimonialLink', testimonialLink);

    if (callToAction) {
      formData.append('callToAction', callToAction);
    }
    if (feedbackMessageText) {
      formData.append('feedbackMessageText', feedbackMessageText);
    }

    if (replyToEmail) {
      formData.append('replyToEmail', replyToEmail);
    }

    dispatch(sendSingleReviewRequest(formData))
      .then(unwrapResult)
      .then(() => {
        realisticLook();
        handleOpenModal();
        showSuccessToast('Review request sent successfully');
      })
      .catch((e) => {
        showErrorToast('Failed to send review request');
      });
  };

  useEffect(() => {
    setValue('replyToEmail', businessInfo?.email ?? ownerEmail ?? '');
    setValue('replyFromName', businessInfo?.name ?? ownerFirstName ?? '');
    setValue('logo', businessInfo?.logo);
    handleActiveTemplate('Default');
  }, [ownerFirstName, ownerEmail, businessInfo]);

  return (
    <InviteCustomerContext.Provider
      value={{
        ownerEmail: personalEmail ?? ownerEmail ?? '',
        testimonialLink,
        formattedSubject,
        formattedMessage,
        activeTemplate,
        currentStep,
        formValues,
        control,
        errors,
        toEmail,
        toName,
        replyFromName,
        handleActiveTemplate,
        setCurrentStep,
        setValue,
        trigger,
        submitForm,
        callToAction,
        feedbackMessageText,
        submittingBulkRequest,
      }}
    >
      {children}
      <AppModal
        size="500px"
        shouldOpenModal={showModal}
        onModalClose={() => {
          navigate('/share-review-form?no-confetti=true');
        }}
        body={
          <SuccessFeedback
            onShareMore={() => {
              onClose();
            }}
            onNextStep={handleNextStep}
          />
        }
      />
    </InviteCustomerContext.Provider>
  );
};

export const useIniviteCustomerContext = () => {
  const context = useContext<InviteType | null>(InviteCustomerContext);
  if (!context) {
    throw new Error(
      'useIniviteCustomerContext must be used within a InviteCustomerProvider'
    );
  }
  return context;
};
