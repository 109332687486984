import { unwrapResult } from '@reduxjs/toolkit';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from 'rsuite';
import styled, { css } from 'styled-components';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import {
  ModalBody,
  ModalHeader,
} from '../../../../../shared/components/modal/modal-parts';
import StyledText from '../../../../../shared/components/styled-text';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import {
  Loading,
  YelpLocationFormData,
} from '../../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { useYelpLocationContext } from '../../../contexts/YelpLocationContext';
import { searchYelpBusiness } from '../../../redux/actions';
import {
  resetYelpSearch,
  socialReviewsSelector,
} from '../../../redux/social-reviews.slice';

const StyledInput = styled(Input)<{ error: boolean }>`
  background: #fff;
  margin-bottom: 20px;
  border-color: #cdcdcd;
  margin-top: 5px;
  outline: none !important;
  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;
const StyledLabel = styled(StyledText)`
  font-size: 14px;
  color: #7b7b7b;
`;

const Wrapper = styled.div`
  padding-top: 20px;
`;

const ContinueButton = styled(Button)<{ disabled?: boolean }>`
  padding-left: 30px;
  padding-right: 30px;
  margin: 30px auto 0 0;
  background-color: #000;
  border-color: #000;
  width: 100%;
`;

const Result = styled(FlexContainer)`
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  min-height: 80px;
  margin-bottom: 20px;
  background-color: rgb(235, 238, 249);
  span.title {
    color: #000;
    font-weight: bold;
  }
  /* box-shadow: inset 0px 10px 15px -3px rgba(0, 0, 0, 0.1); */

  img {
    max-width: 100px;
    max-height: 80px;
    padding: 2px;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
  }
`;

const FormContainer = styled.form`
  padding-top: 20px;
`;

const NoImage = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background-color: #e1e1e1;
`;

const SearchBusiness = ({ onSuccess }: { onSuccess: () => void }) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation('common');
  const { searchingYelpBusiness, yelpBusinesses, addingYelpAccount } =
    useAppSelector(socialReviewsSelector);
  const isSearching = searchingYelpBusiness === Loading.PENDING;

  const { showErrorToast } = useNotification();
  const { formValues, updateFormValues } = useYelpLocationContext();
  const [showNotFoundMessage, setShowNotFoundMessage] = useState(false);
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<YelpLocationFormData>({
    defaultValues: formValues,
  });

  const onSubmit = handleSubmit((data) => {
    updateFormValues(data);
    setShowNotFoundMessage(false);
    dispatch(resetYelpSearch());
    dispatch(searchYelpBusiness(data))
      .then(unwrapResult)
      .then(() => {
        reset();
        onSuccess();
      })
      .catch(() => {
        setShowNotFoundMessage(true);
        showErrorToast(t('reviews.import-source.google.business-find-fail'));
      });
  });

  return (
    <>
      <ModalHeader
        title="Search your business"
        centerText={false}
        closeButton={false}
      />
      <ModalBody>
        <FormContainer onSubmit={onSubmit}>
          <div>
            <StyledLabel>Your business name on Yelp</StyledLabel>

            <Controller
              name="name"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <StyledInput
                  error={errors.name}
                  placeholder="Monkey King Oakland"
                  {...field}
                />
              )}
            />

            <StyledLabel>Your business address on Yelp</StyledLabel>

            <Controller
              name="address"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <StyledInput
                  error={errors.address}
                  placeholder="3920 Piedmont Ave Oakland, CA 94611"
                  {...field}
                />
              )}
            />

            <ContinueButton type="submit" size="large" variant="primary">
              <b>{isSearching ? 'Please wait' : 'Search'}</b>
            </ContinueButton>
          </div>
        </FormContainer>
      </ModalBody>
    </>
  );
};

export default SearchBusiness;
