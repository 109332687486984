export default function AliExpressIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
    >
      <g id="surface1">
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(90.196078%,18.039216%,1.568627%)',
            fillOpacity: 1,
          }}
          d="M 1.5625 0 C 0.699219 0 0 0.699219 0 1.5625 L 0 18.4375 C 0 19.300781 0.699219 20 1.5625 20 L 18.4375 20 C 19.300781 20 20 19.300781 20 18.4375 L 20 1.5625 C 20 0.699219 19.300781 0 18.4375 0 Z M 1.5625 0 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(70.196078%,12.941176%,0%)',
            fillOpacity: 1,
          }}
          d="M 5.5 6.703125 C 5.578125 7.015625 5.535156 7.355469 5.609375 7.671875 C 5.871094 7.480469 6.046875 7.164062 6.011719 6.832031 C 6.003906 6.242188 5.339844 5.777344 4.785156 5.976562 C 4.433594 6.078125 4.175781 6.390625 4.117188 6.746094 C 4.046875 7.152344 4.289062 7.566406 4.652344 7.753906 C 4.617188 7.402344 4.53125 7.035156 4.625 6.691406 C 4.769531 6.316406 5.367188 6.324219 5.5 6.703125 Z M 15.855469 7.128906 C 15.984375 6.667969 15.707031 6.136719 15.25 5.988281 C 14.8125 5.816406 14.273438 6.035156 14.078125 6.464844 C 13.863281 6.859375 13.992188 7.417969 14.386719 7.652344 C 14.464844 7.332031 14.40625 6.996094 14.5 6.6875 C 14.644531 6.320312 15.234375 6.328125 15.371094 6.703125 C 15.453125 7.035156 15.378906 7.386719 15.34375 7.722656 C 15.613281 7.640625 15.777344 7.382812 15.855469 7.128906 Z M 15.855469 7.128906 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,100%,100%)',
            fillOpacity: 1,
          }}
          d="M 14.5 6.6875 C 14.40625 6.996094 14.464844 7.332031 14.386719 7.648438 C 14.160156 9.023438 13.238281 10.253906 11.992188 10.871094 C 10.734375 11.503906 9.25 11.5 7.992188 10.863281 C 6.757812 10.25 5.84375 9.03125 5.609375 7.671875 C 5.535156 7.355469 5.578125 7.019531 5.5 6.703125 C 5.367188 6.328125 4.769531 6.316406 4.628906 6.691406 C 4.535156 7.039062 4.617188 7.402344 4.652344 7.753906 C 4.914062 9.453125 6.054688 10.980469 7.59375 11.738281 C 9.027344 12.445312 10.703125 12.484375 12.167969 11.84375 C 13.835938 11.125 15.082031 9.519531 15.347656 7.71875 C 15.378906 7.382812 15.457031 7.035156 15.371094 6.699219 C 15.234375 6.328125 14.648438 6.316406 14.5 6.683594 Z M 14.5 6.6875 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,60%,0%)',
            fillOpacity: 1,
          }}
          d="M 1.5625 0 C 0.699219 0 0 0.699219 0 1.5625 L 0 4.53125 C 0 3.667969 0.699219 2.96875 1.5625 2.96875 L 18.4375 2.96875 C 19.300781 2.96875 20 3.667969 20 4.53125 L 20 1.5625 C 20 0.699219 19.300781 0 18.4375 0 Z M 1.5625 0 "
        />
      </g>
    </svg>
  );
}
