import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Form, Schema } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../shared/components/Button';
import ReviewsJetCrownLogo from '../../../shared/components/ReviewsJetCrownLogo';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../shared/hooks/useNotification';
import { Loading } from '../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../store';
import { forgotPassword } from '../../userData/redux/action';
import {
  AuthForm,
  AuthFormGroup,
  AuthTextField,
  AuthTitle,
} from '../components';

/* eslint no-console: "warn" */

const StyledFlexContainer = styled(FlexContainer)`
  padding: 20px 0 0;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 40px;
  max-width: 440px;
  max-height: 90%;
  overflow: auto;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  @media (max-width: 530px) {
    max-width: 100%;
    box-shadow: none;
    border: 0;
  }

  input {
    border-radius: 25px;
  }

  .forgot-password {
    color: #8b8e99;
    /* text-decoration: underline !important;
    text-underline-offset: 4px; */
  }
`;

const StyledButton = styled(Button)`
  background-color: #000;
  color: #fff;
  border-radius: 25px;
  min-width: 150px;
  border-color: #000;
`;

const Intro = styled.p`
  margin-bottom: 30px;
  color: #737373;
  font-size: 15px;
`;

const { StringType } = Schema.Types;

const model = Schema.Model({
  email: StringType()
    .isEmail('Please enter a valid email address.')
    .isRequired('This field is required.'),
});

const ForgotPasswordPage = () => {
  const dispatch = useAppDispatch();
  const [submitted, setSubmitted] = useState(false);
  const { showErrorToast } = useNotification();
  const formRef = useRef<{ check: () => void }>(null);
  const {
    forgotPassword: { loading },
  } = useAppSelector((state) => state.userData);

  const [formValue, setFormValue] = useState({
    email: '',
  });

  const handleSubmit = useCallback(() => {
    if (!formRef.current?.check()) {
      return;
    }

    dispatch(forgotPassword(formValue.email))
      .then(unwrapResult)
      .then(() => setSubmitted(true))
      .catch(() => {
        showErrorToast('An error occurred. Please try it again.');
      });
  }, [formValue]);

  return (
    <>
      <Helmet>
        <title>Reset Your Password | ReviewsJet</title>
        <meta
          name="description"
          content="Forgot your ReviewsJet password? Reset it securely to regain access to your account and continue managing your reviews from Google, Yelp, Trustpilot, and more."
        />
      </Helmet>

      <Wrapper>
        <a href={process.env.REACT_APP_REVIEWSJET_WEBSITE!}>
          <ReviewsJetCrownLogo />
        </a>
        {submitted ? (
          <>
            <AuthTitle marginBottom="10px">Password reset submitted!</AuthTitle>
            <p>
              We've received your request. Check your email for further
              instructions on resetting your password.
            </p>
            <StyledFlexContainer gap="10px" justifyContent="flex-start">
              <Link to="/login">Go back to sign-in</Link>
            </StyledFlexContainer>
          </>
        ) : (
          <>
            <AuthTitle marginBottom="10px">Forgot password</AuthTitle>
            <Intro>
              Enter the email address associated with your ReviewsJet account.
              We'll send you instructions to reset your password.
            </Intro>
            <AuthForm
              fluid
              ref={formRef}
              onChange={setFormValue}
              formValue={formValue}
              model={model}
            >
              <AuthFormGroup controlId="email">
                <AuthTextField
                  name="email"
                  type="email"
                  placeholder="e.g. jane@doe.com"
                />
              </AuthFormGroup>

              <div style={{ height: 20 }}></div>

              <Form.Group>
                <FlexContainer
                  justifyContent="space-between"
                  style={{ marginTop: 20 }}
                >
                  <StyledButton
                    size="large"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={loading === Loading.PENDING}
                  >
                    {loading === Loading.PENDING ? (
                      <b>Please wait...</b>
                    ) : (
                      <FlexContainer gap="5px">
                        <b>Reset password</b>
                      </FlexContainer>
                    )}
                  </StyledButton>

                  <FlexContainer
                    justifyContent="flex-start"
                    alignItems="center"
                    gap="5px"
                  >
                    <span>Back to </span>
                    <Link to="/login">Sign in</Link>
                  </FlexContainer>
                </FlexContainer>
              </Form.Group>
            </AuthForm>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default ForgotPasswordPage;
