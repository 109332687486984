import styled from 'styled-components';

import { ReactNode, useEffect, useState } from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import Drawer from 'rsuite/esm/Drawer';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import { ReviewerPlatform } from '../../../../../shared/types/review.types';
import capitalize from '../../../../../shared/utils/capitalize';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { AuthTitle } from '../../../../external/components';
import { fetchReviews } from '../../../../manage-reviews/redux/action';
import { reviewsSelector } from '../../../../manage-reviews/redux/reviews.slice';
import {
  OnboardingStep,
  setCurrentStep,
} from '../../../redux/onboarding.slice';
import updateOnboarding from '../../../services/updateOnboarding';
import ListReviews from '../ListReviews';
import EmptyState from './EmptyState';

const Item = styled(FlexContainer)`
  width: 100%;
  cursor: pointer;
  max-width: 150px;
  padding: 10px;
  border-radius: 6px;
  background-color: #fdfdfd;
  border: 1px solid #e2e2e2;
  gap: 5px;
  &:hover {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  }

  img {
    height: 20px;
  }

  span {
    font-weight: 600;
  }
`;

const DrawerTitle = styled(Drawer.Title)`
  h2 {
    line-height: 45px;
  }
  .drawer-title {
    /* margin-bottom: -20px; */
  }

  .next-step {
    font-family: 'Wix Madefor Text', sans-serif;
    font-size: 14px;
    font-weight: normal;
  }
`;

const BackBtn = styled(Button)`
  background-color: #000;
  color: #fff;
  border-color: #000;
  height: 32px;
  line-height: 20px;
`;

const getSentence = (platform: ReviewerPlatform) => {
  switch (platform) {
    case ReviewerPlatform.ALI_EXPRESS:
      return 'Visit your AliExpress product page';

    case ReviewerPlatform.ETSY:
      return 'Visit your Etsy profile page';
    case ReviewerPlatform.TOUR_RADAR:
      return 'Visit your Tourradar profile page';
    case ReviewerPlatform.AIRBNB:
      return 'Visit your Airbnb profile page';
    default:
      return ``;
  }
};

type Props = {
  logo: ReactNode;
  platform: ReviewerPlatform;
};

export default function ImportWithChromeExtension({ platform, logo }: Props) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const {
    reviews: { loading, data: paginatedResponse },
  } = useAppSelector(reviewsSelector);

  const loadedReviews = paginatedResponse?.docs ?? [];
  const { showSuccessToast } = useNotification();

  const onPublishReviewsComplete = async () => {
    setOpen(false);
    showSuccessToast(
      `Your ${capitalize(
        platform
      )} reviews have been published successfully! Moving to next step.`
    );

    setTimeout(async () => {
      dispatch(setCurrentStep(OnboardingStep.InstallWidget));

      await updateOnboarding({
        nextStep: OnboardingStep.InstallWidget,
      });
    }, 3000);
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    // Only start polling if we have no reviews and modal is open
    if (open) {
      // Initial fetch
      dispatch(fetchReviews({ payload: { platform } }));

      // Set up polling every 3 seconds
      intervalId = setInterval(() => {
        // Double-check conditions before each dispatch
        if (open) {
          dispatch(fetchReviews({ payload: { platform } }));
        } else {
          // Clear interval if conditions are no longer met
          clearInterval(intervalId);
        }
      }, 3000);
    }

    // Cleanup function to clear interval when:
    // 1. Component unmounts
    // 2. loadedReviews becomes non-empty
    // 3. Modal closes (open becomes false)
    // 4. Dependencies change
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [dispatch, loadedReviews.length, platform, open]);

  return (
    <>
      <Item onClick={() => setOpen(true)}>
        {logo}
        <span>{capitalize(platform)}</span>
      </Item>

      <Drawer backdrop="static" open={open} onClose={() => setOpen(false)}>
        <Drawer.Header>
          <DrawerTitle>
            <FlexContainer
              gap="10px"
              justifyContent="flex-start"
              alignItems="center"
            >
              <BackBtn
                rounded
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <FlexContainer>
                  <MdKeyboardArrowLeft />
                  Back
                </FlexContainer>
              </BackBtn>
              <AuthTitle marginBottom="0px">
                <div className="drawer-title">Import reviews</div>
              </AuthTitle>
            </FlexContainer>
          </DrawerTitle>
        </Drawer.Header>
        <Drawer.Body>
          {!loadedReviews.length ? (
            <EmptyState
              platform={platform}
              logo={logo}
              onClose={() => setOpen(false)}
            />
          ) : (
            <ListReviews
              platform={platform}
              onReadyForNextStep={() => {}}
              onNext={onPublishReviewsComplete}
            />
          )}
        </Drawer.Body>
      </Drawer>
    </>
  );
}
