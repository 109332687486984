import { unwrapResult } from '@reduxjs/toolkit';
import axios from 'axios';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button as RsButton, Schema } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../shared/components/Button';
import Preloader from '../../../shared/components/Preloader';
import ReviewsJetCrownLogo from '../../../shared/components/ReviewsJetCrownLogo';
import { useNotification } from '../../../shared/hooks/useNotification';
import { Loading } from '../../../shared/types/review.types';
import localStorage from '../../../shared/utils/localStorage';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { useAppDispatch, useAppSelector } from '../../../store';
import { sendOtp } from '../../userData/redux/action';
import {
  AuthForm,
  AuthFormGroup,
  AuthLabel,
  AuthTextField,
  AuthTitle,
} from '../components';
import ErrorPage from '../components/error-page';

const LogoCrown = styled.img`
  height: 50px;
  /* box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1); */
  border-radius: 100%;
  /* border: 2px solid #eaeaea; */
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 40px;
  max-width: 500px;

  @media (max-width: 530px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0px;
  }
`;

const Intro = styled.p`
  margin-bottom: 30px;
  color: #737373;
  font-size: 15px;
`;

const StyledButton = styled(Button)`
  background-color: #000;
  color: #fff;
  border-radius: 25px;
  min-width: 150px;
  border-color: #000;
`;

const { StringType } = Schema.Types;

const model = Schema.Model({
  otp: StringType().isRequired('This field is required.'),
});

const SuccessAccountCreation = () => {
  const navigate = useNavigate();
  const [validating, setValidating] = useState(Loading.INITIAL);
  const { showWarningToast, showSuccessToast } = useNotification();
  const dispatch = useAppDispatch();
  const formRef = useRef<{ check: () => void }>(null);
  const {
    verifyAccountCreationToken: { loading },
    sendOtp: { loading: sendVerificationEmailLoading },
  } = useAppSelector((state) => state.userData);
  const { showErrorToast } = useNotification();

  const [formValue, setFormValue] = useState({
    otp: '',
  });

  const handleSendVerificationEmail = () => {
    const email = localStorage.get('REVIEWSJET_ACCOUNT_EMAIL');

    if (email) {
      dispatch(sendOtp(email))
        .then(unwrapResult)
        .then(({ ttl }) => {
          if (ttl) {
            showWarningToast(`Try it again in ${ttl}`);
          } else {
            showSuccessToast('Activation email sent – Verify your account');
          }
        })
        .catch(() => {});
    }
  };

  const handleSubmit = useCallback(async () => {
    if (!formRef.current?.check() || validating === Loading.PENDING) {
      return;
    }

    let payload = {
      otp: formValue.otp,
      email: localStorage.get('REVIEWSJET_ACCOUNT_EMAIL'),
    };

    try {
      setValidating(Loading.PENDING);

      await axios.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/account/verify`,
        payload
      );

      setValidating(Loading.SUCCESS);
      localStorage.remove('REVIEWSJET_ACCOUNT_EMAIL');

      showSuccessToast('Account verified! Redirecting to login..');

      setTimeout(() => {
        navigate(`/login`);
      }, 2000);
    } catch (error) {
      setValidating(Loading.ERROR);

      // @ts-ignore
      let errorMessage = error.message;

      // @ts-ignore
      if (error.response) {
        // @ts-ignore
        errorMessage = error.response.data.error;
      }

      showErrorToast(errorMessage ?? 'Failed to confirm account.');
    }
  }, [validating, formValue]);

  if (loading === Loading.PENDING) {
    return <Preloader />;
  }

  if (loading === Loading.ERROR) {
    return (
      <Wrapper>
        <ErrorPage
          showFooter={false}
          onRetryClick={() => {
            // if (token) {
            //   dispatch(verifyAccountCreationToken(token));
            // }
          }}
        />
      </Wrapper>
    );
  }

  return (
    <>
      <Wrapper>
        <a href={process.env.REACT_APP_REVIEWSJET_WEBSITE!}>
          <ReviewsJetCrownLogo />
        </a>
        <AuthTitle marginBottom="10px">
          Almost There! Verify Your Account
        </AuthTitle>

        <Intro>
          A one-time 6-digit code has been sent to your inbox. <br />
          Please enter it in the form below.
        </Intro>

        <AuthForm
          fluid
          ref={formRef}
          onChange={setFormValue}
          formValue={formValue}
          model={model}
        >
          <AuthFormGroup>
            <AuthLabel>OTP</AuthLabel>
            <AuthTextField
              name="otp"
              type="text"
              autoComplete="true"
              placeholder="Enter OTP"
              maxLength={6}
            />
          </AuthFormGroup>
          <div style={{ height: 20 }}></div>

          <div style={{ marginBottom: 10 }}>
            <StyledButton
              type="submit"
              onClick={handleSubmit}
              disabled={validating === Loading.PENDING}
            >
              {validating === Loading.PENDING ? (
                <b>Please wait...</b>
              ) : (
                <b>Submit</b>
              )}
            </StyledButton>
          </div>

          <RsButton
            size="sm"
            appearance="link"
            disabled={sendVerificationEmailLoading === Loading.PENDING}
            onClick={handleSendVerificationEmail}
          >
            {sendVerificationEmailLoading === Loading.PENDING
              ? 'Please wait...'
              : 'Request new OTP'}
          </RsButton>
        </AuthForm>
      </Wrapper>
    </>
  );
};

export default SuccessAccountCreation;
