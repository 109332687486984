import { useEffect, useState } from 'react';
import Http from '../../../../../../../services/http';
import { Loading } from '../../../../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../../../../shared/utils/removeTrailingSlash';
import { ContactItem } from '../../../../../../emails/redux/emails.slice';
import { MailChimpList } from './useContacts';

/* eslint no-console: "warn" */

const http = new Http();

export default function useMailChimpContacts() {
  const [mailchimpListId, setMailchimpListId] = useState<string | null>(null);
  const [loadingMailchimpLists, setLoadingMailchimpLists] = useState(
    Loading.INITIAL
  );
  const [mailchimpLists, setMailchimpLists] = useState<MailChimpList[]>([]);
  const [mailchimpContacts, setMailchimpContacts] = useState<ContactItem[]>([]);
  const [loadingMailChimpContacts, setLoadingMailChimpContacts] = useState(
    Loading.INITIAL
  );
  const [mailchimpOffset, setMailchimpOffset] = useState(null);
  const [loadingInitialContacts, setLoadingInitialContacts] = useState(
    Loading.INITIAL
  );

  const onSelectMailChimpList = (listId: string) => {
    setMailchimpListId(listId);
    getMailChimpContacts(listId);
  };

  const getMailchimpLists = async () => {
    setLoadingMailchimpLists(Loading.PENDING);
    try {
      let url = `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/mailchimp/lists`;

      const response = await http.get(url);

      setMailchimpLists(response.data);
      setMailchimpContacts([]);

      setLoadingMailchimpLists(Loading.SUCCESS);
    } catch (error) {
      setLoadingMailchimpLists(Loading.ERROR);
    }
  };

  const getMailChimpContacts = async (listId: string) => {
    if (mailchimpOffset) {
      setLoadingMailChimpContacts(Loading.PENDING);
    } else {
      setLoadingInitialContacts(Loading.PENDING);
    }

    try {
      let url = `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/mailchimp/contacts/${listId}`;

      if (mailchimpOffset) {
        url += `?offset=${mailchimpOffset}`;
      }

      const response = await http.get(url);

      const { members, offset } = response.data;

      setMailchimpOffset(offset);
      setMailchimpContacts([...mailchimpContacts, ...members]);

      if (mailchimpOffset) {
        setLoadingMailChimpContacts(Loading.SUCCESS);
      } else {
        setLoadingInitialContacts(Loading.SUCCESS);
      }
    } catch (error) {
      if (mailchimpOffset) {
        setLoadingMailChimpContacts(Loading.ERROR);
      } else {
        setLoadingInitialContacts(Loading.ERROR);
      }
    }
  };

  useEffect(() => {
    setMailchimpContacts([]);
  }, []);

  return {
    mailchimpLists,
    mailchimpListId,
    getMailchimpLists,
    loadingMailchimpLists,
    onSelectMailChimpList,
    mailchimpContacts,
    loadingMailChimpContacts,
    mailchimpOffset,
    getMailChimpContacts,
    loadingInitialMailchimpContacts: loadingInitialContacts,
  };
}
