import dayjs from 'dayjs';
import styled from 'styled-components';
import FlexContainer from '../../../../../../../shared/components/layouts/flex-container';
import ReviewRating from '../../../../../../../shared/svgs/ReviewRating';
import getReviewerFullName from '../../../../../../../shared/utils/getReviewerFullName';
import { useAppSelector } from '../../../../../../../store';
import { Review as ReviewType } from '../../../../../../manage-reviews/redux/reviews.slice';
import { settingSelector } from '../../../../../../widgetSettings/redux/widgetSettings.slice';

const Wrapper = styled(FlexContainer)`
  width: 100%;
  padding: 0 20px;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
`;

const ReviewerAvatar = styled.div`
  height: 45px;
  width: 45px;
  border-radius: 50%;
  text-align: center;
  position: relative;
`;

const ReviewerPic = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
`;

const ReviewerName = styled.span<{ color: string; fontFamily: string }>`
  font-size: 15px;
  font-weight: bold;
  color: ${({ color }) => color};
  font-family: ${({ fontFamily }) => fontFamily};
  margin-bottom: 3px;
  display: block;
`;

const ReviewerDate = styled.span<{ color: string; fontFamily: string }>`
  font-size: 11px;
  color: ${({ color }) => color};
  display: block;
  font-family: ${({ fontFamily }) => fontFamily};
`;

type Props = {
  review: ReviewType;
};

export default function Review({ review }: Props) {
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { modalWidget, brandingSettings, translation } =
    siteWidgetSettings ?? {};

  let reviewDate = review.reviewDate;

  if (!modalWidget || !brandingSettings || !translation) {
    return null;
  }

  const { linkColor, starColor, dateColor } = modalWidget ?? {};

  const { useMyWebsiteFontFamily, fontFamily, showFullName, dateFormat } =
    brandingSettings;
  const { verifiedReviewer } = translation;

  const reviewerFullname = getReviewerFullName(
    showFullName,
    review.reviewer,
    verifiedReviewer
  );

  try {
    reviewDate = dayjs(review.reviewDate).format(dateFormat);

    if (reviewDate === 'Invalid Date') {
      reviewDate = review.reviewDate;
    }
  } catch (error) {
    reviewDate = review.reviewDate;
  }

  return (
    <Wrapper justifyContent="flex-start" gap="20px" alignItems="flex-start">
      <ReviewerAvatar>
        <ReviewerPic
          src={review.reviewer.avatar!}
          alt={review.reviewer.fullName}
        />
      </ReviewerAvatar>
      <div style={{ width: '400px' }}>
        <ReviewerName
          className="reviewer-name"
          color={linkColor}
          fontFamily={useMyWebsiteFontFamily ? '' : fontFamily}
        >
          {reviewerFullname}
        </ReviewerName>
        <FlexContainer justifyContent="space-between">
          <ReviewRating size={14} rating={review.rating} color={starColor} />
          <ReviewerDate
            className="reviewer-date"
            color={dateColor}
            fontFamily={useMyWebsiteFontFamily ? '' : fontFamily}
          >
            {reviewDate}
          </ReviewerDate>
        </FlexContainer>
        <p style={{ marginTop: 10, lineHeight: '25px' }}>{review.content}</p>
      </div>
    </Wrapper>
  );
}
