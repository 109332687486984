import { useState } from 'react';
import Http from '../../../../../../../services/http';
import { Loading } from '../../../../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../../../../shared/utils/removeTrailingSlash';
import { ContactItem } from '../../../../../../emails/redux/emails.slice';

/* eslint no-console: "warn" */

const http = new Http();

export default function useKlaviyoContacts() {
  const [klaviyoContacts, setKlaviyoContacts] = useState<ContactItem[]>([]);
  const [loading, setLoading] = useState(Loading.INITIAL);
  const [nextCursor, setNextCursor] = useState('');
  const [loadingInitialContacts, setLoadingInitialContacts] = useState(
    Loading.INITIAL
  );

  const getKlaviyoContacts = async () => {
    if (loading === Loading.PENDING) {
      return null;
    }

    if (nextCursor) {
      setLoading(Loading.PENDING);
    } else {
      setLoadingInitialContacts(Loading.PENDING);
    }

    try {
      let url = `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/klaviyo/contacts`;

      if (nextCursor) {
        url += `?cursor=${nextCursor}`;
      }

      const response = await http.get(url);

      const { contacts, nextCursor: _nextCursor } = response.data;

      setKlaviyoContacts([...klaviyoContacts, ...contacts]);
      setNextCursor(_nextCursor);

      if (nextCursor) {
        setLoading(Loading.SUCCESS);
      } else {
        setLoadingInitialContacts(Loading.SUCCESS);
      }
    } catch (error) {
      if (nextCursor) {
        setLoading(Loading.ERROR);
      } else {
        setLoadingInitialContacts(Loading.ERROR);
      }
    }
  };

  return {
    klaviyoContacts,
    getKlaviyoContacts,
    klaviyoNextCursor: nextCursor,
    loadingKlaviyoContacts: loading,
    loadingInitialKlaviyoContacts: loadingInitialContacts,
  };
}
