import { unwrapResult } from '@reduxjs/toolkit';
import { createRef, useState } from 'react';
import { Input, SelectPicker } from 'rsuite';
import styled, { css } from 'styled-components';
import Button from '../../../../../shared/components/Button';
import StyledText from '../../../../../shared/components/styled-text';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import { Loading } from '../../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { addTrustpilotAccount } from '../../../../socialReviews/redux/actions';
import { socialReviewsSelector } from '../../../../socialReviews/redux/social-reviews.slice';
import { VALID_TRUSTPILOT_DOMAINS } from '../../../../socialReviews/utils/constants';

const FormContainer = styled.form``;

const StyledInput = styled(Input)<{ error: boolean }>`
  background: #fff;
  margin-bottom: 20px;
  border-color: #cdcdcd;
  border-radius: 20px;
  margin-top: 5px;
  outline: none !important;
  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;

const StyledLabel = styled(StyledText)`
  font-size: 14px;
  color: #7b7b7b;
`;

const StyledPicker = styled(SelectPicker)`
  margin-bottom: 20px;
  border-color: #cdcdcd;
  margin-top: 5px;
  outline: none !important;

  .rs-picker-toggle {
    border-radius: 20px !important;
  }
`;

const SearchButton = styled(Button)`
  background-color: #000;
  color: #fff;
  border-color: #000;
  width: 100%;
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:disabled {
    background-color: #bbbbbb;
    color: #000;
    border-color: #bbbbbb;
  }
`;

type Props = {
  onNext: () => void;
};

export default function Form({ onNext }: Props) {
  const ref = createRef<HTMLInputElement>();
  const dispatch = useAppDispatch();
  const {
    get3rdPartyAccount: { data: integrations },
    addingTrustpilotAccount,
  } = useAppSelector(socialReviewsSelector);

  const [reviewUrl, setReviewUrl] = useState('');
  const { showErrorToast, showSuccessToast, showWarningToast } =
    useNotification();

  const connectTrustpilot = () => {
    if (ref.current) {
      ref.current.style.borderColor = '#cdcdcd';

      if (!reviewUrl) {
        ref.current.style.borderColor = 'red';
        return showWarningToast(
          'Please add a valid Trustpilot business page URL'
        );
      }

      try {
        const urlObject = new URL(reviewUrl);
        const trustpilotDomain = urlObject.hostname;

        if (!VALID_TRUSTPILOT_DOMAINS.includes(trustpilotDomain)) {
          ref.current.style.borderColor = 'red';
          return showWarningToast(
            'Please add a valid Trustpilot business page URL'
          );
        }

        const search = encodeURIComponent(reviewUrl);

        const alreadyAdded = (integrations?.trustpilot_reviews ?? []).some(
          (i) => i.pageId === search
        );

        if (alreadyAdded) {
          ref.current.style.borderColor = 'red';
          return showWarningToast(
            'This Trustpilot business page has already been imported'
          );
        }

        dispatch(addTrustpilotAccount({ search, connectAndGetReviews: true }))
          .then(unwrapResult)
          .then(onNext)
          .catch(() => {
            showErrorToast('Something went wrong. Please try it again.');
          });
      } catch (error) {
        ref.current.style.borderColor = 'red';
        return showWarningToast(
          'Please add a valid Trustpilot business page URL.'
        );
      }
    }
  };

  return (
    <>
      <div>
        <StyledLabel>Your Trustpilot Business Page URL</StyledLabel>

        <StyledInput
          ref={ref}
          value={reviewUrl}
          placeholder="https://www.trustpilot.com/review/reviewsjet.com"
          onChange={(url: string) => {
            setReviewUrl(url);
            if (ref.current) {
              ref.current.style.borderColor = '#cdcdcd';
            }
          }}
        />

        <SearchButton
          type="button"
          disabled={addingTrustpilotAccount === Loading.PENDING}
          onClick={connectTrustpilot}
        >
          {addingTrustpilotAccount === Loading.PENDING ? (
            <b>Please wait...</b>
          ) : (
            <b>Import reviews</b>
          )}
        </SearchButton>
      </div>
    </>
  );
}
