import { IoClose } from 'react-icons/io5';
import styled from 'styled-components';
import FlexContainer from '../../../../../../../shared/components/layouts/flex-container';
import { useAppSelector } from '../../../../../../../store';
import { settingSelector } from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import Review from './Review';
import reviews from './reviews';

const Button = styled.button`
  min-width: 200px;
  border: none;
  padding: 10px 30px;
  text-align: center;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  text-decoration: none;
  font-size: 15px;
  cursor: pointer;
  border-radius: 25px;
  line-height: 25px;
`;

const Footer = styled(FlexContainer)`
  padding: 20px 0;
`;

interface ReviewModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ReviewModal({ isOpen, onClose }: ReviewModalProps) {
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { modalWidget, brandingSettings, translation } =
    siteWidgetSettings ?? {};

  if (!modalWidget || !translation || !brandingSettings) {
    return null;
  }

  const { buttonBackgroundColor, buttonTextColor } = modalWidget;
  const { useMyWebsiteFontFamily, fontFamily } = brandingSettings;
  const {
    reviewForm: { addReview },
  } = translation;

  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <FlexContainer
            justifyContent="center"
            alignItems="center"
            gap="2px"
            style={{ fontSize: 15 }}
          >
            <span>★</span>
            <span>★</span>
            <span>★</span>
            <span>★</span>
            <span>★</span>
          </FlexContainer>
          <p style={{ fontSize: 13, marginTop: 5, textAlign: 'center' }}>
            Based on 1K reviews
          </p>
          <p
            style={{
              fontSize: 14,
              marginTop: 8,
              textAlign: 'center',
              fontWeight: 600,
            }}
          >
            What Our Customers Are Saying
          </p>
          <CloseButton onClick={onClose}>
            <IoClose size={24} />
          </CloseButton>
        </ModalHeader>

        <ModalBody>
          <FlexContainer
            stack
            gap="20px"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{
              width: '100%',
              height: 410,
              overflow: 'auto',
              paddingTop: 20,
            }}
          >
            {reviews.map((review) => (
              <Review key={review.id} review={review} />
            ))}
          </FlexContainer>

          <Footer justifyContent="center" alignItems="center">
            <Button
              style={{
                backgroundColor: buttonBackgroundColor,
                color: buttonTextColor,
                fontFamily: useMyWebsiteFontFamily ? fontFamily : 'inherit',
              }}
            >
              {addReview}
            </Button>
          </Footer>
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  );
}

const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ModalHeader = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgb(227, 227, 227);

  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
`;

const ModalBody = styled.div`
  padding: 0;
`;

const CloseButton = styled.button`
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  top: 0px;
  right: 0px;

  &:hover {
    background-color: #f5f5f5;
  }
`;
